import {
  Box,
  Center,
  Flex,
  Image,
  ListItem,
  Text,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import csv_logo from "assets/csv_icon.png";
import DashboardLayout from "components/global/Layouts/DashboardLayout";
import { SDButton, SuccessModal } from "components/global";
import { HiOutlineDownload } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { AllRoutes } from "routes/AllRoutes";

function CreateBulkOrderPage() {
  const navigate = useNavigate();
  const successModalDisclosure = useDisclosure();

  return (
    <DashboardLayout
      role="provider"
      pageTitle="Create Order"
      showBackButton={true}
      isGreyBackground
    >
      <Box maxW="container.md">
        <UnorderedList color="#656566" fontSize="18px" fontWeight={500}>
          <ListItem mb="20px">The file must be CSV.</ListItem>
          <ListItem mb="20px">
            CSV file should contain the following columns - Pick-up location, customer’s name and
            phone number, parcel details. Drop-off location, recipient’s name and phone number.
          </ListItem>
          <ListItem mb="20px">
            The order of the columns should be same as the order in which they are listed above with
            the first row as headers.
          </ListItem>
        </UnorderedList>

        <SDButton
          p={0}
          leftIcon={<HiOutlineDownload />}
          colorScheme="primary"
          color="#102471"
          variant="link"
        >
          Download a sample bulk disburse CSV file
        </SDButton>

        <Box maxW="490px">
          <Center
            h="308px"
            my="40px"
            flexDirection="column"
            rowGap="40px"
            border="1px dashed #ABA7A7"
            color="neutral.200"
            fontSize="18px"
            fontWeight="500"
          >
            <Image src={csv_logo} alt="CSV" boxSize="40px" />

            <Text>
              Drop files to attach, or{" "}
              <Text as="span" color="#102471" cursor="pointer">
                Browse
              </Text>
            </Text>
          </Center>

          <Flex justify="flex-end" align="center" gap="32px">
            <SDButton variant="outline" colorScheme="primary" onClick={() => navigate(-1)}>
              Cancel
            </SDButton>
            <SDButton colorScheme="primary" onClick={successModalDisclosure.onOpen}>
              Upload
            </SDButton>
          </Flex>
        </Box>
      </Box>

      <SuccessModal
        isOpen={successModalDisclosure.isOpen}
        onClose={successModalDisclosure.onClose}
        message="Order created."
        onBtnClick={() => navigate(AllRoutes.providerShipment)}
      />
    </DashboardLayout>
  );
}

export default CreateBulkOrderPage;
