import { Colors, extendTheme, StyleProps } from "@chakra-ui/react";
import { switchTheme } from "./switch";
import { selectTheme } from "./select";

export const colors: Colors = {
  primary: {
    50: "#DCDBEA",
    100: "#cdd0d2",
    200: "#9ba2a5",
    300: "#6a7377",
    400: "#38454a",
    500: "#06161d",
    600: "#051217",
    700: "#040d11",
    800: "#02090c",
    900: "#010406",
  },

  primary2: {
    50: "#DCDBEA",
    100: "#ddc0df",
    200: "#b29dcb",
    300: "#16134f",
    400: "#1C1950",
    500: "#070529",
    600: "#1d1045",
    700: "#040319",
    800: "#030210",
    900: "#02020C",
  },

  secondary: {
    50: "#FEF3E1",
    100: "#f9f0d6",
    200: "#f3e2ad",
    300: "#eed383",
    400: "#e8c55a",
    500: "#e2b631",
    600: "#b59227",
    700: "#886d1d",
    800: "#5a4914",
    900: "#2d240a",
  },

  secondary2: {
    50: "#FEF3E1",
    100: "#fdf1d0",
    200: "#fbe3a2",
    300: "#f9d573",
    400: "#f7c745",
    500: "#f5b916",
    600: "#c49412",
    700: "#936f0d",
    800: "#624a09",
    900: "#312504",
  },

  secondary3: {
    50: "#FEF3E1",
    100: "#FDEED1",
    200: "#FDE8C2",
    300: "#FBDCA4",
    400: "#FAD185",
    500: "#F9C567",
    600: "#F9AB21",
    700: "#D2A553",
    800: "#84642A",
    900: "#715420",
  },

  neutral: {
    50: "#D9D9D9",
    100: "#AAAAAA",
    200: "#ABA7A7",
    300: "#949492",
    400: "#767674",
    500: "#595956",
    600: "#474745",
    700: "#1F1F1F",
    800: "#242422",
    900: "#1B1B1A",
  },

  green: {
    100: "#cef3db",
    200: "#9de8b6",
    300: "#6bdc92",
    400: "#3ad16d",
    500: "#09c549",
    600: "#079e3a",
    700: "#05762c",
    800: "#044f1d",
    900: "#02270f",
  },

  success: {
    50: "#A1E8BA",
    100: "#8EDDA9",
    200: "#7BD399",
    300: "#54BF79",
    400: "#2EAA58",
    500: "#079638",
    600: "#06782D",
    700: "#045A22",
    800: "#033C16",
    900: "#022D11",
  },

  error: {
    50: "#F5CED8",
    100: "#F0B6C4",
    200: "#EB9DB0",
    300: "#E16D89",
    400: "#F32E2E",
    500: "#CD0B3A",
    600: "#A4092E",
    700: "#7B0723",
    800: "#520417",
    900: "#3E0311",
  },
};

const fonts = {
  heading: `'Gilroy', sans-serif`,
  body: `'Gilroy', sans-serif`,
};

const styles = {
  global: (props: StyleProps) => ({
    body: {
      color: "primary.500",
      fontVariant: "normal",
      fontWeight: 400,
    },
  }),
};

export const theme = extendTheme({
  colors,
  fonts,
  styles,
  components: {
    Switch: switchTheme,
    Select: selectTheme,
    Heading: {
      baseStyle: { color: "primary.500", fontVariant: "normal", fontWeight: 600 },
    },
  },
});
