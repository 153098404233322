import { Tab, TabList, TabListProps, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

interface ITabs {
  label: string;
  content: React.ReactNode;
}

interface Props {
  tabs: ITabs[];
  defaultIndex?: number;
  tabListProps?: TabListProps;
}

function SDTabs({ tabs, defaultIndex = 0, tabListProps }: Props) {
  const tabStyles = {
    borderRadius: 4,
    fontWeight: 500,
    color: "neutral.500",
    fontSize: ["sm", null, "md"],
    borderBottom: "4px solid transparent",
  };

  const hoverStyles = {
    color: "neutral.700",
  };

  const selectedStyles = {
    fontWeight: 600,
    color: "neutral.700",
    borderBottom: "4px solid #e2b631",
  };

  return (
    <Tabs defaultIndex={defaultIndex}>
      <TabList
        overflowX="auto"
        overflowY="hidden"
        gap={["6px", "6px", "18px"]}
        border="none"
        {...tabListProps}
      >
        {tabs.map((tab, key) => (
          <Tab
            key={key}
            whiteSpace={"nowrap"}
            {...tabStyles}
            _hover={hoverStyles}
            _selected={selectedStyles}
          >
            {tab.label}
          </Tab>
        ))}
      </TabList>

      <TabPanels>
        {tabs.map((tab, key) => (
          <TabPanel key={key} px={0} py={10}>
            {tab.content}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}

export default SDTabs;
