import { Divider, Flex, Text } from "@chakra-ui/react";
import { SDButton, SDModal } from "components/global";
import {
  convertMetersToKilometers,
  convertSecondsToNearestTime,
  formatAmount,
} from "utils/utilFunctions";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  estimateData: any;
}

function OrderEstimateModal({ isOpen, onClose, estimateData }: Props) {
  return (
    <SDModal isOpen={isOpen} onClose={onClose} title="Order Estimate" size="sm">
      <Flex flexDir="column" gap={8} mx={0}>
        <Flex align="center" justify="space-between" gap={4}>
          <Text fontWeight="500" color="neutral.500">
            Distance
          </Text>

          <Text fontWeight={500}>{convertMetersToKilometers(estimateData?.distance)}km</Text>
        </Flex>

        <Flex align="center" justify="space-between" gap={4}>
          <Text fontWeight="500" color="neutral.500">
            Time
          </Text>

          <Text fontWeight={500}>
            {convertSecondsToNearestTime(estimateData?.duration.slice(0, -1))}
          </Text>
        </Flex>

        <Divider />

        <Flex align="center" justify="space-between" gap={4}>
          <Text fontWeight="500" color="neutral.500">
            Order Payment
          </Text>
          <Text fontWeight={500}>{formatAmount(estimateData?.cost)}</Text>
        </Flex>

        <Flex align="center" justify="space-between" gap={4}>
          <Text fontWeight="500" color="neutral.500">
            Processing Fees
          </Text>

          <Text fontWeight={500}>{formatAmount(estimateData?.processingFee)}</Text>
        </Flex>

        <Flex align="center" justify="space-between" gap={4}>
          <Text fontWeight={700} color="neutral.500">
            Total
          </Text>

          <Text fontWeight={700}>
            {formatAmount(estimateData?.cost + estimateData?.processingFee)}
          </Text>
        </Flex>

        <SDButton colorScheme="primary" w="full" mt={8} onClick={onClose}>
          Okay
        </SDButton>
      </Flex>
    </SDModal>
  );
}

export default OrderEstimateModal;
