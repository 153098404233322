import { Text, useDisclosure } from "@chakra-ui/react";
import { ShipmentDetailsDrawer } from "components/admin/shipment";
import { SDButton, SDTable, SDTag } from "components/global";
import { useEffect, useState } from "react";
import { IShipment } from "redux/slices/adminSlices/shipmentSlice/types";
import {
  selectActiveOrderHistoryStatus,
  selectOrderHistory,
  selectOrderHistoryLoadingState,
  selectRecentOrderHistory,
} from "redux/slices/clientSlices/orderHistorySlice";
import { useAppSelector } from "redux/store";
import {
  handleClientGetOrderHistory,
  handleClientGetRecentOrderHistory,
} from "services/client/orderHistory";
import { formatDate } from "utils/utilFunctions";

interface Props {
  isForRecentOrders?: boolean;
}

function OrderHistoryTable({ isForRecentOrders = false }: Props) {
  const orderHistoryState = useAppSelector(selectOrderHistory);
  const recentOrderHistoryState = useAppSelector(selectRecentOrderHistory);
  const isOrderHistoryLoading = useAppSelector(selectOrderHistoryLoadingState);
  const activeOrderHistoryStatus = useAppSelector(selectActiveOrderHistoryStatus);

  const viewOrderDisclosure = useDisclosure();

  const [activeRecord, setActiveRecord] = useState<IShipment>(null);

  const tableHeader = [
    {
      label: "Destination",
      dataIndex: "dropOffDetails",
      render: (dropOffDetails: any) =>
        dropOffDetails?.location?.description ? (
          <Text title={dropOffDetails?.location?.description}>
            {dropOffDetails?.location?.description.length <= 30
              ? dropOffDetails?.location?.description
              : dropOffDetails?.location?.description.substring(0, 30) + "..."}
          </Text>
        ) : (
          "---"
        ),
    },
    {
      label: "Courier name",
      dataIndex: "dispatch",
      render: dispatch => dispatch?.fullName || "---",
    },
    {
      label: "Order ID",
      dataIndex: "trackingRef",
    },
    {
      label: "Date",
      dataIndex: "createdAt",
      render: (date: string) => formatDate(date),
    },
    { label: "Status", dataIndex: "shipmentStatus", render: status => <SDTag>{status}</SDTag> },
    {
      label: "View",
      dataIndex: "view",
      render: (_, record: IShipment) => (
        <SDButton
          colorScheme="primary"
          size={{ base: "sm", md: "md" }}
          onClick={() => {
            setActiveRecord(record);
            viewOrderDisclosure.onOpen();
          }}
        >
          View
        </SDButton>
      ),
    },
  ];

  async function getOrderHistory(page?: number) {
    if (isForRecentOrders) {
      await handleClientGetRecentOrderHistory();
    } else {
      await handleClientGetOrderHistory(page, null, activeOrderHistoryStatus);
    }
  }

  useEffect(() => {
    getOrderHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SDTable
        header={tableHeader}
        isLoading={isOrderHistoryLoading}
        body={isForRecentOrders ? recentOrderHistoryState.records : orderHistoryState.records}
        {...(isForRecentOrders
          ? {}
          : {
              paginationConfig: orderHistoryState.pagination,
              onPaginationChange: page => getOrderHistory(page),
            })}
      />

      <ShipmentDetailsDrawer
        isOpen={viewOrderDisclosure.isOpen}
        onClose={viewOrderDisclosure.onClose}
        shipmentToView={activeRecord}
        role="client"
      />
    </>
  );
}

export default OrderHistoryTable;
