import React, { useEffect, useState } from "react";
import { Box, Flex, Grid, Heading, Text } from "@chakra-ui/react";
import DashboardHeader from "./DashboardHeader";
import SideBar from "./SideBar";
import { IUserType } from "types/global";
import { fetchClientProfile, fetchProviderProfile } from "services/auth";
import MainLoader from "components/global/Loader/MainLoader";
import { selectClientProfile, selectProviderProfile } from "redux/slices/authSlice";
import { useAppSelector } from "redux/store";
import { fetchBankLists } from "services/admin/dashboard";
import { selectBankLists } from "redux/slices/adminSlices/dashboardSlice";
import DocumentTitleSetter from "../DocumentTitleSetter";
import { RoundedBackButton } from "components/global";
import { fetchProviderPricing } from "services/admin/settings";
import {
  selectLastMilePricing,
  selectInterStatePricing,
} from "redux/slices/adminSlices/settingsSlice";

interface Props {
  role: IUserType;
  children: React.ReactNode;
  prePageComponent?: React.ReactNode;
  pageTitle: string;
  subTitle?: string;
  documentTitle?: string;
  showBackButton?: boolean;
  onBackBtnClick?: () => void;
  isGreyBackground?: boolean;
  isFullBackground?: boolean;
}

function DashboardLayout({
  role,
  prePageComponent,
  pageTitle,
  subTitle,
  documentTitle,
  onBackBtnClick,
  showBackButton = false,
  isGreyBackground = false,
  isFullBackground = false,
  children,
}: Props) {
  const [isProfileLoading, setIsProfileLoading] = useState(true);

  const providerProfile = useAppSelector(selectProviderProfile);
  const clientProfile = useAppSelector(selectClientProfile);

  const bankLists = useAppSelector(selectBankLists);
  const lastMilePricing = useAppSelector(selectLastMilePricing);
  const interStatePricing = useAppSelector(selectInterStatePricing);

  useEffect(() => {
    async function fetchData() {
      setIsProfileLoading(true);

      if (role === "provider") {
        if (!providerProfile) await fetchProviderProfile();
        if (bankLists.length === 0) await fetchBankLists();
      }

      if (role === "client") {
        if (!clientProfile) await fetchClientProfile();
      }

      if (lastMilePricing.length === 0 && interStatePricing.length === 0)
        await fetchProviderPricing();

      setIsProfileLoading(false);
    }

    fetchData();
  }, [
    providerProfile,
    bankLists.length,
    interStatePricing.length,
    lastMilePricing.length,
    role,
    clientProfile,
  ]);

  return (
    <>
      <DocumentTitleSetter title={documentTitle || pageTitle} />

      <Grid
        gridTemplateColumns={{ base: "1fr", lg: "250px 1fr" }}
        h="100vh"
        w="100vw"
        overflow="hidden"
        bgColor={isGreyBackground ? "#F5F5F5" : "#FFFFFF"}
      >
        {/* DESKTOP SIDEBAR */}
        <Box display={{ base: "none", lg: "block" }} w="full">
          <SideBar role={role} />
        </Box>

        <Flex flexDir="column" w="full" overflowY="auto">
          <DashboardHeader role={role} />

          {isProfileLoading &&
          ((role === "provider" && !providerProfile) || (role === "client" && !clientProfile)) ? (
            <MainLoader />
          ) : (
            <Flex
              flexDir="column"
              w="full"
              flex={1}
              overflowY="auto"
              overflowX="hidden"
              {...(isFullBackground ? { p: 0 } : { pt: 8, pb: 6, mb: 6, px: { base: 6, lg: 12 } })}
            >
              <Flex flexDir="column" gap={{ base: "20px", md: "32px" }} flex={1} maxW="1860px">
                {showBackButton && (
                  <Box>
                    <RoundedBackButton onClick={onBackBtnClick} />
                  </Box>
                )}

                {!!prePageComponent && prePageComponent}

                {(!!pageTitle || !!subTitle) && (
                  <Flex gap={2} flexDir="column">
                    {!!pageTitle && <Heading fontSize="24px">{pageTitle}</Heading>}

                    {!!subTitle && (
                      <Text fontSize="16px" fontWeight={500} color="neutral.500">
                        {subTitle}
                      </Text>
                    )}
                  </Flex>
                )}

                <Box h="full" flex={1}>
                  {children}
                </Box>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Grid>
    </>
  );
}

export default DashboardLayout;
