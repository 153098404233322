import { AccountLimits } from "components/admin/dashboard";
import DashboardLayout from "components/global/Layouts/DashboardLayout";

function AccountSetupPage() {
  return (
    <DashboardLayout role="provider" pageTitle="Setup Account" showBackButton={true}>
      <AccountLimits />
    </DashboardLayout>
  );
}

export default AccountSetupPage;
