import { Avatar, Box, Flex, HStack, Icon, IconButton, Text, useDisclosure } from "@chakra-ui/react";
import DashboardLayout from "components/global/Layouts/DashboardLayout";
import {
  SDButton,
  SDInput,
  SDMenuDropdown,
  SDTable,
  SDTag,
  SuccessModal,
  WarningModal,
} from "components/global";
import { ChatIcon, PhoneIcon, Search2Icon, SearchIcon } from "@chakra-ui/icons";
import { AllRoutes } from "routes/AllRoutes";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  handleActivateCourier,
  handleDeactivateCourier,
  handleFetchCouriers,
} from "services/admin/courier";
import { useAppSelector } from "redux/store";
import { selectCouriers } from "redux/slices/adminSlices/courierSlice";
import { ICourier } from "redux/slices/adminSlices/courierSlice/types";
import { COURIER_OPTIONS } from "utils/constants";

const CourierPage = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const couriersState = useAppSelector(selectCouriers);

  const activateCourierDisclosure = useDisclosure();
  const activateCourierSuccessDisclosure = useDisclosure();
  const deactivateCourierDisclosure = useDisclosure();
  const deactivateCourierSuccessDisclosure = useDisclosure();

  const [isDeactivating, setIsDeactivating] = useState(false);
  const [isActivating, setIsActivating] = useState(false);

  const [activeRecord, setActiveRecord] = useState<ICourier | null>(null);

  const [searchValue, setSearchValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const tableHeader = [
    {
      label: "Name",
      dataIndex: "fullName",
      render: (fullName, record) => (
        <Flex align="center" gap={4}>
          <Avatar name={fullName} src={record?.profilePicture} />
          <Text fontWeight={700} color="neutral.900">
            {fullName}
          </Text>
        </Flex>
      ),
    },
    {
      label: "Region",
      dataIndex: "residentialAddress",
      render: residentialAddress => (
        <Text title={residentialAddress?.description}>
          {residentialAddress?.description.length <= 30
            ? residentialAddress?.description
            : residentialAddress?.description.substring(0, 30) + "..."}
        </Text>
      ),
    },
    {
      label: "Transport Type",
      dataIndex: "transportType",
      render: (type: string) => {
        const courierTypeInfo = COURIER_OPTIONS.find(i => i.value === type);

        if (!courierTypeInfo) return type;

        return (
          <HStack align="center">
            <Icon as={courierTypeInfo?.icon} />
            <Text>{courierTypeInfo?.label}</Text>
          </HStack>
        );
      },
    },
    // TODO: Verify the status when it is attached from the backend
    // { label: "Status", dataIndex: "status", render: status => <SDTag>assigned</SDTag> },
    {
      label: "Phone Number",
      dataIndex: "phoneNumber",
      render: phoneNumber =>
        !!phoneNumber ? (
          <Link to={`tel:${phoneNumber}`}>
            <SDButton
              p={2}
              size="xs"
              leftIcon={<PhoneIcon />}
              colorScheme="neutral"
              variant="ghost"
            >
              {phoneNumber}
            </SDButton>
          </Link>
        ) : (
          "---"
        ),
    },
    {
      label: "Message",
      dataIndex: "message",
      render: message => (
        <SDButton p={2} size="xs" leftIcon={<ChatIcon />} colorScheme="neutral" variant="ghost">
          Message
        </SDButton>
      ),
    },
    {
      label: "Status",
      dataIndex: "isActive",
      render: (isActive: boolean) => <SDTag>{isActive ? "Active" : "Deactivated"}</SDTag>,
    },
    {
      label: "Action",
      dataIndex: "action",
      render: (_, record: ICourier) => (
        <SDMenuDropdown
          menu={[
            {
              label: "View profile",
              onClick: () => navigate(AllRoutes.providerViewCourier(record?._id)),
            },
            // TODO: Modify this to be preselected on the shipment section
            // { label: "Assign to order", onClick: () => navigate(AllRoutes.providerShipment) },
            // {
            //   label: "Track courier",
            //   onClick: () => navigate(AllRoutes.providerShipmentRoute("6575e6384be68278d1852c8b")),
            // },
            // TODO: Display side drawer for this
            // { label: "View", onClick: () => {} },
            // TODO: link these two to payroll module
            // { label: "View payslip", onClick: () => {} },
            // { label: "Create payroll", onClick: () => {} },
            // TODO: Change this to Block rider
            ...(record?.isActive
              ? [
                  {
                    label: "Deactivate Courier",
                    onClick: () => {
                      setActiveRecord(record);

                      deactivateCourierDisclosure.onOpen();
                    },
                    isDanger: true,
                  },
                ]
              : [
                  {
                    label: "Activate Courier",
                    onClick: () => {
                      setActiveRecord(record);

                      activateCourierDisclosure.onOpen();
                    },
                  },
                ]),
          ]}
        />
      ),
    },
  ];

  async function deactivateCourier() {
    setIsDeactivating(true);

    await handleDeactivateCourier(activeRecord?._id, () => {
      deactivateCourierDisclosure.onClose();
      deactivateCourierSuccessDisclosure.onOpen();
    });

    setIsDeactivating(false);
  }

  async function activateCourier() {
    setIsActivating(true);

    await handleActivateCourier(activeRecord?._id, () => {
      activateCourierDisclosure.onClose();
      activateCourierSuccessDisclosure.onOpen();
    });

    setIsActivating(false);
  }

  async function handleSearch(e: any, page: number = 1) {
    e?.preventDefault();

    setIsSearching(true);

    await handleFetchCouriers(page, 20, searchValue.trim());

    setIsSearching(false);
  }

  async function fetchData(page?: number) {
    setIsLoading(true);

    await handleFetchCouriers(page);

    setIsLoading(false);
  }

  useEffect(() => {
    if (!searchValue.trim()) fetchData();
  }, [searchValue]);

  return (
    <DashboardLayout role="provider" pageTitle="Courier">
      <Flex
        flexDir={{ base: "column-reverse", sm: "row" }}
        gap={{ sm: 4 }}
        align={{ sm: "center" }}
        justify="space-between"
        my={6}
      >
        <Box maxW={{ sm: "350px" }} flex={1}>
          <form onSubmit={handleSearch} style={{ width: "100%" }}>
            <SDInput
              placeholder="Search by name or phone number"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              addOnElementAfter={
                !!searchValue.trim() ? (
                  <IconButton
                    type="submit"
                    icon={<Search2Icon w="16px" h="16px" />}
                    variant="solid"
                    colorScheme="primary"
                    aria-label="Search"
                    isLoading={isSearching}
                    borderStartRadius={0}
                    size="lg"
                  />
                ) : (
                  <SearchIcon color="neutral.200" />
                )
              }
            />
          </form>
        </Box>

        <SDButton colorScheme="primary" onClick={() => navigate(AllRoutes.providerAddCourier)}>
          Add Courier
        </SDButton>
      </Flex>

      <SDTable
        header={tableHeader}
        isLoading={isLoading}
        body={couriersState.records}
        paginationConfig={couriersState.pagination}
        onPaginationChange={page =>
          !searchValue.trim() ? fetchData(page) : handleSearch(null, page)
        }
      />

      {/* A C T I V A T I O N */}

      <WarningModal
        isOpen={activateCourierDisclosure.isOpen}
        onClose={activateCourierDisclosure.onClose}
        message="Do you want to activate this courier?"
        subMessage={activeRecord?.fullName}
        proceedBtnText="Activate"
        onBtnClick={() => activateCourier()}
        isLoading={isActivating}
      />

      <SuccessModal
        isOpen={activateCourierSuccessDisclosure.isOpen}
        onClose={activateCourierSuccessDisclosure.onClose}
        message="Courier activated."
      />

      {/* D E A C T I V A T I O N */}

      <WarningModal
        isOpen={deactivateCourierDisclosure.isOpen}
        onClose={deactivateCourierDisclosure.onClose}
        message="Do you want to deactivate this courier?"
        subMessage={activeRecord?.fullName}
        proceedBtnText="Deactivate"
        onBtnClick={() => deactivateCourier()}
        isLoading={isDeactivating}
      />

      <SuccessModal
        isOpen={deactivateCourierSuccessDisclosure.isOpen}
        onClose={deactivateCourierSuccessDisclosure.onClose}
        message="Courier deactivated."
      />
    </DashboardLayout>
  );
};

export default CourierPage;
