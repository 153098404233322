import { Flex, Text, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import { SearchIcon } from "@chakra-ui/icons";
import { PayslipModal } from ".";
import { EmptyState, GreyBox, SDButton, SDInput } from "components/global";
import SDSelect, { IOptions } from "components/global/Select";
import { PayrollHistoryIcon } from "utils/customIcons";
import { formatAmount } from "utils/utilFunctions";

export interface IPayroll {
  id: number;
  status: string;
  date: string;
  amount: number;
}

const payrolls: IPayroll[] = [
  {
    id: 1,
    status: "Transaction Successful",
    date: "18th Aug, 2023, 10:00am",
    amount: 60000,
  },
  {
    id: 2,
    status: "Transaction Successful",
    date: "22nd Aug, 2023, 06:00am",
    amount: 75000,
  },
  {
    id: 3,
    status: "Transaction Successful",
    date: "25th Aug, 2023, 10:00am",
    amount: 55000,
  },
];

const sortByOptions = [
  { label: "All", value: "All" },
  { label: "Last 30 days", value: "Last 30 days" },
  { label: "Last week", value: "Last week" },
  { label: "Last two weeks", value: "Last two weeks" },
  { label: "Last 60 days", value: "Last 60 days" },
  { label: "Custom", value: "Custom" },
];

function PayrollHistory() {
  const [sortBy, setSortBy] = useState<IOptions>(sortByOptions[0]);

  const viewPayrollDisclosure = useDisclosure();
  const [payrollToView, setPayrollToView] = useState<IPayroll>(null);

  return (
    <>
      {payrolls.length === 0 ? (
        <EmptyState message="No History to view" variant="lg" mt={10} />
      ) : (
        <>
          <Flex
            flexDir={{ base: "column", md: "row" }}
            justify="flex-end"
            align="center"
            gap={{ md: 8 }}
          >
            <SDInput
              addOnElementAfter={<SearchIcon color="gray.300" />}
              placeholder="Search by date"
              type="text"
              containerProps={{
                w: { md: "fit-content" },
              }}
            />

            <Flex align="center" gap={3}>
              <Text
                flexShrink="0"
                color="neutral.500"
                fontWeight="500"
                fontSize="14px"
                lineHeight="22px"
              >
                Sort by:
              </Text>
              <SDSelect
                id="sortBy"
                value={sortBy}
                options={sortByOptions}
                onChange={(id, value: IOptions) => setSortBy(value)}
                containerProps={{ minW: "120px" }}
              />
            </Flex>
          </Flex>

          <Flex flexDir="column" gap={4}>
            <Text fontWeight="500" fontSize="16px" lineHeight="22px" color="neutral.200">
              2023/01/01 - 2023/01/08
            </Text>

            {payrolls?.map((payroll, key) => (
              <GreyBox
                borderRadius="10px"
                py={4}
                px={[4, 6, 8]}
                key={key}
                onClick={() => {
                  setPayrollToView(payroll);
                  viewPayrollDisclosure.onOpen();
                }}
              >
                <Flex gap={4} align="center">
                  <PayrollHistoryIcon w={{ base: 6, md: 12 }} h={{ base: 6, md: 12 }} />

                  <Flex
                    flex={1}
                    gap={{ md: 4 }}
                    flexDir={{ base: "column", md: "row" }}
                    align={{ md: "center" }}
                  >
                    <Flex flex={1} flexDir="column">
                      <Text
                        color="neutral.700"
                        fontWeight="600"
                        fontSize={{ base: "14px", md: "18px" }}
                        lineHeight="29px"
                      >
                        {payroll?.status}
                      </Text>
                      <Text
                        color="neutral.500"
                        fontWeight="500"
                        fontSize={{ base: "14px", md: "16px" }}
                        lineHeight="29px"
                      >
                        {payroll?.date}
                      </Text>
                    </Flex>

                    <Text
                      color="neutral.700"
                      fontWeight="600"
                      fontSize={{ base: "16px", md: "18px" }}
                      lineHeight="29px"
                    >
                      {formatAmount(payroll?.amount)}
                    </Text>

                    <SDButton
                      display={{ base: "none", md: "flex" }}
                      variant="ghost"
                      colorScheme="primary"
                      size="sm"
                      p={4}
                      color="#102471"
                      onClick={() => {
                        setPayrollToView(payroll);
                        viewPayrollDisclosure.onOpen();
                      }}
                    >
                      View
                    </SDButton>
                  </Flex>
                </Flex>
              </GreyBox>
            ))}
          </Flex>

          <PayslipModal
            isOpen={viewPayrollDisclosure.isOpen}
            onClose={viewPayrollDisclosure.onClose}
            payrollToView={payrollToView}
          />
        </>
      )}
    </>
  );
}

export default PayrollHistory;
