import { handleError } from "services";
import apiClient from "services/axios";
import {
  IAccountDetails,
  IPricingDetails,
  IAutoAssignDetails,
  IFeeBearerDetails,
  IPricingDetailsV2,
  IResolveAccount,
  IBusinessDetails,
  IProfileDetails,
  IChangePasswordDetails,
  IPaymentOnDeliveryDetails,
} from "./types";
import { fetchProviderProfile } from "services/auth";
import { savePricing } from "redux/slices/adminSlices/settingsSlice";
import { store } from "redux/store";
import { setUpQuery } from "utils/utilFunctions";

export const handleUpdateProfile = async (data: IProfileDetails, onSuccess: () => void) => {
  try {
    const res = await apiClient.patch(`provider/profile-info`, data);

    fetchProviderProfile();

    onSuccess();

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleUpdateBusinessInfo = async (data: IBusinessDetails, onSuccess: () => void) => {
  try {
    const res = await apiClient.patch(`provider/update-business-info`, data);

    fetchProviderProfile();

    onSuccess();

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleProviderUpdateAccountDetails = async (
  data: IAccountDetails,
  onSuccess: () => void
) => {
  try {
    const res = await apiClient.patch(`provider/update-bank-info`, data);

    fetchProviderProfile();

    onSuccess();

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleResolveAccount = async (
  data: IResolveAccount,
  onSuccess: (accountName: string) => void
) => {
  try {
    const res = await apiClient.post(`banking/account/resolve`, data);

    onSuccess(res?.data?.account_name);

    return res;
  } catch (error) {
    handleError("Account not found");
  }
};

export const handleProviderChangePassword = async (
  data: IChangePasswordDetails,
  onSuccess: () => void
) => {
  try {
    const res = await apiClient.patch(`provider/change-password`, data);

    onSuccess();

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleProviderUpdatePricingSettings = async (
  data: IPricingDetails,
  onSuccess: () => void
) => {
  try {
    const res = await apiClient.patch(`provider/update-pricing`, data);

    fetchProviderProfile();

    onSuccess();

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleProviderUpdateAutoAssign = async (
  data: IAutoAssignDetails,
  onSuccess: () => void
) => {
  try {
    const res = await apiClient.patch(`provider/update-assign-auto`, data);

    fetchProviderProfile();

    onSuccess();

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleProviderUpdatePaymentOnDelivery = async (
  data: IPaymentOnDeliveryDetails,
  onSuccess: () => void
) => {
  try {
    const res = await apiClient.patch(`provider/update-pay-on-delivery`, data);

    fetchProviderProfile();

    onSuccess();

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleProviderUpdateFeeBearer = async (
  data: IFeeBearerDetails,
  onSuccess: () => void
) => {
  try {
    const res = await apiClient.patch(`provider/update-bearer-fee`, data);

    fetchProviderProfile();

    onSuccess();

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const fetchProviderPricing = async () => {
  try {
    const res = await apiClient.get(`pricing`);

    store.dispatch(savePricing(res?.data));

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleProviderAddPricing = async (data: IPricingDetailsV2, onSuccess: () => void) => {
  try {
    const res = await apiClient.post(`pricing`, data);

    fetchProviderPricing();

    onSuccess();

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleProviderUpdatePricing = async (
  data: IPricingDetailsV2,
  pricingId: string,
  onSuccess: () => void
) => {
  try {
    const res = await apiClient.patch(`pricing/${pricingId}`, data);

    fetchProviderPricing();

    onSuccess();

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleProviderUpdatePricingStatus = async (
  pricingId: string,
  status: boolean,
  onSuccess: () => void
) => {
  try {
    const query = setUpQuery({
      isActive: status,
    });

    const res = await apiClient.patch(`pricing/${pricingId}/availability` + query);

    fetchProviderPricing();

    onSuccess();

    return res;
  } catch (error) {
    handleError(error);
  }
};
