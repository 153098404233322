import { Flex, FlexProps, Icon, Text } from "@chakra-ui/react";
import { BsExclamationCircle } from "react-icons/bs";

interface Props extends FlexProps {
  error: any;
}

function SDInputErrorMessage({ error, ...rest }: Props) {
  if (!error) return <></>;

  return (
    <Flex gap={1} align="center" color="error.500" fontSize={["xs", null, "sm"]} {...rest}>
      <Icon as={BsExclamationCircle} />
      <Text as="span">{error}</Text>
    </Flex>
  );
}

export default SDInputErrorMessage;
