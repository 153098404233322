import { Flex, RadioGroup, Radio, Text, Heading, Box, useDisclosure } from "@chakra-ui/react";
import { MainLoader, SuccessModal } from "components/global";
import React, { useState, useMemo } from "react";
import { selectProviderProfile } from "redux/slices/authSlice";
import { useAppSelector } from "redux/store";
import { handleProviderUpdateFeeBearer } from "services/admin/settings";

function FeeBearerSettings() {
  const providerProfile = useAppSelector(selectProviderProfile);

  const customerBearsFee = useMemo(
    () => providerProfile?.preference?.customerBearsFee,
    [providerProfile?.preference?.customerBearsFee]
  );

  const [isLoading, setIsLoading] = useState(false);

  const successDisclosure = useDisclosure();

  async function handleUpdate(value: string) {
    setIsLoading(true);

    const data = { customerBearsFee: value === "customer" };

    await handleProviderUpdateFeeBearer(data, () => {
      successDisclosure.onOpen();
    });

    setIsLoading(false);
  }

  return (
    <>
      {isLoading && <MainLoader />}

      <Box>
        <Heading color="neutral.500" fontWeight={600} fontSize="20px" mb={8}>
          Who should be responsible for order processing fees?
        </Heading>

        <Text color="#4A5568" fontWeight="450" fontSize="18px" lineHeight="17px" mb={4}>
          Select an option
        </Text>

        <RadioGroup
          onChange={e => handleUpdate(e)}
          value={customerBearsFee ? "customer" : "me"}
          colorScheme="primary"
        >
          <Flex gap={4} flexDir="column">
            <Radio value="me">
              <Text fontWeight={500} color="#4A5568">
                Me
              </Text>
            </Radio>
            <Radio value="customer">
              <Text fontWeight={500} color="#4A5568">
                My customers
              </Text>
            </Radio>
          </Flex>
        </RadioGroup>
      </Box>

      <SuccessModal
        isOpen={successDisclosure.isOpen}
        onClose={successDisclosure.onClose}
        message="Changes applied."
      />
    </>
  );
}

export default FeeBearerSettings;
