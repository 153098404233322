import { Flex } from "@chakra-ui/react";
import { OrderHistoryFilters, OrderHistoryTable } from "components/client/orderHistory";
import DashboardLayout from "components/global/Layouts/DashboardLayout";

function ClientOrderHistoryPage() {
  return (
    <DashboardLayout role="client" pageTitle="Order History">
      <Flex flexDir="column" gap="32px">
        <OrderHistoryFilters />

        <OrderHistoryTable />
      </Flex>
    </DashboardLayout>
  );
}

export default ClientOrderHistoryPage;
