import { Heading, useDisclosure } from "@chakra-ui/react";
import { SDButton, SDInput, SuccessModal, WhiteBox } from "components/global";
import { useFormik } from "formik";
import { handleProviderChangePassword } from "services/admin/settings";
import validationSchema from "utils/validationSchema";

interface IFormValues {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export const ChangePassword = () => {
  const successDisclosure = useDisclosure();

  const { values, errors, touched, handleBlur, handleChange, isSubmitting, handleSubmit } =
    useFormik({
      initialValues: {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      validationSchema: validationSchema.providerChangePassword,
      onSubmit: async (values: IFormValues) => {
        const data = {
          current: values.currentPassword,
          new: values.newPassword,
        };

        await handleProviderChangePassword(data, () => {
          successDisclosure.onOpen();
        });
      },
    });

  return (
    <>
      <WhiteBox maxW="500px" mx="auto">
        <Heading color="neutral.500" fontWeight={600} fontSize="20px" mb={8}>
          Change Password
        </Heading>

        <form onSubmit={handleSubmit} onBlur={handleBlur}>
          <SDInput
            id="currentPassword"
            type="password"
            label="Current Password"
            placeholder="••••••••••••••••"
            value={values.currentPassword}
            onChange={handleChange}
            error={touched.currentPassword && errors.currentPassword}
          />

          <SDInput
            id="newPassword"
            type="password"
            label="New Password"
            placeholder="••••••••••••••••"
            value={values.newPassword}
            onChange={handleChange}
            error={touched.newPassword && errors.newPassword}
          />

          <SDInput
            id="confirmNewPassword"
            type="password"
            label="Confirm New Password"
            placeholder="••••••••••••••••"
            value={values.confirmNewPassword}
            onChange={handleChange}
            error={touched.confirmNewPassword && errors.confirmNewPassword}
          />

          <SDButton mt="48px" colorScheme="primary" type="submit" isLoading={isSubmitting}>
            Save Changes
          </SDButton>
        </form>
      </WhiteBox>

      <SuccessModal
        isOpen={successDisclosure.isOpen}
        onClose={successDisclosure.onClose}
        message="Password changed successfully."
      />
    </>
  );
};
