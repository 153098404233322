import { Flex, Box, Heading, useDisclosure } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

import {
  EmptyState,
  MainLoader,
  SDButton,
  SuccessModal,
  WarningModal,
  WhiteBox,
} from "components/global";
import { Form, Formik } from "formik";
import DashboardLayout from "components/global/Layouts/DashboardLayout";
import { useParams } from "react-router-dom";
import { COURIER_TYPE_OPTIONS, GENDER_TYPE_OPTIONS } from "utils/constants";
import {
  handleActivateCourier,
  handleDeactivateCourier,
  handleFetchSingleCourier,
} from "services/admin/courier";
import { ICourier } from "redux/slices/adminSlices/courierSlice/types";
import { IUploadValue } from "components/global/Upload/FileUploadWrapper";
import { IGoogleAddress } from "types/global";
import { CourierForm } from "components/admin/courier";

interface IFormValues {
  profilePic: IUploadValue;
  name: string;
  courierType: { label: string | JSX.Element; value: string };
  phoneNumber: string;
  email: string;
  gender: { label: string; value: string };
  address: IGoogleAddress;
  username: string;
}

const ViewCourierPage = () => {
  const params = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [courierToView, setCourierToView] = useState<ICourier>(null);

  const activateCourierDisclosure = useDisclosure();
  const activateCourierSuccessDisclosure = useDisclosure();
  const deactivateCourierDisclosure = useDisclosure();
  const deactivateCourierSuccessDisclosure = useDisclosure();

  const [isDeactivating, setIsDeactivating] = useState(false);
  const [isActivating, setIsActivating] = useState(false);

  async function handleSubmit(values: IFormValues) {
    console.log(values);
  }

  async function deactivateCourier() {
    setIsDeactivating(true);

    await handleDeactivateCourier(courierToView?._id, () => {
      fetchData(true);

      deactivateCourierDisclosure.onClose();
      deactivateCourierSuccessDisclosure.onOpen();
    });

    setIsDeactivating(false);
  }

  async function activateCourier() {
    setIsActivating(true);

    await handleActivateCourier(courierToView?._id, () => {
      fetchData(true);

      activateCourierDisclosure.onClose();
      activateCourierSuccessDisclosure.onOpen();
    });

    setIsActivating(false);
  }

  async function fetchData(dontReload: boolean) {
    if (!dontReload) setIsLoading(true);

    const res = await handleFetchSingleCourier(params?.id);
    if (res?.data) setCourierToView(res?.data);

    setIsLoading(false);
  }

  useEffect(() => {
    if (params?.id) fetchData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  return (
    <>
      <DashboardLayout
        role="provider"
        pageTitle="Courier Profile"
        isGreyBackground={true}
        showBackButton={true}
      >
        <WhiteBox>
          {isLoading ? (
            <MainLoader />
          ) : !courierToView || !params?.id ? (
            <EmptyState message="Courier not found" />
          ) : (
            <Box maxW="1000px" mx="auto">
              <Heading color="neutral.500" fontWeight={600} fontSize="20px" mb={8}>
                Personal Information
              </Heading>

              <Formik
                initialValues={{
                  profilePic: courierToView?.profilePicture
                    ? {
                        filename: courierToView?._id, // This is used to trigger an auto update when the name changes
                        url: courierToView?.profilePicture,
                      }
                    : null,
                  name: courierToView?.fullName,
                  courierType: COURIER_TYPE_OPTIONS.find(
                    item => item?.value === courierToView?.transportType
                  ),
                  phoneNumber: courierToView?.phoneNumber,
                  email: courierToView?.email,
                  gender: GENDER_TYPE_OPTIONS.find(item => item?.value === courierToView?.gender),
                  address: {
                    label: courierToView?.residentialAddress?.description,
                    value: {
                      description: courierToView?.residentialAddress?.description,
                      place_id: courierToView?.residentialAddress?.placeId,
                    },
                  },
                  username: courierToView?.userName,
                }}
                // validationSchema={validationSchema.providerBusinessInfoSignup}
                onSubmit={values => handleSubmit(values)}
              >
                <Form>
                  <CourierForm isFormForEdit={true} courierToView={courierToView} />

                  <Flex
                    mt="48px"
                    gap={4}
                    align={{ md: "center" }}
                    justify="space-between"
                    flexDir={{ base: "column", md: "row" }}
                  >
                    {courierToView?.isActive ? (
                      <SDButton
                        colorScheme="error"
                        onClick={() => deactivateCourierDisclosure.onOpen()}
                      >
                        Deactivate Courier
                      </SDButton>
                    ) : (
                      <SDButton
                        colorScheme="success"
                        onClick={() => activateCourierDisclosure.onOpen()}
                      >
                        Activate Courier
                      </SDButton>
                    )}
                  </Flex>
                </Form>
              </Formik>
            </Box>
          )}
        </WhiteBox>
      </DashboardLayout>

      {/* A C T I V A T I O N */}

      <WarningModal
        isOpen={activateCourierDisclosure.isOpen}
        onClose={activateCourierDisclosure.onClose}
        message="Do you want to activate this courier?"
        subMessage={courierToView?.fullName}
        proceedBtnText="Activate"
        onBtnClick={() => activateCourier()}
        isLoading={isActivating}
      />

      <SuccessModal
        isOpen={activateCourierSuccessDisclosure.isOpen}
        onClose={activateCourierSuccessDisclosure.onClose}
        message="Courier activated."
      />

      {/* D E A C T I V A T I O N */}

      <WarningModal
        isOpen={deactivateCourierDisclosure.isOpen}
        onClose={deactivateCourierDisclosure.onClose}
        message="Do you want to deactivate this courier?"
        subMessage={courierToView?.fullName}
        proceedBtnText="Deactivate"
        onBtnClick={() => deactivateCourier()}
        isLoading={isDeactivating}
      />

      <SuccessModal
        isOpen={deactivateCourierSuccessDisclosure.isOpen}
        onClose={deactivateCourierSuccessDisclosure.onClose}
        message="Courier deactivated."
      />
    </>
  );
};

export default ViewCourierPage;
