import { Flex, FlexProps, FormHelperText, Icon, Text, TextProps } from "@chakra-ui/react";
import { BsInfoCircleFill } from "react-icons/bs";

interface Props extends FlexProps {
  helperText: React.ReactNode;
  textProps?: TextProps;
}

function SDInputHelperText({ helperText, textProps, ...rest }: Props) {
  return (
    <Flex as={FormHelperText} gap={1} align="center" fontSize={["xs", null, "sm"]} {...rest}>
      <Icon as={BsInfoCircleFill} />
      <Text as="span" {...textProps}>
        {helperText}
      </Text>
    </Flex>
  );
}

export default SDInputHelperText;
