import React, { useEffect, useMemo } from "react";
import * as fileStack from "filestack-react";
import { handleError } from "services";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onUploadComplete: (url: string, filename: string) => void;
  supportedFileTypes?: string[];
};

const FileStack = ({ isOpen, onClose, onUploadComplete, supportedFileTypes }: Props) => {
  const client = useMemo(() => fileStack.client, []);
  const FILE_STACK_KEY = process.env.REACT_APP_FILESTACK_KEY;

  const fileStackPickerOptions = {
    fromSources: ["local_file_system", "googledrive", "googlephotos"],
    accept: supportedFileTypes || ["image/*"],
    maxFiles: 1,
  };

  const fileStackss = client.init(FILE_STACK_KEY, {
    ...fileStackPickerOptions,
  });

  const pageImagePicker = fileStackss.picker({
    ...fileStackPickerOptions,
    onUploadDone: (res: any) => {
      if (res?.filesUploaded.length > 0) {
        onUploadComplete(res?.filesUploaded?.[0]?.url, res?.filesUploaded?.[0]?.filename);
        onClose();
      }
    },
    onFileUploadFailed: (res: any) => {
      handleError("Upload failed!");
    },
    onCancel: () => {
      onClose();
    },
    onClose: () => {
      onClose();
    },
  });

  useEffect(() => {
    if (isOpen) {
      pageImagePicker.open();
    }
  }, [pageImagePicker, isOpen]);

  return <></>;
};

export default FileStack;
