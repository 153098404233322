import { handleError } from "services";
import apiClient from "services/axios";
import {
  saveRecentShipments,
  saveShipments,
  saveShipmentsLoadingState,
  saveShipmentStats,
} from "redux/slices/adminSlices/shipmentSlice";
import { store } from "redux/store";
import { IShipmentDetails, IShipmentFeeDetails, IStatsFilter } from "./types";
import { setUpQuery } from "utils/utilFunctions";
import { IShipment } from "redux/slices/adminSlices/shipmentSlice/types";
import { IUserType } from "types/global";
import {
  handleClientGetOrderHistory,
  handleClientGetRecentOrderHistory,
} from "services/client/orderHistory";

const { dispatch, getState } = store;

export const handleGetShipmentFee = async (
  data: IShipmentFeeDetails,
  pricingId: string,
  onSuccess: (res: any) => void
) => {
  try {
    const res = await apiClient.post(`provider/shipment/v2/cost/${pricingId}`, data);

    onSuccess(res);

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleProviderCreateSingleOrder = async (
  data: IShipmentDetails,
  billingId: string,
  onSuccess: (res: any) => void
) => {
  try {
    const res = await apiClient.post(`provider/shipment/v2/create/${billingId}`, data);

    onSuccess(res);

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleProviderGetRecentShipments = async () => {
  try {
    dispatch(saveShipmentsLoadingState(true));

    const query = setUpQuery({
      page: 1,
      size: 10,
      status: "Pending",
    });

    const res = await apiClient.get(`provider/shipments` + query);

    dispatch(saveRecentShipments(res?.data));

    return res;
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(saveShipmentsLoadingState(false));
  }
};

export const handleProviderGetShipments = async (page?: number, size?: number, status?: string) => {
  try {
    dispatch(saveShipmentsLoadingState(true));

    const pageFromStore = getState().shipmentReducer.shipments?.pagination?.page;
    const pageToLoad = page || pageFromStore || 1;

    const pageSizeFromStore = getState().shipmentReducer.shipments?.pagination?.size;
    const pageSizeToLoad = size || pageSizeFromStore || 20;

    const statusFromStore = getState().shipmentReducer.activeShipmentStatus;
    const statusToLoad = status || statusFromStore || "";

    const query = setUpQuery({
      page: pageToLoad,
      size: pageSizeToLoad,
      status: statusToLoad,
    });

    const res = await apiClient.get(`provider/shipments` + query);

    dispatch(saveShipments(res?.data));

    return res;
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(saveShipmentsLoadingState(false));
  }
};

export const handleProviderFetchSingleShipment = async (id: string) => {
  try {
    const res = await apiClient.get(`provider/shipments/${id}`);

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleProviderGetShipmentByRef = async (
  trackingRef: string,
  onSuccess: (res: IShipment) => void
) => {
  try {
    const res = await apiClient.get(`provider/shipment/track/${trackingRef}`);

    onSuccess(res?.data);

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleProviderGetShipmentStats = async (filter?: IStatsFilter) => {
  try {
    const query = setUpQuery({ ...filter });

    const res = await apiClient.get(`provider/shipments/stats` + query);

    dispatch(saveShipmentStats(res?.data));

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleProviderAssignOrder = async (
  orderId: string,
  courierId: string,
  onSuccess: () => void
) => {
  try {
    const res = await apiClient.patch(`provider/shipment/assign/${orderId}/${courierId}`);

    handleProviderGetShipments();
    // handleProviderGetRecentShipments();
    handleProviderGetShipmentStats();

    onSuccess();

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleGetMapRoute = async (
  pickupCoordinates: { longitude: number; latitude: number },
  dropoffCoordinates: { longitude: number; latitude: number }
) => {
  try {
    const query = setUpQuery({
      steps: true,
      geometries: "geojson",
      access_token: process.env.REACT_APP_MAP_TOKEN,
    });

    const res = await apiClient.get(
      `https://api.mapbox.com/directions/v5/mapbox/driving/${pickupCoordinates?.longitude},${pickupCoordinates?.latitude};${dropoffCoordinates?.longitude},${dropoffCoordinates?.latitude}` +
        query
    );

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleInitiateOrderPayment = async (orderId: string, callbackUrl: string) => {
  try {
    const res = await apiClient.post(`/settlement/shipment/checkout/${orderId}`, {
      callback: callbackUrl,
    });

    window.location.href = res?.data?.authorizationUrl;

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleProviderCancelOrder = async (
  orderId: string,
  role: IUserType,
  onSuccess: () => void
) => {
  try {
    const res = await apiClient.patch(`provider/shipment/cancel/${orderId}`);

    onSuccess();

    if (role === "provider") {
      handleProviderGetShipments();
      // handleProviderGetRecentShipments();
      handleProviderGetShipmentStats();
    } else {
      handleClientGetOrderHistory();
      handleClientGetRecentOrderHistory();
    }

    return res;
  } catch (error) {
    handleError(error);
  }
};
