import { ComponentWithAs, IconProps } from "@chakra-ui/react";
import {
  SettingsIcon,
  DashboardIcon,
  ShipmentIcon,
  CustomerIcon,
  CourierIcon,
  PayrollIcon,
  TransactionIcon,
  LogoutIcon,
  ProfileIcon,
  RequestIcon,
  EstimateIcon,
  CourierFilledIcon,
  CustomerFilledIcon,
  DashboardFilledIcon,
  EstimateFilledIcon,
  LogoutFilledIcon,
  PayrollFilledIcon,
  ProfileFilledIcon,
  RequestFilledIcon,
  SettingsFilledIcon,
  ShipmentFilledIcon,
  TransactionFilledIcon,
  RecentActivitiesFilledIcon,
  RecentActivitiesIcon,
} from "utils/customIcons";
import { AllRoutes } from "routes/AllRoutes";
import { isRideHailingTenant } from "utils/utilFunctions";

export interface ISideBarRoute {
  icon: ComponentWithAs<"svg", IconProps>;
  filledIcon: ComponentWithAs<"svg", IconProps>;
  text: string;
  href?: string;
  subHref?: string;
  isForLogout?: boolean;
  isComingSoon?: boolean;
}

export const ProviderSidebarRoutes: ISideBarRoute[] = [
  {
    icon: DashboardIcon,
    filledIcon: DashboardFilledIcon,
    text: "Dashboard",
    href: AllRoutes.providerDashboard,
  },
  {
    icon: ShipmentIcon,
    filledIcon: ShipmentFilledIcon,
    text: "Shipment",
    href: AllRoutes.providerShipment,
  },
  {
    icon: CustomerIcon,
    filledIcon: CustomerFilledIcon,
    text: "Customer",
    href: AllRoutes.providerCustomer,
  },
  {
    icon: CourierIcon,
    filledIcon: CourierFilledIcon,
    text: "Courier",
    href: AllRoutes.providerCourier,
  },
  {
    icon: PayrollIcon,
    filledIcon: PayrollFilledIcon,
    text: "Payroll",
    href: AllRoutes.providerPayroll,
    isComingSoon: true,
  },
  {
    icon: TransactionIcon,
    filledIcon: TransactionFilledIcon,
    text: "Transaction",
    href: AllRoutes.providerTransactions,
  },

  {
    icon: SettingsIcon,
    filledIcon: SettingsFilledIcon,
    text: "Settings",
    href: AllRoutes.providerSettings,
  },
  {
    icon: LogoutIcon,
    filledIcon: LogoutFilledIcon,
    text: "Logout",
    isForLogout: true,
  },
];

export const clientSidebarRoutes: ISideBarRoute[] = [
  {
    icon: DashboardIcon,
    filledIcon: DashboardFilledIcon,
    text: isRideHailingTenant() ? "Book a Ride" : "Dashboard",
    href: AllRoutes.clientDashboard,
    subHref: AllRoutes.clientEntryPoint,
  },
  {
    icon: EstimateIcon,
    filledIcon: EstimateFilledIcon,
    text: "Estimate",
    href: AllRoutes.clientEstimate,
  },
  ...(isRideHailingTenant()
    ? [
        {
          icon: RecentActivitiesIcon,
          filledIcon: RecentActivitiesFilledIcon,
          text: "Recent Activities",
          href: AllRoutes.clientRecentActivities,
        },
      ]
    : [
        {
          icon: RequestIcon,
          filledIcon: RequestFilledIcon,
          text: "Order History",
          href: AllRoutes.clientOrderHistory,
        },
      ]),
  {
    icon: ProfileIcon,
    filledIcon: ProfileFilledIcon,
    text: "Profile",
    href: AllRoutes.clientProfile,
  },
  {
    icon: LogoutIcon,
    filledIcon: LogoutFilledIcon,
    text: "Logout",
    isForLogout: true,
  },
];

export const clientOfflineSidebarRoutes: ISideBarRoute[] = [
  {
    icon: RequestIcon,
    filledIcon: RequestFilledIcon,
    text: isRideHailingTenant() ? "Book a Ride" : "Create Order",
    href: AllRoutes.clientEntryPoint,
    subHref: AllRoutes.clientDashboard,
  },
];
