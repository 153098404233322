import { RequestFilledIcon } from "utils/customIcons";
import { SDVerticalStepper } from "components/global";
import { IShipment } from "redux/slices/adminSlices/shipmentSlice/types";
import { formatDate } from "utils/utilFunctions";
import { useMemo } from "react";

interface Props {
  shipmentToView: IShipment;
}

function RouteDetails({ shipmentToView }: Props) {
  const steps = useMemo(
    () =>
      shipmentToView?.logs.map(item => ({
        title: item?.action,
        description: formatDate(item?.time),
        icon: RequestFilledIcon,
      })),
    [shipmentToView?.logs]
  );

  return <SDVerticalStepper steps={steps} />;
}

export default RouteDetails;
