import { handleClientGoogleLogin, handleClientLogin } from "services/auth";
import { SDButton, SDInput } from "components/global";
import AuthLayout from "components/global/Layouts/AuthLayout";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { AllRoutes } from "routes/AllRoutes";
import validationSchema from "utils/validationSchema";
import { useState } from "react";
import { Divider, Flex, Text } from "@chakra-ui/react";
import { GoogleIcon } from "utils/customIcons";

interface IFormValues {
  email: string;
}

function ClientLoginPage() {
  const navigate = useNavigate();

  const [isLoadingGoogleSignIn, setIsLoadingGoogleSignIn] = useState(false);

  const { values, errors, touched, handleBlur, handleChange, isSubmitting, handleSubmit } =
    useFormik({
      initialValues: { email: "" },
      validationSchema: validationSchema.clientLogin,
      onSubmit: async (values: IFormValues) => {
        const data = {
          identifier: values.email,
        };

        await handleClientLogin(data, () => {
          navigate(AllRoutes.verifyEmail(values.email, "client-login"));
        });
      },
    });

  const handleGoogleLogin = async () => {
    setIsLoadingGoogleSignIn(true);

    await handleClientGoogleLogin(() => navigate(AllRoutes.clientDashboard));

    setIsLoadingGoogleSignIn(false);
  };

  return (
    <AuthLayout
      role="client"
      title="Get Started"
      description="Experience a faster, easier and more reliable way to move items from one location to another on Sendrail"
    >
      <form onSubmit={handleSubmit} onBlur={handleBlur}>
        <SDInput
          variant="grey"
          id="email"
          type="email"
          label="Email Address"
          placeholder="example@gmail.com"
          value={values.email}
          onChange={handleChange}
          error={touched.email && errors.email}
          helperText="An OTP will be sent to your email address"
        />

        <SDButton colorScheme="primary" mt={10} w="full" type="submit" isLoading={isSubmitting}>
          Proceed
        </SDButton>

        <Flex my={6} gap="27px" align="center">
          <Divider flex={1} />
          <Text>OR</Text>
          <Divider flex={1} />
        </Flex>

        <SDButton
          colorScheme="primary"
          w="full"
          variant="outline"
          border="1px solid #DCDBEA"
          leftIcon={<GoogleIcon />}
          onClick={handleGoogleLogin}
          isLoading={isLoadingGoogleSignIn}
        >
          Continue with google
        </SDButton>
      </form>
    </AuthLayout>
  );
}

export default ClientLoginPage;
