import authBg from "assets/svg/AuthBg.svg";
import blackLogo from "assets/logo/black-logo.png";
import smallBlackLogo from "assets/logo/s-black-logo.png";
import { Box, Text, Flex, Center, Heading, Image, BoxProps } from "@chakra-ui/react";
import { WhiteBox } from "components/global";
import { Link } from "react-router-dom";
import { AllRoutes } from "routes/AllRoutes";
import DocumentTitleSetter from "../DocumentTitleSetter";
import { IUserType } from "types/global";

interface Props extends BoxProps {
  role?: IUserType;
  children: React.ReactNode;
  title?: string;
  documentTitle?: string;
  description?: string;
  showSmallLogo?: boolean;
  topComponent?: React.ReactNode;
}

function AuthLayout({
  role = "provider",
  title,
  documentTitle,
  description,
  topComponent,
  showSmallLogo = true,
  children,
  ...rest
}: Props) {
  return (
    <Box pos="relative">
      <DocumentTitleSetter title={documentTitle || title} />

      {/* Do not remove */}
      <Box id="authTop" pos="absolute" top={0} zIndex={99999} />

      <Flex
        flexDir="column"
        gap={12}
        minH="100vh"
        w="100vw"
        bgColor="#e2b631"
        bgImage={authBg}
        bgRepeat="no-repeat"
        bgSize={["auto", "cover"]}
        bgPosition="center"
        py={[8, 8, 16]}
        px={[4, 8, 16]}
      >
        <Box w="fit-content">
          <Link to={role === "client" ? AllRoutes.clientLogin : AllRoutes.providerLogin}>
            <Image src={blackLogo} alt="Sendrail" maxW="128px" />
          </Link>
        </Box>

        <Center flex={1}>
          <WhiteBox px={[6, 6, 16, 20]} maxW="620px" w="full" mx="auto" {...rest}>
            {!!topComponent && topComponent}

            {(showSmallLogo || title || description) && (
              <Center flexDir="column" gap={4} mb={12}>
                {showSmallLogo && <Image src={smallBlackLogo} alt="Sendrail" maxW="30px" />}

                {title && <Heading fontSize="24px">{title}</Heading>}

                {description && (
                  <Text
                    maxW="450px"
                    textAlign="center"
                    color="neutral.500"
                    fontSize={{ base: "sm", md: "md" }}
                  >
                    {description}
                  </Text>
                )}
              </Center>
            )}

            <Box pb={10}>{children}</Box>
          </WhiteBox>
        </Center>
      </Flex>
    </Box>
  );
}

export default AuthLayout;
