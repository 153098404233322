import { handleError } from "services";
import apiClient from "services/axios";
import { store } from "redux/store";
import { setUpQuery } from "utils/utilFunctions";
import {
  saveIsTransactionsLoading,
  saveTransactions,
} from "redux/slices/adminSlices/transactionsSlice";

const { dispatch } = store;

export const handleProviderGetTransactions = async (page: number = 1, size: number = 20) => {
  try {
    dispatch(saveIsTransactionsLoading(true));

    const query = setUpQuery({
      page: page,
      size: size,
    });

    const res = await apiClient.get(`/settlement/provider/payments` + query);

    dispatch(saveTransactions(res?.data));

    return res;
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(saveIsTransactionsLoading(false));
  }
};
