import CurrencyFormat from "react-currency-format";
import { SDInput } from "..";
import { BoxProps } from "@chakra-ui/react";

interface Props {
  id?: string;
  name?: string;
  value: string;
  prefix?: string;
  label?: string;
  placeholder?: string;
  error?: any;
  hidePrefix?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  addOnBefore?: React.ReactNode;
  addOnAfter?: React.ReactNode;
  addOnElementBefore?: React.ReactNode;
  addOnElementAfter?: React.ReactNode;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: (values: { formattedValue: string; value: string; floatValue: number }) => void;
  containerProps?: BoxProps;
}

const SDCurrencyInput = ({
  hidePrefix = false,
  id,
  name,
  value,
  label,
  error,
  prefix,
  onChange,
  isReadOnly,
  isDisabled,
  placeholder,
  addOnBefore,
  addOnAfter,
  addOnElementBefore,
  addOnElementAfter,
  containerProps,
  onBlur,
  ...props
}: Props) => {
  return (
    <CurrencyFormat
      {...props}
      id={id}
      name={name}
      value={value}
      error={error}
      decimalScale={2}
      label={label || ""}
      readOnly={isReadOnly}
      disabled={isDisabled}
      displayType={"input"}
      allowNegative={false}
      onValueChange={onChange}
      onBlur={onBlur}
      thousandSeparator={true}
      customInput={SDInput}
      prefix={hidePrefix ? "" : prefix ? prefix : "₦ "}
      placeholder={placeholder ? placeholder : "₦ 0.00"}
      addOnBefore={addOnBefore}
      addOnAfter={addOnAfter}
      addOnElementBefore={addOnElementBefore}
      addOnElementAfter={addOnElementAfter}
      containerProps={containerProps}
    />
  );
};

export default SDCurrencyInput;
