import { SDButton, SDInput, SDModal } from "components/global";
import { useFormik } from "formik";
import { handleClientLogin } from "services/auth";
import validationSchema from "utils/validationSchema";
import ClientOTPVerificationModal from "./ClientOTPVerificationModal";
import { useDisclosure } from "@chakra-ui/react";

interface IFormValues {
  email: string;
}

interface Props {
  onClose: () => void;
  isOpen: boolean;
  onSuccess: (() => Promise<void>) & (() => Promise<any>);
}

function ClientEmailLoginModal({ isOpen, onClose, onSuccess }: Props) {
  const verifyOTPDisclosure = useDisclosure();

  const { values, errors, touched, handleBlur, handleChange, isSubmitting, handleSubmit } =
    useFormik({
      initialValues: { email: "" },
      validationSchema: validationSchema.clientLogin,
      onSubmit: async (values: IFormValues) => {
        const data = {
          identifier: values.email,
        };

        await handleClientLogin(data, () => {
          onClose();
          verifyOTPDisclosure.onOpen();
        });
      },
    });

  return (
    <>
      <SDModal isOpen={isOpen} onClose={onClose} title="Enter your email address" size="md">
        <form onSubmit={handleSubmit} onBlur={handleBlur}>
          <SDInput
            id="email"
            type="email"
            label="Email Address"
            placeholder="example@gmail.com"
            value={values.email}
            onChange={handleChange}
            error={touched.email && errors.email}
            helperText="An OTP will be sent to your email address"
            containerProps={{ mt: 0 }}
          />

          <SDButton colorScheme="primary" mt={10} w="full" type="submit" isLoading={isSubmitting}>
            Proceed
          </SDButton>
        </form>
      </SDModal>

      <ClientOTPVerificationModal
        isOpen={verifyOTPDisclosure.isOpen}
        onClose={verifyOTPDisclosure.onClose}
        email={values.email}
        onSuccess={onSuccess}
      />
    </>
  );
}

export default ClientEmailLoginModal;
