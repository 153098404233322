import React from "react";
import { ReviewData, SDVerticalStepper } from "components/global";
import { IShipment } from "redux/slices/adminSlices/shipmentSlice/types";
import { RequestFilledIcon } from "utils/customIcons";
import { Image } from "@chakra-ui/react";

interface Props {
  shipmentToView: IShipment;
}

function ParcelDetails({ shipmentToView }: Props) {
  const steps = [
    ...shipmentToView?.parcelDetails.map(item => ({
      title: "Parcel details",
      description: (
        <>
          {[
            { title: "No of Content", value: item?.noOfContents },
            { title: "Parcel", value: item?.percelContents },
            { title: "Category", value: item?.category },
          ].map((item, key) => (
            <ReviewData
              key={key}
              title={item?.title}
              value={item?.value}
              borderBottom="none"
              titleColor="neutral.200"
              valueColor="neutral.500"
              titleFontWeight="300"
              valueFontWeight="300"
            />
          ))}
        </>
      ),
      icon: RequestFilledIcon,
    })),
    ...(shipmentToView?.pickUpDetails?.packageImage
      ? [
          {
            title: "Parcel Image",
            description: (
              <Image
                borderRadius="10px"
                src={shipmentToView?.pickUpDetails?.packageImage}
                maxW="100%"
              />
            ),
            icon: RequestFilledIcon,
          },
        ]
      : []),
  ];

  return <SDVerticalStepper steps={steps} showBorder={true} />;
}
export default ParcelDetails;
