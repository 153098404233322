import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { PaginatedResponse } from "types/global";
import { IShipment, IShipmentStats } from "./types";

// Define a type for the slice state
interface InitialState {
  shipmentsLoading: boolean;
  recentShipments: PaginatedResponse<IShipment[]>;
  shipments: PaginatedResponse<IShipment[]>;

  activeShipmentStatus: string;
  stats: IShipmentStats;
}

// Define the initial state using that type
const initialState: InitialState = {
  shipmentsLoading: false,
  recentShipments: { records: [], pagination: null },
  shipments: { records: [], pagination: null },

  activeShipmentStatus: "",
  stats: { total: 0, pending: 0, accepted: 0, pickedUp: 0, delivered: 0, cancelled: 0 },
};

export const shipmentSlice = createSlice({
  name: "shipmentReducer",
  initialState,
  reducers: {
    saveShipmentsLoadingState: (state, action: PayloadAction<boolean>) => {
      state.shipmentsLoading = action.payload;
    },

    saveShipments: (state, action: PayloadAction<PaginatedResponse<IShipment[]>>) => {
      state.shipments = action.payload;
    },
    saveRecentShipments: (state, action: PayloadAction<PaginatedResponse<IShipment[]>>) => {
      state.recentShipments = action.payload;
    },

    saveShipmentStats: (state, action: PayloadAction<IShipmentStats>) => {
      state.stats = action.payload;
    },

    saveActiveShipmentStatus: (state, action: PayloadAction<string>) => {
      state.activeShipmentStatus = action.payload;
    },
  },
});

export const {
  saveShipmentsLoadingState,
  saveShipments,
  saveRecentShipments,
  saveShipmentStats,
  saveActiveShipmentStatus,
} = shipmentSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectShipmentsLoadingState = (state: RootState) =>
  state.shipmentReducer.shipmentsLoading;
export const selectShipments = (state: RootState) => state.shipmentReducer.shipments;
export const selectRecentShipments = (state: RootState) => state.shipmentReducer.recentShipments;
export const selectShipmentStats = (state: RootState) => state.shipmentReducer.stats;
export const selectActiveShipmentStatus = (state: RootState) =>
  state.shipmentReducer.activeShipmentStatus;

// Reducer
export const shipmentReducer = shipmentSlice.reducer;
