import { Text, Flex, HStack, Icon, useDisclosure } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { AllRoutes } from "routes/AllRoutes";
import { IShipment } from "redux/slices/adminSlices/shipmentSlice/types";
import { useEffect, useMemo, useState } from "react";
import { ClockIcon, MapIcon, OrderBoxIcon, PinDistanceIcon } from "utils/customIcons";
import { SDButton, SDTag, SuccessModal, WarningModal } from "components/global";
import {
  convertMetersToKilometers,
  convertSecondsToNearestTime,
  isRideHailingTenant,
} from "utils/utilFunctions";
import { RouteDetails, ParcelDetails, OrderDetails, AssignCourierModal, RideDetails } from "..";
import { handleProviderCancelOrder } from "services/admin/shimpent";
import { IUserType } from "types/global";

interface Props {
  shipmentToView: IShipment;
  role: IUserType;
}

export type IShipmentPreviewPage = "ROUTE_DETAILS" | "ORDER_DETAILS" | "PARCEL_DETAILS";

function ShipmentDetailsCard({ shipmentToView, role }: Props) {
  const navigate = useNavigate();

  const cancelOrderDisclosure = useDisclosure();
  const cancelOrderSuccessDisclosure = useDisclosure();
  const assignOrderDisclosure = useDisclosure();

  const [currentPage, setCurrentPage] = useState<IShipmentPreviewPage>("ROUTE_DETAILS");
  const [isCancellingOrder, setIsCancellingOrder] = useState(false);

  const distanceMetrics = useMemo(
    () => [
      {
        label: "Distance",
        value: `${convertMetersToKilometers(shipmentToView?.billing?.distance)}km`,
        icon: PinDistanceIcon,
      },
      {
        label: "Time",
        value: convertSecondsToNearestTime(shipmentToView?.billing?.duration.slice(0, -1)),
        icon: ClockIcon,
      },
    ],
    [shipmentToView?.billing?.distance, shipmentToView?.billing?.duration]
  );

  const isPending = useMemo(
    () => shipmentToView?.shipmentStatus?.toLowerCase() === "pending",
    [shipmentToView?.shipmentStatus]
  );

  const isCancelled = useMemo(
    () => shipmentToView?.shipmentStatus?.toLowerCase() === "cancelled",
    [shipmentToView?.shipmentStatus]
  );

  async function handleCancelOrder() {
    setIsCancellingOrder(true);

    await handleProviderCancelOrder(shipmentToView?._id, role, () => {
      cancelOrderDisclosure.onClose();
      cancelOrderSuccessDisclosure.onOpen();
    });

    setIsCancellingOrder(false);
  }

  useEffect(() => {
    if (isPending || isCancelled) {
      setCurrentPage("ORDER_DETAILS");
    }
  }, [isPending, isCancelled]);

  return (
    <>
      <Flex flexDir="column" borderRadius="20px" bgColor="#fff" overflow="hidden">
        <Flex p={{ base: 4, md: 6 }} gap={2} align="center" borderBottom="1px solid #0600891A">
          <OrderBoxIcon boxSize={{ base: 5, md: 6 }} color="neutral.500" />

          <Text
            color="neutral.500"
            fontWeight={700}
            fontSize={{ base: "18px", md: "24px" }}
            lineHeight="30px"
          >
            {isRideHailingTenant() ? "Ride ID: " : "Order ID: "}
            <Text as="span" fontWeight={400}>
              # {shipmentToView?.trackingRef}
            </Text>
          </Text>
        </Flex>

        <Flex gap={8} flexDir="column" px={{ base: 4, md: 6 }} py={{ base: 6, md: 8 }}>
          <Flex wrap="wrap" gap={{ base: 2, md: 10 }} align="center">
            {distanceMetrics.map((item, key) => (
              <Flex
                key={key}
                gap={{ base: 2, md: 5 }}
                flexDir="column"
                borderRadius="10px"
                border="1px solid #0600891A"
                minW={{ base: "fit-content", md: "150px" }}
                p={{ base: 3, md: 4 }}
                pb={{ base: 3, md: 6 }}
              >
                <HStack>
                  <Text
                    color="#000"
                    fontWeight="300"
                    fontSize={{ base: "12px", md: "16px" }}
                    lineHeight="17px"
                  >
                    {item?.label}
                  </Text>

                  <Icon as={item?.icon} boxSize={{ base: 2, md: 4 }} />
                </HStack>

                <Text
                  color="#000"
                  fontWeight="500"
                  fontSize={{ base: "16px", md: "32px" }}
                  lineHeight="40px"
                  textAlign="center"
                >
                  {item?.value}
                </Text>
              </Flex>
            ))}

            <SDButton
              ml="auto"
              colorScheme="error"
              size={{ base: "md", md: "lg" }}
              onClick={() => cancelOrderDisclosure.onOpen()}
              isDisabled={!["Pending", "Accepted"].includes(shipmentToView?.shipmentStatus)}
              display={
                role === "provider" && shipmentToView?.originator !== "provider" ? "none" : "flex"
              }
            >
              Cancel Order
            </SDButton>

            {role === "provider" && (
              <SDButton
                ml={
                  role === "provider" && shipmentToView?.originator !== "provider" ? "auto" : "none"
                }
                w={{ base: "full", sm: "fit-content" }}
                size={{ base: "md", md: "lg" }}
                colorScheme="primary"
                leftIcon={<MapIcon />}
                onClick={() =>
                  navigate(
                    role === "provider"
                      ? AllRoutes.providerShipmentRoute(shipmentToView._id)
                      : AllRoutes.clientOrderRoute(shipmentToView._id)
                  )
                }
                isDisabled={["Cancelled", "Delivered"].includes(shipmentToView?.shipmentStatus)}
              >
                View route
              </SDButton>
            )}

            {role === "provider" &&
              shipmentToView?.orderStatus !== "Assigned" &&
              shipmentToView?.shipmentStatus !== "Cancelled" && (
                <SDButton
                  ml={shipmentToView?.originator !== "provider" ? "auto" : "none"}
                  w={{ base: "full", sm: "fit-content" }}
                  size={{ base: "md", md: "lg" }}
                  // colorScheme="secondary2"
                  colorScheme="primary"
                  onClick={assignOrderDisclosure.onOpen}
                  isDisabled={
                    shipmentToView?.orderStatus === "Assigned" ||
                    shipmentToView?.shipmentStatus === "Cancelled"
                  }
                >
                  Assign Order
                </SDButton>
              )}
          </Flex>

          <Flex gap={1} align="center" justifyContent="space-between">
            <HStack>
              <Text
                color="black"
                fontWeight="500"
                fontSize={{ base: "md", md: "xl" }}
                lineHeight="17px"
              >
                Status:
              </Text>
              <SDTag>{shipmentToView?.shipmentStatus}</SDTag>
            </HStack>

            {((!isPending && !isCancelled) || currentPage === "PARCEL_DETAILS") && (
              <SDButton
                variant="link"
                colorScheme="primary"
                fontWeight={300}
                fontSize={{ base: "md", md: "lg" }}
                p={0}
                textDecor="underline"
                _hover={{ textDecor: "none" }}
                onClick={() =>
                  setCurrentPage(
                    currentPage === "ROUTE_DETAILS"
                      ? "ORDER_DETAILS"
                      : currentPage === "ORDER_DETAILS"
                      ? "ROUTE_DETAILS"
                      : "ORDER_DETAILS"
                  )
                }
              >
                {currentPage === "ROUTE_DETAILS"
                  ? "View order details"
                  : currentPage === "ORDER_DETAILS"
                  ? "Back to shipment logs"
                  : "Back to Order Details"}
              </SDButton>
            )}
          </Flex>

          {currentPage === "ROUTE_DETAILS" && <RouteDetails shipmentToView={shipmentToView} />}
          {currentPage === "ORDER_DETAILS" &&
            (isRideHailingTenant() ? (
              <RideDetails shipmentToView={shipmentToView} role={role} />
            ) : (
              <OrderDetails
                shipmentToView={shipmentToView}
                setCurrentPage={setCurrentPage}
                role={role}
              />
            ))}
          {currentPage === "PARCEL_DETAILS" && <ParcelDetails shipmentToView={shipmentToView} />}
        </Flex>
      </Flex>

      <WarningModal
        isOpen={cancelOrderDisclosure.isOpen}
        onClose={cancelOrderDisclosure.onClose}
        message={`Are you sure you want to cancel this order with the tracking ref #${shipmentToView?.trackingRef}?`}
        onBtnClick={handleCancelOrder}
        isLoading={isCancellingOrder}
      />

      <SuccessModal
        isOpen={cancelOrderSuccessDisclosure.isOpen}
        onClose={cancelOrderSuccessDisclosure.onClose}
        message="Order cancelled."
      />

      {role === "provider" && (
        <AssignCourierModal
          isOpen={assignOrderDisclosure.isOpen}
          onClose={assignOrderDisclosure.onClose}
          shipmentToAssign={shipmentToView}
        />
      )}
    </>
  );
}

export default ShipmentDetailsCard;
