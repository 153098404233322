import { SDButton, SDInput, SDModal } from "components/global";
import { useFormik } from "formik";
import validationSchema from "utils/validationSchema";

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

interface IFormValues {
  role: string;
}

function AddNewRoleModal({ onClose, isOpen }: Props) {
  const { values, errors, touched, handleBlur, handleChange, isSubmitting, handleSubmit } =
    useFormik({
      initialValues: { role: "" },
      validationSchema: validationSchema.providerAddNewRole,
      onSubmit: async (values: IFormValues, actions) => {
        //   const data = {
        //     role: values.role,
        //   };
        //   await handleAddRole(data, res => {
        //   action.resetForm();
        //     onClose()
        //     }
        //   });
        actions.resetForm();
        onClose();
      },
    });

  return (
    <SDModal isOpen={isOpen} onClose={onClose} title="Create Custom Role" size="sm">
      <form onSubmit={handleSubmit} onBlur={handleBlur}>
        <SDInput
          id="role"
          type="text"
          label="Role"
          placeholder="Enter a role"
          helperText="You can create any role for your staffs, so that their earnings can be processed by our payroll system."
          value={values.role}
          onChange={handleChange}
          error={touched.role && errors.role}
        />

        <SDButton type="submit" colorScheme="primary" w="full" mt={8} isLoading={isSubmitting}>
          Continue
        </SDButton>
      </form>
    </SDModal>
  );
}

export default AddNewRoleModal;
