import courierBg from "assets/svg/CourierBg.svg";
import blackLogo from "assets/logo/black-logo.png";
import { Box, Text, Flex, Center, Heading, Image } from "@chakra-ui/react";
import { SDButton } from "components/global";
import { BsAndroid2, BsApple } from "react-icons/bs";
import DocumentTitleSetter from "components/global/Layouts/DocumentTitleSetter";

function CourierSignUpPage() {
  return (
    <>
      <DocumentTitleSetter title="Sign up" />

      <Flex
        flexDir="column"
        gap={12}
        minH="100vh"
        w="100vw"
        bgColor="white"
        bgImage={courierBg}
        bgRepeat="no-repeat"
        bgSize={["auto", "cover"]}
        bgPosition="center"
        py={[8, 8, 16]}
        px={[4, 8, 16]}
      >
        <Box>
          <Image src={blackLogo} alt="Sendrail" maxW="128px" />
        </Box>

        <Box maxW="750px" m="auto" w="full">
          <Heading
            fontWeight={900}
            fontSize={{ base: "28px", md: "48px", lg: "64px" }}
            textAlign="center"
          >
            Opportunities{" "}
            <Text as="span" color="secondary.600">
              Abounds
            </Text>{" "}
            In Every{" "}
            <Text as="span" color="secondary.600">
              Directions.
            </Text>
          </Heading>

          <Center mt={6} gap={6}>
            <SDButton colorScheme="primary" leftIcon={<BsAndroid2 />}>
              Android
            </SDButton>
            <SDButton colorScheme="primary" leftIcon={<BsApple />}>
              iOS
            </SDButton>
          </Center>
        </Box>
      </Flex>
    </>
  );
}

export default CourierSignUpPage;
