import { Circle, Spinner } from "@chakra-ui/react";
import { BiSolidCamera } from "react-icons/bi";
import SDFileUploadWrapper, { UploadProps } from "./FileUploadWrapper";

interface Props extends UploadProps {
  isLoading?: boolean;
}

function ProfilePicUpload({ isLoading, ...props }: Props) {
  return (
    <SDFileUploadWrapper {...props} borderRadius="full">
      <Circle
        position="relative"
        size="100px"
        background={props?.value?.url ? `url(${props?.value?.url})` : "neutral.200"}
        backgroundPosition="center"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        transition="0.2s"
        borderWidth={1}
        _hover={{ transform: "scale(0.95)" }}
      >
        <Circle
          position="absolute"
          background="#FFFFFF"
          size="32px"
          right="15px"
          bottom="15px"
          color="neutral.500"
        >
          {isLoading ? <Spinner /> : <BiSolidCamera />}
        </Circle>
      </Circle>
    </SDFileUploadWrapper>
  );
}

export default ProfilePicUpload;
