import {
  Avatar,
  Box,
  Flex,
  Heading,
  Icon,
  Image,
  Text,
  Link as ChakraLink,
} from "@chakra-ui/react";
import Logo from "assets/logo/yellow-logo.png";
import { Link, useLocation } from "react-router-dom";
import { AllRoutes } from "routes/AllRoutes";
import {
  clientOfflineSidebarRoutes,
  clientSidebarRoutes,
  ISideBarRoute,
  ProviderSidebarRoutes,
} from "./SideBarRoutes";
import { IUserType } from "types/global";
import Cookies from "js-cookie";
import { COOKIES_KEYS } from "utils/cookies";
import { isClientLoggedIn } from "utils/utilFunctions";
import { useAppSelector } from "redux/store";
import { selectTenantProfile } from "redux/slices/authSlice";
import { SENDRAIL_WEBSITE } from "utils/constants";
import { toast } from "react-toastify";
import { auth, signOut } from "utils/firebase";

interface Props {
  role: IUserType;
}

function SideBar({ role }: Props) {
  const tenantProfile = useAppSelector(selectTenantProfile);

  const location = useLocation();

  function getSideBarRoute(): ISideBarRoute[] {
    if (role === "provider") {
      return ProviderSidebarRoutes;
    } else if (role === "client") {
      if (isClientLoggedIn()) {
        return clientSidebarRoutes;
      } else {
        return clientOfflineSidebarRoutes;
      }
    } else {
      return [];
    }
  }

  function isRouteActive(href: string, subHref?: string): boolean {
    return location.pathname.startsWith(href) || location.pathname.startsWith(subHref);
  }

  async function handleLogout() {
    // Firebase google auth
    await signOut(auth);

    Cookies.remove(COOKIES_KEYS.TOKEN);
    Cookies.remove(COOKIES_KEYS.USER_TYPE);
    Cookies.remove(COOKIES_KEYS.X_ACCESS);

    window.location.href = role === "provider" ? AllRoutes.providerLogin : AllRoutes.clientLogin;
  }

  return (
    <Flex flexDir="column" w="full" h="full" py={12} bg="primary.500" overflowY="auto">
      {role === "provider" && (
        <Box px={6} mb={{ base: 8, lg: 12 }}>
          <Link to={AllRoutes.providerDashboard}>
            <Image src={Logo} alt="Sendrail" maxW="140px" />
          </Link>
        </Box>
      )}

      {role === "client" && (
        <Box px={6} mb={4}>
          {!!tenantProfile?.businessInfo?.logo ? (
            <Link to={AllRoutes.clientDashboard}>
              <Avatar
                size="xl"
                src={tenantProfile?.businessInfo?.logo}
                name={tenantProfile?.businessInfo?.name}
              />
            </Link>
          ) : (
            <Box bgColor="secondary.500" borderRadius="8px" p="10px" maxW="180px">
              <Heading fontSize={{ base: "18px", md: "20px", lg: "24px" }}>Your Logo</Heading>
            </Box>
          )}
        </Box>
      )}

      <Flex flexDir="column" fontWeight="500" color="#fff" gap="10px" flex={1}>
        {getSideBarRoute().map((item, key) => (
          <Box
            key={key}
            {...(item?.isComingSoon
              ? {
                  onClick: e => {
                    e.preventDefault();
                    toast.info("Coming Soon");
                  },
                }
              : item?.isForLogout
              ? { onClick: handleLogout }
              : { as: Link, to: item?.href })}
            title={item?.isComingSoon ? "Coming soon" : ""}
            position="relative"
            p="12px 6px 12px 24px"
            fontWeight="500"
            color="#fff"
            textAlign="left"
            cursor="pointer"
            transition="all 100ms ease-in-out"
            _hover={{
              background: "#2f2e3987",
              textDecoration: "none",
            }}
            {...(isRouteActive(item?.href, item?.subHref)
              ? {
                  color: "secondary.500",
                  _hover: { background: "initial", textDecoration: "none" },
                }
              : {})}
          >
            <Flex placeItems="center" gap="16px">
              <Icon
                as={isRouteActive(item?.href, item?.subHref) ? item.filledIcon : item.icon}
                w={{ base: 4, lg: 5 }}
                h={{ base: 4, lg: 5 }}
              />
              <Text fontSize={{ base: "16px", lg: "18px" }} whiteSpace="nowrap">
                {item.text}
              </Text>
            </Flex>

            {isRouteActive(item?.href, item?.subHref) && (
              <Box
                position="absolute"
                top={0}
                bottom={0}
                right={1}
                bgColor="secondary.500"
                borderRadius="md"
                h="full"
                w="5px"
              />
            )}
          </Box>
        ))}
      </Flex>

      {role === "client" && (
        <Flex gap={1} align="center" px={6}>
          <Text color="secondary.500" fontWeight="500">
            Powered By
          </Text>
          <ChakraLink href={SENDRAIL_WEBSITE} target="_blank">
            <Image src={Logo} alt="Sendrail" maxW="88px" />
          </ChakraLink>
        </Flex>
      )}
    </Flex>
  );
}
export default SideBar;
