import { Flex, Heading, Icon, Text } from "@chakra-ui/react";
import { SDButton, SDInput } from "components/global";
import AuthLayout from "components/global/Layouts/AuthLayout";
import { Link } from "react-router-dom";
import { AllRoutes } from "routes/AllRoutes";
import { useFormik } from "formik";
import { BiLeftArrowCircle } from "react-icons/bi";
import { handleProviderSignUp } from "services/auth";
import validationSchema from "utils/validationSchema";
import { useEffect, useState } from "react";
import { attachSubdomainPath, scrollToSection } from "utils/utilFunctions";

interface IFormValues {
  step: number;

  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  // dob: string;
  // bvn: string;

  businessName: string;
  businessEmail: string;
  businessAddress: string;
  portal: string;
  password: string;
  confirmPassword: string;
}

function ProviderSignUpPage() {
  const [step, setStep] = useState(1);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    isSubmitting,
    handleSubmit,
  } = useFormik({
    initialValues: {
      step: 1,

      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      // dob: "",
      // bvn: "",

      businessName: "",
      businessEmail: "",
      businessAddress: "",
      portal: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema:
      step === 1
        ? validationSchema.providerPersonalInfoSignup
        : validationSchema.providerBusinessInfoSignup,
    onSubmit: async (values: IFormValues, { setFieldValue }) => {
      if (values.step === 1) {
        setFieldValue("step", 2);
        setStep(2);

        return;
      }

      const data = {
        fullName: `${values.firstName} ${values.lastName}`,
        phoneNumber: values.phoneNumber,
        email: values.email,
        // dob: values.dob,
        // bvn: values.bvn,
        password: values.password,
        portal: values.portal,
        businessInfo: {
          name: values.businessName,
          email: values.businessEmail,
          address: values.businessAddress,
        },
      };

      await handleProviderSignUp(data, () => {
        window.location.href = attachSubdomainPath(
          values.portal,
          AllRoutes.verifyEmail(values.email)
        );
      });
    },
  });

  useEffect(() => {
    scrollToSection("authTop");
    setStep(values.step);
  }, [values.step]);

  return (
    <AuthLayout
      title="Sign up"
      description="Experience a faster, easier and more reliable way to move items from one location to another on Sendrail"
      topComponent={
        values.step !== 1 && (
          <Flex gap={4} align="center" justify="space-between" mb={6}>
            <Icon
              w="24px"
              h="24px"
              cursor="pointer"
              as={BiLeftArrowCircle}
              onClick={() => setFieldValue("step", 1)}
            />

            <Text color="neutral.200" fontSize="sm">
              Already have an account?{" "}
              <Link to={AllRoutes.providerLogin}>
                <Text as="span" color="primary.500" fontWeight={700}>
                  Sign in
                </Text>
              </Link>
            </Text>
          </Flex>
        )
      }
    >
      <Heading fontSize="18px" textAlign="center" mb={6}>
        {values.step === 1 ? "Personal Information" : "Business Information"}
      </Heading>

      <form onSubmit={handleSubmit} onBlur={handleBlur}>
        {values.step === 1 && (
          <>
            <SDInput
              variant="grey"
              id="firstName"
              type="text"
              label="First name"
              placeholder="Enter first name"
              value={values.firstName}
              onChange={handleChange}
              error={touched.firstName && errors.firstName}
            />

            <SDInput
              variant="grey"
              id="lastName"
              type="text"
              label="Last name"
              placeholder="Enter last name"
              value={values.lastName}
              onChange={handleChange}
              error={touched.lastName && errors.lastName}
            />

            <SDInput
              variant="grey"
              id="phoneNumber"
              type="text"
              label="Phone number"
              placeholder="Enter your phone number"
              value={values.phoneNumber}
              onChange={handleChange}
              error={touched.phoneNumber && errors.phoneNumber}
            />

            <SDInput
              variant="grey"
              id="email"
              type="email"
              label="Email"
              placeholder="example@gmail.com"
              value={values.email}
              onChange={handleChange}
              error={touched.email && errors.email}
            />

            {/* <SDInput
              variant="grey"
              id="dob"
              type="date"
              label="Date of Birth"
              placeholder="Enter date of birth"
              value={values.dob}
              onChange={handleChange}
              error={touched.dob && errors.dob}
            />

            <SDInput
              variant="grey"
              id="bvn"
              type="nuber"
              label="BVN"
              placeholder="Enter your BVN"
              value={values.bvn}
              onChange={handleChange}
              error={touched.bvn && errors.bvn}
            /> */}

            <SDButton type="submit" colorScheme="primary" mt={10} w="full">
              Next
            </SDButton>

            <Flex
              flexDir={{ base: "column", md: "row" }}
              gap={4}
              align="center"
              justify="space-between"
              mt={6}
            >
              <Text>
                Already have an account?{" "}
                <Link to={AllRoutes.providerLogin}>
                  <Text as="span" fontWeight={700}>
                    Sign in
                  </Text>
                </Link>
              </Text>
            </Flex>
          </>
        )}

        {values.step === 2 && (
          <>
            <SDInput
              variant="grey"
              id="businessName"
              type="text"
              label="Business name"
              placeholder="Enter your business name"
              value={values.businessName}
              onChange={handleChange}
              error={touched.businessName && errors.businessName}
            />

            <SDInput
              variant="grey"
              id="businessEmail"
              type="email"
              label="Business email"
              placeholder="Enter your business email"
              value={values.businessEmail}
              onChange={handleChange}
              error={touched.businessEmail && errors.businessEmail}
            />

            <SDInput
              variant="grey"
              id="businessAddress"
              type="text"
              label="Business address"
              placeholder="Enter your business address"
              value={values.businessAddress}
              onChange={handleChange}
              error={touched.businessAddress && errors.businessAddress}
            />

            <SDInput
              variant="grey"
              id="portal"
              type="text"
              label="Website"
              placeholder="speedylogistics"
              value={values.portal}
              onChange={e =>
                setFieldValue("portal", e.target.value.trim().replace(" ", "").toLowerCase())
              }
              error={touched.portal && errors.portal}
              addOnBefore="https://"
              addOnAfter=".sendrail.co"
            />

            <SDInput
              variant="grey"
              id="password"
              type="password"
              label="Password"
              placeholder="••••••••••••••••"
              value={values.password}
              onChange={handleChange}
              error={touched.password && errors.password}
            />

            <SDInput
              variant="grey"
              id="confirmPassword"
              type="password"
              label="Confirm password"
              placeholder="••••••••••••••••"
              value={values.confirmPassword}
              onChange={handleChange}
              error={touched.confirmPassword && errors.confirmPassword}
            />

            <SDButton colorScheme="primary" mt={10} w="full" type="submit" isLoading={isSubmitting}>
              Submit
            </SDButton>

            <Flex
              flexDir={{ base: "column", md: "row" }}
              gap={4}
              align="center"
              justify="space-between"
              mt={6}
            >
              <Text fontWeight={300}>
                By clicking Submit, you acknowledge that you have read and accepted the{" "}
                <Link to={"#"}>
                  <Text as="span" fontWeight={700}>
                    Terms of Service{" "}
                  </Text>
                </Link>
                and{" "}
                <Link to={"#"}>
                  <Text as="span" fontWeight={700}>
                    Privacy Policy.
                  </Text>
                </Link>
              </Text>
            </Flex>
          </>
        )}
      </form>
    </AuthLayout>
  );
}

export default ProviderSignUpPage;
