import { Text, useDisclosure } from "@chakra-ui/react";
import { ShipmentDetailsDrawer } from "components/admin/shipment";
import { SDTag, SDTable } from "components/global";
import DashboardLayout from "components/global/Layouts/DashboardLayout";
import { useState, useEffect } from "react";
import { IShipment } from "redux/slices/adminSlices/shipmentSlice/types";
import {
  selectOrderHistory,
  selectOrderHistoryLoadingState,
} from "redux/slices/clientSlices/orderHistorySlice";
import { useAppSelector } from "redux/store";
import { handleClientGetOrderHistory } from "services/client/orderHistory";
import { PAYMENT_METHOD_OPTIONS } from "utils/constants";
import { formatAmount, formatDate } from "utils/utilFunctions";

function ClientRecentActivitiesPage() {
  const orderHistoryState = useAppSelector(selectOrderHistory);
  const isOrderHistoryLoading = useAppSelector(selectOrderHistoryLoadingState);

  const viewOrderDisclosure = useDisclosure();

  const [activeRecord, setActiveRecord] = useState<IShipment>(null);

  const tableHeader = [
    {
      label: "Destination",
      dataIndex: "dropOffDetails",
      render: (dropOffDetails: any) =>
        dropOffDetails?.location?.description ? (
          <Text title={dropOffDetails?.location?.description}>
            {dropOffDetails?.location?.description.length <= 30
              ? dropOffDetails?.location?.description
              : dropOffDetails?.location?.description.substring(0, 30) + "..."}
          </Text>
        ) : (
          "---"
        ),
    },
    {
      label: "Driver name",
      dataIndex: "dispatch",
      render: dispatch => dispatch?.fullName || "---",
    },
    {
      label: "Price",
      dataIndex: "billing",
      render: billing => formatAmount(billing?.cost),
    },
    {
      label: "Ride ID",
      dataIndex: "trackingRef",
    },
    { label: "Status", dataIndex: "shipmentStatus", render: status => <SDTag>{status}</SDTag> },
    {
      label: "Payment Method",
      dataIndex: "paymentMethod",
      render: paymentMethod =>
        PAYMENT_METHOD_OPTIONS.find(i => i.value === paymentMethod)?.label || paymentMethod,
    },
    {
      label: "Payment Status",
      dataIndex: "paymentStatus",
      render: status => <SDTag>{status}</SDTag>,
    },
    {
      label: "Time",
      dataIndex: "pickUpDetails",
      render: pickUpDetails => pickUpDetails?.scheduleForPickUp?.enterTime || "---",
    },
    {
      label: "Date",
      dataIndex: "createdAt",
      render: (date: string) => formatDate(date),
    },
  ];

  async function getOrderHistory(page?: number) {
    await handleClientGetOrderHistory(page, null);
  }

  useEffect(() => {
    getOrderHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardLayout role="client" pageTitle="Recent Activities">
      <SDTable
        header={tableHeader}
        isLoading={isOrderHistoryLoading}
        body={orderHistoryState.records}
        paginationConfig={orderHistoryState.pagination}
        onPaginationChange={page => getOrderHistory(page)}
        rowClassName="cursor-pointer"
        onRowClick={(record: IShipment) => {
          setActiveRecord(record);
          viewOrderDisclosure.onOpen();
        }}
      />

      <ShipmentDetailsDrawer
        isOpen={viewOrderDisclosure.isOpen}
        onClose={viewOrderDisclosure.onClose}
        shipmentToView={activeRecord}
        role="client"
      />
    </DashboardLayout>
  );
}

export default ClientRecentActivitiesPage;
