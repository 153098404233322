import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AllRoutes } from "routes/AllRoutes";
import { isDashboardDomain } from "utils/utilFunctions";

function HomePage() {
  const navigate = useNavigate();

  useEffect(() => {
    // TODO: Redirect if there is a user session, Do that in the route page
    // Redirect to signup on the base entry i.e dashboard.sendrail.co
    if (isDashboardDomain()) {
      navigate(AllRoutes.providerSignUp);
    } else {
      navigate(AllRoutes.providerLogin);
    }
  }, [navigate]);

  return <></>;
}

export default HomePage;
