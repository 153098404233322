import { Box, Flex, Heading, IconButton, Progress, useDisclosure } from "@chakra-ui/react";
import { FaChevronRight } from "react-icons/fa";
import { AddBankAccountModal } from ".";
import { selectProviderProfile } from "redux/slices/authSlice";
import { useAppSelector } from "redux/store";

function SetupAccountCTA() {
  const providerProfile = useAppSelector(selectProviderProfile);

  const addBankDisclosure = useDisclosure();

  // If bankAccount has already been added
  if (!!providerProfile?.bankDetails?.bankAccountNumber) {
    return <></>;
  }

  return (
    <>
      <Box
        borderRadius="10px"
        border="1px solid #0600891A"
        boxShadow="0px 4px 4px 0px #F6F6FB"
        py={6}
        px={4}
      >
        <Flex gap={1} align="center" justify="space-between" mb={5}>
          <Heading fontSize="18px" fontWeight={600}>
            Setup Account
          </Heading>
          <IconButton
            aria-label={"Show"}
            icon={<FaChevronRight />}
            variant="outline"
            onClick={addBankDisclosure.onOpen}
            // onClick={() => navigate(AllRoutes.providerAccountSetup)}
          />
        </Flex>

        <Progress
          value={50}
          h={{ base: "30px", md: "45px", lg: "60px" }}
          colorScheme="green"
          borderRadius="8px"
        />
      </Box>

      <AddBankAccountModal isOpen={addBankDisclosure.isOpen} onClose={addBankDisclosure.onClose} />
    </>
  );
}

export default SetupAccountCTA;
