import DashboardLayout from "components/global/Layouts/DashboardLayout";
import { CouriersPayroll, PayrollHistory } from "components/admin/payroll";
import { SDTabs } from "components/global";

function PayrollPage() {
  return (
    <DashboardLayout role="provider" pageTitle="Payroll">
      <SDTabs
        tabs={[
          { label: "Couriers Payroll", content: <CouriersPayroll /> },
          { label: "Payroll History", content: <PayrollHistory /> },
        ]}
      />
    </DashboardLayout>
  );
}

export default PayrollPage;
