import {
  Box,
  Input,
  InputProps,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  FormLabel,
  FormControl,
  InputLeftElement,
  InputRightElement,
  Flex,
  IconButton,
  BoxProps,
  FlexProps,
} from "@chakra-ui/react";
import { useState } from "react";

import { BsEyeSlashFill, BsEyeFill } from "react-icons/bs";
import SDInputErrorMessage from "./InputErrorMessage";
import SDInputHelperText from "./InputHelperText";

export interface InputFieldProps extends InputProps {
  error?: any;
  label?: string;
  helperText?: string | React.ReactNode;
  addOnBefore?: React.ReactNode;
  addOnAfter?: React.ReactNode;
  addOnElementBefore?: React.ReactNode;
  addOnElementAfter?: React.ReactNode;
  variant?: "grey";
  containerProps?: BoxProps;
  helperTextContainerProps?: FlexProps;
}

const SDInput = ({
  error,
  label,
  helperText,
  addOnBefore,
  addOnAfter,
  addOnElementBefore,
  addOnElementAfter,
  variant,
  containerProps,
  helperTextContainerProps,
  ...rest
}: InputFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box as={FormControl} my={4} {...containerProps}>
      {label && (
        <FormLabel fontSize="14px" fontWeight={700} color="neutral.500" mb={1}>
          {label}
        </FormLabel>
      )}

      <InputGroup size={rest?.size || "lg"} alignItems="center">
        {addOnElementBefore && <InputLeftElement>{addOnElementBefore}</InputLeftElement>}
        {addOnBefore && (
          <InputLeftAddon
            fontSize={{ base: "sm", md: "md" }}
            color="neutral.500"
            fontWeight={450}
            bgColor="#0600890D"
          >
            {addOnBefore}
          </InputLeftAddon>
        )}

        <Input
          size="lg"
          _placeholder={{ color: "#ABA7A7" }}
          color="neutral.500"
          bgColor={variant === "grey" ? "#0600890D" : "white"}
          borderColor={variant === "grey" ? "#0600890D" : "#CBD5E0"}
          borderWidth={variant === "grey" ? "0" : "2"}
          fontSize={{ base: "sm", md: "md" }}
          fontWeight={450}
          {...rest}
          onWheel={e => e.currentTarget.blur()}
          type={showPassword ? "text" : rest?.type}
        />

        {addOnAfter && (
          <InputRightAddon
            fontSize={{ base: "sm", md: "md" }}
            color="neutral.500"
            fontWeight={450}
            bgColor="#0600890D"
          >
            {addOnAfter}
          </InputRightAddon>
        )}

        {addOnElementAfter && rest?.type !== "password" && (
          <InputRightElement>{addOnElementAfter}</InputRightElement>
        )}

        {rest?.type === "password" && (
          <InputRightElement w="fit-content" pr={1}>
            <Flex gap={1} alignItems="center">
              <IconButton
                variant="ghost"
                colorScheme="primary"
                onClick={() => setShowPassword(prevState => !prevState)}
                aria-label="Show/Hide"
                icon={showPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
                tabIndex={-1}
              />
              {addOnAfter}
            </Flex>
          </InputRightElement>
        )}
      </InputGroup>

      {error && <SDInputErrorMessage error={error} />}

      {helperText && <SDInputHelperText helperText={helperText} {...helperTextContainerProps} />}
    </Box>
  );
};

export default SDInput;
