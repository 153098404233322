import { Box, Flex, Heading, useDisclosure } from "@chakra-ui/react";
import { SelectDeliveryTypeModal } from "components/admin/shipment";
import { ClientRecentOrders, CreateOrderBox } from "components/client/dashboard";
import DashboardLayout from "components/global/Layouts/DashboardLayout";
import SearchOrderByTrackingRef from "components/global/Layouts/DashboardLayout/SearchOrderByTrackingRef";
import { useNavigate } from "react-router-dom";
import { AllRoutes } from "routes/AllRoutes";
import { BulkOrderBoxIcon, SingleOrderBoxIcon } from "utils/customIcons";
import { isClientLoggedIn } from "utils/utilFunctions";

function ClientDashboardPage() {
  const navigate = useNavigate();

  const createOrderDisclosure = useDisclosure();

  return (
    <DashboardLayout
      role="client"
      pageTitle="Create Order"
      subTitle="How do you want to create this order?"
      prePageComponent={
        isClientLoggedIn() && (
          <Box display={{ base: "block", md: "none" }} mb={4}>
            <Heading fontSize="24px" mb={4}>
              Track Order
            </Heading>
            <SearchOrderByTrackingRef role="client" />
          </Box>
        )
      }
    >
      <Flex flexDir="column" gap="32px" h="full">
        <Flex gap={{ base: 4, md: 8 }}>
          <CreateOrderBox
            title="Single order"
            // TODO: Uncomment this later
            // onClick={createOrderDisclosure.onOpen}
            onClick={() => navigate(AllRoutes.clientCreateSingleOrder("lastMile"))}
            icon={
              <SingleOrderBoxIcon
                w={{ base: "64px", md: "81px" }}
                h={{ base: "64px", md: "81px" }}
              />
            }
          />
          <CreateOrderBox
            title="Bulk orders"
            onClick={() => navigate(AllRoutes.clientDashboard)}
            isDisabled={true}
            icon={
              <BulkOrderBoxIcon
                w={{ base: "77px", md: "103px" }}
                h={{ base: "64px", md: "86px" }}
              />
            }
          />
        </Flex>

        <ClientRecentOrders />
      </Flex>

      <SelectDeliveryTypeModal
        isOpen={createOrderDisclosure.isOpen}
        onClose={createOrderDisclosure.onClose}
        role="client"
      />
    </DashboardLayout>
  );
}

export default ClientDashboardPage;
