import { AddIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading, Text, useDisclosure } from "@chakra-ui/react";
import {
  EmptyState,
  SDButton,
  SDMenuDropdown,
  SuccessModal,
  WarningModal,
} from "components/global";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { selectLastMilePricing } from "redux/slices/adminSlices/settingsSlice";
import { IPricing } from "redux/slices/adminSlices/settingsSlice/types";
import { useAppSelector } from "redux/store";
import { AllRoutes } from "routes/AllRoutes";
import { handleProviderUpdatePricingStatus } from "services/admin/settings";
import { DELIVERY_OPTIONS } from "utils/constants";

function LastMileLists() {
  const navigate = useNavigate();

  const lastMile = useAppSelector(selectLastMilePricing);

  const updatePricingStatusDisclosure = useDisclosure();
  const updatePricingStatusSuccessDisclosure = useDisclosure();

  const [activeRecord, setActiveRecord] = useState<IPricing>(null);
  const [updateAction, setUpdateAction] = useState<"activate" | "deactivate" | "">("");
  const [isUpdating, setIsUpdating] = useState(false);

  async function handleUpdateStatus() {
    setIsUpdating(true);

    await handleProviderUpdatePricingStatus(
      activeRecord?._id,
      updateAction === "activate" ? true : false,
      () => {
        updatePricingStatusDisclosure.onClose();
        updatePricingStatusSuccessDisclosure.onOpen();
      }
    );

    setIsUpdating(false);
  }

  return lastMile.length === 0 ? (
    <EmptyState
      variant="sm"
      title="Nothing to see here"
      message="Setup pricing for delivery orders"
      actionBtnText="Add Pricing"
      onActionBtnClick={() => navigate(AllRoutes.providerAddLastMilePricing)}
      actionBtnProps={{ leftIcon: <AddIcon /> }}
    />
  ) : (
    <>
      <Box>
        <Flex
          gap={4}
          align="center"
          justify="space-between"
          mb={4}
          flexDir={{ base: "column", md: "row" }}
        >
          <Text fontSize="16px" lineHeight="22px" fontWeight={600}>
            Available pricing for last mile{" "}
            <Text as="span" color="neutral.200">
              ({lastMile.length})
            </Text>
          </Text>

          <SDButton
            leftIcon={<AddIcon />}
            variant="ghost"
            colorScheme="primary"
            size="sm"
            fontSize="14px"
            px={2}
            onClick={() => navigate(AllRoutes.providerAddLastMilePricing)}
          >
            Add Pricing
          </SDButton>
        </Flex>

        <Flex flexDir="column" gap={4}>
          {lastMile.map(item => (
            <Flex
              key={item?._id}
              borderRadius="10px"
              border="1px solid #0600891A"
              px={{ base: 2.5, md: 6 }}
              py={2.5}
              gap={4}
              align="center"
              justify="space-between"
            >
              <Box>
                <Heading
                  fontSize="12px"
                  lineHeight="16px"
                  fontWeight={600}
                  color="neutral.500"
                  mb={2}
                >
                  {item?.name}{" "}
                  <Text as="span" color="neutral.200">
                    (
                    {DELIVERY_OPTIONS.find(i => i.value === item?.deliveryType)?.label ||
                      item?.deliveryType}
                    )
                  </Text>
                </Heading>
                <Text fontSize="12px" lineHeight="16px" color="neutral.200">
                  {item?.description}
                </Text>
              </Box>

              {!item?.isActive ? (
                <SDButton
                  colorScheme="success"
                  variant="ghost"
                  size="sm"
                  fontSize="14px"
                  px={2}
                  onClick={() => {
                    setUpdateAction("activate");
                    setActiveRecord(item);
                    updatePricingStatusDisclosure.onOpen();
                  }}
                >
                  Activate
                </SDButton>
              ) : (
                <SDMenuDropdown
                  menu={[
                    {
                      label: "Edit",
                      onClick: () => navigate(AllRoutes.providerEditLastMilePricing(item?._id)),
                    },

                    {
                      label: "Deactivate",
                      onClick: () => {
                        setUpdateAction("deactivate");
                        setActiveRecord(item);
                        updatePricingStatusDisclosure.onOpen();
                      },
                      isDanger: true,
                    },
                  ]}
                />
              )}
            </Flex>
          ))}
        </Flex>
      </Box>

      <WarningModal
        isOpen={updatePricingStatusDisclosure.isOpen}
        onClose={updatePricingStatusDisclosure.onClose}
        message={`Are you sure you want to ${updateAction} this pricing?`}
        subMessage={activeRecord?.name}
        onBtnClick={() => handleUpdateStatus()}
        isLoading={isUpdating}
      />

      <SuccessModal
        isOpen={updatePricingStatusSuccessDisclosure.isOpen}
        onClose={updatePricingStatusSuccessDisclosure.onClose}
        message={`Pricing ${updateAction}d.`}
      />
    </>
  );
}

export default LastMileLists;
