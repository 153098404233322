import { StylesConfig } from "react-select";
import { Box, FormLabel, FormControl } from "@chakra-ui/react";
import { SDInputErrorMessage, SDInputHelperText } from "..";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import GooglePlacesAutocompleteProps, {
  GooglePlacesAutocompleteHandle,
} from "react-google-places-autocomplete/build/types";
import { RefAttributes } from "react";

interface SelectFieldProps
  extends GooglePlacesAutocompleteProps,
    RefAttributes<GooglePlacesAutocompleteHandle> {
  onChange: (id: string, selectedVal: string | IOptions) => void;
  onBlur?: (e: any) => void;
  placeholder?: string;
  error?: any;
  label?: string;
  showSearch?: boolean;
  value: any;
  id: string;
  clearable?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  isMulti?: boolean;
  isLoading?: boolean;
  helperText?: string | React.ReactNode;
  containerProps?: any;
  variant?: "grey";
  [rest: string]: any;
}

interface IOptions {
  value: string | number | boolean;
  label: string | number | React.ReactElement;
}

function SDGooglePlacesAutoComplete({
  variant,
  placeholder,
  error,
  onChange,
  onBlur,
  label,
  showSearch,
  value,
  id,
  clearable,
  isDisabled,
  isRequired,
  isMulti,
  helperText,
  isLoading,
  containerProps,
  ...rest
}: SelectFieldProps) {
  const customStyles: StylesConfig = {
    indicatorSeparator: provided => ({ display: "none" }),
    control: provided => ({
      ...provided,
      minWidth: rest?.minW || provided.minWidth,
      minHeight: "48px",
      paddingInline: "8px",
      borderRadius: "0.375rem",
      borderColor: variant === "grey" ? "#0600890D" : "#CBD5E0",
      backgroundColor: variant === "grey" ? "#0600890D" : rest?.bgColor || provided.backgroundColor,
      borderWidth: variant === "grey" ? "0" : "1px",
      ":hover": {
        ...provided[":hover"],
        borderColor: "#E2E8F0",
      },
    }),
    placeholder: provided => ({
      ...provided,
      color: "#ABA7A7",
      whiteSpace: "nowrap",
      fontSize: "16px",
    }),
    singleValue: provided => ({
      ...provided,
      color: rest?.color || provided.color,
      fontSize: "16px",
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: rest?.indicatorColor || provided.color,
      display: "none",
    }),
    input: provided => ({
      ...provided,
      autoComplete: "off",
    }),
  };

  function handleChange(selectedValue: any) {
    onChange(id, selectedValue);
  }

  return (
    <Box as={FormControl} my={4} {...containerProps}>
      {label && (
        <FormLabel fontSize="14px" fontWeight={700} color="neutral.500" mb={1}>
          {label}
        </FormLabel>
      )}
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_API_URI}
        apiOptions={{ language: "en", region: "NG" }}
        autocompletionRequest={{ componentRestrictions: { country: ["ng"] } }}
        selectProps={{
          id: id,
          instanceId: id,
          placeholder: placeholder || "",
          onChange: handleChange,
          onBlur: onBlur,
          value: value,
          isSearchable: showSearch,
          isClearable: clearable,
          isDisabled: isDisabled,
          required: isRequired,
          styles: customStyles,
          isLoading: isLoading,
        }}
      />

      {error && <SDInputErrorMessage error={error} />}

      {helperText && <SDInputHelperText helperText={helperText} />}
    </Box>
  );
}

export default SDGooglePlacesAutoComplete;
