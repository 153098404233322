import { Button, ButtonProps } from "@chakra-ui/react";

const SDButton = ({ children, ...rest }: ButtonProps) => {
  const styles: ButtonProps = {
    p: "16px 24px",
    fontWeight: "600",
    borderRadius: "8px",
    ...(rest.colorScheme === "primary" && (!rest.variant || rest.variant === "solid")
      ? { _hover: { boxShadow: "0px 2px 5px 0px #070529a0" } }
      : {}),
  };

  return (
    <Button size="lg" {...styles} {...rest}>
      {children}
    </Button>
  );
};

export default SDButton;
