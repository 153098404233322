import { MainLoader } from "components/global";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AllRoutes } from "routes/AllRoutes";
import { COOKIES_KEYS } from "utils/cookies";

function HandoverPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");
  const type = searchParams.get("type");
  const xAccess = searchParams.get("x_access");

  useEffect(() => {
    if (token && type && xAccess) {
      Cookies.set(COOKIES_KEYS.TOKEN, token);
      Cookies.set(COOKIES_KEYS.USER_TYPE, type);
      Cookies.set(COOKIES_KEYS.X_ACCESS, xAccess);

      navigate(AllRoutes.providerDashboard);
    } else {
      navigate(-1);
    }
  }, [navigate, token, type, xAccess]);

  return <MainLoader />;
}

export default HandoverPage;
