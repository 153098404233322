import * as Yup from "yup";
import { isRideHailingTenant } from "./utilFunctions";

const portalRegExp = /^[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?$/;

const validationSchema = {
  providerLogin: Yup.object().shape({
    email: Yup.string().trim().email("Invalid Email").required().label("Email Address"),
    password: Yup.string().trim().required().label("Password"),
  }),

  clientLogin: Yup.object().shape({
    email: Yup.string().trim().email("Invalid Email").required().label("Email Address"),
  }),

  providerPersonalInfoSignup: Yup.object().shape({
    firstName: Yup.string().trim().required().label("First Name"),
    lastName: Yup.string().trim().required().label("Last Name"),
    phoneNumber: Yup.string().min(11).max(11).trim().required().label("Phone Number"),
    email: Yup.string().trim().email("Invalid Email").required().label("Email Address"),
    // dob: Yup.string().trim().required().label("Date of Birth"),
    // bvn: Yup.string()
    //   .min(11, "Please enter a valid BVN")
    //   .max(11, "Please enter a valid BVN")
    //   .required()
    //   .label("BVN"),
    // gender: Yup.string()
    //   .transform(obj => obj?.value)
    //   .required()
    //   .label("Gender"),
  }),

  providerBusinessInfoSignup: Yup.object().shape({
    businessName: Yup.string().trim().required().label("Business Name"),
    businessEmail: Yup.string().trim().email("Invalid Email").required().label("Business Email"),
    businessAddress: Yup.string().trim().required().label("Business Address"),
    portal: Yup.string()
      .trim()
      .required()
      .matches(portalRegExp, "Invalid subdomain")
      .label("Website Subdomain"),
    // businessType: Yup.string()
    //   .transform(obj => obj?.value)
    //   .required()
    //   .label("Business Type"),
    // country: Yup.string()
    //   .transform(obj => obj?.value)
    //   .required()
    //   .label("Country"),
    // state: Yup.string()
    //   .transform(obj => obj?.value)
    //   .required()
    //   .label("State"),
    password: Yup.string().trim().min(8).required().label("Password"),
    confirmPassword: Yup.string()
      .trim()
      .nullable()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required()
      .label("Confirm Password"),
  }),

  forgotPassword: Yup.object().shape({
    email: Yup.string().trim().email("Invalid Email").required().label("Email Address"),
  }),

  resetPassword: Yup.object().shape({
    password: Yup.string().trim().min(8).required().label("Password"),
    confirmPassword: Yup.string()
      .trim()
      .nullable()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required()
      .label("Confirm Password"),
  }),

  providerAddCustomer: Yup.object().shape({
    fullName: Yup.string().trim().required().label("Full Name"),
    email: Yup.string().trim().email("Invalid Email").required().label("Email Address"),
    gender: Yup.string()
      .transform(obj => obj?.value)
      .required()
      .label("Gender"),
    phoneNumber: Yup.string().min(11).max(11).trim().required().label("Phone Number"),
  }),

  providerAddCourier: Yup.object().shape({
    profilePic: Yup.string()
      .transform(obj => obj?.url)
      .label("Profile Pic"),
    name: Yup.string().trim().required().label("Name"),
    courierType: Yup.string()
      .transform(obj => obj?.value)
      .required()
      .label("Courier Type"),
    phoneNumber: Yup.string().min(11).max(11).trim().required().label("Phone Number"),
    email: Yup.string().trim().email("Invalid Email").required().label("Email Address"),
    gender: Yup.string()
      .transform(obj => obj?.value)
      .required()
      .label("Gender"),
    address: Yup.string()
      .transform(obj => obj?.value?.place_id)
      .required()
      .label("Residential Address"),
    username: Yup.string().trim().required().label("Username"),
    password: Yup.string().trim().min(8).required().label("Password"),
  }),

  providerEditProfile: Yup.object().shape({
    profilePic: Yup.string()
      .transform(obj => obj?.url)
      .label("Profile Pic"),
    name: Yup.string().trim().required().label("Name"),
    gender: Yup.string()
      .transform(obj => obj?.value)
      .required()
      .label("Gender"),
  }),

  clientEditProfile: Yup.object().shape({
    profilePic: Yup.string()
      .transform(obj => obj?.url)
      .label("Profile Pic"),
    name: Yup.string().trim().required().label("Name"),
    gender: Yup.string()
      .transform(obj => obj?.value)
      .required()
      .label("Gender"),
    phoneNumber: Yup.string().trim().required().label("Phone Number"),
  }),

  providerEditBusinessInfo: Yup.object().shape({
    businessLogo: Yup.string()
      .transform(obj => obj?.url)
      .label("Business Logo"),
    businessAddress: Yup.string().trim().required().label("Business Address"),
    businessType: Yup.string()
      .transform(obj => obj?.value)
      .required()
      .label("Business Type"),
    country: Yup.string()
      .transform(obj => obj?.value)
      .required()
      .label("Country"),
    state: Yup.string()
      .transform(obj => obj?.value)
      .required()
      .label("State"),
  }),

  providerEditAccountDetails: Yup.object().shape({
    accountNumber: Yup.string().min(10).max(10).trim().required().label("Account Number"),
    bank: Yup.string()
      .transform(obj => obj?.value)
      .required()
      .label("Bank Name"),
  }),

  providerChangePassword: Yup.object().shape({
    currentPassword: Yup.string().trim().required().label("Current Password"),
    newPassword: Yup.string().trim().nullable().min(8).required().label("New Password"),
    confirmNewPassword: Yup.string()
      .trim()
      .nullable()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required()
      .label("Confirm Password"),
  }),

  providerEditPricing: Yup.object().shape({
    amount: Yup.number()
      .positive()
      .min(1000, "A minimum of ₦1000 is required")
      .nullable()
      .required()
      .label("Amount"),
    miles: Yup.number().positive().nullable().required().label("Miles"),
  }),

  providerAddNewEmployee: Yup.object().shape({
    name: Yup.string().trim().required().label("Full Name"),
    email: Yup.string().trim().email("Invalid Email").required().label("Email Address"),
    accountNumber: Yup.string().min(10).max(10).trim().required().label("Account Number"),
    bank: Yup.string()
      .transform(obj => obj?.value)
      .required()
      .label("Bank Name"),
    role: Yup.string()
      .transform(obj => obj?.value)
      .required()
      .label("Role"),
  }),

  providerAddNewRole: Yup.object().shape({
    role: Yup.string().trim().required().label("Role"),
  }),

  providerPricing: Yup.object().shape({
    ...(isRideHailingTenant()
      ? {}
      : { customName: Yup.string().trim().required().label("Custom Name") }),
    deliveryType: Yup.string()
      .transform(obj => obj?.value)
      .required()
      .label("Delivery Type"),
    description: Yup.string().trim().required().label("Description"),
    locations: Yup.array()
      .transform(field => (field.length === 1 ? field : []))
      .of(
        Yup.object().shape({
          country: Yup.string()
            .transform(obj => obj?.value)
            .required()
            .label("Country"),
          state: Yup.string()
            .transform(obj => obj?.value)
            .required()
            .label("State"),
          city: Yup.string()
            .transform(obj => obj?.value)
            .required()
            .label("City"),
        })
      )
      .required()
      .label("Locations"),
    costing: Yup.array()
      .of(
        Yup.object().shape({
          from: Yup.number().positive().required().label("From"),
          to: Yup.number().positive().required().label("To"),
          amount: Yup.number().positive().required().label("Amount"),
          type: Yup.string()
            .transform(obj => obj?.value)
            .required()
            .label("Pricing type"),
        })
      )
      .required()
      .label("Pricing"),
  }),

  providerPickupDetails: Yup.object().shape({
    pricingId: Yup.string().required("Please choose a delivery type").label("Delivery Type"),
    customerName: Yup.string().required().label("Customer Name"),
    customerPhoneNumber: Yup.string()
      // .min(11)
      // .max(11)
      .trim()
      .required()
      .label("Customer Phone Number"),
    pickupLocation: Yup.string()
      .transform(obj => obj?.value?.place_id)
      .required()
      .label("Pickup Location"),
    image: Yup.string()
      .transform(obj => obj?.url)
      .required()
      .label("Package Image"),

    parcelDetails: Yup.array()
      .of(
        Yup.object().shape({
          content: Yup.string().required().label("Parcel Content"),
          noOfContents: Yup.number().positive().integer().required().label("No. of Contents"),
          parcelType: Yup.string()
            .transform(obj => obj?.value)
            .required()
            .label("Category"),
        })
      )
      .required()
      .label("Parcel Details"),
  }),

  providerDropoffDetails: Yup.object().shape({
    recipientName: Yup.string().required().label("Recipient Name"),
    recipientPhoneNumber: Yup.string()
      // .min(11)
      // .max(11)
      .trim()
      .required()
      .label("Recipient Phone Number"),
    dropOffLocation: Yup.string()
      .transform(obj => obj?.value?.place_id)
      .required()
      .label("Dropoff Location"),
  }),

  bookARide: Yup.object().shape({
    pricingId: Yup.string().required("Please choose a ride type").label("Ride Type"),
    customerName: Yup.string().required().label("Full Name"),
    customerPhoneNumber: Yup.string()
      // .min(11)
      // .max(11)
      .trim()
      .required()
      .label("Phone Number"),
    pickupLocation: Yup.string()
      .transform(obj => obj?.value?.place_id)
      .required()
      .label("Pickup Location"),
    dropOffLocation: Yup.string()
      .transform(obj => obj?.value?.place_id)
      .required()
      .label("Dropoff Location"),
  }),

  providerKycTier1: Yup.object().shape({
    dob: Yup.string().trim().required().label("Date of Birth"),
    bvn: Yup.string()
      .min(11, "Please enter a valid BVN")
      .max(11, "Please enter a valid BVN")
      .required()
      .label("BVN"),
  }),

  providerKycTier2: Yup.object().shape({
    idType: Yup.string()
      .transform(obj => obj?.value)
      .required()
      .label("ID Type"),
    idNumber: Yup.string().required().label("ID Number"),
    idFile: Yup.string()
      .transform(obj => obj?.url)
      .required()
      .label("ID Image"),
  }),

  providerKycTier3: Yup.object().shape({
    idType: Yup.string()
      .transform(obj => obj?.value)
      .required()
      .label("ID Type"),
    idFile: Yup.string()
      .transform(obj => obj?.url)
      .required()
      .label("ID Image"),
  }),

  clientEstimateOrder: Yup.object().shape({
    pricingId: Yup.string().required("Please choose a delivery type").label("Delivery Type"),
    pickupLocation: Yup.string()
      .transform(obj => obj?.value?.place_id)
      .required()
      .label("Pickup Location"),
    dropOffLocation: Yup.string()
      .transform(obj => obj?.value?.place_id)
      .required()
      .label("Dropoff Location"),
  }),
};

export default validationSchema;
