import { Center } from "@chakra-ui/react";
import { SDButton } from "..";
import { useState } from "react";

interface ITab {
  title: string;
  onClick?: () => void;
  isDisabled?: boolean;
}

interface Props {
  tabs: ITab[];
  defaultTab?: number;
  disableClick?: boolean;
}

function TabSwitch({ tabs, defaultTab, disableClick = false }: Props) {
  const [activeTabIndex, setActiveTabIndex] = useState(defaultTab || 0);

  return (
    <Center>
      <Center
        gap={1}
        borderRadius="12px"
        border="1px solid #0600891A"
        boxShadow="0px 4px 20px 0px #DFDEF0C3"
        py="10px"
        px={{ base: "10px", md: "32px" }}
      >
        {tabs.map((tab, key) => (
          <SDButton
            key={key}
            colorScheme="primary"
            size={{ base: "xs", md: "md" }}
            variant={activeTabIndex === key ? "solid" : "ghost"}
            cursor={disableClick ? "initial" : "pointer"}
            isDisabled={tab?.isDisabled}
            onClick={() => {
              if (disableClick === false && !tab?.isDisabled) {
                setActiveTabIndex(key);
                tab.onClick();
              }
            }}
          >
            {tab.title}
          </SDButton>
        ))}
      </Center>
    </Center>
  );
}

export default TabSwitch;
