import { Center, Icon, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";

interface Props {
  menu: {
    label: React.ReactNode;
    onClick: () => void;
    isDanger?: boolean;
    isDisabled?: boolean;
    isHidden?: boolean;
  }[];
}

function SDMenuDropdown({ menu }: Props) {
  return (
    <Menu>
      <Center>
        <MenuButton>
          <Icon as={BsThreeDotsVertical} w={4} h={4} color="neutral.800" cursor="pointer" />
        </MenuButton>
      </Center>

      <MenuList p={0} color="neutral.100" borderRadius="10px" overflow="hidden" minW="150px">
        {menu.map((item, key) => (
          <MenuItem
            key={key}
            fontSize="12px"
            fontWeight="600"
            px={4}
            py={3}
            onClick={item?.onClick}
            isDisabled={item?.isDisabled}
            color={item?.isDanger ? "#F32E2E" : "inherit"}
            _hover={{
              background: item?.isDanger ? "#F32E2E" : "#D9D9D944",
              color: item?.isDanger ? "white" : "neutral.500",
            }}
            hidden={item?.isHidden}
          >
            {item.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

export default SDMenuDropdown;
