import { Flex } from "@chakra-ui/react";
import { WhiteBox } from "components/global";
import {
  AutoAssignOrderSettings,
  PaymentOnDeliverySettings,
  FeeBearerSettings,
  // PriceSettings
} from "..";

const BusinessPreferencesSettings = () => {
  return (
    <WhiteBox>
      <Flex flexDir="column" gap={16} maxW="1000px" mx="auto">
        {/* <PriceSettings /> */}

        <AutoAssignOrderSettings />

        <PaymentOnDeliverySettings />

        <FeeBearerSettings />
      </Flex>
    </WhiteBox>
  );
};

export default BusinessPreferencesSettings;
