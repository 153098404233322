import { Box, FormLabel, FormControl, Textarea, TextareaProps } from "@chakra-ui/react";
import { SDInputErrorMessage, SDInputHelperText } from "components/global";

interface ITextareaProps extends TextareaProps {
  error?: any;
  label?: string;
  helperText?: string | React.ReactNode;
}

const SDTextarea = ({ error, label, helperText, ...rest }: ITextareaProps) => {
  return (
    <Box as={FormControl} my={4}>
      {label && (
        <FormLabel fontSize="14px" fontWeight={700} color="neutral.500" mb={1}>
          {label}
        </FormLabel>
      )}

      <Textarea borderWidth={2} resize="none" fontSize={{ base: "sm", md: "md" }} {...rest} />

      {error && <SDInputErrorMessage error={error} />}

      {helperText && <SDInputHelperText helperText={helperText} />}
    </Box>
  );
};

export default SDTextarea;
