import { Flex, Text, TextProps } from "@chakra-ui/react";
import { SDTag } from "..";

export interface IReviewDataProps {
  title: string;
  value: string | React.ReactNode;
  valueAsTag?: boolean;
  size?: "sm";
  titleSize?: string;
  valueSize?: string;
  isVertical?: boolean;
  valueColor?: string;
  titleColor?: string;
  titleFontWeight?: string;
  valueFontWeight?: string;
  titleProps?: TextProps;
  valueProps?: TextProps;
  [rest: string]: any;
}

const ReviewData = ({
  title,
  value,
  valueAsTag = false,
  size,
  titleSize,
  valueSize,
  isVertical = false,
  valueColor,
  titleColor,
  titleFontWeight,
  valueFontWeight,
  titleProps,
  valueProps,
  ...rest
}: IReviewDataProps) => {
  return (
    <Flex
      flexDir={isVertical ? "column" : "row"}
      align={isVertical ? "flex-start" : "center"}
      justify="space-between"
      gap={isVertical ? 2 : 1}
      py={size === "sm" ? "2" : "4"}
      borderBottom="1px dashed #A0A0AA"
      {...(!isVertical && {
        _first: { paddingTop: "0px" },
        _last: { paddingBottom: "0px", borderBottom: "none" },
      })}
      {...rest}
    >
      <Text
        fontSize={{ base: "small", md: titleSize || size || "md" }}
        color={titleColor || "#01001B"}
        lineHeight="14px"
        fontWeight={titleFontWeight || 800}
        textTransform="capitalize"
        {...titleProps}
      >
        {title}
      </Text>

      {valueAsTag ? (
        <SDTag>{String(value)}</SDTag>
      ) : (
        <Text
          fontSize={{ base: "small", md: valueSize || size || "14px" }}
          fontWeight={valueFontWeight || 500}
          lineHeight="14px"
          color={valueColor || "#656566"}
          textAlign={isVertical ? "left" : "right"}
          textTransform="capitalize"
          {...valueProps}
        >
          {value}
        </Text>
      )}
    </Flex>
  );
};

export default ReviewData;
