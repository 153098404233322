import { handleError } from "services";
import apiClient from "services/axios";
import { saveCustomers } from "redux/slices/adminSlices/customerSlice";
import { store } from "redux/store";
import { ICustomerDetails } from "./types";
import { setUpQuery } from "utils/utilFunctions";

const { dispatch } = store;

export const handleFetchCustomers = async (page: number = 1, size: number = 20) => {
  try {
    const query = setUpQuery({
      page: page,
      size: size,
    });

    const res = await apiClient.get(`provider/customers` + query);

    dispatch(saveCustomers(res?.data));

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleCreateCustomer = async (data: ICustomerDetails, onSuccess: () => void) => {
  try {
    const res = await apiClient.post(`provider/customer`, data);

    handleFetchCustomers();

    onSuccess();

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleDeleteCustomer = async (id: string, onSuccess: () => void) => {
  try {
    const res = await apiClient.delete(`provider/customer/${id}`);

    handleFetchCustomers();

    onSuccess();

    return res;
  } catch (error) {
    handleError(error);
  }
};
