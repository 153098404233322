import {
  Avatar,
  Box,
  Center,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { EmptyState, MainLoader, SDButton } from "components/global";
import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "components/global/Layouts/DashboardLayout";
import { CourierProfileCard } from "components/admin/courier";
import { db } from "utils/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import ReactMapGl, {
  Marker,
  NavigationControl,
  FullscreenControl,
  Layer,
  Source,
  LineLayer,
} from "react-map-gl";
import {
  RiderMarkerIcon,
  PickUpMarkerIcon,
  DropOffMarkerIcon,
  TriangleMarkerIcon,
} from "utils/customIcons";
import { IShipment } from "redux/slices/adminSlices/shipmentSlice/types";
import { handleGetMapRoute, handleProviderFetchSingleShipment } from "services/admin/shimpent";
import { calculateZoom, getValidSubdomain } from "utils/utilFunctions";
import { IUserType } from "types/global";
import { handleClientGetSingleOrder } from "services/client/orderHistory";

interface IRiderLocationMatrix {
  id: string;
  base: { lat: number; lng: number };
  current: { lng: number; lat: number };
}

interface IViewPort {
  latitude: number;
  longitude: number;
  zoom: number;
}

interface Props {
  role: IUserType;
}

function ShipmentRoutePage({ role }: Props) {
  const navigate = useNavigate();
  const params = useParams();

  const shipmentId = useMemo(
    () => (role === "provider" ? params?.shipmentId : params?.orderId),
    [params?.orderId, params?.shipmentId, role]
  );

  const [isLoading, setIsLoading] = useState(true);
  const [shipmentToView, setShipmentToView] = useState<IShipment>(null);

  // Coming from firebase
  const [riderLocationMetrix, setRiderLocationMetrix] = useState<IRiderLocationMatrix>(null);

  // To show the travel path
  const [pathCoordinates, setPathCoordinates] = useState<number[][]>([]);

  const [viewPort, setViewPort] = useState<IViewPort>({ latitude: 0, longitude: 0, zoom: 10 });

  const pickupCoordinates = useMemo(
    () => ({
      longitude: shipmentToView?.billing?.pickUp?.lng,
      latitude: shipmentToView?.billing?.pickUp?.lat,
    }),
    [shipmentToView]
  );

  const dropoffCoordinates = useMemo(
    () => ({
      longitude: shipmentToView?.billing?.dropOff?.lng,
      latitude: shipmentToView?.billing?.dropOff?.lat,
    }),
    [shipmentToView]
  );

  // Doc Ref
  const docRef = useMemo(() => {
    const firebaseLocation = shipmentToView?.dispatch?.firebaseLocation;

    if (!!firebaseLocation) {
      const portal = getValidSubdomain();

      return doc(db, `riders/location/${portal}/${firebaseLocation || ""}`);
    }
  }, [shipmentToView?.dispatch?.firebaseLocation]);

  useEffect(() => {
    let unsubscribe: any = () => {};

    if (docRef) {
      unsubscribe = onSnapshot(docRef, snapshot => {
        if (snapshot.exists) {
          const riderLocationMetrix = snapshot.data();

          setRiderLocationMetrix(riderLocationMetrix as IRiderLocationMatrix);
        }
      });
    }

    return () => {
      // Unsubscribe when the component unmounts
      unsubscribe();
    };
  }, [docRef]);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      const res =
        role === "provider"
          ? await handleProviderFetchSingleShipment(shipmentId)
          : await handleClientGetSingleOrder(shipmentId);
      if (res?.data) setShipmentToView(res?.data);

      setIsLoading(false);
    }

    if (shipmentId) fetchData();
  }, [role, shipmentId]);

  useEffect(() => {
    async function getPathCoordinates() {
      const res = await handleGetMapRoute(pickupCoordinates, dropoffCoordinates);

      if (res?.data) {
        const coordinates: number[][] = res?.data?.routes[0]?.geometry?.coordinates;

        setPathCoordinates(coordinates);

        // Calculate bounding box of the path
        const bounds = coordinates.reduce(
          (acc, coord) => ({
            minLon: Math.min(acc.minLon, coord[0]),
            minLat: Math.min(acc.minLat, coord[1]),
            maxLon: Math.max(acc.maxLon, coord[0]),
            maxLat: Math.max(acc.maxLat, coord[1]),
          }),
          { minLon: Infinity, minLat: Infinity, maxLon: -Infinity, maxLat: -Infinity }
        );

        // Adjust viewport to fit the entire path
        const newViewport = {
          latitude: (bounds.minLat + bounds.maxLat) / 2,
          longitude: (bounds.minLon + bounds.maxLon) / 2,
          zoom: calculateZoom(bounds),
        };

        setViewPort(prev => ({ ...prev, ...newViewport }));
      }
    }

    if (
      pickupCoordinates?.longitude &&
      pickupCoordinates?.latitude &&
      dropoffCoordinates?.longitude &&
      dropoffCoordinates?.latitude
    ) {
      getPathCoordinates();
    }
  }, [dropoffCoordinates, pickupCoordinates]);

  const geojson: any = useMemo(
    () => ({
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: [...pathCoordinates],
          },
          properties: {},
        },
      ],
    }),
    [pathCoordinates]
  );

  const lineStyle: LineLayer = {
    id: "roadLayer",
    type: "line",
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": "#070529",
      "line-width": 5,
      "line-opacity": 0.75,
    },
  };

  const markers = useMemo(
    () => (
      <>
        {/* Pickup Location Marker */}
        <Marker {...pickupCoordinates}>
          <MarkerPopover
            title="Pick-up Location"
            description={shipmentToView?.pickUpDetails?.location?.description}
          >
            <PickUpMarkerIcon w={{ base: "24px", md: "40px" }} h={{ base: "36px", mmd: "64px" }} />
          </MarkerPopover>
        </Marker>

        {/* Dropoff Location Marker */}
        <Marker {...dropoffCoordinates}>
          <MarkerPopover
            title="Drop-off Location"
            description={shipmentToView?.dropOffDetails?.location?.description}
          >
            <DropOffMarkerIcon w={{ base: "24px", md: "40px" }} h={{ base: "36px", mmd: "64px" }} />
          </MarkerPopover>
        </Marker>

        {/* Courier Location Marker */}
        {riderLocationMetrix && (
          <Marker
            latitude={riderLocationMetrix?.current?.lat}
            longitude={riderLocationMetrix?.current?.lng}
          >
            <MarkerPopover
              title={shipmentToView?.dispatch?.fullName}
              description={shipmentToView?.dispatch?.transportType}
            >
              <Center flexDir="column" pos="relative">
                <Avatar
                  size={{ base: "sm", md: "lg" }}
                  name={shipmentToView?.dispatch?.fullName}
                  src={shipmentToView?.dispatch?.profilePicture}
                  mb={-2}
                  zIndex={3}
                />
                <TriangleMarkerIcon w="21px" h="19px" mb={-5} zIndex={2} />
                <RiderMarkerIcon
                  w={{ base: "32px", md: "52px" }}
                  h={{ base: "32px", md: "52px" }}
                  zIndex={1}
                />
              </Center>
            </MarkerPopover>
          </Marker>
        )}
      </>
    ),
    [dropoffCoordinates, pickupCoordinates, riderLocationMetrix, shipmentToView]
  );

  return (
    <DashboardLayout
      role={role}
      pageTitle=""
      documentTitle={role === "provider" ? "Track Shipment" : "Track Order"}
      isFullBackground={!shipmentToView || !shipmentId ? false : true}
    >
      {isLoading ? (
        <MainLoader />
      ) : !shipmentToView || !shipmentId ? (
        <Center h="full">
          <EmptyState
            variant="lg"
            message="Order not found"
            actionBtnText="Go back"
            onActionBtnClick={() => navigate(-1)}
          />
        </Center>
      ) : (
        <Box position="relative">
          {shipmentToView?.dispatch && (
            <Box position="absolute" top="30px" left="30px" zIndex={10}>
              <CourierProfileCard shipmentToView={shipmentToView} />
            </Box>
          )}

          <Box w="full" h="95vh">
            {pathCoordinates.length > 0 && (
              <ReactMapGl
                reuseMaps
                mapboxAccessToken={process.env.REACT_APP_MAP_TOKEN}
                mapStyle="mapbox://styles/kloudlot/clpyaz2sf004b01qs4i7hckm1"
                style={{ height: "100%" }}
                onMove={evt => setViewPort(evt.viewState)}
                {...viewPort}
                interactive={true}
                padding={{ top: 20, bottom: 20, right: 20, left: 20 }}
                attributionControl={false}
              >
                {/* Path Line */}
                <Source id="routeSource" type="geojson" data={geojson}>
                  <Layer {...lineStyle} />
                </Source>

                <NavigationControl />

                <FullscreenControl />

                {markers}
              </ReactMapGl>
            )}
          </Box>

          <SDButton
            position="fixed"
            bottom={10}
            right={10}
            colorScheme="primary"
            onClick={() => navigate(-1)}
          >
            Back
          </SDButton>
        </Box>
      )}
    </DashboardLayout>
  );
}

interface IMarkerPopover {
  children: React.ReactNode;
  title: string;
  description: string;
}

function MarkerPopover({ title, description, children }: IMarkerPopover) {
  const { isOpen, onClose, onToggle } = useDisclosure();
  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Box onMouseEnter={onToggle} onMouseLeave={onToggle} onClick={onToggle}>
          {children}
        </Box>
      </PopoverTrigger>

      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader border={0}>
          <Heading fontSize={"14px"} fontWeight={700}>
            {title}
          </Heading>
        </PopoverHeader>
        <PopoverBody>
          <Text color="neutral.500" fontWeight={400} fontSize="sm">
            {description}
          </Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default ShipmentRoutePage;
