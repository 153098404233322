import { SideDrawer } from "components/global";
import React from "react";
import { IUserType } from "types/global";
import SideBar from "./SideBar";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  role: IUserType;
}
function MobileSideBar({ onClose, isOpen, role }: Props) {
  return (
    <SideDrawer
      onClose={onClose}
      isOpen={isOpen}
      size="xs"
      placement="left"
      closeButtonColor="white"
    >
      <SideBar role={role} />
    </SideDrawer>
  );
}

export default MobileSideBar;
