import { useEffect, useMemo, useState } from "react";
import { Box, Text, Flex, Heading, Circle, Center, Skeleton, Square } from "@chakra-ui/react";
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { fetchRevenueChart } from "services/admin/dashboard";
import { IRevenueChartType } from "services/admin/dashboard/types";
import { useAppSelector } from "redux/store";
import { selectRevenueChart } from "redux/slices/adminSlices/dashboardSlice";
import numeral from "numeral";
import { WalletAddIcon } from "utils/customIcons";
import { REVENUE_CHART_TYPES } from "utils/constants";
import { formatAmount } from "utils/utilFunctions";

ChartJS.register(
  LineElement,
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip
);

function RevenueChart() {
  const revenueChart = useAppSelector(selectRevenueChart);

  const [isLoading, setIsLoading] = useState(true);
  const [activeFilter, setActiveFilter] = useState<IRevenueChartType>("1D");

  const data = useMemo(
    () => ({
      labels: revenueChart?.chartData?.labels as number[],
      datasets: [
        {
          label: "",
          data: revenueChart?.chartData?.values,
          backgroundColor: "#CEF3DB",
          borderColor: "#09C549",
          fill: false,
          tension: 0.4,
        },
      ],
    }),
    [revenueChart?.chartData?.labels, revenueChart?.chartData?.values]
  );

  const options = useMemo(
    () => ({
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {},
        y: {
          min: 0,
          ticks: {
            callback: (value: number) => `₦${numeral(value).format("0.[0]a")}`,
          },
        },
      },
    }),
    []
  );

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      await fetchRevenueChart(activeFilter);

      setIsLoading(false);
    }

    fetchData();
  }, [activeFilter]);

  return (
    <Flex
      flexDir="column"
      gap={{ base: 5, md: 10 }}
      box-shadow="0px 4px 4px 0px #F7F6FB"
      borderWidth={1}
      bgColor="white"
      p={4}
      borderRadius="10px"
    >
      <Flex align="center" justify="space-between">
        <Heading fontSize={["18px", "18px", "22px", "28px"]}>Revenue Overview</Heading>

        <Flex align="center" gap={1} bgColor="#07052912" borderRadius="4px" py={1} px={2}>
          <Circle backgroundColor="#28CC60" size="10px" />
          <Text fontSize="12px" color="#000000" fontWeight={500}>
            Earned
          </Text>
        </Flex>
      </Flex>

      <Flex
        flexDir={{ base: "column", md: "row" }}
        align={{ md: "center" }}
        justify="space-between"
        gap={4}
        mb={2}
      >
        <Flex gap={3}>
          <Square
            flexShrink={0}
            borderRadius={14}
            bgColor="#07052912"
            p={{ base: "10px", md: "14px" }}
          >
            <WalletAddIcon boxSize={{ base: 6, md: 8 }} />
          </Square>
          <Box>
            <Text color="#000000" fontSize={{ base: "12px", md: "14px" }} lineHeight="17px" mb={1}>
              Total earned
            </Text>
            <Skeleton isLoaded={!isLoading}>
              <Heading fontWeight={700} fontSize={{ base: "16px", md: "24px" }} lineHeight="30px">
                {formatAmount(
                  revenueChart?.chartData?.values?.reduce(
                    (accumulator, currentValue) => accumulator + currentValue,
                    0
                  )
                )}
              </Heading>
            </Skeleton>
          </Box>
        </Flex>

        <Flex gap="10px" align="center" alignSelf="flex-end">
          {REVENUE_CHART_TYPES.map((item, key) => (
            <Center
              key={key}
              p={{ base: "4px", md: "14px" }}
              borderRadius={8}
              cursor="pointer"
              bgColor={activeFilter === item ? "#000000" : "#07052912"}
              onClick={() => setActiveFilter(item)}
            >
              <Text
                fontWeight={500}
                color={activeFilter === item ? "#FFFFFF" : "neutral.500"}
                fontSize={{ base: "12px", md: "md" }}
              >
                {item}
              </Text>
            </Center>
          ))}
        </Flex>
      </Flex>

      <Skeleton isLoaded={!isLoading}>
        <Line data={data} options={options} />
      </Skeleton>
    </Flex>
  );
}

export default RevenueChart;
