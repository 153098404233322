import { Text, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { SDMenuDropdown, SDTable, SDTag, SuccessModal, WarningModal } from "components/global";
import {
  handleProviderCancelOrder,
  handleProviderGetRecentShipments,
  handleProviderGetShipments,
} from "services/admin/shimpent";
import { useAppSelector } from "redux/store";
import {
  selectActiveShipmentStatus,
  selectRecentShipments,
  selectShipments,
  selectShipmentsLoadingState,
} from "redux/slices/adminSlices/shipmentSlice";
import { formatDate } from "utils/utilFunctions";
import { IShipment } from "redux/slices/adminSlices/shipmentSlice/types";
import { AssignCourierModal, ShipmentDetailsDrawer } from ".";

interface Props {
  isForRecentOrders?: boolean;
}

function ShipmentTable({ isForRecentOrders = false }: Props) {
  const shipmentsState = useAppSelector(selectShipments);
  const recentShipmentsState = useAppSelector(selectRecentShipments);
  const isShipmentLoading = useAppSelector(selectShipmentsLoadingState);
  const activeShipmentStatus = useAppSelector(selectActiveShipmentStatus);

  const viewOrderDisclosure = useDisclosure();
  const cancelOrderDisclosure = useDisclosure();
  const cancelOrderSuccessDisclosure = useDisclosure();
  const assignOrderDisclosure = useDisclosure();

  const [activeRecord, setActiveRecord] = useState<IShipment>(null);
  const [isCancellingOrder, setIsCancellingOrder] = useState(false);

  const tableHeader = [
    {
      label: "Destination",
      dataIndex: "dropOffDetails",
      render: (dropOffDetails: any) =>
        dropOffDetails?.location?.description ? (
          <Text title={dropOffDetails?.location?.description}>
            {dropOffDetails?.location?.description.length <= 30
              ? dropOffDetails?.location?.description
              : dropOffDetails?.location?.description.substring(0, 30) + "..."}
          </Text>
        ) : (
          "---"
        ),
    },
    {
      label: "Courier name",
      dataIndex: "dispatch",
      render: dispatch => dispatch?.fullName || "---",
    },
    {
      label: "Order status",
      dataIndex: "orderStatus",
      render: (status: string) => <SDTag>{status}</SDTag>,
    },
    {
      label: "Tracking ref",
      dataIndex: "trackingRef",
    },
    {
      label: "Date",
      dataIndex: "createdAt",
      render: (date: string) => formatDate(date),
    },
    { label: "Status", dataIndex: "shipmentStatus", render: status => <SDTag>{status}</SDTag> },
    {
      label: "Action",
      dataIndex: "action",
      render: (_, record: IShipment) => (
        <SDMenuDropdown
          menu={[
            {
              label: "View Order",
              onClick: () => {
                setActiveRecord(record);
                viewOrderDisclosure.onOpen();
              },
            },
            {
              label: "Assign to Rider",
              onClick: () => {
                setActiveRecord(record);
                assignOrderDisclosure.onOpen();
              },
              isDisabled:
                record?.orderStatus === "Assigned" || record?.shipmentStatus === "Cancelled",
              isHidden:
                record?.orderStatus === "Assigned" || record?.shipmentStatus === "Cancelled",
            },
            {
              label: "Cancel Order",
              onClick: () => {
                setActiveRecord(record);
                cancelOrderDisclosure.onOpen();
              },
              isDanger: true,
              isDisabled:
                record?.originator !== "provider" ||
                !["Pending", "Accepted"].includes(record?.shipmentStatus),
              isHidden:
                record?.originator !== "provider" ||
                !["Pending", "Accepted"].includes(record?.shipmentStatus),
            },
          ]}
        />
      ),
    },
  ];

  async function getShipmentsData(page?: number) {
    if (isForRecentOrders) {
      await handleProviderGetRecentShipments();
    } else {
      await handleProviderGetShipments(page, null, activeShipmentStatus);
    }
  }

  async function handleCancelOrder() {
    setIsCancellingOrder(true);

    await handleProviderCancelOrder(activeRecord?._id, "provider", () => {
      cancelOrderDisclosure.onClose();
      cancelOrderSuccessDisclosure.onOpen();
    });

    setIsCancellingOrder(false);
  }

  useEffect(() => {
    getShipmentsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SDTable
        header={tableHeader}
        isLoading={isShipmentLoading}
        body={isForRecentOrders ? recentShipmentsState.records : shipmentsState.records}
        {...(isForRecentOrders
          ? {}
          : {
              paginationConfig: shipmentsState.pagination,
              onPaginationChange: page => getShipmentsData(page),
            })}
      />

      <ShipmentDetailsDrawer
        isOpen={viewOrderDisclosure.isOpen}
        onClose={viewOrderDisclosure.onClose}
        shipmentToView={activeRecord}
        role="provider"
      />

      <WarningModal
        isOpen={cancelOrderDisclosure.isOpen}
        onClose={cancelOrderDisclosure.onClose}
        message={`Are you sure you want to cancel this order with the tracking ref #${activeRecord?.trackingRef}?`}
        onBtnClick={handleCancelOrder}
        isLoading={isCancellingOrder}
      />

      <SuccessModal
        isOpen={cancelOrderSuccessDisclosure.isOpen}
        onClose={cancelOrderSuccessDisclosure.onClose}
        message="Order cancelled."
      />

      <AssignCourierModal
        isOpen={assignOrderDisclosure.isOpen}
        onClose={assignOrderDisclosure.onClose}
        shipmentToAssign={activeRecord}
      />
    </>
  );
}

export default ShipmentTable;
