import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Center, Flex, Grid, Image, Text } from "@chakra-ui/react";
import { GreyBox, ReviewData, SDButton, SDModal } from "components/global";
import { PayslipDownloadIcon } from "utils/customIcons";
import blackLogo from "assets/logo/black-logo.png";
import { SUPPORT_EMAIL } from "utils/constants";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  payrollToView: any;
}

function PayslipModal({ isOpen, onClose, payrollToView }: Props) {
  return (
    <SDModal isOpen={isOpen} onClose={onClose} size="md">
      <Image src={blackLogo} alt="Sendrail" maxW="128px" />

      <Flex alignItems="center" my="30px" fontWeight="500" fontSize="18px">
        <ChevronLeftIcon
          boxSize={30}
          cursor="pointer"
          transition="0.1s"
          _hover={{ transform: "translateX(-3px)" }}
        />
        <Text>10, Dec, 2023</Text>
        <ChevronRightIcon
          boxSize={30}
          cursor="pointer"
          transition="0.1s"
          _hover={{ transform: "translateX(3px)" }}
        />
      </Flex>

      <Flex
        flexDir="column"
        gap={10}
        border="1px dashed #595956"
        borderRadius="10px"
        p="25px"
        position="relative"
      >
        <Center
          position="absolute"
          left="35%"
          top="30%"
          transform="translateX(-35%)"
          zIndex={-1}
          opacity={0.07}
        >
          <Image
            src={blackLogo}
            alt="Sendrail"
            transform="rotate(-45deg)"
            w="200%"
            maxW="initial"
          />
        </Center>

        <Box>
          <Text fontSize="18px" fontWeight="700" mb={8} textAlign="center">
            Courier's Information
          </Text>
          <Box mt="10px">
            <ReviewData title="Name" value="Micheal miracle" />
            <ReviewData title="Designation" value="Rider" />
            <ReviewData title="Rider ID" value="SNDR/22/109" />
          </Box>
        </Box>

        <Box>
          <Text fontSize="18px" fontWeight="700" mb={8} textAlign="center">
            Payment Details
          </Text>
          <Box mt="10px">
            <ReviewData title="Pay date" value="10-12-2022" />
            <ReviewData title="Payroll ID" value="#11122890" />
            <ReviewData title="Tax ID" value="#11122890" />
            <ReviewData title="Pension ID" value="#11122890" />
          </Box>
        </Box>

        <Box>
          <Text fontSize="18px" fontWeight="700" mb={8} textAlign="center">
            Earnings
          </Text>
          <Box mt="10px">
            <ReviewData title="Basic salary" value="₦60,000" />
            <ReviewData title="Utility" value="₦0.00" />
            <ReviewData title="Bonus" value="₦0.00" />
          </Box>

          <GreyBox borderRadius="4px" px={2} py={2} mt={4}>
            <ReviewData title="Total" value="₦60,000.00" />
          </GreyBox>
        </Box>
      </Flex>

      <Grid placeItems="center">
        <Text
          maxW="262px"
          mx="auto"
          my="30px"
          color="neutral.200"
          fontSize="14px"
          textAlign="center"
        >
          For questions and enquires, send a mail to{" "}
          <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
        </Text>

        <SDButton
          leftIcon={<PayslipDownloadIcon />}
          variant="outline"
          colorScheme="secondary"
          color="#000000"
          border="1px solid #e2b631"
          fontSize="16px"
          onClick={() => alert("Rest, Not yet implemented")}
          mb={30}
        >
          Download Payslip
        </SDButton>
      </Grid>
    </SDModal>
  );
}

export default PayslipModal;
