import { ChangePassword } from "./ChangePassword";

const SecuritySettings = () => {
  return (
    <>
      <ChangePassword />
    </>
  );
};

export default SecuritySettings;
