import { ChatIcon, PhoneIcon } from "@chakra-ui/icons";
import { Avatar, Box, Flex, Wrap, Text, useDisclosure } from "@chakra-ui/react";
import {
  SDButton,
  SDInput,
  SDSelect,
  SDTextarea,
  ComponentItem,
  SDModal,
  SuccessModal,
  ErrorModal,
  WarningModal,
  MainLoader,
  SDTag,
  GreyBox,
  WhiteBox,
  SDTabs,
  SDTable,
  SDMenuDropdown,
  EmptyState,
  SideDrawer,
  ConfirmModal,
} from "components/global";
import { ITableHeader } from "components/global/Table";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AllRoutes } from "routes/AllRoutes";

interface ITableData {
  imageUrl: string;
  name: string;
  region: string;
  status: string;
  phone: string;
  message: string;
}

function ComponentsPage() {
  const navigate = useNavigate();

  const sampleModalDisclosure = useDisclosure();
  const successModalDisclosure = useDisclosure();
  const errorModalDisclosure = useDisclosure();
  const warningModalDisclosure = useDisclosure();
  const confirmModalDisclosure = useDisclosure();

  const sideDrawerDisclosure = useDisclosure();

  const mainLoaderDisclosure = useDisclosure();

  const tableHeader: ITableHeader[] = [
    {
      label: "Name",
      dataIndex: "name",
      render: (name, record) => (
        <Flex align="center" gap={4}>
          <Avatar name={name} src={record?.imageUrl} />
          <Text fontWeight={700} color="neutral.900">
            {name}
          </Text>
        </Flex>
      ),
    },
    { label: "Region", dataIndex: "region" },
    { label: "Status", dataIndex: "status", render: status => <SDTag>{status}</SDTag> },
    {
      label: "Phone",
      dataIndex: "phone",
      render: phone => (
        <SDButton p={2} size="xs" leftIcon={<PhoneIcon />} colorScheme="neutral" variant="ghost">
          {phone}
        </SDButton>
      ),
    },
    {
      label: "Message",
      dataIndex: "message",
      render: message => (
        <SDButton p={2} size="xs" leftIcon={<ChatIcon />} colorScheme="neutral" variant="ghost">
          {message}
        </SDButton>
      ),
    },
    {
      label: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <SDMenuDropdown
          menu={[
            { label: "View", onClick: () => {} },
            { label: "Edit", onClick: () => {}, isDisabled: true },
            { label: "Delete", onClick: () => {}, isDanger: true },
          ]}
        />
      ),
    },
  ];

  const tableData: ITableData[] = [
    {
      imageUrl: "https://bit.ly/dan-abramov",
      name: "John tae",
      region: "Ikeja",
      status: "Assigned",
      phone: "08059152790",
      message: "Message",
    },
    {
      imageUrl: "https://bit.ly/dan-abramov",
      name: "John tae",
      region: "Ikeja",
      status: "Unassigned",
      phone: "08059152790",
      message: "Message",
    },
  ];

  // Hide the MainLoader after 3 seconds
  useEffect(() => {
    if (mainLoaderDisclosure.isOpen) {
      setTimeout(() => {
        mainLoaderDisclosure.onClose();
      }, 3000);
    }
  }, [mainLoaderDisclosure, mainLoaderDisclosure.isOpen]);

  return (
    <Box p={6} mx="auto" maxW="1200px">
      <Flex justify="flex-end" mb={1}>
        <SDButton
          onClick={() => navigate(AllRoutes.colors)}
          variant="ghost"
          colorScheme="secondary"
        >
          Go to Color pallete page
        </SDButton>
      </Flex>

      <Flex flexDir="column" gap={5}>
        {/* Buttons */}
        <ComponentItem title="Button">
          <Wrap>
            <SDButton colorScheme="primary">Primary</SDButton>
            <SDButton colorScheme="primary" variant="outline">
              Primary
            </SDButton>

            <SDButton colorScheme="secondary">Secondary</SDButton>
            <SDButton colorScheme="secondary" variant="outline">
              Secondary
            </SDButton>

            <SDButton colorScheme="neutral">Neutral</SDButton>
            <SDButton colorScheme="neutral" variant="outline">
              Neutral
            </SDButton>

            <SDButton colorScheme="success">Success</SDButton>
            <SDButton colorScheme="success" variant="outline">
              Success
            </SDButton>

            <SDButton colorScheme="error">Error</SDButton>
            <SDButton colorScheme="error" variant="outline">
              Error
            </SDButton>

            <SDButton>Default</SDButton>
            <SDButton variant="outline">Default</SDButton>
          </Wrap>
        </ComponentItem>

        {/* Disabled Buttons */}
        <ComponentItem title="Disabled Button">
          <Wrap>
            <SDButton isDisabled={true} colorScheme="primary">
              Primary
            </SDButton>
            <SDButton isDisabled={true} colorScheme="primary" variant="outline">
              Primary
            </SDButton>

            <SDButton isDisabled={true} colorScheme="secondary">
              Secondary
            </SDButton>
            <SDButton isDisabled={true} colorScheme="secondary" variant="outline">
              Secondary
            </SDButton>

            <SDButton isDisabled={true} colorScheme="neutral">
              Neutral
            </SDButton>
            <SDButton isDisabled={true} colorScheme="neutral" variant="outline">
              Neutral
            </SDButton>

            <SDButton isDisabled={true} colorScheme="success">
              Success
            </SDButton>
            <SDButton isDisabled={true} colorScheme="success" variant="outline">
              Success
            </SDButton>

            <SDButton isDisabled={true} colorScheme="error">
              Error
            </SDButton>
            <SDButton isDisabled={true} colorScheme="error" variant="outline">
              Error
            </SDButton>

            <SDButton isDisabled={true}>Default</SDButton>
            <SDButton isDisabled={true} variant="outline">
              Default
            </SDButton>
          </Wrap>
        </ComponentItem>

        {/* Input Field */}
        <ComponentItem title="Input">
          <SDInput
            id="name"
            type="text"
            label="Name"
            placeholder="John Doe"
            onChange={e => console.log(e)}
            error={["Field error will show here"]}
            helperText="More description about the field"
          />
        </ComponentItem>

        {/* Select Field */}
        <ComponentItem title="Select">
          <SDSelect
            id="gender"
            label="Gender"
            placeholder="Select one"
            onChange={e => console.log(e)}
            options={[
              { label: "Male", value: "M" },
              { label: "Female", value: "F" },
              { label: "Prefer not to say", value: "NIL" },
            ]}
            value={{ label: "", value: "" }}
            error={["Field error will show here"]}
            helperText="More description about the field"
          />
        </ComponentItem>

        {/* Textarea Field */}
        <ComponentItem title="Textarea">
          <SDTextarea
            id="bio"
            label="About you"
            placeholder="Type something"
            onChange={e => console.log(e)}
            helperText="Let's know about you"
          />
        </ComponentItem>

        {/* Modal */}
        <ComponentItem title="Modal">
          <Wrap>
            {/* Sample Modal */}
            <SDButton onClick={sampleModalDisclosure.onOpen}>Open sample modal</SDButton>
            <SDModal
              isOpen={sampleModalDisclosure.isOpen}
              onClose={sampleModalDisclosure.onClose}
              title="Modal Title (Optional)"
            >
              This is a sample modal
            </SDModal>

            {/* Success Modal */}
            <SDButton colorScheme="success" onClick={successModalDisclosure.onOpen}>
              Open success modal
            </SDButton>
            <SuccessModal
              isOpen={successModalDisclosure.isOpen}
              onClose={successModalDisclosure.onClose}
              message="Account created."
            />

            {/* Error Modal */}
            <SDButton colorScheme="error" onClick={errorModalDisclosure.onOpen}>
              Open error modal
            </SDButton>
            <ErrorModal
              isOpen={errorModalDisclosure.isOpen}
              onClose={errorModalDisclosure.onClose}
              caption="We are unable to complete your request due to insufficient funds."
              message="Please kindly fund your wallet to continue."
            />

            {/* Warning Modal */}
            <SDButton colorScheme="red" onClick={warningModalDisclosure.onOpen}>
              Open warning modal
            </SDButton>
            <WarningModal
              isOpen={warningModalDisclosure.isOpen}
              onClose={warningModalDisclosure.onClose}
              message="Are you sure you want to delete this parcel?"
              onBtnClick={() => {}}
              isLoading={false}
            />

            {/* Confirm Modal */}
            <SDButton colorScheme="secondary" onClick={confirmModalDisclosure.onOpen}>
              Open confirm modal
            </SDButton>
            <ConfirmModal
              isOpen={confirmModalDisclosure.isOpen}
              onClose={confirmModalDisclosure.onClose}
              message="Are you sure you want to assign this order parcel?"
              onBtnClick={() => {}}
              isLoading={false}
            />
          </Wrap>
        </ComponentItem>

        {/* Loader */}
        <ComponentItem title="Loader">
          <SDButton onClick={mainLoaderDisclosure.onOpen} colorScheme="secondary">
            Show main loader
          </SDButton>
          {mainLoaderDisclosure.isOpen && <MainLoader />}
        </ComponentItem>

        {/* Empty State */}
        <ComponentItem title="Empty State">
          <Wrap spacing={16}>
            <EmptyState variant="sm" />
            <EmptyState variant="md" />
            <EmptyState
              variant="lg"
              actionBtnText="Create Order"
              onActionBtnClick={() => {}}
              message="No record found"
            />
          </Wrap>
        </ComponentItem>

        {/* Tags */}
        <ComponentItem title="Tag">
          <Wrap>
            <SDTag>Assigned</SDTag>
            <SDTag>Unassigned</SDTag>
            <SDTag>In transit</SDTag>
            <SDTag>Pending</SDTag>
            <SDTag>Cancelled</SDTag>
            <SDTag>Delivered</SDTag>
            <SDTag>Successful</SDTag>
            <SDTag>Scheduled</SDTag>
            <SDTag>Returning</SDTag>
          </Wrap>
        </ComponentItem>

        {/* Side Drawer */}
        <ComponentItem title="Side Drawer">
          <SDButton colorScheme="teal" onClick={sideDrawerDisclosure.onOpen}>
            Open Side drawer
          </SDButton>
          <SideDrawer isOpen={sideDrawerDisclosure.isOpen} onClose={sideDrawerDisclosure.onClose}>
            This is a sample side drawer
          </SideDrawer>
        </ComponentItem>

        {/* Courier Profile Card */}

        {/* Tabs */}
        <ComponentItem title="Tabs">
          <SDTabs
            tabs={[
              { label: "Tab One", content: <GreyBox>Tab Content 1 goes here</GreyBox> },
              { label: "Tab Two", content: <GreyBox>Tab Content 2 goes here</GreyBox> },
              { label: "Tab Three", content: <GreyBox>Tab Content 3 goes here</GreyBox> },
            ]}
          />
        </ComponentItem>

        {/* GreyBox & WhiteBox */}
        <ComponentItem title="GreyBox & WhiteBox" bgColor="#ccc3">
          <Wrap>
            <GreyBox>This is a sample grey box</GreyBox>
            <WhiteBox>This is a sample white box </WhiteBox>
          </Wrap>
        </ComponentItem>

        {/* Table */}
        <ComponentItem title="Table">
          <SDTable header={tableHeader} body={tableData} />
        </ComponentItem>

        {/* Table with no data */}
        <ComponentItem title="Table with no data">
          <SDTable
            header={tableHeader}
            body={[]}
            actionBtnText="Create Order"
            onActionBtnClick={() => {}}
          />
        </ComponentItem>
      </Flex>
    </Box>
  );
}

export default ComponentsPage;
