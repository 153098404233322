import { Avatar, Box, Center, Flex, Icon, Image, Text, useDisclosure } from "@chakra-ui/react";
// import { FiBell } from "react-icons/fi";
import { CgMenuRight } from "react-icons/cg";
import SearchOrderByTrackingRef from "./SearchOrderByTrackingRef";
import MobileSideBar from "./MobileSideBar";
import { IUserType } from "types/global";
import { useAppSelector } from "redux/store";
import { selectClientProfile, selectProviderProfile } from "redux/slices/authSlice";
import { capitalize } from "lodash";
import { Link } from "react-router-dom";
import { AllRoutes } from "routes/AllRoutes";
import NotificationsModal from "components/shared/NotificationsModal";
import { isClientLoggedIn } from "utils/utilFunctions";
import SDButton from "components/global/Button";
import Logo from "assets/logo/yellow-logo.png";

interface Props {
  role: IUserType;
}

function DashboardHeader({ role }: Props) {
  const notificationDisclosure = useDisclosure();
  const mobileSidebarDisclosure = useDisclosure();

  const providerProfile = useAppSelector(selectProviderProfile);
  const clientProfile = useAppSelector(selectClientProfile);

  return (
    <Flex
      align="center"
      justify="space-between"
      bgColor={{ base: "primary.500", md: "#fff" }}
      borderBottom="1px solid #EDEDF3"
      gap={{ base: 6, md: 20 }}
      py={4}
      px={4}
    >
      <Flex gap={{ base: 6, lg: 20 }} flex={1}>
        {/* MOBILE MENU BUTTON */}
        <Center
          display={{ base: "flex", lg: "none" }}
          color={{ base: "#fff", md: "primary.500" }}
          onClick={mobileSidebarDisclosure.onOpen}
        >
          <Icon as={CgMenuRight} boxSize={6} cursor="pointer" />
        </Center>

        {/* MOBILE SIDEBAR */}
        <MobileSideBar
          isOpen={mobileSidebarDisclosure.isOpen}
          onClose={mobileSidebarDisclosure.onClose}
          role={role}
        />

        <Box flex={1} maxW="425px" display={{ base: "none", md: "flex" }}>
          <SearchOrderByTrackingRef role={role} />
        </Box>

        <Center flex={1} display={{ base: "flex", md: "none" }}>
          <Link to={role === "provider" ? AllRoutes.providerDashboard : AllRoutes.clientDashboard}>
            <Image src={Logo} alt="Sendrail" maxW="120px" />
          </Link>
        </Center>
      </Flex>

      <Flex align="center" gap="20px">
        {role === "client" && !isClientLoggedIn() ? (
          <Link to={AllRoutes.clientLogin} style={{ textDecoration: "none" }}>
            <SDButton colorScheme="primary" display={{ base: "none", md: "flex" }}>
              Login/Sign up
            </SDButton>
            <SDButton
              colorScheme="secondary2"
              display={{ base: "flex", md: "none" }}
              size="xs"
              color="primary.500"
              p={3}
            >
              Login/Sign up
            </SDButton>
          </Link>
        ) : (
          <>
            {/* <Flex
              gap="4px"
              padding="7px 10px"
              background="neutral.100"
              borderRadius="12px"
              cursor="pointer"
              display={{ base: "none", md: "flex" }}
              onClick={notificationDisclosure.onOpen}
            >
              <FiBell width="12" height="13px" color="#ffffff" />
              <Text fontWeight="400" fontSize="12px" lineHeight="14px" color="#FFFFFF">
                0
              </Text>
            </Flex> */}

            <NotificationsModal
              isOpen={notificationDisclosure.isOpen}
              onClose={notificationDisclosure.onClose}
            />

            <Link
              to={role === "provider" ? AllRoutes.providerSettings : AllRoutes.clientProfile}
              style={{ textDecoration: "none" }}
            >
              <Flex gap="10px" align="center">
                <Avatar
                  border="1px solid #E2E8F0"
                  size={{ base: "sm", md: "md" }}
                  name={
                    role === "provider"
                      ? providerProfile?.businessInfo?.name || providerProfile?.fullName
                      : clientProfile?.fullName
                  }
                  src={
                    role === "provider"
                      ? providerProfile?.businessInfo?.logo
                      : clientProfile?.avatar
                  }
                />
                <Flex flexDir="column" display={{ base: "none", lg: "flex" }}>
                  <Text
                    fontSize="16px"
                    lineHeight="26px"
                    fontWeight="500"
                    color="#7C7C88"
                    flexWrap="nowrap"
                    whiteSpace="nowrap"
                  >
                    {role === "provider"
                      ? providerProfile?.businessInfo?.name || providerProfile?.fullName
                      : clientProfile?.fullName}
                  </Text>
                  <Text fontSize="12px" lineHeight="14px" fontWeight="450" color="#7C7C88">
                    {role === "provider" ? capitalize(providerProfile?.roles) : "User"}
                  </Text>
                </Flex>
              </Flex>
            </Link>
          </>
        )}
      </Flex>
    </Flex>
  );
}

export default DashboardHeader;
