import { Box, Flex, Heading, Icon, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { SDModal } from "components/global";
import { MdKeyboardArrowRight } from "react-icons/md";
import { AllRoutes } from "routes/AllRoutes";
import { IUserType } from "types/global";
import { ICustomer } from "redux/slices/adminSlices/customerSlice/types";
import { setUpQuery } from "utils/utilFunctions";
import { useMemo } from "react";
import { toast } from "react-toastify";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  role: IUserType;
  customerFromProvider?: ICustomer;
}

function SelectDeliveryTypeModal({ onClose, isOpen, role, customerFromProvider }: Props) {
  const navigate = useNavigate();

  const query = useMemo(
    () =>
      setUpQuery({
        customerName: customerFromProvider?.fullName,
        customerPhoneNumber: customerFromProvider?.phoneNumber,
      }).replace("?", "&"),
    [customerFromProvider?.fullName, customerFromProvider?.phoneNumber]
  );

  const orderOptions = [
    {
      label: "Last mile delivery",
      description: "Delivery within your state.",
      onClick: () =>
        navigate(
          role === "provider"
            ? AllRoutes.providerCreateSingleOrder("lastMile") + query
            : AllRoutes.clientCreateSingleOrder("lastMile")
        ),
    },
    {
      label: "Interstate delivery",
      description: "Delivery from one state to another.",
      onClick: () => toast.info("Coming Soon"),

      // onClick: () =>
      //   navigate(
      //     role === "provider"
      //       ? AllRoutes.providerCreateSingleOrder("interState") + query
      //       : AllRoutes.clientCreateSingleOrder("interState")
      //   ),
    },
  ];

  return (
    <SDModal isOpen={isOpen} onClose={onClose} title="Select delivery type">
      <Flex flexDir="column" gap={8} mb={8}>
        {orderOptions.map((item, key) => (
          <Flex
            key={key}
            onClick={item.onClick}
            cursor="pointer"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
            border="2px solid #0600891A"
            p="16px 24px 16px 24px"
            borderRadius="10px"
            _hover={{ textDecor: "none" }}
            role="group"
          >
            <Box>
              <Heading fontSize="20px" fontWeight={500} lineHeight="24px" mb={1}>
                {item.label}
              </Heading>
              <Text color="neutral.200" fontSize="14px">
                {item.description}
              </Text>
            </Box>
            <Icon
              as={MdKeyboardArrowRight}
              color="#292D32"
              _groupHover={{ transform: "scale(1.3)", transition: "all 150ms ease-in-out" }}
              boxSize={6}
            />
          </Flex>
        ))}
      </Flex>
    </SDModal>
  );
}
export default SelectDeliveryTypeModal;
