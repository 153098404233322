import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { IClientProfile, ITenantProfile, IProviderProfile } from "./types";

// Define a type for the slice state
interface InitialState {
  providerProfile: IProviderProfile | null;
  clientProfile: IClientProfile | null;
  tenantProfile: ITenantProfile | null;
}

// Define the initial state using that type
const initialState: InitialState = {
  providerProfile: null,
  clientProfile: null,
  tenantProfile: null,
};

export const authSlice = createSlice({
  name: "authReducer",
  initialState,
  reducers: {
    saveProviderProfile: (state, action: PayloadAction<IProviderProfile>) => {
      state.providerProfile = action.payload;
    },

    saveClientProfile: (state, action: PayloadAction<IClientProfile>) => {
      state.clientProfile = action.payload;
    },

    saveTenantProfile: (state, action: PayloadAction<ITenantProfile>) => {
      state.tenantProfile = action.payload;
    },
  },
});

export const { saveProviderProfile, saveClientProfile, saveTenantProfile } = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectProviderProfile = (state: RootState) => state.authReducer.providerProfile;
export const selectClientProfile = (state: RootState) => state.authReducer.clientProfile;
export const selectTenantProfile = (state: RootState) => state.authReducer.tenantProfile;

// Reducer
export const authReducer = authSlice.reducer;
