import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { IPricing } from "./types";

// Define a type for the slice state
interface InitialState {
  lastMilePricing: IPricing[];
  interStatePricing: IPricing[];
}

// Define the initial state using that type
const initialState: InitialState = {
  lastMilePricing: [],
  interStatePricing: [],
};

export const settingsSlice = createSlice({
  name: "settingsReducer",
  initialState,
  reducers: {
    savePricing: (state, action: PayloadAction<IPricing[]>) => {
      state.lastMilePricing = action.payload.filter(i => i.pricingType === "Last Mile");
      state.interStatePricing = action.payload.filter(i => i.pricingType === "Inter State");
    },
  },
});

export const { savePricing } = settingsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectLastMilePricing = (state: RootState) => state.settingsReducer.lastMilePricing;
export const selectInterStatePricing = (state: RootState) =>
  state.settingsReducer.interStatePricing;

// Reducer
export const settingsReducer = settingsSlice.reducer;
