import { Box, Flex, Heading, SimpleGrid } from "@chakra-ui/react";
import React, { Dispatch, SetStateAction } from "react";
import { SDButton, SDInput, TabSwitch, WhiteBox } from "components/global";
import { FormikValues, useFormikContext } from "formik";
import SDGooglePlacesAutoComplete from "components/global/Select/SDGooglePlacesAutoComplete";
import { IUserType } from "types/global";

interface Props {
  setPage: Dispatch<SetStateAction<number>>;
  role: IUserType;
}

function DropoffDetailsForm({ setPage, role }: Props) {
  const { values, errors, touched, handleBlur, handleChange, setFieldValue, isSubmitting } =
    useFormikContext<FormikValues>();

  return (
    <WhiteBox
      py={role === "provider" ? [8, 8, 8, 10] : 0}
      px={role === "provider" ? [6, 6, 8, 12] : 0}
    >
      <Box maxW="1000px" mx={role === "provider" ? "auto" : ""}>
        {role === "provider" && (
          <Heading color="neutral.500" fontWeight={600} fontSize="20px">
            Add drop-off details
          </Heading>
        )}

        {role === "client" && (
          <Box mt={12}>
            <TabSwitch
              tabs={[
                { title: "Pick-up details", onClick: () => setPage(1) },
                { title: "Drop-off details", onClick: () => setPage(2) },
              ]}
              defaultTab={1}
            />
          </Box>
        )}

        <SimpleGrid columns={[1, 1, 2]} columnGap={6} mt={8}>
          <SDInput
            id="recipientName"
            type="text"
            label="Recipient’s Name"
            placeholder="Enter full name"
            value={values.recipientName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.recipientName && errors.recipientName}
          />

          <SDInput
            id="recipientPhoneNumber"
            type="tel"
            label="Recipient’s Phone Number"
            placeholder="Enter phone number"
            value={values.recipientPhoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.recipientPhoneNumber && errors.recipientPhoneNumber}
          />

          <SDGooglePlacesAutoComplete
            id="dropOffLocation"
            label="Drop-Off Location"
            placeholder="Search for a location"
            value={values.dropOffLocation}
            onChange={setFieldValue}
            onBlur={handleBlur}
            error={touched.dropOffLocation && errors.dropOffLocation}
          />

          <SDInput
            id="recipientDropOffCode"
            type="text"
            label="Recipient’s Drop-Off Code"
            placeholder=""
            defaultValue={values.recipientDropOffCode}
            onBlur={handleBlur}
            error={touched.recipientDropOffCode && errors.recipientDropOffCode}
            isReadOnly={true}
          />
        </SimpleGrid>

        <Flex mt={8} justify="flex-end" gap={4}>
          <SDButton
            colorScheme="primary"
            variant="outline"
            onClick={() => setPage(page => page - 1)}
          >
            Previous
          </SDButton>
          <SDButton
            colorScheme="primary"
            type="submit"
            isLoading={isSubmitting && values.shipmentFee === 0}
          >
            Confirm
          </SDButton>
        </Flex>
      </Box>
    </WhiteBox>
  );
}

export default DropoffDetailsForm;
