import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";

interface Props extends ModalProps {
  title?: string;
  titleColor?: string;
  showClosingButton?: boolean;
}

function SDModal({
  title,
  titleColor = "",
  showClosingButton = true,
  isOpen,
  onClose,
  size = "xl",
  children,
  ...rest
}: Props) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={size}
      motionPreset="slideInBottom"
      preserveScrollBarGap={true}
      isCentered={true}
      {...rest}
    >
      <ModalOverlay />
      <ModalContent mx={4}>
        {title && (
          <ModalHeader textAlign="center" color={titleColor} borderBottom="1px solid #F5F5F5">
            {title}
          </ModalHeader>
        )}

        {showClosingButton && <ModalCloseButton />}

        <ModalBody py={6}>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default SDModal;
