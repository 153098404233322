import { HStack, Icon, Text } from "@chakra-ui/react";
import {
  BicycleIcon,
  BikeIcon,
  CarIcon,
  VanIcon,
  ExpressBicycleIcon,
  RegularBicycleIcon,
  ExpressBikeIcon,
  RegularBikeIcon,
  ExpressCarIcon,
  RegularCarIcon,
  ExpressTruckIcon,
  RegularTruckIcon,
  SingleRideIcon,
  SharedRideIcon,
} from "./customIcons";
import { IPaymentMethod } from "types/global";
import { IRevenueChartType } from "services/admin/dashboard/types";
import { isRideHailingTenant } from "./utilFunctions";

export const SUPPORT_EMAIL = "support@sendrail.co";

export const SENDRAIL_WEBSITE = "https://sendrail.co";

export const REVENUE_CHART_TYPES: IRevenueChartType[] = ["1D", "1W", "1M", "1Y"];

export const SELECT_ALL_OPTION_TYPE = {
  label: (
    <Text color="blue.500" cursor="pointer">
      Select all
    </Text>
  ),
  value: "select_all",
};

export const PARCEL_TYPE_OPTIONS = [
  { label: "Computer Accessories", value: "Computer Accessories" },
  { label: "Clothings", value: "Clothings" },
  { label: "Phones", value: "Phones" },
  { label: "Documents", value: "Documents" },
  { label: "Electronics", value: "Electronics" },
  { label: "Others", value: "Others" },
];

export const COURIER_OPTIONS = [
  { label: "Bike rider", value: "Bike rider", icon: BikeIcon },
  { label: "Van driver", value: "Van driver", icon: VanIcon },
  { label: "Car driver", value: "Car driver", icon: CarIcon },
  { label: "Bicycle rider", value: "Bicycle rider", icon: BicycleIcon },
];

export const COURIER_TYPE_OPTIONS = COURIER_OPTIONS.map(item => ({
  label: (
    <HStack align="center">
      <Icon as={item.icon} />
      <Text>{item.label}</Text>
    </HStack>
  ),
  value: item.value,
}));

export const DELIVERY_OPTIONS = isRideHailingTenant()
  ? [
      { label: "Single Ride", value: "express_bike", icon: SingleRideIcon },
      { label: "Shared Ride", value: "regular_bike", icon: SharedRideIcon },
    ]
  : [
      { label: "Express Bicycle", value: "express_bicycle", icon: ExpressBicycleIcon },
      { label: "Regular Bicycle", value: "regular_bicycle", icon: RegularBicycleIcon },
      { label: "Express Bike", value: "express_bike", icon: ExpressBikeIcon },
      { label: "Regular Bike", value: "regular_bike", icon: RegularBikeIcon },
      { label: "Express Car", value: "express_car", icon: ExpressCarIcon },
      { label: "Regular Car", value: "regular_car", icon: RegularCarIcon },
      { label: "Express Truck", value: "express_truck", icon: ExpressTruckIcon },
      { label: "Regular Truck", value: "regular_truck", icon: RegularTruckIcon },
    ];

export const DELIVERY_TYPE_OPTIONS = DELIVERY_OPTIONS.map(item => ({
  label: (
    <HStack align="center">
      <Icon as={item.icon} />
      <Text>{item.label}</Text>
    </HStack>
  ),
  value: item.value,
  altLabel: item.label, // For Ride Hailing
}));

export const COST_TYPE_OPTIONS = [
  { label: "Fixed price", value: "fixed" },
  { label: "Price per km", value: "per_distance_km" },
];

export const GENDER_TYPE_OPTIONS = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

export const BUSINESS_TYPE_OPTIONS = [
  { label: "Logistics Provider", value: "Logistics Provider" },
  { label: "Restaurant & Kitchen", value: "Restaurant & Kitchen" },
  { label: "E-commerce & Corporates", value: "E-commerce & Corporates" },
];

export const NEW_EMPLOYEE_ROLES_TYPE_OPTIONS = [
  { label: "Customer care agent", value: "Customer care agent" },
  { label: "Accountant", value: "Accountant" },
  { label: "Create custom role", value: "Create custom role" },
];

export const PAYROLL_TYPE_OPTIONS = [
  { label: "Salary", value: "salary" },
  { label: "Commission", value: "commission" },
];

export const PAYROLL_SALARY_FREQUENCY_TYPE_OPTIONS = [
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
];

export const PAYROLL_COMMISSION_FREQUENCY_TYPE_OPTIONS = [
  { label: "Percentage", value: "percentage" },
  { label: "Flat", value: "flat" },
];

export const TIER_2_ID_OPTIONS = [
  { label: "National ID", value: "nin" },
  { label: "Passport", value: "passport" },
  { label: "Voter’s Card", value: "voters_card" },
];

export const TIER_3_ID_OPTIONS = [
  { label: "Water bill", value: "water_bill" },
  { label: "Electricity bill", value: "electricity_bill" },
];

export const PAYMENT_METHOD_OPTIONS: { label: string; value: IPaymentMethod }[] = [
  {
    label: isRideHailingTenant() ? "Payment on drop-off" : "Payment on delivery",
    value: "At Delivery",
  },
  { label: "Pay now", value: "At Creation" },
  { label: "Pay on pick-up", value: "At Pickup" },
];

export const SHIPMENT_STATS_TYPE_OPTIONS = [
  { label: "All time", value: "" },
  { label: "Today", value: "today" },
  { label: "This week", value: "week" },
  { label: "This month", value: "month" },
  // DO NOT CHANGE ANY THING HERE
  { label: "Custom", value: "custom" },
];
