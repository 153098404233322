import { Box, Flex, Heading, Link, SimpleGrid, Spinner, useDisclosure } from "@chakra-ui/react";
import {
  WhiteBox,
  SDInput,
  SDSelect,
  SDButton,
  SuccessModal,
  SDInputHelperText,
} from "components/global";
import { useFormik } from "formik";
import validationSchema from "utils/validationSchema";
import { selectProviderProfile } from "redux/slices/authSlice";
import { useAppSelector } from "redux/store";
import { selectBankLists } from "redux/slices/adminSlices/dashboardSlice";
import { handleProviderUpdateAccountDetails, handleResolveAccount } from "services/admin/settings";
import { SUPPORT_EMAIL } from "utils/constants";
import { useEffect, useMemo, useState } from "react";

interface IFormValues {
  accountNumber: string;
  bank: { label: string; value: string };
  accountName: string;
}

const BankInfoSettings = () => {
  const providerProfile = useAppSelector(selectProviderProfile);
  const bankLists = useAppSelector(selectBankLists);

  const successDisclosure = useDisclosure();

  const [isresolvingAccount, setIsresolvingAccount] = useState(false);

  const isAccountAdded = useMemo(
    () => !!providerProfile?.bankDetails?.bankAccountNumber,
    [providerProfile?.bankDetails?.bankAccountNumber]
  );

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    isSubmitting,
    handleSubmit,
  } = useFormik({
    initialValues: {
      accountNumber: providerProfile?.bankDetails?.bankAccountNumber || "",
      bank: providerProfile?.bankDetails?.bankCode
        ? {
            label: providerProfile?.bankDetails?.bankName,
            value: providerProfile?.bankDetails?.bankCode,
          }
        : null,
      accountName: providerProfile?.bankDetails?.accountName || "",
    },
    validationSchema: validationSchema.providerEditAccountDetails,
    onSubmit: async (values: IFormValues) => {
      const data = {
        bankCode: values.bank?.value,
        bankName: values.bank?.label,
        bankAccountNumber: values.accountNumber,
        accountName: values.accountName,
      };

      await handleProviderUpdateAccountDetails(data, () => {
        successDisclosure.onOpen();
      });
    },
  });

  useEffect(() => {
    async function resolveAccount() {
      setIsresolvingAccount(true);

      const data = {
        bankCode: values.bank?.value,
        accountNumber: values.accountNumber,
      };

      setFieldValue("accountName", "");

      await handleResolveAccount(data, accountName => {
        setFieldValue("accountName", accountName);
      });

      setIsresolvingAccount(false);
    }

    if (!isAccountAdded) {
      if (values.bank?.value && values.accountNumber && values.accountNumber.length === 10) {
        resolveAccount();
      } else {
        setFieldValue("accountName", "");
      }
    }
  }, [isAccountAdded, setFieldValue, values.accountNumber, values.bank?.value]);

  return (
    <>
      <WhiteBox>
        <Box maxW="1000px" mx="auto">
          <Heading color="neutral.500" fontWeight={600} fontSize="20px" mb={8}>
            Account Details
          </Heading>

          <SDInputHelperText
            as={Flex}
            align="flex-start"
            color="neutral.500"
            textProps={{ color: "neutral.200", mt: -1 }}
            helperText={
              isAccountAdded ? (
                <>
                  To change your saved bank account kindly reach out to{" "}
                  <Link as="a" color="secondary.500" href={`mailto:${SUPPORT_EMAIL}`}>
                    {SUPPORT_EMAIL}
                  </Link>
                </>
              ) : (
                "For smooth payment, add an existing account you would like to receive your payment with."
              )
            }
          />
          <form onSubmit={handleSubmit} onBlur={handleBlur}>
            <SimpleGrid columns={[1, 1, 2]} columnGap={12} mt={8}>
              <SDSelect
                id="bank"
                label="Bank Name"
                placeholder="Select a bank"
                options={bankLists.map(item => ({
                  label: item.name,
                  value: item.code,
                }))}
                value={values.bank}
                onChange={setFieldValue}
                error={touched.bank && errors.bank}
                isDisabled={isAccountAdded}
              />

              <SDInput
                id="accountNumber"
                type="text"
                label="Account Number"
                placeholder="9999999999"
                value={values.accountNumber}
                onChange={e => e.target.value.length <= 10 && handleChange(e)}
                error={touched.accountNumber && errors.accountNumber}
                min={10}
                max={10}
                helperText={
                  isresolvingAccount ? <Spinner colorScheme="primary" /> : values.accountName
                }
                helperTextContainerProps={{ color: "success.500", textTransform: "uppercase" }}
                isDisabled={isAccountAdded}
              />
            </SimpleGrid>

            {isAccountAdded === false && (
              <SDButton
                mt="48px"
                colorScheme="primary"
                type="submit"
                isLoading={isSubmitting}
                isDisabled={!values.accountName.trim()}
              >
                Save Changes
              </SDButton>
            )}
          </form>
        </Box>
      </WhiteBox>

      <SuccessModal
        isOpen={successDisclosure.isOpen}
        onClose={successDisclosure.onClose}
        message="Changes applied."
      />
    </>
  );
};

export default BankInfoSettings;
