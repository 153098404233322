import { Avatar, Box, Flex, Text, useDisclosure, HStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { SearchIcon } from "@chakra-ui/icons";
import PayslipModal from "./PayslipModal";
import { useNavigate } from "react-router-dom";
import { BiFilter } from "react-icons/bi";
import { AllRoutes } from "routes/AllRoutes";
import { SDInput, SDButton, SDTable, SDMenuDropdown } from "components/global";
import { AddEmployeeModal } from ".";

interface IPayrollUser {
  id: number;
  fullName: string;
  email: string;
  payroll: string;
  income: string;
  type: string;
}

const payrollUsers: IPayrollUser[] = [
  {
    id: 1,
    fullName: "John Tae",
    email: "tousie@gmail.com",
    payroll: "Commission",
    income: "#65,000",
    type: "Motorcycle Rider",
  },
  {
    id: 2,
    fullName: "John Tae",
    email: "tousie@gmail.com",
    payroll: "Salary",
    income: "#65,000",
    type: "Motorcycle Rider",
  },
  {
    id: 3,
    fullName: "John Tae",
    email: "tousie@gmail.com",
    payroll: "Salary",
    income: "#65,000",
    type: "Motorcycle Rider",
  },
  {
    id: 4,
    fullName: "John Tae",
    email: "tousie@gmail.com",
    payroll: "Commission",
    income: "#65,000",
    type: "Motorcycle Rider",
  },
];

function CouriersPayroll() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [payrollToView, setPayrollToView] = useState<IPayrollUser>(null);
  const viewPayrollDisclosure = useDisclosure();
  const addEmployeeDisclosure = useDisclosure();

  const tableHeader = [
    {
      label: "Name",
      dataIndex: "fullName",
      render: (fullName, record) => (
        <Flex align="center" gap={4}>
          <Avatar name={fullName} src={record?.profilePicture} />
          <Text fontWeight={700} color="neutral.900">
            {fullName}
          </Text>
        </Flex>
      ),
    },
    { label: "Email", dataIndex: "email" },
    { label: "Payroll", dataIndex: "payroll" },
    { label: "Income", dataIndex: "income" },
    { label: "Type", dataIndex: "type" },
    {
      label: "Action",
      dataIndex: "action",
      render: (_, record: IPayrollUser) => (
        <SDMenuDropdown
          menu={[
            {
              label: "View payslip",
              onClick: () => {
                setPayrollToView(record);
                viewPayrollDisclosure.onOpen();
              },
            },
            {
              label: "Edit payroll",
              onClick: () => navigate(AllRoutes.providerEditPayroll),
            },
          ]}
        />
      ),
    },
  ];

  async function fetchData(page?: number) {
    setIsLoading(true);

    // await handleFetchPayrollUsers(page);

    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box>
      <Flex
        flexDir={{ base: "column-reverse", md: "row" }}
        gap={{ md: 4 }}
        align={{ md: "center" }}
        justify="space-between"
        mb={6}
      >
        <HStack>
          <SDInput
            placeholder="Search by name"
            addOnElementAfter={<SearchIcon color="neutral.200" />}
          />

          <SDButton leftIcon={<BiFilter />} variant="outline" colorScheme="gray">
            Filter
          </SDButton>
        </HStack>

        <HStack>
          <SDButton
            colorScheme="primary"
            variant="outline"
            onClick={() => addEmployeeDisclosure.onOpen()}
          >
            Add Employee
          </SDButton>
          {/* TODO: How can we select multiple users when creating a payroll */}
          <SDButton colorScheme="primary" onClick={() => navigate(AllRoutes.providerAddPayroll)}>
            Create Payroll
          </SDButton>
        </HStack>
      </Flex>

      <SDTable
        header={tableHeader}
        isLoading={isLoading}
        body={payrollUsers}
        // paginationConfig={couriersState.pagination}
        // onPaginationChange={page => fetchData(page)}
      />

      <PayslipModal
        isOpen={viewPayrollDisclosure.isOpen}
        onClose={viewPayrollDisclosure.onClose}
        payrollToView={payrollToView}
      />

      <AddEmployeeModal
        isOpen={addEmployeeDisclosure.isOpen}
        onClose={addEmployeeDisclosure.onClose}
      />
    </Box>
  );
}

export default CouriersPayroll;
