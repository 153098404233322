import { useDisclosure, Flex, HStack, Box } from "@chakra-ui/react";
import { ShipmentStats, ShipmentTable, CreateOrderModal } from "components/admin/shipment";
import { SDButton, SDFilterButton } from "components/global";
import DashboardLayout from "components/global/Layouts/DashboardLayout";
import SearchOrderByTrackingRef from "components/global/Layouts/DashboardLayout/SearchOrderByTrackingRef";
import { useNavigate } from "react-router-dom";
import { AllRoutes } from "routes/AllRoutes";

function ShipmentPage() {
  const createOrderDisclosure = useDisclosure();

  const navigate = useNavigate();

  return (
    <DashboardLayout role="provider" pageTitle="Shipment">
      <ShipmentStats isForShipment={true} />

      <Flex
        gap={4}
        justifyContent="space-between"
        alignItems={{ md: "center" }}
        flexDir={{ base: "column", md: "row" }}
        mt={12}
        mb={8}
      >
        <HStack flex={1}>
          <Box flex={1} maxW="425px">
            <SearchOrderByTrackingRef role="provider" size="lg" removeFancyStyles={true} />
          </Box>
          <SDFilterButton />
        </HStack>

        <SDButton
          colorScheme="primary"
          onClick={() => navigate(AllRoutes.providerCreateSingleOrder("lastMile"))}
          // onClick={createOrderDisclosure.onOpen}
        >
          Create Order
        </SDButton>
      </Flex>

      <ShipmentTable />

      <CreateOrderModal
        onClose={createOrderDisclosure.onClose}
        isOpen={createOrderDisclosure.isOpen}
      />
    </DashboardLayout>
  );
}

export default ShipmentPage;
