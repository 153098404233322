export const sampleData: {
  title: string;
  message: string;
  time: string;
}[] = [
  {
    title: "John Toe",
    message: "Order Successfully delivered",
    time: "5 mins ago",
  },
  {
    title: "John Tae",
    message: "Order successfully assigned to Rider Daniel Jones",
    time: "7 mins ago",
  },
  {
    title: "SendRail",
    message: "Order Available for pickup",
    time: "7 hours ago",
  },
];
