import React, { useState } from "react";
import { ReviewData, SDButton, SDVerticalStepper } from "components/global";
import {
  CalendarIcon,
  EmptyWalletIcon,
  ProfileFilledIcon,
  TrackFilledIcon,
} from "utils/customIcons";
import { IShipment } from "redux/slices/adminSlices/shipmentSlice/types";
import {
  attachSubdomainPath,
  convertMetersToKilometers,
  formatAmount,
  getValidSubdomain,
} from "utils/utilFunctions";
import { PAYMENT_METHOD_OPTIONS } from "utils/constants";
import { handleInitiateOrderPayment } from "services/admin/shimpent";
import { AllRoutes } from "routes/AllRoutes";
import { IUserType } from "types/global";

interface Props {
  shipmentToView: IShipment;
  role: IUserType;
}

function RideDetails({ shipmentToView, role }: Props) {
  const [isInitiatingPayment, setIsInitiatingPayment] = useState(false);

  async function handlePayNow() {
    setIsInitiatingPayment(true);

    await handleInitiateOrderPayment(
      shipmentToView?._id,
      attachSubdomainPath(
        getValidSubdomain(),
        role === "provider" ? AllRoutes.providerShipment : AllRoutes.clientRecentActivities
      )
    );

    setIsInitiatingPayment(false);
  }

  const steps = [
    ...(shipmentToView?.pickUpDetails?.scheduleForPickUp?.enterDate
      ? [
          {
            title: "Scheduled for",
            description: (
              <>
                {[
                  {
                    title: "Date",
                    value: shipmentToView?.pickUpDetails?.scheduleForPickUp?.enterDate || "---",
                  },
                  {
                    title: "Time",
                    value: shipmentToView?.pickUpDetails?.scheduleForPickUp?.enterTime || "---",
                  },
                ].map((item, key) => (
                  <ReviewData
                    key={key}
                    title={item?.title}
                    value={item?.value}
                    borderBottom="none"
                    titleColor="neutral.200"
                    valueColor="neutral.500"
                    titleFontWeight="400"
                    valueFontWeight="400"
                  />
                ))}
              </>
            ),
            icon: CalendarIcon,
          },
        ]
      : []),
    {
      title: "Personal details",
      description: (
        <>
          {[
            { title: "Full Name", value: shipmentToView?.pickUpDetails?.customerName },
            {
              title: "Phone Number",
              value: shipmentToView?.pickUpDetails?.customerPhoneNumber,
            },
          ].map((item, key) => (
            <ReviewData
              key={key}
              title={item?.title}
              value={item?.value}
              borderBottom="none"
              titleColor="neutral.200"
              valueColor="neutral.500"
              titleFontWeight="400"
              valueFontWeight="400"
            />
          ))}
        </>
      ),
      icon: ProfileFilledIcon,
    },
    {
      title: "Pick-up details",
      description: (
        <>
          {[
            // TODO: Fix the title with last mile or international
            // { title: "Last mile delivery type", value: "Bike" },
            {
              title: "Pick-up Location",
              value: shipmentToView?.pickUpDetails?.location?.description,
            },
            { title: "Pick-up Code", value: shipmentToView?.pickUpDetails?.customerPickUpCode },
          ].map((item, key) => (
            <ReviewData
              key={key}
              title={item?.title}
              value={item?.value}
              borderBottom="none"
              titleColor="neutral.200"
              valueColor="neutral.500"
              titleFontWeight="400"
              valueFontWeight="400"
            />
          ))}
        </>
      ),
      icon: TrackFilledIcon,
    },
    {
      title: "Drop-off details",
      description: (
        <>
          {[
            {
              title: "Drop-off Location",
              value: shipmentToView?.dropOffDetails?.location?.description,
            },
            { title: "Drop-off Code", value: shipmentToView?.dropOffDetails?.recipientDropOffCode },
          ].map((item, key) => (
            <ReviewData
              key={key}
              title={item?.title}
              value={item?.value}
              borderBottom="none"
              titleColor="neutral.200"
              valueColor="neutral.500"
              titleFontWeight="400"
              valueFontWeight="400"
            />
          ))}
        </>
      ),
      icon: ProfileFilledIcon,
    },

    {
      title: "Payment fees",
      description: (
        <>
          {[
            {
              title: "Order fee",
              value: formatAmount(shipmentToView?.billing?.cost),
            },
            {
              title: "Distance",
              value: convertMetersToKilometers(shipmentToView?.billing?.distance) + "km",
            },
            {
              title: "Payment method",
              value:
                PAYMENT_METHOD_OPTIONS.find(i => i.value === shipmentToView?.paymentMethod)
                  ?.label || shipmentToView?.paymentMethod,
            },
            {
              title: "Payment status",
              value: shipmentToView?.paymentStatus,
              valueAsTag: true,
            },
          ].map((item, key) => (
            <ReviewData
              key={key}
              title={item?.title}
              value={item?.value}
              valueAsTag={item?.valueAsTag}
              borderBottom="none"
              titleColor="neutral.200"
              valueColor="neutral.500"
              titleFontWeight="400"
              valueFontWeight="400"
            />
          ))}

          {shipmentToView?.paymentStatus === "Pending" &&
            (shipmentToView?.originator === "provider" || role === "client") && (
              <SDButton
                mt={6}
                size="md"
                variant="solid"
                colorScheme="primary"
                onClick={() => handlePayNow()}
                isLoading={isInitiatingPayment}
                isDisabled={shipmentToView?.shipmentStatus === "Cancelled"}
              >
                Proceed to payment
              </SDButton>
            )}
        </>
      ),
      icon: EmptyWalletIcon,
    },
  ];

  return <SDVerticalStepper steps={steps} showBorder={true} />;
}

export default RideDetails;
