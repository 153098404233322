import { Box, Center, Flex, Heading, Text } from "@chakra-ui/react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { AllRoutes } from "routes/AllRoutes";
import { Link } from "react-router-dom";
import { StarInfoIcon } from "utils/customIcons";
import { OrderHistoryTable } from "../orderHistory";
import { isClientLoggedIn } from "utils/utilFunctions";
import { SDButton } from "components/global";

function ClientRecentOrders() {
  if (!isClientLoggedIn()) {
    return (
      <Center flexDir="column" gap={4} py={10} flex={1}>
        <StarInfoIcon color="#292D32" boxSize={{ base: 5, md: 10 }} />

        <Text
          color="neutral.200"
          fontWeight={600}
          textAlign="center"
          fontSize={{ base: "16px", md: "20px" }}
        >
          You need to sign in to view your recent orders and other features
        </Text>

        <Link to={AllRoutes.clientLogin} style={{ textDecoration: "none" }}>
          <SDButton colorScheme="primary" variant="ghost">
            Login/Sign up
          </SDButton>
        </Link>
      </Center>
    );
  }

  return (
    <Box>
      <Flex mb="24px" justify="space-between" align="center">
        <Heading fontSize={["16px", "16px", "18px", "20px"]} color="neutral.500" fontWeight={600}>
          Recent Orders
        </Heading>

        <Flex as={Link} to={AllRoutes.clientOrderHistory} color="secondary2.500" align="center">
          <Text fontSize="14px">View all</Text>
          <MdKeyboardArrowRight />
        </Flex>
      </Flex>

      <OrderHistoryTable isForRecentOrders={true} />
    </Box>
  );
}

export default ClientRecentOrders;
