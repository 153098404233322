import { Box, FormControl, Heading, useDisclosure } from "@chakra-ui/react";
import {
  SDButton,
  SDCurrencyInput,
  SDInput,
  SDInputHelperText,
  SuccessModal,
} from "components/global";
import { Form, Formik } from "formik";
import { selectProviderProfile } from "redux/slices/authSlice";
import { useAppSelector } from "redux/store";
import { handleProviderUpdatePricingSettings } from "services/admin/settings";
import validationSchema from "utils/validationSchema";

interface IFormValues {
  amount: number | string;
  miles: number | string;
}

function PriceSettings() {
  const providerProfile = useAppSelector(selectProviderProfile);

  const successDisclosure = useDisclosure();

  async function handleSubmit(values: IFormValues) {
    const data = {
      cost: Number(values.amount),
      distance: Number(values.miles),
    };

    await handleProviderUpdatePricingSettings(data, () => {
      successDisclosure.onOpen();
    });
  }

  return (
    <>
      <Box>
        <Heading color="neutral.500" fontWeight={600} fontSize="20px" mb={8}>
          Set price in miles
        </Heading>

        <Formik
          initialValues={{
            amount: providerProfile?.preference?.priceSettings?.cost || "",
            miles: providerProfile?.preference?.priceSettings?.distance || "",
          }}
          validationSchema={validationSchema.providerEditPricing}
          onSubmit={values => handleSubmit(values)}
        >
          {({ values, errors, touched, handleBlur, handleChange, setFieldValue, isSubmitting }) => (
            <Form onBlur={handleBlur}>
              <Box maxW="325px" mt={8}>
                <SDCurrencyInput
                  id="amount"
                  value={String(values.amount)}
                  onChange={values => setFieldValue("amount", values.value)}
                  error={touched.amount && errors.amount}
                  placeholder="1000"
                  addOnElementBefore="₦"
                  hidePrefix={true}
                />

                <SDInput
                  id="miles"
                  type="number"
                  placeholder="10"
                  value={values.miles}
                  onChange={handleChange}
                  error={touched.miles && errors.miles}
                  addOnElementBefore="Mi"
                />
              </Box>

              <FormControl>
                <SDInputHelperText helperText="You can only set a minimum price of ₦1000 per (10 miles) and more." />
              </FormControl>

              <SDButton mt="36px" colorScheme="primary" type="submit" isLoading={isSubmitting}>
                Save Changes
              </SDButton>
            </Form>
          )}
        </Formik>
      </Box>

      <SuccessModal
        isOpen={successDisclosure.isOpen}
        onClose={successDisclosure.onClose}
        message="Price successfully updated."
      />
    </>
  );
}

export default PriceSettings;
