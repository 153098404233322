import { Flex } from "@chakra-ui/react";
import { IShipment } from "redux/slices/adminSlices/shipmentSlice/types";
import { SideDrawer } from "components/global";
import { CourierProfileCard } from "components/admin/courier";
import { ShipmentDetailsCard } from "..";
import { useMemo } from "react";
import { selectShipments, selectRecentShipments } from "redux/slices/adminSlices/shipmentSlice";
import { useAppSelector } from "redux/store";
import { IUserType } from "types/global";
import {
  selectOrderHistory,
  selectRecentOrderHistory,
} from "redux/slices/clientSlices/orderHistorySlice";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  shipmentToView: IShipment;
  role: IUserType;
}

function ShipmentDetailsDrawer({ isOpen, onClose, shipmentToView, role }: Props) {
  // For Providers
  const shipmentsState = useAppSelector(selectShipments);
  const recentShipmentsState = useAppSelector(selectRecentShipments);

  // For Clients
  const orderHistoryState = useAppSelector(selectOrderHistory);
  const recentOrderHistoryState = useAppSelector(selectRecentOrderHistory);

  // Update the value in case of any changes
  shipmentToView = useMemo(
    () =>
      role === "provider"
        ? shipmentsState?.records.find(i => i._id === shipmentToView?._id) ||
          recentShipmentsState?.records.find(i => i._id === shipmentToView?._id) ||
          shipmentToView
        : orderHistoryState?.records.find(i => i._id === shipmentToView?._id) ||
          recentOrderHistoryState?.records.find(i => i._id === shipmentToView?._id) ||
          shipmentToView,
    [
      orderHistoryState?.records,
      recentOrderHistoryState?.records,
      recentShipmentsState?.records,
      role,
      shipmentToView,
      shipmentsState?.records,
    ]
  );

  return (
    <SideDrawer
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton={shipmentToView?.dispatch ? true : false}
    >
      <Flex flexDir="column" gap={8}>
        {shipmentToView?.dispatch && (
          <CourierProfileCard
            alwaysShow={true}
            onCloseAction={onClose}
            shipmentToView={shipmentToView}
            border="none"
            maxW="initial"
          />
        )}

        <ShipmentDetailsCard shipmentToView={shipmentToView} role={role} />
      </Flex>
    </SideDrawer>
  );
}
export default ShipmentDetailsDrawer;
