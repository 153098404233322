import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { IBankList, IRevenuChart, IWallet } from "./types";

// Define a type for the slice state
interface InitialState {
  wallet: IWallet;
  bankLists: IBankList[];
  revenueChart: IRevenuChart;
}

// Define the initial state using that type
const initialState: InitialState = {
  wallet: { ledgerBalance: 0, availableBalance: 0, withdrawableBalance: 0 },
  bankLists: [],
  revenueChart: null,
};

export const dashboardSlice = createSlice({
  name: "dashboardReducer",
  initialState,
  reducers: {
    saveWalletBalance: (state, action: PayloadAction<IWallet>) => {
      state.wallet = action.payload;
    },

    saveBankLists: (state, action: PayloadAction<IBankList[]>) => {
      state.bankLists = action.payload;
    },

    saveRevenueChart: (state, action: PayloadAction<IRevenuChart>) => {
      state.revenueChart = action.payload;
    },
  },
});

export const { saveWalletBalance, saveBankLists, saveRevenueChart } = dashboardSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectWalletBalance = (state: RootState) => state.dashboardReducer.wallet;
export const selectBankLists = (state: RootState) => state.dashboardReducer.bankLists;
export const selectRevenueChart = (state: RootState) => state.dashboardReducer.revenueChart;

// Reducer
export const dashboardReducer = dashboardSlice.reducer;
