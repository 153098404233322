import { Box, Flex, Heading, Icon, Text, useDisclosure } from "@chakra-ui/react";
import { SDModal } from "components/global";
import { MdKeyboardArrowRight } from "react-icons/md";
import { SelectDeliveryTypeModal } from ".";
import { toast } from "react-toastify";

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

function CreateOrderModal({ onClose, isOpen }: Props) {
  const selectDeliveryTypeDisclosure = useDisclosure();

  const orderOptions = [
    {
      label: "Single Order",
      description: "You have access to create a single order.",
      onClick: () => selectDeliveryTypeDisclosure.onOpen(),
    },
    {
      label: "Bulk Orders",
      description: "You are able to create as much orders as you want.",
      onClick: () => toast.info("Coming Soon"),
      // onClick: () => navigate(AllRoutes.providerCreateBulkOrder),
    },
  ];

  return (
    <>
      <SDModal isOpen={isOpen} onClose={onClose} title="How do you want to create this order">
        <Flex flexDir="column" gap={8} mb={8}>
          {orderOptions.map((item, key) => (
            <Flex
              key={key}
              onClick={item.onClick}
              cursor="pointer"
              justifyContent="space-between"
              alignItems="center"
              gap={1}
              border="2px solid #0600891A"
              p="16px 24px 16px 24px"
              borderRadius="10px"
              _hover={{ textDecor: "none" }}
              role="group"
            >
              <Box>
                <Heading fontSize="20px" fontWeight={500} lineHeight="24px" mb={1}>
                  {item.label}
                </Heading>
                <Text color="neutral.200" fontSize="14px">
                  {item.description}
                </Text>
              </Box>
              <Icon
                as={MdKeyboardArrowRight}
                color="#292D32"
                _groupHover={{ transform: "scale(1.3)", transition: "all 150ms ease-in-out" }}
                boxSize={6}
              />
            </Flex>
          ))}
        </Flex>
      </SDModal>

      <SelectDeliveryTypeModal
        isOpen={selectDeliveryTypeDisclosure.isOpen}
        onClose={selectDeliveryTypeDisclosure.onClose}
        role="provider"
      />
    </>
  );
}
export default CreateOrderModal;
