import { Flex, Box, Heading, useDisclosure } from "@chakra-ui/react";
import { SDButton, SuccessModal, WhiteBox } from "components/global";
import { Form, Formik } from "formik";
import DashboardLayout from "components/global/Layouts/DashboardLayout";
import { useNavigate } from "react-router-dom";
import { AllRoutes } from "routes/AllRoutes";
import { handleCreateCourier } from "services/admin/courier";
import validationSchema from "utils/validationSchema";
import { IUploadValue } from "components/global/Upload/FileUploadWrapper";
import { IGoogleAddress } from "types/global";
import { CourierForm } from "components/admin/courier";

interface IFormValues {
  profilePic: IUploadValue;
  name: string;
  courierType: { label: string; value: string };
  phoneNumber: string;
  email: string;
  gender: { label: string; value: string };
  address: IGoogleAddress;
  username: string;
  password: string;
}

const AddCourierPage = () => {
  const navigate = useNavigate();

  const addCourierSuccessDisclosure = useDisclosure();

  async function handleSubmit(values: IFormValues) {
    const data = {
      profilePicture: values.profilePic?.url,
      fullName: values.name,
      phoneNumber: values.phoneNumber,
      dateOfBirth: "",
      residentialAddress: {
        placeId: values.address?.value?.place_id,
        description: values.address?.value?.description,
      },
      userName: values.username,
      transportType: values.courierType?.value,
      email: values.email,
      gender: values.gender?.value,
      accountPassword: values.password,
    };

    await handleCreateCourier(data, () => {
      addCourierSuccessDisclosure.onOpen();
    });
  }

  return (
    <>
      <DashboardLayout
        role="provider"
        pageTitle="Add Courier"
        isGreyBackground={true}
        showBackButton={true}
      >
        <WhiteBox>
          <Box maxW="1000px" mx="auto">
            <Heading color="neutral.500" fontWeight={600} fontSize="20px" mb={8}>
              Personal Information
            </Heading>

            <Formik
              initialValues={{
                profilePic: null,
                name: "",
                courierType: null,
                phoneNumber: "",
                email: "",
                gender: null,
                address: null,
                username: "",
                password: "",
              }}
              validationSchema={validationSchema.providerAddCourier}
              onSubmit={values => handleSubmit(values)}
            >
              {({ isSubmitting }) => (
                <Form>
                  <CourierForm />

                  <Flex
                    mt="48px"
                    gap={4}
                    align={{ md: "center" }}
                    justify="space-between"
                    flexDir={{ base: "column", md: "row" }}
                  >
                    <SDButton colorScheme="primary" type="submit" isLoading={isSubmitting}>
                      Save Courier
                    </SDButton>
                  </Flex>
                </Form>
              )}
            </Formik>
          </Box>
        </WhiteBox>
      </DashboardLayout>

      <SuccessModal
        isOpen={addCourierSuccessDisclosure.isOpen}
        onClose={addCourierSuccessDisclosure.onClose}
        onBtnClick={() => navigate(AllRoutes.providerCourier)}
        message="Courier added."
      />
    </>
  );
};

export default AddCourierPage;
