import axios, { InternalAxiosRequestConfig } from "axios";
import { COOKIES_KEYS } from "utils/cookies";
import Cookies from "js-cookie";
import { getValidSubdomain } from "utils/utilFunctions";

const BASE_URL = process.env.REACT_APP_BACKEND;

const defaultOptions = {
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
};

const apiClient = axios.create(defaultOptions);

// Set the AUTH token for any request
apiClient.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    // Get token & x_access from the Cookies
    const token = Cookies.get(COOKIES_KEYS.TOKEN);
    const x_access = Cookies.get(COOKIES_KEYS.X_ACCESS);
    const xTenantId = config.headers!["x-tenant-id"];

    if (token) config.headers!.Authorization = `Bearer ${token}`;
    if (x_access) config.headers!["x-access-id"] = `${x_access}`;

    // Attach the current subdomain as the tenant id or set it to 'register'
    if (!xTenantId) config.headers!["x-tenant-id"] = getValidSubdomain();

    return config;
  }
);

export default apiClient;
