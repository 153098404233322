import { Box, Flex, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import { ComponentItem, SDButton } from "components/global";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AllRoutes } from "routes/AllRoutes";
import { colors } from "theme/color";

function ColorsPage() {
  const navigate = useNavigate();

  return (
    <Box p={6} mx="auto" maxW="1200px">
      <Flex justify="flex-end" mb={1}>
        <SDButton
          onClick={() => navigate(AllRoutes.components)}
          variant="ghost"
          colorScheme="secondary"
        >
          Go to Components page
        </SDButton>
      </Flex>

      <ComponentItem title="Color Pallete">
        <VStack spacing={5} align="flex-start">
          {Object.entries(colors).map(([colorType, colorVariants], key) => (
            <Box key={key}>
              <Heading fontSize="16px" mb={1} textTransform="capitalize">
                {colorType}
              </Heading>
              <HStack>
                {Object.entries(colorVariants).map(([variant, hex], key) => (
                  <ColorItem key={key} type={colorType} variant={`.${variant}`} hex={hex} />
                ))}
              </HStack>
            </Box>
          ))}
        </VStack>
      </ComponentItem>
    </Box>
  );
}

export default ColorsPage;

function ColorItem({ type, variant, hex }: any) {
  return (
    <CopyToClipboard text={type + variant} onCopy={() => toast.info(`Copied (${type + variant})!`)}>
      <Box cursor="pointer" transition="all 200ms ease-out" _hover={{ transform: "scale(1.05)" }}>
        <Box
          borderRadius={4}
          borderWidth={1}
          borderColor={type + ".200"}
          bgColor={type + variant}
          w="65px"
          h="45px"
        />
        <Text fontSize="10px" fontWeight="700" textAlign="center">
          {variant}
          <br /> ({hex.toUpperCase()})
        </Text>
      </Box>
    </CopyToClipboard>
  );
}
