import { Heading, Text, Center } from "@chakra-ui/react";
import { SuccessIcon } from "utils/customIcons";
import SDModal from ".";
import { SDButton } from "..";
import modalBg from "assets/svg/modalBg.svg";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  message: string;
  btnText?: string;
  onBtnClick?: () => void;
}

function SuccessModal({ isOpen, onClose, message, btnText, onBtnClick }: Props) {
  function handleClose() {
    !!onBtnClick && onBtnClick();
    onClose();
  }
  return (
    <SDModal isOpen={isOpen} onClose={handleClose} showClosingButton={false} size="md">
      <Center
        flexDir="column"
        bgImage={modalBg}
        bgRepeat="no-repeat"
        bgSize="cover"
        bgPosition="center"
      >
        <SuccessIcon w="55px" h="55px" mb={8} />

        <Heading
          textTransform="uppercase"
          color="success.500"
          fontWeight={700}
          fontSize="20px"
          mb={6}
          textAlign="center"
        >
          Success
        </Heading>

        <Text fontWeight={300} fontSize="18px" textAlign="center" mb={12}>
          {message}
        </Text>

        <SDButton colorScheme="primary" onClick={handleClose} w={{ md: "60%" }}>
          {btnText || "Continue"}
        </SDButton>
      </Center>
    </SDModal>
  );
}

export default SuccessModal;
