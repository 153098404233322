import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { PaginatedResponse } from "types/global";
import { ITransaction } from "./types";

// Define a type for the slice state
interface InitialState {
  isTransactionsLoading: boolean;
  transactions: PaginatedResponse<ITransaction[]>;
}

// Define the initial state using that type
const initialState: InitialState = {
  isTransactionsLoading: false,
  transactions: { records: [], pagination: null },
};

export const transactionSlice = createSlice({
  name: "transactionReducer",
  initialState,
  reducers: {
    saveIsTransactionsLoading: (state, action: PayloadAction<boolean>) => {
      state.isTransactionsLoading = action.payload;
    },

    saveTransactions: (state, action: PayloadAction<PaginatedResponse<ITransaction[]>>) => {
      state.transactions = action.payload;
    },
  },
});

export const { saveTransactions, saveIsTransactionsLoading } = transactionSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectIsTransactionLoading = (state: RootState) =>
  state.transactionReducer.isTransactionsLoading;
export const selectTransactions = (state: RootState) => state.transactionReducer.transactions;

// Reducer
export const transactionReducer = transactionSlice.reducer;
