import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  DrawerProps,
} from "@chakra-ui/react";

interface Props extends DrawerProps {
  closeButtonColor?: string;
  hideCloseButton?: boolean;
}

function SideDrawer({
  isOpen,
  onClose,
  size = "lg",
  closeButtonColor = "primary.500",
  children,
  hideCloseButton = false,
  ...rest
}: Props) {
  return (
    <Drawer isOpen={isOpen} onClose={onClose} size={size} preserveScrollBarGap={true} {...rest}>
      <DrawerOverlay />
      <DrawerContent bgColor="transparent">
        {hideCloseButton === false && <DrawerCloseButton color={closeButtonColor} />}
        <DrawerBody p={0}>{children}</DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default SideDrawer;
