import { Box, BoxProps, FormControl, FormLabel, useDisclosure } from "@chakra-ui/react";
import FileStack from "./FileStack";
import { SDInputErrorMessage } from "..";
import { PropsWithChildren } from "react";

export interface IUploadValue {
  filename: string;
  url: string;
}

export interface UploadProps extends PropsWithChildren, BoxProps {
  onFileChange: (name: string, val: IUploadValue) => void;
  name: string;
  label?: string;
  value?: IUploadValue;
  error?: any;
  isDisabled?: boolean;
  supportedFileTypes?: string[];
}

function SDFileUploadWrapper({
  label,
  error,
  isDisabled,
  name,
  value,
  onFileChange,
  children,
  supportedFileTypes,
  ...rest
}: UploadProps) {
  const fileStackDisclosure = useDisclosure();

  return (
    <Box as={FormControl} my={4}>
      <FileStack
        isOpen={fileStackDisclosure.isOpen}
        onClose={fileStackDisclosure.onClose}
        supportedFileTypes={supportedFileTypes}
        onUploadComplete={(url, filename) => {
          onFileChange(name, { filename, url });
        }}
      />

      {!!label && (
        <FormLabel fontSize="14px" fontWeight={700} color="neutral.500" mb={1}>
          {label}
        </FormLabel>
      )}

      <Box
        onClick={e => (!isDisabled ? fileStackDisclosure.onOpen() : e.preventDefault())}
        w="fit-content"
        cursor="pointer"
        {...rest}
      >
        {children}
      </Box>

      {error && <SDInputErrorMessage error={error} />}
    </Box>
  );
}

export default SDFileUploadWrapper;
