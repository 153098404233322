import { Flex, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import { SDButton, SDFileUpload, SDModal } from "components/global";
import SDSelect, { IOptions } from "components/global/Select";
import { IUploadValue } from "components/global/Upload/FileUploadWrapper";
import { useFormik } from "formik";
import { selectProviderProfile } from "redux/slices/authSlice";
import { useAppSelector } from "redux/store";
import { handleUpgradeTier3 } from "services/admin/dashboard";
import { TIER_3_ID_OPTIONS } from "utils/constants";
import validationSchema from "utils/validationSchema";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface IFormValues {
  idType: IOptions;
  idFile: IUploadValue;
}

function UpgradeToTier3Modal({ isOpen, onClose }: Props) {
  const providerProfile = useAppSelector(selectProviderProfile);

  const { values, errors, touched, setFieldValue, handleBlur, isSubmitting, handleSubmit } =
    useFormik({
      initialValues: {
        idType: TIER_3_ID_OPTIONS.find(i => i.value === providerProfile?.kyc?.level3?.type) || null,
        idFile: providerProfile?.kyc?.level3?.imageUrl
          ? { filename: "", url: providerProfile?.kyc?.level3?.imageUrl }
          : null,
      },
      validationSchema: validationSchema.providerKycTier3,
      onSubmit: async (values: IFormValues) => {
        const data = {
          type: values.idType?.value as string,
          imageUrl: values.idFile?.url,
        };

        await handleUpgradeTier3(data, () => onClose());
      },
    });

  return (
    <SDModal isOpen={isOpen} onClose={onClose} size="2xl">
      <Heading fontSize="20px" fontWeight={600} lineHeight="25px" mb={3}>
        Upgrade Account to Tier 3
      </Heading>
      <Text fontWeight={300} color="neutral.500" mb={8}>
        Upgrade account to tier 3 for the highest transaction limit
      </Text>

      <form onSubmit={handleSubmit} onBlur={handleBlur}>
        <SimpleGrid columns={[1, 1, 2]} columnGap={8}>
          <SDSelect
            id="idType"
            label="ID Type"
            placeholder="Select an option"
            options={TIER_3_ID_OPTIONS}
            value={values.idType}
            onChange={setFieldValue}
            error={touched.idType && errors.idType}
          />
        </SimpleGrid>

        <SDFileUpload
          label="Upload an image of (Water bill or electricity)"
          name="idFile"
          onFileChange={setFieldValue}
          value={values.idFile}
          error={touched.idFile && errors.idFile}
          supportedFileTypes={["image/*", "application/pdf"]}
        />

        <Flex gap={4} mt={10} justify="flex-end">
          <SDButton colorScheme="primary" variant="outline" onClick={onClose}>
            Cancel
          </SDButton>
          <SDButton colorScheme="primary" type="submit" isLoading={isSubmitting}>
            Confirm
          </SDButton>
        </Flex>
      </form>
    </SDModal>
  );
}

export default UpgradeToTier3Modal;
