import { AddIcon } from "@chakra-ui/icons";
import {
  Center,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  SimpleGrid,
  Text,
  Wrap,
} from "@chakra-ui/react";
import { SDButton, SDCurrencyInput, SDInput, SDSelect, WhiteBox } from "components/global";
import { Country, State } from "country-state-city";
import { NIGERIAN_CITIES } from "data/nigerianCities";
import { FieldArray, FormikValues, useFormikContext } from "formik";
import { useEffect, useMemo } from "react";
import { HiOutlineArrowCircleLeft } from "react-icons/hi";
import { IoIosClose } from "react-icons/io";
import { PiXCircleBold } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { selectLastMilePricing } from "redux/slices/adminSlices/settingsSlice";
import { useAppSelector } from "redux/store";
import { handleError } from "services";
import { COST_TYPE_OPTIONS, DELIVERY_TYPE_OPTIONS, SELECT_ALL_OPTION_TYPE } from "utils/constants";
import { isRideHailingTenant } from "utils/utilFunctions";

interface Props {
  isFormForEdit?: boolean;
}

function LastMilePricingForm({ isFormForEdit = false }: Props) {
  const lastMilePricing = useAppSelector(selectLastMilePricing);

  const navigate = useNavigate();
  const { values, errors, touched, handleBlur, handleChange, setFieldValue, isSubmitting } =
    useFormikContext<FormikValues>();

  const citiesToShow = useMemo(
    () =>
      (
        NIGERIAN_CITIES.find(
          i => i?.state.toLowerCase() === values?.locations[0]?.state?.label?.toLowerCase()
        )?.lgas || []
      )
        // Filter out the selected city
        .filter(i => values?.locations.findIndex(item => item?.city?.label === i) === -1)
        .map(city => ({
          label: city,
          value: city,
        })),
    [values?.locations]
  );

  // const citiesToShow = useMemo(
  //   () =>
  //     City.getCitiesOfState(
  //       values?.locations[0]?.country?.value,
  //       values?.locations[0]?.state?.value
  //     )
  //       // Filter out the selected city
  //       .filter(i => values?.locations.findIndex(item => item?.city?.label === i.name) === -1)
  //       .map(city => ({
  //         label: city.name,
  //         value: city.name,
  //       })),
  //   [values?.locations]
  // );

  function addAnotherLocation(unshift: <X = any>(value: X) => number) {
    const firstLocation = values?.locations[0];

    // Make sure the first location is filled before unshifting another one to the array
    if (!firstLocation?.country?.label) return handleError("Please choose a country");
    if (!firstLocation?.state?.label) return handleError("Please choose a state");
    if (!firstLocation?.city?.label) return handleError("Please choose a city");

    // Add a new location to the list
    unshift({ country: firstLocation?.country, state: firstLocation?.state, city: null });
  }

  function addNewCosting(push: <X = any>(obj: X) => void) {
    const lastCosting = values?.costing[values?.costing.length - 1];

    // Make sure the last costing is filled before adding a new one
    if (lastCosting?.from === "") return handleError("Please enter a valid km range");
    if (lastCosting?.to === "") return handleError("Please enter a valid km range");
    if (Number(lastCosting?.from) >= Number(lastCosting?.to))
      return handleError('"from" must be less than "to"');
    if (lastCosting?.amount === "")
      return handleError("Please enter the price for the specified distance");

    // Add a new costing to the list
    push({
      from: "",
      to: "",
      amount: "",
      type:
        COST_TYPE_OPTIONS.find(i => i.value === lastCosting?.type?.value) || COST_TYPE_OPTIONS[0],
    });
  }

  useEffect(() => {
    function selectAllCities() {
      const firstLocation = values?.locations[0];

      if (firstLocation?.city?.value !== "select_all") return;

      const updatedLocations = [
        { country: firstLocation?.country, state: firstLocation?.state, city: null },
        ...citiesToShow.map(city => ({
          country: firstLocation?.country,
          state: firstLocation?.state,
          city: city,
        })),
        // Remove the first location that has the 'select all' option
        ...values.locations.slice(1),
      ];

      setFieldValue("locations", updatedLocations);
    }

    if (values?.locations[0]?.city?.value === "select_all") selectAllCities();
  }, [citiesToShow, setFieldValue, values.locations]);

  return (
    <WhiteBox px={{ base: 6, md: 9 }} py={6}>
      <Flex gap={1} align="center" pb={4} borderBottom="1px solid #0600891A" mb={6}>
        <IconButton
          variant="ghost"
          borderRadius="full"
          icon={<Icon as={HiOutlineArrowCircleLeft} boxSize={6} />}
          aria-label={"Arrow Back"}
          onClick={() => navigate(-1)}
        />
        <Heading
          fontSize={{ base: "16px", md: "20px" }}
          lineHeight="22px"
          color="black"
          textAlign="center"
          flex={1}
        >
          {isFormForEdit ? "Edit last mile pricing" : "Add last mile pricing"}
        </Heading>
      </Flex>

      {!isRideHailingTenant() && (
        <SDInput
          id="customName"
          type="text"
          label="Add custom name"
          placeholder="Enter a custom name"
          value={values.customName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.customName && errors.customName}
        />
      )}

      <SDSelect
        id="deliveryType"
        label="Delivery type"
        placeholder="Select a type"
        options={
          isRideHailingTenant()
            ? // Filter the option that has been added
              DELIVERY_TYPE_OPTIONS.filter(
                i => lastMilePricing.findIndex(j => j.deliveryType === i.value) === -1
              )
            : DELIVERY_TYPE_OPTIONS
        }
        value={values.deliveryType}
        onChange={setFieldValue}
        onBlur={handleBlur}
        error={touched.deliveryType && errors.deliveryType}
      />

      <SDInput
        id="description"
        type="text"
        label="Description"
        placeholder="Enter a short description"
        value={values.description}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.description && errors.description}
      />

      <FieldArray name="locations">
        {({ unshift, remove }) => (
          <>
            <Heading fontSize="16px" lineHeight="22px" fontWeight={600}>
              Location
            </Heading>

            <SimpleGrid columns={{ base: 1, md: 3 }} columnGap={4}>
              <SDSelect
                id="locations.0.country"
                name="locations.0.country"
                label="Country"
                placeholder="Select a country"
                value={values?.locations[0]?.country}
                onChange={(_, value) => {
                  setFieldValue("locations.0.country", value);
                  // Reset the selected state and the city
                  setFieldValue("locations.0.state", null);
                  setFieldValue("locations.0.city", null);
                }}
                options={Country.getAllCountries()
                  .filter(i => i.isoCode === "NG")
                  .map(country => ({
                    label: country.name,
                    value: country.isoCode,
                  }))}
                error={touched.locations?.[0]?.country && errors.locations?.[0]?.country}
                containerProps={{ mb: { base: 0, md: 4 } }}
              />

              <SDSelect
                id="locations.0.state"
                name="locations.0.state"
                label="State"
                placeholder="Select a state"
                value={values?.locations[0]?.state}
                onChange={(_, value) => {
                  setFieldValue("locations.0.state", value);
                  // Reset the selected city
                  setFieldValue("locations.0.city", null);
                }}
                options={State.getStatesOfCountry(values?.locations[0]?.country?.value).map(
                  state => ({ label: state.name, value: state.isoCode })
                )}
                error={touched.locations?.[0]?.state && errors.locations?.[0]?.state}
                containerProps={{ mb: { base: 0, md: 4 } }}
              />

              <SDSelect
                id="locations.0.city"
                name="locations.0.city"
                label="City"
                placeholder="Select a city"
                value={values?.locations[0]?.city}
                onChange={setFieldValue}
                options={
                  citiesToShow.length > 1 ? [SELECT_ALL_OPTION_TYPE, ...citiesToShow] : citiesToShow
                }
                error={touched.locations?.[0]?.city && errors.locations?.[0]?.city}
              />
            </SimpleGrid>

            <SDButton
              variant="ghost"
              leftIcon={<AddIcon />}
              fontSize="12px"
              size="sm"
              px={2}
              onClick={() => addAnotherLocation(unshift)}
              mb={4}
            >
              Add another location
            </SDButton>

            {values?.locations.length > 1 && (
              <Wrap border="1px solid #0600891A" p={4} borderRadius="8px" mb={4}>
                {values?.locations.slice(1)?.map((item, key) => (
                  <HStack key={key} bgColor="#07052912" borderRadius="20px" p="6px">
                    <Text fontSize="12px" fontWeight={600} lineHeight="22px">
                      {item?.country?.label} - {item?.state?.label} - {item?.city?.label}
                    </Text>
                    <IconButton
                      bgColor="#07052912"
                      icon={<Icon as={IoIosClose} boxSize={4} />}
                      size="xs"
                      borderRadius="full"
                      p="5px"
                      aria-label={"Delete"}
                      onClick={() => remove(key + 1)}
                    />
                  </HStack>
                ))}
              </Wrap>
            )}
          </>
        )}
      </FieldArray>

      <FieldArray name="costing">
        {({ push, remove }) => (
          <>
            <Heading fontSize="16px" lineHeight="22px" fontWeight={600}>
              Price
            </Heading>

            {values?.costing.map((item, key) => (
              <SimpleGrid
                key={key}
                columns={{ base: 2, md: 4 }}
                columnGap={4}
                pos="relative"
                borderBottomWidth={{ base: 1, md: 0 }}
                pb={{ base: 4, md: 0 }}
              >
                <SDInput
                  id={`costing.${key}.from`}
                  name={`costing.${key}.from`}
                  type="number"
                  label="From"
                  addOnElementBefore="km"
                  placeholder="0"
                  value={values?.costing[key]?.from}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.costing?.[key]?.from && errors.costing?.[key]?.from}
                  containerProps={{ mb: 0 }}
                />

                <SDInput
                  id={`costing.${key}.to`}
                  name={`costing.${key}.to`}
                  type="number"
                  label="To"
                  addOnElementBefore="km"
                  placeholder="0"
                  value={values?.costing[key]?.to}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.costing?.[key]?.to && errors.costing?.[key]?.to}
                  containerProps={{ mb: 0 }}
                />

                <SDSelect
                  id={`costing.${key}.type`}
                  name={`costing.${key}.type`}
                  label="Pricing type"
                  placeholder="Select a pricing type"
                  value={values?.costing[key]?.type}
                  onChange={setFieldValue}
                  options={COST_TYPE_OPTIONS}
                  error={touched.costing?.[0]?.type && errors.costing?.[0]?.type}
                  containerProps={{ mb: 0, gridColumn: { base: "1/3", md: "initial" } }}
                />

                <SDCurrencyInput
                  id={`costing.${key}.amount`}
                  name={`costing.${key}.amount`}
                  value={String(values?.costing[key]?.amount)}
                  label="Price"
                  onChange={values => setFieldValue(`costing.${key}.amount`, values.value)}
                  onBlur={handleBlur}
                  placeholder="1000"
                  addOnElementBefore="₦"
                  hidePrefix={true}
                  error={touched.costing?.[key]?.amount && errors.costing?.[key]?.amount}
                  containerProps={{
                    mb: 0,
                    gridColumn: { base: "1/3", md: "initial" },
                  }}
                />

                {values.costing.length > 1 && (
                  <IconButton
                    variant="ghost"
                    colorScheme="error"
                    onClick={() => remove(key)}
                    aria-label="Delete Costing"
                    icon={<PiXCircleBold />}
                    borderRadius="full"
                    position="absolute"
                    top={5}
                    right={-5}
                  />
                )}
              </SimpleGrid>
            ))}

            <SDButton
              variant="ghost"
              leftIcon={<AddIcon />}
              fontSize="12px"
              size="sm"
              px={2}
              mt={4}
              onClick={() => addNewCosting(push)}
            >
              Add another step
            </SDButton>
          </>
        )}
      </FieldArray>

      <Center>
        <SDButton
          isLoading={isSubmitting}
          type="submit"
          mb={6}
          mt={12}
          colorScheme="primary"
          maxW="460px"
          w="full"
        >
          Save
        </SDButton>
      </Center>
    </WhiteBox>
  );
}

export default LastMilePricingForm;
