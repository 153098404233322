import { Center, Heading } from "@chakra-ui/react";
import { SDButton } from "components/global";
import { LuPlusCircle } from "react-icons/lu";

interface Props {
  title: string;
  icon: any;
  onClick: () => void;
  isDisabled?: boolean;
}

function CreateOrderBox({ icon, onClick, title, isDisabled }: Props) {
  return (
    <Center
      flexDir="column"
      gap={2}
      py={6}
      px={{ base: 3, md: 6 }}
      borderRadius="10px"
      bgColor="primary.500"
      maxW="343px"
      w="full"
    >
      {icon}

      <Heading fontSize={{ base: "12px", md: "18px" }} fontWeight={500} color="white" flex={1}>
        {title}
      </Heading>

      <SDButton
        colorScheme="secondary2"
        leftIcon={<LuPlusCircle />}
        onClick={() => onClick()}
        mt={8}
        borderRadius="full"
        size={{ base: "xs", md: "lg" }}
        color="primary.500"
        isDisabled={isDisabled}
      >
        Create Order
      </SDButton>
    </Center>
  );
}

export default CreateOrderBox;
