import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { AllRoutes } from "routes/AllRoutes";
import { COOKIES_KEYS } from "utils/cookies";

export function handleSuccessMessage(res: any) {
  if (typeof res === "string") {
    toast.success(res);
    return;
  }

  toast.success(res?.data?.message || "Success!");
}

export function handleError(error: any) {
  console.log(error);

  if (typeof error === "string") {
    toast.error(error);
    return;
  }

  // PROCESS THE ERROR MESSAGE
  const errorMessage = error?.response?.data?.message || error?.message;

  // Handle 401
  const statusCode = error?.response?.status || error?.response?.data?.statusCode;
  console.log(statusCode);
  if (
    (typeof errorMessage === "string" && errorMessage.includes("Invalid token specified")) ||
    statusCode === 401
  ) {
    const loginRoute =
      Cookies.get(COOKIES_KEYS.USER_TYPE) === "customerOneTime"
        ? AllRoutes.clientLogin
        : AllRoutes.providerLogin;

    return (window.location.href = loginRoute);
  }

  if (typeof errorMessage === "string") {
    toast.error(errorMessage);
  } else if (errorMessage?.length > 0) {
    errorMessage.map((error: string) => toast.error(error));
  } else if (error?.code === "ERR_NETWORK") {
    toast.error("Network Error");
  } else {
    toast.error("An error occurred");
  }
}
