import {
  Box,
  Center,
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { SDButton, SDTag } from "components/global";
import { CardIcon, IDIcon, MedalStarIcon } from "utils/customIcons";
import { UpdateTier1Modal, UpgradeToTier2Modal, UpgradeToTier3Modal } from "..";
import { MdDateRange } from "react-icons/md";
import { useAppSelector } from "redux/store";
import { selectProviderProfile } from "redux/slices/authSlice";

function AccountLimits() {
  const providerProfile = useAppSelector(selectProviderProfile);

  const updateTier1Disclosure = useDisclosure();
  const upgradeToTier2Disclosure = useDisclosure();
  const upgradeToTier3Disclosure = useDisclosure();

  const tiers = [
    {
      isVerified: !!providerProfile?.kyc?.level1?.verified,
      requirements: [
        { icon: CardIcon, title: "BVN" },
        { icon: MdDateRange, title: "Date of Birth" },
      ],
      dailyTransactionLimit: "₦ 50,000.00",
      maxAccountLimit: "₦ 300,000.00",
      isCurrentLevel: true,
      onClick: () => updateTier1Disclosure.onOpen(),
    },
    {
      isVerified: !!providerProfile?.kyc?.level2?.verified,
      requirements: [{ icon: IDIcon, title: "Upload ID details" }],
      dailyTransactionLimit: "₦ 200,000.00",
      maxAccountLimit: "₦ 500,000.00",
      isCurrentLevel: false,
      onClick: () => upgradeToTier2Disclosure.onOpen(),
    },
    {
      isVerified: !!providerProfile?.kyc?.level3?.verified,
      requirements: [{ icon: IDIcon, title: "Upload a utility bill" }],
      dailyTransactionLimit: "₦ 5,000,000.00",
      maxAccountLimit: "Unlimited",
      isCurrentLevel: false,
      onClick: () => upgradeToTier3Disclosure.onOpen(),
    },
  ];

  function isPreviousTierVerified(currentIndex: number): boolean {
    if (currentIndex === 0) return true;

    return tiers[currentIndex - 1]?.isVerified;
  }

  return (
    <>
      <Box
        borderRadius="10px"
        border="1px solid #0600891A"
        p={{ base: 4, md: 6 }}
        maxW="863px"
        mx="auto"
      >
        <Flex gap={2} align="center" justify="space-between" mb={5}>
          <Heading fontSize="18px" fontWeight={600}>
            Account Limits
          </Heading>
          <Flex gap={6} borderRadius="4px" border="1px solid #0600891A" px={3} py={2}>
            <Text
              display={{ base: "none", md: "block" }}
              fontSize="16px"
              lineHeight="20px"
              fontWeight={500}
              color="neutral.500"
            >
              Account Tier
            </Text>
            <Flex gap="2px" align="center">
              <MedalStarIcon color="#292D32" boxSize={4} />
              {/* TODO: Handle this */}
              <Text fontSize="14px" lineHeight="18px" fontWeight={500} color="neutral.200">
                Tier 1
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex flexDir="column" gap={4}>
          {tiers.map((item, key) => (
            <Box key={key} borderRadius="10px" border="1px solid #0600891A" p={4}>
              <Flex gap={1} align={{ md: "center" }} justify="space-between" mb={5}>
                <Flex gap={1}>
                  <MedalStarIcon color="primary.500" boxSize={6} />
                  <Text fontSize="16px" lineHeight="20px" fontWeight={500} color="neutral.500">
                    Tier {key + 1} {item?.isCurrentLevel && " - current level"}
                  </Text>
                </Flex>
                <SDTag>{item?.isVerified ? "verified" : "pending"}</SDTag>
              </Flex>

              <Flex
                gap={{ base: 5, md: 10 }}
                flexDir={{ base: "column", md: "row" }}
                justify="space-between"
              >
                <Flex flexDir="column" gap={3}>
                  <Text
                    fontSize="14px"
                    lineHeight="18px"
                    fontWeight={500}
                    color="neutral.500"
                    mb={1}
                  >
                    *Requirement{item.requirements.length > 1 ? "s" : ""}
                  </Text>

                  {item.requirements.map((i, key) => (
                    <Flex key={key} gap="2px" align="center">
                      <Icon as={i.icon} color="neutral.200" boxSize={4} />
                      <Text fontSize="12px" lineHeight="16px" fontWeight={300} color="neutral.200">
                        {i.title}
                      </Text>
                    </Flex>
                  ))}
                </Flex>

                <Flex flexDir="column" gap={3} flex={1}>
                  <Text
                    fontSize="14px"
                    lineHeight="18px"
                    fontWeight={500}
                    color="neutral.500"
                    mb={1}
                  >
                    *Transaction Limits
                  </Text>

                  <SimpleGrid columns={2} gap={10}>
                    <Text fontSize="12px" lineHeight="16px" fontWeight={300} color="neutral.200">
                      Daily transaction limit
                    </Text>
                    <Text fontSize="12px" lineHeight="16px" fontWeight={300} color="primary.500">
                      {item?.dailyTransactionLimit}
                    </Text>
                  </SimpleGrid>

                  <SimpleGrid columns={2} gap={10}>
                    <Text fontSize="12px" lineHeight="16px" fontWeight={300} color="neutral.200">
                      Maximum account balance
                    </Text>
                    <Text fontSize="12px" lineHeight="16px" fontWeight={300} color="primary.500">
                      {item?.maxAccountLimit}
                    </Text>
                  </SimpleGrid>
                </Flex>

                <Center alignItems="flex-end">
                  <SDButton
                    colorScheme="primary"
                    isDisabled={item?.isVerified || !isPreviousTierVerified(key)}
                    size="md"
                    onClick={item?.onClick}
                  >
                    {item?.isVerified ? "Verified" : "Upgrade"}
                  </SDButton>
                </Center>
              </Flex>
            </Box>
          ))}
        </Flex>
      </Box>

      <UpdateTier1Modal
        isOpen={updateTier1Disclosure.isOpen}
        onClose={updateTier1Disclosure.onClose}
      />

      <UpgradeToTier2Modal
        isOpen={upgradeToTier2Disclosure.isOpen}
        onClose={upgradeToTier2Disclosure.onClose}
      />

      <UpgradeToTier3Modal
        isOpen={upgradeToTier3Disclosure.isOpen}
        onClose={upgradeToTier3Disclosure.onClose}
      />
    </>
  );
}

export default AccountLimits;
