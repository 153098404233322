import { useDisclosure } from "@chakra-ui/react";
import { EmptyState, SuccessModal, WhiteBox } from "components/global";
import { Form, Formik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import { InterStatePricingForm } from "../..";
import { IOptions } from "components/global/Select";
import { handleProviderUpdatePricing } from "services/admin/settings";
import validationSchema from "utils/validationSchema";
import { useMemo } from "react";
import { useAppSelector } from "redux/store";
import { selectInterStatePricing } from "redux/slices/adminSlices/settingsSlice";
import { DELIVERY_TYPE_OPTIONS } from "utils/constants";
import { Country, State } from "country-state-city";

interface IFormValues {
  customName: string;
  deliveryType: IOptions;
  description: string;
  locations: { country: IOptions; state: IOptions; city: IOptions }[];
  costing: { from: string; to: string; amount: string; type: string }[];
}

function EditInterStatePricing() {
  const navigate = useNavigate();

  const updatePricingSuccessDisclosure = useDisclosure();
  const interState = useAppSelector(selectInterStatePricing);

  const [searchParams] = useSearchParams();
  const pricingToEditId = useMemo(() => searchParams.get("id") || "", [searchParams]);
  const pricingToEdit = useMemo(
    () => interState.find(i => i?._id === pricingToEditId) || null,
    [interState, pricingToEditId]
  );

  async function handleSubmit(values: IFormValues) {
    const data = {
      name: values?.customName,
      deliveryType: values?.deliveryType?.value as string,
      pricingType: "Inter State",
      description: values?.description,
      location: values?.locations
        .filter(loc => loc?.country?.value && loc?.state?.value && loc?.city?.value)
        .map(loc => ({
          country: loc?.country?.value as string,
          state: loc?.state?.value as string,
          city: loc?.city?.value as string,
        })),
      costing: values?.costing,
    };

    await handleProviderUpdatePricing(data, pricingToEdit?._id, () => {
      updatePricingSuccessDisclosure.onOpen();
    });
  }

  if (!pricingToEdit) {
    return (
      <WhiteBox>
        <EmptyState
          variant="sm"
          message="Pricing not found"
          actionBtnText="Go back"
          onActionBtnClick={() => navigate(-1)}
        />
      </WhiteBox>
    );
  }

  return (
    <>
      <Formik
        initialValues={{
          customName: pricingToEdit?.name || "",
          deliveryType:
            DELIVERY_TYPE_OPTIONS.find(i => i.value === pricingToEdit?.deliveryType) || null,
          description: pricingToEdit?.description || "",
          locations: [
            { country: null, state: null, city: null },
            ...pricingToEdit?.location?.map(i => {
              const countryObj = Country.getCountryByCode(i?.country);
              const stateObj = State.getStateByCodeAndCountry(i?.state, i?.country);
              // const cityObj = City.getCitiesOfState(i?.country, i?.state).find(
              //   j => j.name === i?.city
              // );

              return {
                country: { label: countryObj?.name || "", value: countryObj?.isoCode || "" },
                state: { label: stateObj?.name || "", value: stateObj?.isoCode || "" },
                city: { label: i?.city || "", value: i?.city || "" },
                // city: { label: cityObj?.name || "", value: cityObj?.name || "" },
              };
            }),
          ],
          costing: pricingToEdit?.costing.length
            ? pricingToEdit?.costing
            : [{ from: "", to: "", amount: "", type: "fixed" }],
        }}
        validationSchema={validationSchema.providerPricing}
        onSubmit={(values: IFormValues) => handleSubmit(values)}
      >
        <Form>
          <InterStatePricingForm isFormForEdit={true} />
        </Form>
      </Formik>

      <SuccessModal
        isOpen={updatePricingSuccessDisclosure.isOpen}
        onClose={updatePricingSuccessDisclosure.onClose}
        onBtnClick={() => navigate(-1)}
        message="Pricing updated."
      />
    </>
  );
}

export default EditInterStatePricing;
