import { Box, Center, Flex, Icon, Text, Wrap, useDisclosure } from "@chakra-ui/react";
import { OrderEstimateModal } from "components/client/estimate";
import { SDButton, SDInputErrorMessage, TabSwitch, WhiteBox } from "components/global";
import DashboardLayout from "components/global/Layouts/DashboardLayout";
import SDGooglePlacesAutoComplete from "components/global/Select/SDGooglePlacesAutoComplete";
import { Form, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { FaDotCircle, FaRegCircle } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  selectLastMilePricing,
  selectInterStatePricing,
} from "redux/slices/adminSlices/settingsSlice";
import { useAppSelector } from "redux/store";
import { fetchProviderPricing } from "services/admin/settings";
import { handleGetShipmentFee } from "services/admin/shimpent";
import { IGoogleAddress, IOrderType } from "types/global";
import { DELIVERY_OPTIONS } from "utils/constants";
import validationSchema from "utils/validationSchema";

interface IFormValues {
  pricingId: string;

  pickupLocation: IGoogleAddress;
  dropOffLocation: IGoogleAddress;
}

function ClientEstimatePage() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const orderType = useMemo(
    () => searchParams.get("type") || "lastMile",
    [searchParams]
  ) as IOrderType;

  const lastMilePricing = useAppSelector(selectLastMilePricing);
  const interStatePricing = useAppSelector(selectInterStatePricing);

  const successModalDisclosure = useDisclosure();

  const [estimateData, setEstimateData] = useState(null);

  async function handleSubmit(values: IFormValues) {
    const data = {
      pickId: values.pickupLocation?.value?.place_id,
      dropId: values.dropOffLocation?.value?.place_id,
    };

    return await handleGetShipmentFee(data, values.pricingId, res => {
      setEstimateData(res?.data);

      successModalDisclosure.onOpen();
    });
  }

  function navigateTo(orderType: IOrderType) {
    navigate(`?type=${orderType}`, { replace: true });
  }

  useEffect(() => {
    fetchProviderPricing();
  }, []);

  return (
    <DashboardLayout role="client" pageTitle="Estimate" isGreyBackground>
      <Formik
        initialValues={{
          pricingId: "",
          pickupLocation: null,
          dropOffLocation: null,
        }}
        validationSchema={validationSchema.clientEstimateOrder}
        onSubmit={(values: IFormValues) => handleSubmit(values)}
      >
        {({ setFieldValue, values, errors, handleBlur, touched, isSubmitting }) => (
          <Form>
            <WhiteBox maxW="846px" mx="auto">
              <Flex flexDir="column" gap={2} maxW="646px" mx="auto">
                <TabSwitch
                  tabs={[
                    {
                      title: "Last-mile delivery",
                      onClick: () => {
                        navigateTo("lastMile");
                        setFieldValue("pricingId", "");
                      },
                      isDisabled: lastMilePricing.length === 0,
                    },
                    {
                      title: "Interstate delivery",
                      onClick: () => {
                        navigateTo("interState");
                        setFieldValue("pricingId", "");
                      },
                      isDisabled: interStatePricing.length === 0,
                    },
                  ]}
                  defaultTab={0}
                />

                <Box mt={6} mb={4}>
                  <Text color="neutral.500">
                    Select {orderType === "lastMile" ? "last mile" : "inter state"} delivery type
                  </Text>

                  <Wrap mt={4}>
                    {(orderType === "lastMile" ? lastMilePricing : interStatePricing).map(
                      (item, key) => (
                        <Center
                          flexDir="column"
                          gap={2}
                          px="14px"
                          py="10px"
                          maxW="100px"
                          w="full"
                          key={key}
                          border="1px solid #0600891A"
                          borderRadius="4px"
                          cursor="pointer"
                          transition="all 200ms ease"
                          position="relative"
                          onClick={() => setFieldValue("pricingId", item?._id)}
                          _hover={{ bgColor: "#0600891A" }}
                        >
                          {/* Floating Radio Icon */}
                          <Icon
                            as={values.pricingId === item?._id ? FaDotCircle : FaRegCircle}
                            boxSize={4}
                            pos="absolute"
                            top="5px"
                            right="5px"
                            color="primary.500"
                          />

                          {/* Vehicle Icon */}
                          <Icon
                            as={DELIVERY_OPTIONS.find(i => i.value === item?.deliveryType)?.icon}
                            boxSize={8}
                          />

                          <Text fontSize="10px" fontWeight={300} textAlign="center">
                            {item?.name}
                          </Text>
                        </Center>
                      )
                    )}
                  </Wrap>
                  {errors.pricingId && <SDInputErrorMessage error={errors.pricingId} />}
                </Box>

                <SDGooglePlacesAutoComplete
                  id="pickupLocation"
                  label="Pick-Up Location"
                  placeholder="Search for a location"
                  value={values.pickupLocation}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  error={touched.pickupLocation && errors.pickupLocation}
                />

                <SDGooglePlacesAutoComplete
                  id="dropOffLocation"
                  label="Drop-Off Location"
                  placeholder="Search for a location"
                  value={values.dropOffLocation}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  error={touched.dropOffLocation && errors.dropOffLocation}
                />

                <Flex mt={8} justify="center">
                  <SDButton colorScheme="primary" type="submit" isLoading={isSubmitting}>
                    Get estimate
                  </SDButton>
                </Flex>

                <OrderEstimateModal
                  isOpen={successModalDisclosure.isOpen}
                  onClose={successModalDisclosure.onClose}
                  estimateData={estimateData}
                />
              </Flex>
            </WhiteBox>
          </Form>
        )}
      </Formik>
    </DashboardLayout>
  );
}

export default ClientEstimatePage;
