import { Flex, Heading, ListItem, Switch, UnorderedList, useDisclosure } from "@chakra-ui/react";
import { MainLoader, SuccessModal } from "components/global";
import { useMemo, useState } from "react";
import { selectProviderProfile } from "redux/slices/authSlice";
import { useAppSelector } from "redux/store";
import { handleProviderUpdatePaymentOnDelivery } from "services/admin/settings";

function PaymentOnDeliverySettings() {
  const providerProfile = useAppSelector(selectProviderProfile);

  const isChecked = useMemo(
    () => providerProfile?.preference?.allowPayOnDelivery,
    [providerProfile?.preference?.allowPayOnDelivery]
  );

  const [isLoading, setIsLoading] = useState(false);

  const successDisclosure = useDisclosure();

  async function handleUpdate(value: boolean) {
    setIsLoading(true);

    const data = { allowPayOnDelivery: value };

    await handleProviderUpdatePaymentOnDelivery(data, () => {
      successDisclosure.onOpen();
    });

    setIsLoading(false);
  }

  return (
    <>
      <Flex flexDir="column" gap={8}>
        {isLoading && <MainLoader />}

        <Flex align="center" justify="space-between" gap={2} maxW="800px">
          <Heading color="neutral.500" fontWeight={600} fontSize="20px">
            Enable Payment on delivery
          </Heading>

          <Switch
            colorScheme="primary"
            size={{ base: "md", md: "lg" }}
            isChecked={isChecked}
            onChange={e => handleUpdate(e.target.checked)}
          />
        </Flex>

        <UnorderedList>
          <ListItem fontSize="14px" color="#4A5568" mb={4}>
            Toggle on to add payment on delivery as a payment option
          </ListItem>
          <ListItem fontSize="14px" color="#4A5568" mb={4}>
            Toggle off to disable it from the payment option
          </ListItem>
        </UnorderedList>
      </Flex>

      <SuccessModal
        isOpen={successDisclosure.isOpen}
        onClose={successDisclosure.onClose}
        message="Changes applied."
      />
    </>
  );
}

export default PaymentOnDeliverySettings;
