import { Flex, Spinner, useDisclosure } from "@chakra-ui/react";
import {
  SDButton,
  SDInput,
  SDInputHelperText,
  SDModal,
  SDSelect,
  SuccessModal,
} from "components/global";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { selectBankLists } from "redux/slices/adminSlices/dashboardSlice";
import { useAppSelector } from "redux/store";
import { handleProviderUpdateAccountDetails, handleResolveAccount } from "services/admin/settings";
import validationSchema from "utils/validationSchema";

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

interface IFormValues {
  accountNumber: string;
  bank: { label: string; value: string };
  accountName: string;
}

function AddBankAccountModal({ isOpen, onClose }: Props) {
  const bankLists = useAppSelector(selectBankLists);
  const [isresolvingAccount, setIsresolvingAccount] = useState(false);

  const successDisclosure = useDisclosure();

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    isSubmitting,
    handleSubmit,
  } = useFormik({
    initialValues: { accountNumber: "", bank: null, accountName: "" },
    validationSchema: validationSchema.providerEditAccountDetails,
    onSubmit: async (values: IFormValues) => {
      const data = {
        bankCode: values.bank?.value,
        bankName: values.bank?.label,
        bankAccountNumber: values.accountNumber,
        accountName: values.accountName,
      };

      await handleProviderUpdateAccountDetails(data, () => {
        onClose();
        successDisclosure.onOpen();
      });
    },
  });

  useEffect(() => {
    async function resolveAccount() {
      setIsresolvingAccount(true);

      const data = {
        bankCode: values.bank?.value,
        accountNumber: values.accountNumber,
      };

      setFieldValue("accountName", "");

      await handleResolveAccount(data, accountName => {
        setFieldValue("accountName", accountName);
      });

      setIsresolvingAccount(false);
    }

    if (values.bank?.value && values.accountNumber && values.accountNumber.length === 10) {
      resolveAccount();
    } else {
      setFieldValue("accountName", "");
    }
  }, [setFieldValue, values.accountNumber, values.bank?.value]);

  return (
    <>
      <SDModal isOpen={isOpen} onClose={onClose} title="Add Settlement Account" size="sm">
        <form onSubmit={handleSubmit} onBlur={handleBlur}>
          <SDSelect
            id="bank"
            label="Bank Name"
            placeholder="Select a bank"
            options={bankLists.map(item => ({
              label: item.name,
              value: item.code,
            }))}
            value={values.bank}
            onChange={setFieldValue}
            error={touched.bank && errors.bank}
            containerProps={{ mt: 0 }}
          />

          <SDInput
            id="accountNumber"
            type="text"
            label="Account Number"
            placeholder="9999999999"
            value={values.accountNumber}
            onChange={e => e.target.value.length <= 10 && handleChange(e)}
            error={touched.accountNumber && errors.accountNumber}
            min={10}
            max={10}
            helperText={isresolvingAccount ? <Spinner colorScheme="primary" /> : values.accountName}
            helperTextContainerProps={{ color: "success.500", textTransform: "uppercase" }}
          />

          <SDInputHelperText
            as={Flex}
            align="flex-start"
            textProps={{ color: "neutral.500", mt: -1 }}
            helperText="For smooth payment, add an existing account you would like to receive your payment with."
          />

          <SDButton
            mt="48px"
            w="full"
            colorScheme="primary"
            type="submit"
            isLoading={isSubmitting}
            isDisabled={!values.accountName.trim()}
          >
            Save Changes
          </SDButton>
        </form>
      </SDModal>

      <SuccessModal
        isOpen={successDisclosure.isOpen}
        onClose={successDisclosure.onClose}
        message="Changes applied."
      />
    </>
  );
}

export default AddBankAccountModal;
