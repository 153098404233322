import { Heading, Text, Center } from "@chakra-ui/react";
import { ErrorIcon } from "utils/customIcons";
import SDModal from ".";
import { SDButton } from "..";
import modalBg from "assets/svg/modalBg.svg";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  caption?: string;
  message: string;
  onBtnClick?: () => void;
}

function ErrorModal({ isOpen, onClose, caption, message, onBtnClick }: Props) {
  return (
    <SDModal isOpen={isOpen} onClose={onClose} showClosingButton={false} size="lg">
      <Center
        flexDir="column"
        bgImage={modalBg}
        bgRepeat="no-repeat"
        bgSize="cover"
        bgPosition="center"
      >
        <ErrorIcon w="55px" h="55px" mb={8} />

        <Heading
          textTransform="uppercase"
          color="error.400"
          fontWeight={700}
          fontSize="20px"
          mb={6}
          textAlign="center"
        >
          Error
        </Heading>

        <Text fontWeight={500} fontSize="18px" textAlign="center">
          {caption || "We are unable to process your request."}
        </Text>

        <Text fontWeight={300} fontSize="18px" textAlign="center" mt={3} mb={12}>
          {message}
        </Text>

        <SDButton colorScheme="error" onClick={() => (onBtnClick ? onBtnClick() : onClose())}>
          Close
        </SDButton>
      </Center>
    </SDModal>
  );
}

export default ErrorModal;
