import { Box, Center, Flex, Heading, Text } from "@chakra-ui/react";
import AuthLayout from "components/global/Layouts/AuthLayout";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { AllRoutes } from "routes/AllRoutes";

function SignUpPage() {
  const signUpOptions = [
    { label: "LOGISTIC PROVIDER", link: AllRoutes.providerSignUp },
    { label: "BUSINESS & INDIVIDUAL", link: AllRoutes.clientSignUp },
    { label: "COURIER", link: AllRoutes.courierSignUp },
  ];

  return (
    <AuthLayout showSmallLogo={false} px={[6, 6, 8, 12]} maxW="700px" documentTitle="Sign up">
      <Flex gap={4} align="flex-start" justify="space-between">
        <Box>
          <Heading fontSize="32px" fontWeight={700} mb={1}>
            Before we proceed
          </Heading>
          <Text color="neutral.200" fontWeight={500} fontSize={{ base: "sm", md: "md" }}>
            Choose how you want to onboard
          </Text>
        </Box>

        <Text color="neutral.200" fontSize="sm" display={{ base: "none", md: "block" }}>
          Already have an account?{" "}
          <Link to={AllRoutes.providerLogin}>
            <Text as="span" color="primary.500" fontWeight={700}>
              Sign in
            </Text>
          </Link>
        </Text>
      </Flex>

      <Flex flexDir="column" gap={6} maxW="380px" mx="auto" my={16}>
        {signUpOptions.map((item, key) => (
          <Link key={key} to={item.link}>
            <Flex
              align="center"
              justify="space-between"
              px={6}
              py={3}
              borderRadius="4px"
              border="1px solid #0600891A"
            >
              <Heading fontSize="16px">{item.label}</Heading>
              <BsArrowRight />
            </Flex>
          </Link>
        ))}

        <Text color="neutral.200" fontSize="sm" display={{ base: "blpck", md: "none" }}>
          Already have an account?{" "}
          <Link to={AllRoutes.providerLogin}>
            <Text as="span" color="primary.500" fontWeight={700}>
              Sign in
            </Text>
          </Link>
        </Text>
      </Flex>

      <Center textAlign="center">
        <Link to="#">Learn more about our logistics management solutions &#8599;</Link>
      </Center>
    </AuthLayout>
  );
}

export default SignUpPage;
