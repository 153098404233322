import { useDisclosure } from "@chakra-ui/react";
import { handleResetPassword } from "services/auth";
import { SDButton, SDInput, SuccessModal } from "components/global";
import AuthLayout from "components/global/Layouts/AuthLayout";
import { useFormik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AllRoutes } from "routes/AllRoutes";
import validationSchema from "utils/validationSchema";

interface IFormValues {
  password: string;
  confirmPassword: string;
}

function ResetPasswordPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resetTokenFromURL = searchParams.get("token");

  const successModalDisclosure = useDisclosure();

  const { values, handleChange, touched, handleBlur, isSubmitting, handleSubmit, errors } =
    useFormik({
      initialValues: { password: "", confirmPassword: "" },
      validationSchema: validationSchema.resetPassword,
      onSubmit: async (values: IFormValues) => {
        const data = {
          token: resetTokenFromURL,
          password: values.password,
        };

        await handleResetPassword(data, () => {
          successModalDisclosure.onOpen();
        });
      },
    });

  return (
    <>
      <AuthLayout title="Reset password" description="Enter a new password">
        <form onSubmit={handleSubmit} onBlur={handleBlur}>
          <SDInput
            variant="grey"
            id="password"
            type="password"
            label="New password"
            placeholder="••••••••••••••••"
            value={values.password}
            onChange={handleChange}
            error={touched.password && errors.password}
          />

          <SDInput
            variant="grey"
            id="confirmPassword"
            type="password"
            label="Confirm password"
            placeholder="••••••••••••••••"
            value={values.confirmPassword}
            onChange={handleChange}
            error={touched.confirmPassword && errors.confirmPassword}
          />

          <SDButton colorScheme="primary" mt={10} w="full" type="submit" isLoading={isSubmitting}>
            Submit
          </SDButton>
        </form>
      </AuthLayout>

      <SuccessModal
        isOpen={successModalDisclosure.isOpen}
        onClose={successModalDisclosure.onClose}
        message="Password Changed."
        btnText="Sign in"
        onBtnClick={() => navigate(AllRoutes.providerLogin)}
      />
    </>
  );
}

export default ResetPasswordPage;
