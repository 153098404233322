import { handleError } from "services";
import apiClient from "services/axios";
import { store } from "redux/store";
import { ICourierDetails } from "./types";
import { saveCouriers } from "redux/slices/adminSlices/courierSlice";
import { setUpQuery } from "utils/utilFunctions";

const { dispatch } = store;

export const handleFetchCouriers = async (page: number = 1, size: number = 20, search?: string) => {
  try {
    const query = setUpQuery({
      page,
      size,
      search,
    });

    const res = await apiClient.get(`provider/riders` + query);

    dispatch(saveCouriers(res?.data));

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleFetchSingleCourier = async (id: string) => {
  try {
    const res = await apiClient.get(`provider/rider/${id}`);

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleCreateCourier = async (data: ICourierDetails, onSuccess: () => void) => {
  try {
    const res = await apiClient.post(`/provider/create-dispatcher`, data);

    handleFetchCouriers();

    onSuccess();

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleUpdateCourierProfilePic = async (
  id: string,
  data: { profilePicture: string },
  onSuccess: () => void
) => {
  try {
    const res = await apiClient.patch(`provider/rider/${id}/profilePicture`, data);

    handleFetchCouriers();

    onSuccess();

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleActivateCourier = async (id: string, onSuccess: () => void) => {
  try {
    const res = await apiClient.patch(`provider/rider/${id}/enable`);

    handleFetchCouriers();

    onSuccess();

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleDeactivateCourier = async (id: string, onSuccess: () => void) => {
  try {
    const res = await apiClient.patch(`provider/rider/${id}/disable`);

    handleFetchCouriers();

    onSuccess();

    return res;
  } catch (error) {
    handleError(error);
  }
};
