import { useDisclosure } from "@chakra-ui/react";
import { SuccessModal } from "components/global";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { LastMilePricingForm } from "../..";
import { IOptions } from "components/global/Select";
import { handleProviderAddPricing } from "services/admin/settings";
import validationSchema from "utils/validationSchema";
import { COST_TYPE_OPTIONS } from "utils/constants";
import { isRideHailingTenant } from "utils/utilFunctions";

interface IFormValues {
  customName: string;
  deliveryType: IOptions;
  description: string;
  locations: { country: IOptions; state: IOptions; city: IOptions }[];
  costing: { from: string; to: string; amount: string; type: IOptions }[];
}

function AddLastMilePricing() {
  const navigate = useNavigate();

  const addPricingSuccessDisclosure = useDisclosure();

  async function handleSubmit(values: IFormValues) {
    const data = {
      name: isRideHailingTenant() ? values?.deliveryType?.altLabel : values?.customName,
      deliveryType: values?.deliveryType?.value as string,
      pricingType: "Last Mile",
      description: values?.description,
      location: values?.locations
        .filter(loc => loc?.country?.value && loc?.state?.value && loc?.city?.value)
        .map(loc => ({
          country: loc?.country?.value as string,
          state: loc?.state?.value as string,
          city: loc?.city?.value as string,
        })),
      costing: values?.costing.map(costing => ({ ...costing, type: costing?.type?.value })),
    };

    await handleProviderAddPricing(data, () => {
      addPricingSuccessDisclosure.onOpen();
    });
  }

  return (
    <>
      <Formik
        initialValues={{
          customName: "",
          deliveryType: null,
          description: "",
          locations: [{ country: { label: "Nigeria", value: "NG" }, state: null, city: null }],
          // locations: [{ country: null, state: null, city: null }],
          costing: [{ from: "", to: "", amount: "", type: COST_TYPE_OPTIONS[0] }],
        }}
        validationSchema={validationSchema.providerPricing}
        onSubmit={(values: IFormValues) => handleSubmit(values)}
      >
        <Form>
          <LastMilePricingForm />
        </Form>
      </Formik>

      <SuccessModal
        isOpen={addPricingSuccessDisclosure.isOpen}
        onClose={addPricingSuccessDisclosure.onClose}
        onBtnClick={() => navigate(-1)}
        message="Pricing added."
      />
    </>
  );
}

export default AddLastMilePricing;
