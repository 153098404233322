import { Flex, SimpleGrid } from "@chakra-ui/react";
import { SDButton, SDInput, SDModal } from "components/global";
import React, { Dispatch, SetStateAction } from "react";
import { formatDateToFormField } from "utils/utilFunctions";

interface Props {
  onClose: () => void;
  isOpen: boolean;

  startDate: string;
  endDate: string;
  setStartDate: Dispatch<SetStateAction<string>>;
  setEndDate: Dispatch<SetStateAction<string>>;
  onCustomFilterSubmit: () => Promise<void>;
}

function CustomShipmentStatsFilterModal({
  isOpen,
  onClose,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  onCustomFilterSubmit,
}: Props) {
  function handleSubmit(e: any) {
    e.preventDefault();

    onCustomFilterSubmit();

    onClose();
  }

  return (
    <SDModal isOpen={isOpen} onClose={onClose} title="Custom Date" size="sm">
      <form onSubmit={handleSubmit}>
        <SimpleGrid columns={2} gap={4}>
          <SDInput
            id="startDate"
            type="date"
            label="Start Date"
            placeholder="start date"
            value={startDate}
            onChange={e => setStartDate(e.target.value)}
            max={formatDateToFormField(Date.now())}
            containerProps={{ my: 0 }}
          />

          <SDInput
            id="endDate"
            type="date"
            label="End Date"
            placeholder="end date"
            value={endDate}
            onChange={e => setEndDate(e.target.value)}
            min={formatDateToFormField(startDate)}
            max={formatDateToFormField(Date.now())}
            containerProps={{ my: 0 }}
          />
        </SimpleGrid>

        <Flex gap={4} justify="flex-end" align="center" mt={10}>
          <SDButton onClick={onClose} colorScheme="error" variant="ghost" size="md">
            Cancel
          </SDButton>
          <SDButton type="submit" colorScheme="primary" size="md">
            Confirm
          </SDButton>
        </Flex>
      </form>
    </SDModal>
  );
}

export default CustomShipmentStatsFilterModal;
