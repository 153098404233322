import { Center, PinInput, PinInputField, Text, useDisclosure } from "@chakra-ui/react";
import {
  handleVerifyEmail,
  handleGetPasswordResetToken,
  handleForgotPassword,
  handleVerifyClientLogin,
  handleClientLogin,
  handleResendOTP,
} from "services/auth";
import { MainLoader, SDButton, SuccessModal } from "components/global";
import AuthLayout from "components/global/Layouts/AuthLayout";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AllRoutes } from "routes/AllRoutes";
import { IUserVerificationType } from "types/global";
import { handleSuccessMessage } from "services";
import { IResendOTP } from "services/auth/types";

interface IFormValues {
  code: string;
}

function VerificationPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const emailFromURL = searchParams.get("email");
  const typeFromURL = searchParams.get("type") as IUserVerificationType;

  const [isResendingOTP, setIsResendingOTP] = useState(false);

  const successModalDisclosure = useDisclosure();

  const { values, setFieldValue, isSubmitting, handleSubmit, submitForm } = useFormik({
    initialValues: { code: "" },
    onSubmit: async (values: IFormValues) => {
      const data = {
        email: emailFromURL,
        code: values.code,
      };

      if (typeFromURL === "password") {
        await handleGetPasswordResetToken(data, res => {
          navigate(AllRoutes.resetPassword(res?.data?.resetToken));
        });
      } else if (typeFromURL === "client-login") {
        const data = {
          identifier: emailFromURL,
          code: values.code,
        };

        await handleVerifyClientLogin(data, () => {
          navigate(AllRoutes.clientDashboard);
        });
      } else {
        await handleVerifyEmail(data, () => {
          successModalDisclosure.onOpen();
        });
      }
    },
  });

  async function resendOTP() {
    setIsResendingOTP(true);

    if (typeFromURL === "password") {
      const data = { email: emailFromURL };

      await handleForgotPassword(data, () => {});
    } else if (typeFromURL === "client-login") {
      const data = {
        identifier: emailFromURL,
      };

      await handleClientLogin(data, () => {
        handleSuccessMessage("OTP has been resent successfully");
      });
    } else {
      const data = {
        identifier: emailFromURL,
        purpose: "ACCOUNT_EMAIL_VERIFY",
      } as IResendOTP;

      await handleResendOTP(data, () => handleSuccessMessage("OTP has been resent successfully"));
    }
    setIsResendingOTP(false);
  }

  return (
    <>
      {isResendingOTP && <MainLoader />}

      <AuthLayout
        role={typeFromURL === "client-login" ? "client" : "provider"}
        title="Verification"
        description="Enter the verification code sent to your email"
      >
        <form onSubmit={handleSubmit}>
          <Center gap={[2, 3, 6]}>
            <PinInput
              id="code"
              placeholder=""
              value={values.code}
              onChange={value => setFieldValue("code", value)}
              onComplete={() => !isSubmitting && submitForm()}
            >
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </Center>

          <SDButton
            colorScheme="primary"
            mt={10}
            w="full"
            isDisabled={values.code.length !== 5}
            type="submit"
            isLoading={isSubmitting}
          >
            Verify
          </SDButton>
          <Center flexDir={{ base: "column", md: "row" }} gap={4} mt={6}>
            <Text>
              Did not receive OTP?{" "}
              <Text
                as="span"
                fontWeight={700}
                cursor="pointer"
                onClick={resendOTP}
                _hover={{ textDecoration: "underline" }}
              >
                Resend
              </Text>
            </Text>
          </Center>
        </form>
      </AuthLayout>

      <SuccessModal
        isOpen={successModalDisclosure.isOpen}
        onClose={successModalDisclosure.onClose}
        message="Verification completed."
        btnText="Sign in"
        onBtnClick={() => navigate(AllRoutes.providerLogin)}
      />
    </>
  );
}

export default VerificationPage;
