import { Document, Page, Text, View, StyleSheet, Svg, Circle, Path } from "@react-pdf/renderer";
import { IPreviewInfo } from "./TransactionDetailsModal";

interface Props {
  previewInfo: IPreviewInfo;
}

function TransactionReceipt({ previewInfo }: Props) {
  return (
    <Document author="Sendrail" creator="Sendrail" producer="Sendrail">
      <Page size="A4" style={styles.page}>
        <Text style={styles.headerText}>Transaction Details</Text>

        <View style={styles.topRow}>
          <View style={styles.amountBlock}>
            <CreditIcon />
            <Text style={styles.amount}>{previewInfo?.amount.replaceAll("₦", "N ")}</Text>
          </View>

          <Text style={styles.date}>{previewInfo?.date}</Text>
        </View>

        <View style={styles.container}>
          {previewInfo?.data.map((props, key) => (
            <View
              key={key}
              style={[styles.section, props?.isVerticalRow ? styles.verticalSection : null]}
            >
              <Text style={styles.title}>{props?.title}</Text>
              <Text style={[styles.value, { textAlign: props?.isVerticalRow ? "left" : "right" }]}>
                {props?.value?.toString()?.replaceAll("₦", "N ")}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
}

const CreditIcon = () => (
  <Svg width="48" height="48" viewBox="0 0 48 48" fill="none">
    <Circle cx="24.0039" cy="24" r="24" fill="#06161D" />
    <Path
      d="M21.2378 30.9023V26.9503H20.3098V25.7663H21.2378V24.6143H20.3098V23.4303H21.2378V19.4783H23.7498L24.9978 23.4303H26.4538V19.4783H28.2298V23.4303H29.1418V24.6143H28.2298V25.7663H29.1418V26.9503H28.2298V30.9023H25.7018L24.4218 26.9503H23.0138V30.9023H21.2378ZM23.0138 25.7663H24.0698L23.7178 24.6143H22.9818L23.0138 25.7663ZM26.5018 28.4063H26.5818L26.5178 26.9503H26.0858L26.5018 28.4063ZM22.9498 23.4303H23.3978L22.9338 21.8943H22.8698L22.9498 23.4303ZM25.7498 25.7663H26.4858L26.4538 24.6143H25.3818L25.7498 25.7663Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clip-rule="evenodd"
      d="M25.25 14.9244C19.5505 14.9244 14.9244 19.5505 14.9244 25.25C14.9244 30.9495 19.5505 35.5756 25.25 35.5756C30.9495 35.5756 35.5756 30.9495 35.5756 25.25C35.5756 24.7876 35.9504 24.4128 36.4128 24.4128C36.8752 24.4128 37.25 24.7876 37.25 25.25C37.25 31.8742 31.8742 37.25 25.25 37.25C18.6258 37.25 13.25 31.8742 13.25 25.25C13.25 18.6258 18.6258 13.25 25.25 13.25C25.7124 13.25 26.0872 13.6248 26.0872 14.0872C26.0872 14.5496 25.7124 14.9244 25.25 14.9244Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clip-rule="evenodd"
      d="M31.1113 14.0872C31.1113 13.6248 31.4862 13.25 31.9485 13.25H36.4137C36.876 13.25 37.2509 13.6248 37.2509 14.0872V18.5523C37.2509 19.0147 36.876 19.3895 36.4137 19.3895C35.9513 19.3895 35.5764 19.0147 35.5764 18.5523V14.9244H31.9485C31.4862 14.9244 31.1113 14.5496 31.1113 14.0872Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clip-rule="evenodd"
      d="M37.0047 13.4952C37.3317 13.8222 37.3317 14.3523 37.0047 14.6792L31.4233 20.2606C31.0964 20.5876 30.5663 20.5876 30.2394 20.2606C29.9124 19.9337 29.9124 19.4036 30.2394 19.0766L35.8208 13.4952C36.1477 13.1683 36.6778 13.1683 37.0047 13.4952Z"
      fill="white"
    />
  </Svg>
);

const styles = StyleSheet.create({
  page: {
    padding: 30,
    gap: 16,
  },

  headerText: {
    textAlign: "center",
    fontSize: 24,
    fontWeight: "extrabold",
    paddingVertical: 20,
    borderBottom: "1px solid #F5F5F5",
  },

  topRow: {
    flexDirection: "row",
    gap: 16,
    alignItems: "center",
    justifyContent: "space-between",
  },

  amountBlock: {
    flexDirection: "row",
    gap: 12,
    alignItems: "center",
  },

  image: {
    width: 32,
    height: 32,
  },

  amount: {
    fontSize: 20,
    fontWeight: "extrabold",
    color: "#1F1F1F",
  },

  date: {
    fontSize: 16,
    fontWeight: "medium",
    textAlign: "right",
    color: "#595956",
  },

  container: {
    padding: 30,
    backgroundColor: "rgba(7, 5, 41, 0.07)",
  },

  section: {
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px dashed #A0A0AA",
    paddingVertical: 16,
  },

  verticalSection: {
    flexDirection: "column",
    alignItems: "flex-start",
  },

  title: {
    fontSize: 16,
    fontWeight: "ultrabold",
    color: "#01001B",
    textTransform: "capitalize",
  },

  value: {
    fontSize: 16,
    fontWeight: "medium",
    color: "#656566",
    textAlign: "right",
    textTransform: "capitalize",
    overflowWrap: "anywhere",
    textOverflow: "ellipsis",
  },
});

export default TransactionReceipt;
