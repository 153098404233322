import { Box, Flex, Grid, Text, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  handleProviderGetShipments,
  handleProviderGetShipmentStats,
} from "services/admin/shimpent";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
  saveActiveShipmentStatus,
  selectActiveShipmentStatus,
  selectShipmentStats,
} from "redux/slices/adminSlices/shipmentSlice";
import { CustomShipmentStatsFilterModal, ShipmentStatItem } from ".";
import { SDSelect } from "components/global";
import { SHIPMENT_STATS_TYPE_OPTIONS } from "utils/constants";
import { formatDateWithSlash } from "utils/utilFunctions";

export interface IShipmentStat {
  title: string;
  value: number;
  percentage: string;
  isIncrease: boolean;
  status: string;
}

interface Props {
  isForShipment?: boolean;
}

function ShipmentStats({ isForShipment = false }: Props) {
  const dispatch = useAppDispatch();

  const shipmentStats = useAppSelector(selectShipmentStats);
  const activeShipmentStatus = useAppSelector(selectActiveShipmentStatus);

  const [activeFilter, setActiveFilter] = useState(
    SHIPMENT_STATS_TYPE_OPTIONS.find(i => i?.value === "today") || SHIPMENT_STATS_TYPE_OPTIONS[1]
  );

  const [isLoading, setIsLoading] = useState(false);

  const customDateDisclosure = useDisclosure();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const stats: IShipmentStat[] = [
    {
      title: "Total Orders",
      value: shipmentStats.total,
      status: "",
      percentage: "0.0%",
      isIncrease: true,
    },
    {
      title: "Pending",
      value: shipmentStats.pending,
      status: "Pending",
      percentage: "0.0%",
      isIncrease: false,
    },
    {
      title: "Accepted",
      value: shipmentStats.accepted,
      status: "Accepted",
      percentage: "0.0%",
      isIncrease: false,
    },
    {
      title: "Picked Up",
      value: shipmentStats.pickedUp,
      status: "Picked-up",
      percentage: "0.0%",
      isIncrease: false,
    },
    {
      title: "Delivered",
      value: shipmentStats.delivered,
      status: "Delivered",
      percentage: "0.0%",
      isIncrease: true,
    },
    {
      title: "Cancelled",
      value: shipmentStats.cancelled,
      status: "Cancelled",
      percentage: "0.0%",
      isIncrease: true,
    },
  ];

  async function fetchStats(filter?: any) {
    setIsLoading(true);

    await handleProviderGetShipmentStats({ ...filter });

    setIsLoading(false);
  }

  async function onFilterChange(value: string) {
    if (value === "custom") {
      customDateDisclosure.onOpen();
    } else {
      fetchStats({ type: value });
    }
  }

  async function onCustomFilterSubmit() {
    fetchStats({ type: "custom", start: startDate, end: endDate });
  }

  useEffect(() => {
    if (isForShipment) {
      fetchStats();
    } else {
      fetchStats({ type: "today" });
    }
  }, [isForShipment]);

  return (
    <>
      <Box>
        {!isForShipment && (
          <Flex gap={4} align="center" mb={4}>
            <SDSelect
              id="filterType"
              label=""
              placeholder="Select a type"
              options={SHIPMENT_STATS_TYPE_OPTIONS}
              value={activeFilter}
              onChange={(_, value: any) => {
                setActiveFilter(value);
                onFilterChange(value?.value);
              }}
              containerProps={{ my: 0, w: "fit-content" }}
              size="sm"
            />

            {activeFilter?.value === "custom" && startDate && endDate && (
              <Text fontSize={{ base: "xs", md: "md" }} color="neutral.200">
                ({formatDateWithSlash(startDate)} --- {formatDateWithSlash(endDate)})
              </Text>
            )}
          </Flex>
        )}

        <Grid className="chakra-wrapper" overflowX="auto" maxW="full">
          <Grid
            templateColumns={{
              base: `repeat(${stats.length}, 208px)`,
              xl: `repeat(${stats.length}, 1fr)`,
            }}
            gap={4}
            overflowX="auto"
            pb={4}
            pe={{ base: 4, xl: 0 }}
          >
            {stats?.map((stat, key) => (
              <ShipmentStatItem
                key={key}
                stat={stat}
                isLoading={isLoading}
                {...(isForShipment
                  ? {
                      onClick: () => {
                        dispatch(saveActiveShipmentStatus(stat.status));
                        handleProviderGetShipments(1, null, stat.status);
                      },
                      isActive: stat.status === activeShipmentStatus,
                    }
                  : {})}
              />
            ))}
          </Grid>
        </Grid>
      </Box>

      <CustomShipmentStatsFilterModal
        isOpen={customDateDisclosure.isOpen}
        onClose={customDateDisclosure.onClose}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        onCustomFilterSubmit={onCustomFilterSubmit}
      />
    </>
  );
}

export default ShipmentStats;
