import { IShipment } from "redux/slices/adminSlices/shipmentSlice/types";
import {
  saveOrderHistoryLoadingState,
  saveOrderHistory,
  saveRecentOrderHistory,
} from "redux/slices/clientSlices/orderHistorySlice";
import { store } from "redux/store";
import { handleError } from "services";
import apiClient from "services/axios";
import { setUpQuery } from "utils/utilFunctions";

const { dispatch, getState } = store;

export const handleClientGetOrderHistory = async (
  page?: number,
  size?: number,
  status?: string
) => {
  try {
    dispatch(saveOrderHistoryLoadingState(true));

    const pageFromStore = getState().orderHistoryReducer?.orderHistory?.pagination?.page;
    const pageToLoad = page || pageFromStore || 1;

    const pageSizeFromStore = getState().orderHistoryReducer?.orderHistory?.pagination?.size;
    const pageSizeToLoad = size || pageSizeFromStore || 20;

    const statusFromStore = getState().orderHistoryReducer?.activeOrderHistoryStatus;
    const statusToLoad = status || statusFromStore || "";

    const query = setUpQuery({
      page: pageToLoad,
      size: pageSizeToLoad,
      status: statusToLoad,
    });

    const res = await apiClient.get(`customer/orders` + query);

    dispatch(saveOrderHistory(res?.data));

    return res;
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(saveOrderHistoryLoadingState(false));
  }
};

export const handleClientGetRecentOrderHistory = async () => {
  try {
    dispatch(saveOrderHistoryLoadingState(true));

    const query = setUpQuery({
      size: 10,
    });

    const res = await apiClient.get(`customer/orders` + query);

    dispatch(saveRecentOrderHistory(res?.data));

    return res;
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(saveOrderHistoryLoadingState(false));
  }
};

export const handleClientGetSingleOrder = async (id: string) => {
  try {
    const res = await apiClient.get(`customer/order/${id}`);

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleClientGetOrderByRef = async (
  trackingRef: string,
  onSuccess: (res: IShipment) => void
) => {
  try {
    const res = await apiClient.get(`customer/order/track/${trackingRef}`);

    onSuccess(res?.data);

    return res;
  } catch (error) {
    handleError(error);
  }
};
