import {
  Box,
  Center,
  Flex,
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ConfirmOrderModal } from "components/admin/shipment";
import { ClientEmailLoginModal } from "components/client/dashboard";
import {
  MainLoader,
  SDButton,
  SDInput,
  SDInputErrorMessage,
  SuccessModal,
  WhiteBox,
} from "components/global";
import DashboardLayout from "components/global/Layouts/DashboardLayout";
import { IOptions } from "components/global/Select";
import SDGooglePlacesAutoComplete from "components/global/Select/SDGooglePlacesAutoComplete";
import { IUploadValue } from "components/global/Upload/FileUploadWrapper";
import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { FaDotCircle, FaRegCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { selectLastMilePricing } from "redux/slices/adminSlices/settingsSlice";
import { selectClientProfile } from "redux/slices/authSlice";
import { useAppSelector } from "redux/store";
import { AllRoutes } from "routes/AllRoutes";
import {
  handleGetShipmentFee,
  handleInitiateOrderPayment,
  handleProviderCreateSingleOrder,
} from "services/admin/shimpent";
import { IShipmentDetails } from "services/admin/shimpent/types";
import { IGoogleAddress, IPaymentMethod } from "types/global";
import { DELIVERY_OPTIONS } from "utils/constants";
import {
  attachSubdomainPath,
  formatDateToFormField,
  generateRandomNumbers,
  getValidSubdomain,
  isClientLoggedIn,
} from "utils/utilFunctions";
import validationSchema from "utils/validationSchema";
import kekeIcon from "assets/svg/kekeIcon.svg";

interface IFormValues {
  autoAssignToCourier: boolean;

  pricingId: string;

  customerName: string;
  customerPhoneNumber: string;
  pickupLocation: IGoogleAddress;
  customerPickupCode: string;
  scheduleForPickupDate: string;
  scheduleForPickupTime: string;
  image: IUploadValue;
  parcelDetails: {
    content: string;
    noOfContents: string;
    parcelType: IOptions;
  }[];

  recipientName: string;
  recipientPhoneNumber: string;
  dropOffLocation: IGoogleAddress;
  recipientDropOffCode: string;

  shipmentFee: number;
  processingFee: number;
  billingId: string;
  paymentMethod: IPaymentMethod;
}

function ClientBookARidePage() {
  const clientProfile = useAppSelector(selectClientProfile);
  const lastMilePricing = useAppSelector(selectLastMilePricing);

  const [isInitiatingPayment, setIsInitiatingPayment] = useState(false);
  const [newOrderId, setNewOrderId] = useState("");

  const navigate = useNavigate();

  const successModalDisclosure = useDisclosure();
  const confirmOrderDisclosure = useDisclosure();
  const getClientEmailDisclosure = useDisclosure();

  async function handleSubmit(values: IFormValues, actions: FormikHelpers<IFormValues>) {
    if (!isClientLoggedIn()) {
      return getClientEmailDisclosure.onOpen();
    }

    // Fetch the shipment fee
    if (confirmOrderDisclosure.isOpen === false) {
      const data = {
        pickId: values.pickupLocation?.value?.place_id,
        dropId: values.dropOffLocation?.value?.place_id,
      };

      return await handleGetShipmentFee(data, values.pricingId, res => {
        actions.setFieldValue("shipmentFee", res?.data?.baseCost);
        actions.setFieldValue("processingFee", res?.data?.processingFee);
        actions.setFieldValue("billingId", res?.data?._id);

        confirmOrderDisclosure.onOpen();
      });
    }

    // Create Shipment
    const data: IShipmentDetails = {
      pickUpDetails: {
        customerName: values.customerName,
        customerPhoneNumber: values.customerPhoneNumber,
        location: {
          placeId: values.pickupLocation?.value?.place_id,
          description: values.pickupLocation?.value?.description,
        },
        customerPickUpCode: values.customerPickupCode,
        scheduleForPickUp: {
          enterDate: values.scheduleForPickupDate,
          enterTime: values.scheduleForPickupTime,
        },
        // TODO: To be moved to parcelDetails section
        packageImage: "",
      },
      parcelDetails: [
        {
          percelContents: "Passenger",
          noOfContents: 1,
          category: "Other",
        },
      ],
      dropOffDetails: {
        recipientName: values.customerName,
        recipientPhoneNumber: values.customerPhoneNumber,
        location: {
          placeId: values.dropOffLocation?.value?.place_id,
          description: values.dropOffLocation?.value?.description,
        },
        recipientDropOffCode: values.recipientDropOffCode,
      },
      paymentMethod: values.paymentMethod,
      originator: "customer",
    };

    await handleProviderCreateSingleOrder(data, values?.billingId, res => {
      setNewOrderId(res?.data?._id);

      confirmOrderDisclosure.onClose();
      successModalDisclosure.onOpen();
    });
  }

  async function handlePayNow() {
    setIsInitiatingPayment(true);

    await handleInitiateOrderPayment(
      newOrderId,
      attachSubdomainPath(getValidSubdomain(), AllRoutes.clientRecentActivities)
    );

    setIsInitiatingPayment(false);
  }

  return (
    <DashboardLayout role="client" pageTitle="" isGreyBackground={true}>
      {isInitiatingPayment && <MainLoader />}

      <Formik
        initialValues={{
          autoAssignToCourier: false,

          pricingId: "",

          customerName: clientProfile?.fullName || "",
          customerPhoneNumber: clientProfile?.phoneNumber || "",
          pickupLocation: null,
          customerPickupCode: generateRandomNumbers(5),
          scheduleForPickupDate: "",
          scheduleForPickupTime: "",
          image: null,
          parcelDetails: [{ content: "", noOfContents: "", parcelType: null }],

          recipientName: "",
          recipientPhoneNumber: "",
          dropOffLocation: null,
          recipientDropOffCode: generateRandomNumbers(5),

          shipmentFee: 0,
          processingFee: 0,
          billingId: "",
          paymentMethod: "At Creation",
        }}
        validationSchema={validationSchema.bookARide}
        onSubmit={(values: IFormValues, actions) => handleSubmit(values, actions)}
      >
        {({
          setFieldValue,
          values,
          errors,
          handleChange,
          handleBlur,
          isSubmitting,
          touched,
          submitForm,
        }) => (
          <Form>
            <WhiteBox>
              <Center>
                <Flex flexDir="column" gap="32px" maxW="548px" w="full">
                  <Flex gap={[1, 1, 2]} flexDir="column">
                    <Heading fontSize={{ base: "18px", md: "20px" }} textAlign="center">
                      Book a Ride
                    </Heading>

                    <Text
                      textAlign="center"
                      fontSize={{ base: "14px", md: "16px" }}
                      fontWeight={500}
                      color="neutral.200"
                    >
                      How do you want to book this ride?
                    </Text>
                  </Flex>

                  <Box>
                    <SimpleGrid columns={2} gap={[4, 4, 6]}>
                      {lastMilePricing.map((item, key) => (
                        <Center
                          key={key}
                          flexDir="column"
                          gap={4}
                          px={4}
                          py={[4, 4, 6]}
                          // maxW="100px"
                          w="full"
                          border="1px solid #0600891A"
                          borderRadius="4px"
                          cursor="pointer"
                          transition="all 200ms ease"
                          position="relative"
                          boxShadow="0px 4px 20px 0px #DFDEF0C3"
                          onClick={() => setFieldValue("pricingId", item?._id)}
                          _hover={{ bgColor: "#0600891A" }}
                        >
                          {/* Floating Radio Icon */}
                          <Icon
                            as={values.pricingId === item?._id ? FaDotCircle : FaRegCircle}
                            boxSize={[4, 4, 5]}
                            pos="absolute"
                            top="10px"
                            right="10px"
                            color="primary.500"
                          />

                          <Image
                            src={kekeIcon}
                            alt="Keke"
                            w={{ base: "40px", md: "63px" }}
                            h={{ base: "48px", md: "58px" }}
                          />

                          <Flex gap={1} align="center" mt="auto">
                            <Icon
                              as={DELIVERY_OPTIONS.find(i => i.value === item?.deliveryType)?.icon}
                              boxSize={[3, 3, 4]}
                            />

                            <Text
                              fontSize={{ base: "10px", md: "14px" }}
                              fontWeight={500}
                              color="neutral.500"
                            >
                              {item?.name}
                            </Text>
                          </Flex>
                        </Center>
                      ))}
                    </SimpleGrid>
                    {errors.pricingId && <SDInputErrorMessage error={errors.pricingId} />}
                  </Box>

                  <Box>
                    <Heading fontWeight={600} fontSize={{ base: "16px", md: "20px" }}>
                      Personal Details
                    </Heading>

                    <SDInput
                      id="customerName"
                      type="text"
                      label="Full Name:"
                      placeholder="Enter full name"
                      value={values.customerName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.customerName && errors.customerName}
                    />

                    <SDInput
                      id="customerPhoneNumber"
                      type="tel"
                      label="Phone Number:"
                      placeholder="Enter phone number"
                      value={values.customerPhoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.customerPhoneNumber && errors.customerPhoneNumber}
                    />
                  </Box>

                  <Box>
                    <Heading fontWeight={600} fontSize={{ base: "16px", md: "20px" }}>
                      Enter Your Destination
                    </Heading>

                    <SDGooglePlacesAutoComplete
                      id="pickupLocation"
                      label="From:"
                      placeholder="Search for a location"
                      value={values.pickupLocation}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      error={touched.pickupLocation && errors.pickupLocation}
                    />

                    <SDGooglePlacesAutoComplete
                      id="dropOffLocation"
                      label="To:"
                      placeholder="Search for a location"
                      value={values.dropOffLocation}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      error={touched.dropOffLocation && errors.dropOffLocation}
                    />
                  </Box>

                  <Box>
                    <Heading fontWeight={600} fontSize={{ base: "16px", md: "20px" }}>
                      Schedule Ride? (optional)
                    </Heading>

                    <SDInput
                      id="scheduleForPickupDate"
                      type="date"
                      label="Date:"
                      placeholder="Enter a date"
                      value={values.scheduleForPickupDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.scheduleForPickupDate && errors.scheduleForPickupDate}
                      min={formatDateToFormField(Date.now())}
                    />

                    <SDInput
                      id="scheduleForPickupTime"
                      type="time"
                      label="Time:"
                      placeholder="Enter a time"
                      value={values.scheduleForPickupTime}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.scheduleForPickupTime && errors.scheduleForPickupTime}
                    />
                  </Box>

                  <SDButton colorScheme="primary" type="submit" isLoading={isSubmitting}>
                    Proceed
                  </SDButton>
                </Flex>
              </Center>
            </WhiteBox>

            <ClientEmailLoginModal
              isOpen={getClientEmailDisclosure.isOpen}
              onClose={getClientEmailDisclosure.onClose}
              onSuccess={submitForm}
            />

            <ConfirmOrderModal
              role="client"
              isOpen={confirmOrderDisclosure.isOpen}
              onClose={() => {
                setFieldValue("shipmentFee", 0);
                confirmOrderDisclosure.onClose();
              }}
            />

            <SuccessModal
              isOpen={successModalDisclosure.isOpen}
              onClose={successModalDisclosure.onClose}
              message="Order created."
              btnText={values.paymentMethod === "At Creation" && "Proceed to payment"}
              onBtnClick={() =>
                values.paymentMethod === "At Creation"
                  ? handlePayNow()
                  : navigate(AllRoutes.clientDashboard)
              }
            />
          </Form>
        )}
      </Formik>
    </DashboardLayout>
  );
}

export default ClientBookARidePage;
