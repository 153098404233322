import { Grid } from "@chakra-ui/react";
import { SDButton } from "components/global";
import {
  saveActiveOrderHistoryStatus,
  selectActiveOrderHistoryStatus,
} from "redux/slices/clientSlices/orderHistorySlice";
import { useAppDispatch, useAppSelector } from "redux/store";
import { handleClientGetOrderHistory } from "services/client/orderHistory";

export interface IOrderFilter {
  title: string;
  status: string;
}

function OrderHistoryFilters() {
  const dispatch = useAppDispatch();

  const activeOrderHistoryStatus = useAppSelector(selectActiveOrderHistoryStatus);

  const filters: IOrderFilter[] = [
    {
      title: "All",
      status: "",
    },
    {
      title: "Pending",
      status: "Pending",
    },
    {
      title: "Accepted",
      status: "Accepted",
    },
    {
      title: "Picked Up",
      status: "Picked-up",
    },
    {
      title: "Delivered",
      status: "Delivered",
    },
    {
      title: "Cancelled",
      status: "Cancelled",
    },
  ];

  return (
    <Grid overflowX="auto" maxW="full">
      <Grid
        templateColumns={`repeat(${filters.length}, max-content)`}
        gap={4}
        overflowX="auto"
        pb={4}
        pe={{ base: 4, xl: 0 }}
      >
        {filters?.map((item, key) => (
          <SDButton
            key={key}
            colorScheme={item?.status === activeOrderHistoryStatus ? "primary" : "gray"}
            size={{ base: "xs", md: "md" }}
            variant={item?.status === activeOrderHistoryStatus ? "solid" : "outline"}
            cursor="pointer"
            onClick={() => {
              dispatch(saveActiveOrderHistoryStatus(item.status));
              handleClientGetOrderHistory(1, null, item.status);
            }}
          >
            {item.title}
          </SDButton>
        ))}
      </Grid>
    </Grid>
  );
}

export default OrderHistoryFilters;
