import { Flex, Text } from "@chakra-ui/react";
import { handleGlobalLogin, handleProviderLogin } from "services/auth";
import { SDButton, SDInput } from "components/global";
import AuthLayout from "components/global/Layouts/AuthLayout";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { AllRoutes } from "routes/AllRoutes";
import validationSchema from "utils/validationSchema";
import { handleError } from "services";
import { isDashboardDomain } from "utils/utilFunctions";

interface IFormValues {
  email: string;
  password: string;
}

function ProviderLoginPage() {
  const navigate = useNavigate();

  const { values, errors, touched, handleBlur, handleChange, isSubmitting, handleSubmit } =
    useFormik({
      initialValues: { email: "", password: "" },
      validationSchema: validationSchema.providerLogin,
      onSubmit: async (values: IFormValues) => {
        const data = {
          identifier: values.email,
          password: values.password,
        };

        if (isDashboardDomain()) {
          await handleGlobalLogin(data);
        } else {
          await handleProviderLogin(
            data,
            res => {
              if (res?.data?.type === "ADMIN") {
                navigate(AllRoutes.providerDashboard);
              } else {
                // TODO: Change this to clientDashboard
                // navigate(AllRoutes.providerDashboard);
                handleError("User type not found");
              }
            },
            () => navigate(AllRoutes.verifyEmail(values.email))
          );
        }
      },
    });

  return (
    <AuthLayout
      title="Sign in"
      description="Experience a faster, easier and more reliable way to move items from one location to another on Sendrail"
    >
      <form onSubmit={handleSubmit} onBlur={handleBlur}>
        <SDInput
          variant="grey"
          id="email"
          type="email"
          label="Email"
          placeholder="example@gmail.com"
          value={values.email}
          onChange={handleChange}
          error={touched.email && errors.email}
        />

        <SDInput
          variant="grey"
          id="password"
          type="password"
          label="Password"
          placeholder="••••••••••••••••"
          value={values.password}
          onChange={handleChange}
          error={touched.password && errors.password}
        />

        <SDButton colorScheme="primary" mt={10} w="full" type="submit" isLoading={isSubmitting}>
          Sign in
        </SDButton>
      </form>

      <Flex
        flexDir={{ base: "column", md: "row" }}
        gap={4}
        align="center"
        justify="space-between"
        mt={6}
      >
        <Link to={AllRoutes.forgotPassword}>
          <Text fontWeight={700}>Forgot Password?</Text>
        </Link>
        {isDashboardDomain() && (
          <Text>
            Don’t have an account?{" "}
            <Link to={AllRoutes.providerSignUp}>
              <Text as="span" fontWeight={700}>
                Sign up
              </Text>
            </Link>
          </Text>
        )}
      </Flex>
    </AuthLayout>
  );
}

export default ProviderLoginPage;
