import { useLocation, Navigate, Outlet } from "react-router-dom";
import { AllRoutes } from "routes/AllRoutes";
import Cookies from "js-cookie";
import { COOKIES_KEYS } from "utils/cookies";
import { IUserType } from "types/global";
import { useMemo } from "react";
import { getDecodedToken, isAuthExcludedPage, isComingSoonPage } from "utils/utilFunctions";

interface Props {
  role: IUserType;
}

function ProtectAuth({ role }: Props) {
  const location = useLocation();

  const loginRoute = useMemo(
    () => (role === "provider" ? AllRoutes.providerLogin : AllRoutes.clientLogin),
    [role]
  );

  const dashboardRoute = useMemo(
    () => (role === "provider" ? AllRoutes.providerDashboard : AllRoutes.clientDashboard),
    [role]
  );

  if (isComingSoonPage()) {
    return <Navigate to={dashboardRoute} state={{ from: location }} replace />;
  }

  if (isAuthExcludedPage()) {
    return <Outlet />;
  }

  const token = Cookies.get(COOKIES_KEYS.TOKEN);

  if (!token) {
    return <Navigate to={loginRoute} state={{ from: location }} replace />;
  }

  const decodedToken = getDecodedToken(token);
  if (token && decodedToken && decodedToken?.exp < Date.now()) {
    return <Outlet />;
  }

  return <Navigate to={loginRoute} state={{ from: location }} replace />;
}

export default ProtectAuth;
