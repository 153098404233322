import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { PaginatedResponse } from "types/global";
import { ICourier } from "./types";

// Define a type for the slice state
interface InitialState {
  couriers: PaginatedResponse<ICourier[]>;
}

// Define the initial state using that type
const initialState: InitialState = {
  couriers: { records: [], pagination: null },
};

export const courierSlice = createSlice({
  name: "courierReducer",
  initialState,
  reducers: {
    saveCouriers: (state, action: PayloadAction<PaginatedResponse<ICourier[]>>) => {
      state.couriers = action.payload;
    },
  },
});

export const { saveCouriers } = courierSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCouriers = (state: RootState) => state.courierReducer.couriers;

// Reducer
export const courierReducer = courierSlice.reducer;
