import { Box, ButtonProps, Center, Heading, StackProps, Text } from "@chakra-ui/react";
import { EmptyStateIcon } from "utils/customIcons";
import { SDButton } from "..";

type IVariant = "sm" | "md" | "lg";

interface Props extends StackProps {
  variant?: IVariant;
  title?: string;
  message?: string;
  actionBtnText?: string;
  onActionBtnClick?: () => void;
  actionBtnProps?: ButtonProps;
}

function EmptyState({
  variant = "md",
  title,
  message,
  actionBtnText,
  onActionBtnClick,
  actionBtnProps,
  ...rest
}: Props) {
  function getSizeConfig() {
    switch (variant) {
      case "sm":
        return { w: "144px", h: "195px" };

      case "md":
        return { w: { base: "144px", md: "220px" }, h: { base: "195px", md: "210px" } };

      case "lg":
        return { w: { base: "220px", md: "350px" }, h: { base: "210px", md: "340px" } };
    }
  }

  return (
    <Center flexDir="column" gap={4} {...rest}>
      <EmptyStateIcon {...getSizeConfig()} />

      <Box>
        {title && (
          <Heading
            fontWeight={600}
            color="black"
            fontSize={{ base: "16px", md: "18px" }}
            textAlign="center"
            mb={2}
          >
            {title}
          </Heading>
        )}

        <Text
          fontWeight={500}
          color="neutral.500"
          fontSize={{ base: "16px", md: "18px" }}
          textAlign="center"
        >
          {message || "No data to show"}
        </Text>
      </Box>

      {actionBtnText && onActionBtnClick && (
        <SDButton colorScheme="primary" onClick={onActionBtnClick} my={3} {...actionBtnProps}>
          {actionBtnText}
        </SDButton>
      )}
    </Center>
  );
}

export default EmptyState;
