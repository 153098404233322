import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { PaginatedResponse } from "types/global";
import { ICustomer } from "./types";

// Define a type for the slice state
interface InitialState {
  customers: PaginatedResponse<ICustomer[]>;
}

// Define the initial state using that type
const initialState: InitialState = {
  customers: { records: [], pagination: null },
};

export const customerslice = createSlice({
  name: "customerReducer",
  initialState,
  reducers: {
    saveCustomers: (state, action: PayloadAction<PaginatedResponse<ICustomer[]>>) => {
      state.customers = action.payload;
    },
  },
});

export const { saveCustomers } = customerslice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCustomers = (state: RootState) => state.customerReducer.customers;

// Reducer
export const customerReducer = customerslice.reducer;
