import { Flex, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import { SDButton, SDInput, SDModal } from "components/global";
import { useFormik } from "formik";
import { selectProviderProfile } from "redux/slices/authSlice";
import { useAppSelector } from "redux/store";
import { handleUpgradeTier1 } from "services/admin/dashboard";
import validationSchema from "utils/validationSchema";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface IFormValues {
  dob: string;
  bvn: string;
}

function UpdateTier1Modal({ isOpen, onClose }: Props) {
  const providerProfile = useAppSelector(selectProviderProfile);

  const { values, errors, touched, handleBlur, handleChange, isSubmitting, handleSubmit } =
    useFormik({
      initialValues: {
        dob: providerProfile?.kyc?.level1?.dob || "",
        bvn: providerProfile?.kyc?.level1?.bvn || "",
      },
      validationSchema: validationSchema.providerKycTier1,
      onSubmit: async (values: IFormValues) => {
        const data = {
          dob: values.dob,
          bvn: values.bvn,
        };

        await handleUpgradeTier1(data, () => onClose());
      },
    });

  return (
    <SDModal isOpen={isOpen} onClose={onClose} size="2xl">
      <Heading fontSize="20px" fontWeight={600} lineHeight="25px" mb={3}>
        Update Tier 1 Details
      </Heading>
      <Text fontWeight={300} color="neutral.500" mb={8}>
        Update your tier 1 details to be able to carry out transactions
      </Text>

      <form onSubmit={handleSubmit} onBlur={handleBlur}>
        <SimpleGrid columns={[1, 1, 2]} columnGap={8}>
          <SDInput
            id="bvn"
            type="nuber"
            label="BVN"
            placeholder="Enter your BVN"
            value={values.bvn}
            onChange={handleChange}
            error={touched.bvn && errors.bvn}
          />

          <SDInput
            id="dob"
            type="date"
            label="Date of Birth"
            placeholder="Enter date of birth"
            value={values.dob}
            onChange={handleChange}
            error={touched.dob && errors.dob}
          />
        </SimpleGrid>

        <Flex gap={4} mt={10} justify="flex-end">
          <SDButton colorScheme="primary" variant="outline" onClick={onClose}>
            Cancel
          </SDButton>
          <SDButton colorScheme="primary" type="submit" isLoading={isSubmitting}>
            Confirm
          </SDButton>
        </Flex>
      </form>
    </SDModal>
  );
}

export default UpdateTier1Modal;
