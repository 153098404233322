import { handleError, handleSuccessMessage } from "services";
import apiClient from "services/axios";
import Cookies from "js-cookie";
import { saveClientProfile, saveTenantProfile, saveProviderProfile } from "redux/slices/authSlice";
import { store } from "redux/store";
import { COOKIES_KEYS } from "utils/cookies";
import {
  IClientLoginDetails,
  IForgotPassword,
  IProviderLoginDetails,
  IProviderSignUpDetails,
  IResendOTP,
  IResetPassword,
  IVerifyClientLoginDetails,
  IVerifyEmail,
} from "./types";
import { attachSubdomainPath, isClientLoggedIn, setUpQuery } from "utils/utilFunctions";
import { AllRoutes } from "routes/AllRoutes";
import { auth, provider, signInWithPopup } from "utils/firebase";

const { dispatch } = store;

export const handleProviderSignUp = async (data: IProviderSignUpDetails, onSuccess: () => void) => {
  try {
    const res = await apiClient.post(`auth/business/register`, data, {
      headers: { "x-tenant-id": "register" },
    });

    onSuccess();

    Cookies.set(COOKIES_KEYS.TOKEN, res?.data?.token);
    Cookies.set(COOKIES_KEYS.USER_TYPE, res?.data?.userType);
    Cookies.set(COOKIES_KEYS.X_ACCESS, res?.data?.x_access);

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleProviderLogin = async (
  data: IProviderLoginDetails,
  onSuccess: (res: any) => void,
  onVerifyError: () => void
) => {
  try {
    const res = await apiClient.post(`auth/provider/login`, data);

    if (res?.data?.type === "DISPATCHER") {
      return handleError(
        "This is a rider account. Please login through our mobile app. You can download it on the play store or app store."
      );
    }

    onSuccess(res);

    Cookies.set(COOKIES_KEYS.TOKEN, res?.data?.token);
    Cookies.set(COOKIES_KEYS.USER_TYPE, res?.data?.type);
    Cookies.set(COOKIES_KEYS.X_ACCESS, res?.data?.x_access);

    return res;
  } catch (error) {
    if (error?.response?.data?.message?.includes("Please verify your email")) {
      const _data = {
        identifier: data.identifier,
        purpose: "ACCOUNT_EMAIL_VERIFY",
      } as IResendOTP;

      await handleResendOTP(_data, () => {});

      onVerifyError();
    }

    handleError(error);
  }
};

export const handleGlobalLogin = async (data: IProviderLoginDetails) => {
  try {
    const res = await apiClient.post(`auth/global/login`, data, {
      headers: { "x-tenant-id": "register" },
    });

    if (res?.data?.type === "tenantuser") {
      return handleError(
        "This is a rider account. Please login through our mobile app. You can download it on the play store or app store."
      );
    }

    await handleSwapToken(res?.data?.handoverToken, res?.data?.portal, data.identifier);

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleSwapToken = async (token: string, portal: string, identifier: string) => {
  try {
    const res = await apiClient.post(
      `auth/swap/handover-token`,
      { token },
      { headers: { "x-tenant-id": portal } }
    );

    const query = setUpQuery(res?.data);

    window.location.href = attachSubdomainPath(portal, AllRoutes.providerLoginHandover + query);

    return res;
  } catch (error) {
    if (error?.response?.data?.message?.includes("Please verify your email")) {
      const _data = {
        identifier: identifier,
        purpose: "ACCOUNT_EMAIL_VERIFY",
      } as IResendOTP;

      await handleResendOTP(_data, () => {});

      window.location.href = attachSubdomainPath(portal, AllRoutes.verifyEmail(identifier));
    }

    handleError(error);
  }
};

export const handleClientLogin = async (data: IClientLoginDetails, onSuccess: () => void) => {
  try {
    const res = await apiClient.post(`/auth/send-order-otp`, data);

    onSuccess();

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleClientGoogleLogin = async (onSuccess: () => void) => {
  try {
    const authRes: any = await signInWithPopup(auth, provider);

    const data = {
      id_token: authRes?._tokenResponse?.idToken,
      access_token: authRes?.user?.accessToken,
      expires_in: authRes?._tokenResponse?.expiresIn,
      token_type: "string",
      scope: "string",
      authuser: authRes?.user,
      prompt: "string",
      profile: authRes?.user,
    };

    const res = await apiClient.post(`/auth/google/verify`, data);

    onSuccess();

    Cookies.set(COOKIES_KEYS.TOKEN, res?.data?.token);
    Cookies.set(COOKIES_KEYS.USER_TYPE, res?.data?.type);
    Cookies.set(COOKIES_KEYS.X_ACCESS, res?.data?.x_access);

    fetchClientProfile();

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleVerifyClientLogin = async (
  data: IVerifyClientLoginDetails,
  onSuccess: (res: any) => void
) => {
  try {
    const res = await apiClient.post(`/auth/send-order-otp/verify`, data);

    onSuccess(res);

    Cookies.set(COOKIES_KEYS.TOKEN, res?.data?.token);
    Cookies.set(COOKIES_KEYS.USER_TYPE, res?.data?.type);
    Cookies.set(COOKIES_KEYS.X_ACCESS, res?.data?.x_access);

    fetchClientProfile();

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleResendOTP = async (data: IResendOTP, onSuccess: () => void) => {
  try {
    const res = await apiClient.post(`auth/resend-code`, data, {
      headers: { "x-tenant-id": "register" },
    });

    onSuccess();

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleVerifyEmail = async (data: IVerifyEmail, onSuccess: () => void) => {
  try {
    const res = await apiClient.post(`auth/email/verify`, data, {
      headers: { "x-tenant-id": "register" },
    });

    onSuccess();

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleForgotPassword = async (data: IForgotPassword, onSuccess: () => void) => {
  try {
    const res = await apiClient.post(`auth/password/forgot`, data, {
      headers: { "x-tenant-id": "register" },
    });

    onSuccess();

    handleSuccessMessage(res);

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleGetPasswordResetToken = async (
  data: IVerifyEmail,
  onSuccess: (res: any) => void
) => {
  try {
    const res = await apiClient.post(`auth/reset/token`, data, {
      headers: { "x-tenant-id": "register" },
    });

    onSuccess(res);

    handleSuccessMessage(res);

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleResetPassword = async (data: IResetPassword, onSuccess: () => void) => {
  try {
    const res = await apiClient.post(`auth/password/change`, data, {
      headers: { "x-tenant-id": "register" },
    });

    onSuccess();

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const fetchProviderProfile = async () => {
  try {
    const res = await apiClient.get(`provider/profile`);

    dispatch(saveProviderProfile(res?.data));

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const fetchClientProfile = async () => {
  try {
    if (!isClientLoggedIn()) return;

    const res = await apiClient.get(`customer/me`);

    dispatch(saveClientProfile(res?.data));

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const fetchTenantProfile = async () => {
  try {
    const res = await apiClient.get(`tenant`);

    dispatch(saveTenantProfile(res?.data));

    return res;
  } catch (error) {
    handleError(error);
  }
};
