import { Heading, Text, Center, SimpleGrid } from "@chakra-ui/react";
import { OrderBoxIcon } from "utils/customIcons";
import SDModal from ".";
import { SDButton } from "..";
import modalBg from "assets/svg/modalBg.svg";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  message?: string | React.ReactNode;
  subMessage?: string | React.ReactNode;
  proceedBtnText?: string;
  onBtnClick: () => void;
  isLoading?: boolean;
}

function ConfirmModal({
  isOpen,
  onClose,
  message,
  subMessage,
  proceedBtnText,
  onBtnClick,
  isLoading = false,
}: Props) {
  return (
    <SDModal isOpen={isOpen} onClose={onClose} showClosingButton={false} size="md">
      <Center
        flexDir="column"
        bgImage={modalBg}
        bgRepeat="no-repeat"
        bgSize="cover"
        bgPosition="center"
      >
        <OrderBoxIcon w="55px" h="55px" color="#292D32" mb={8} />

        <Heading
          textTransform="uppercase"
          color="primary.500"
          fontWeight={700}
          fontSize="20px"
          mb={6}
          textAlign="center"
        >
          Order Alert
        </Heading>

        <Text fontWeight={500} fontSize="18px" textAlign="center" mb={12}>
          {message || "Are you sure you want to proceed with this request?"}
          {subMessage && (
            <>
              <br />({subMessage})
            </>
          )}
        </Text>

        <SimpleGrid columns={2} gap={4}>
          <SDButton colorScheme="primary" variant="outline" onClick={onClose} size="md">
            Cancel
          </SDButton>
          <SDButton colorScheme="primary" isLoading={isLoading} onClick={onBtnClick} size="md">
            {proceedBtnText || "Yes"}
          </SDButton>
        </SimpleGrid>
      </Center>
    </SDModal>
  );
}

export default ConfirmModal;
