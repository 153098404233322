import { Center, Heading, Link, SimpleGrid, Text } from "@chakra-ui/react";
import { SDButton } from "components/global";
import DocumentTitleSetter from "components/global/Layouts/DocumentTitleSetter";
import { useNavigate } from "react-router-dom";
import { SUPPORT_EMAIL } from "utils/constants";
import notFoundDesktop from "assets/svg/notFoundDesktop.svg";
import notFoundMobile from "assets/svg/notFoundMobile.svg";

function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <>
      <DocumentTitleSetter title="Page Not Found" />

      <Center
        bgImage={[notFoundMobile, notFoundDesktop]}
        bgRepeat="no-repeat"
        bgSize="cover"
        bgPosition="center"
        py={[8, 8, 16]}
        px={[4, 8, 16]}
        minH="100vh"
        w="100vw"
      >
        <Center maxW="693px" flexDir="column" gap={{ base: 6, md: 8 }} textAlign="center">
          <Heading
            fontWeight={700}
            color="primary.500"
            fontSize={{ base: "110px", md: "180px" }}
            lineHeight={{ base: "100px", md: "140px" }}
          >
            404
          </Heading>
          <Heading
            fontWeight={700}
            color="primary.500"
            fontSize={{ base: "28px", md: "48px" }}
            lineHeight={{ base: "33px", md: "57px" }}
          >
            Page Not Found
          </Heading>
          <Text
            fontWeight={400}
            color="primary.500"
            fontSize={{ base: "16px", md: "20px" }}
            lineHeight={{ base: "22px", md: "28px" }}
          >
            Oops! The page you're looking for isn't here. Please check the website address for any
            typos or try searching for the correct page. If you believe this is an error, please
            contact{" "}
            <Link as="a" color="secondary.500" href={`mailto:${SUPPORT_EMAIL}`}>
              {SUPPORT_EMAIL}
            </Link>{" "}
            for assistance
          </Text>
          <SimpleGrid
            w={{ base: "full", md: "auto" }}
            columns={{ base: 1, md: 2 }}
            gap={{ base: 4, md: 6 }}
          >
            <SDButton
              borderRadius="full"
              colorScheme="primary"
              onClick={() => window.location.reload()}
            >
              Try Again
            </SDButton>
            <SDButton
              borderRadius="full"
              colorScheme="primary"
              variant="outline"
              onClick={() => navigate(-1)}
            >
              Back
            </SDButton>
          </SimpleGrid>
        </Center>
      </Center>
    </>
  );
}

export default NotFoundPage;
