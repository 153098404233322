import { Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import SDButton from "../Button";
import { NextIcon, PreviousIcon } from "utils/customIcons";
import { IPaginationConfig } from "types/global";
import { scrollToSection } from "utils/utilFunctions";

interface Props {
  paginationConfig?: IPaginationConfig;
  onPaginationChange?: (page?: number) => Promise<void>;
  sectionToSrollTo: string;
}

function SDPagination({ paginationConfig, onPaginationChange, sectionToSrollTo }: Props) {
  const ITEMS_PER_PAGE = Number(paginationConfig?.size) || 50;

  const totalPages = Math.ceil(Number(paginationConfig?.total) / ITEMS_PER_PAGE);

  const [isPrevFetching, setIsPrevFetching] = useState(false);
  const [isNextFetching, setIsNextFetching] = useState(false);

  async function nextPage() {
    setIsNextFetching(true);

    onPaginationChange && (await onPaginationChange(paginationConfig?.page + 1));

    scrollToSection(sectionToSrollTo);

    setIsNextFetching(false);
  }

  async function prevPage() {
    setIsPrevFetching(true);

    onPaginationChange && (await onPaginationChange(paginationConfig?.page - 1));

    scrollToSection(sectionToSrollTo);

    setIsPrevFetching(false);
  }

  if (!totalPages) {
    return <></>;
  }

  return (
    <Flex align="center" justify="flex-end" gap={1} my={4}>
      <SDButton
        variant="ghost"
        rightIcon={<PreviousIcon />}
        onClick={prevPage}
        size="sm"
        p="8px"
        isLoading={isPrevFetching}
        isDisabled={paginationConfig?.page <= 1}
      >
        Previous
      </SDButton>

      <Text fontSize="lg" fontWeight={700}>
        {paginationConfig?.page}
        <Text as="span" color="#757575">
          /{totalPages}
        </Text>
      </Text>

      <SDButton
        variant="ghost"
        leftIcon={<NextIcon />}
        onClick={nextPage}
        size="sm"
        p="8px"
        isLoading={isNextFetching}
        isDisabled={paginationConfig?.page >= totalPages}
      >
        Next
      </SDButton>
    </Flex>
  );
}

export default SDPagination;
