import { Box, Heading, SimpleGrid, useDisclosure } from "@chakra-ui/react";
import {
  WhiteBox,
  SDInput,
  SDSelect,
  SDButton,
  SuccessModal,
  ProfilePicUpload,
} from "components/global";
import { Formik, Form } from "formik";
import { BUSINESS_TYPE_OPTIONS } from "utils/constants";
import validationSchema from "utils/validationSchema";
import { IUploadValue } from "components/global/Upload/FileUploadWrapper";
import { selectProviderProfile } from "redux/slices/authSlice";
import { useAppSelector } from "redux/store";
import { Country, State } from "country-state-city";
import { handleUpdateBusinessInfo } from "services/admin/settings";

interface IFormValues {
  businessLogo: IUploadValue;

  businessName: string;
  businessEmail: string;
  businessAddress: string;
  portal: string;
  businessType: { label: string; value: string };
  state: { label: string; value: string };
  country: { label: string; value: string };
}

const BusinessInfoSettings = () => {
  const providerProfile = useAppSelector(selectProviderProfile);

  const successDisclosure = useDisclosure();

  const CountryObj = Country.getCountryByCode(providerProfile?.businessInfo?.country);
  const StateObj = State.getStateByCodeAndCountry(
    providerProfile?.businessInfo?.state,
    providerProfile?.businessInfo?.country
  );

  async function handleSubmit(values: IFormValues) {
    const data = {
      logo: values.businessLogo?.url,
      category: values.businessType?.value,
      address: values.businessAddress,
      country: values.country?.value,
      state: values.state?.value,
    };

    await handleUpdateBusinessInfo(data, () => {
      successDisclosure.onOpen();
    });
  }

  return (
    <>
      <WhiteBox>
        <Box maxW="1000px" mx="auto">
          <Heading color="neutral.500" fontWeight={600} fontSize="20px" mb={8}>
            Business Information
          </Heading>

          <Formik
            initialValues={{
              businessLogo: providerProfile?.businessInfo?.logo
                ? {
                    filename: providerProfile?.businessInfo?.name,
                    url: providerProfile?.businessInfo?.logo,
                  }
                : null,

              businessName: providerProfile?.businessInfo?.name,
              businessEmail: providerProfile?.businessInfo?.email,
              businessAddress: providerProfile?.businessInfo?.address,
              portal: providerProfile?.portal,
              businessType:
                BUSINESS_TYPE_OPTIONS.find(
                  item => item.value === providerProfile?.businessInfo?.category
                ) || null,
              country: {
                label: CountryObj?.name || providerProfile?.businessInfo?.country,
                value: providerProfile?.businessInfo?.country,
              },
              state: {
                label: StateObj?.name || providerProfile?.businessInfo?.state,
                value: providerProfile?.businessInfo?.state,
              },
            }}
            validationSchema={validationSchema.providerEditBusinessInfo}
            onSubmit={values => handleSubmit(values)}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              setFieldValue,
              handleChange,
              isSubmitting,
            }) => (
              <Form onBlur={handleBlur}>
                <ProfilePicUpload
                  name="businessLogo"
                  error={touched.businessLogo && errors.businessLogo}
                  value={values.businessLogo}
                  onFileChange={setFieldValue}
                />

                <SimpleGrid columns={[1, 1, 2]} columnGap={12} mt={8}>
                  <SDInput
                    id="businessName"
                    type="text"
                    label="Business name"
                    placeholder="Enter your business name"
                    defaultValue={values.businessName}
                    isReadOnly={true}
                  />

                  <SDInput
                    id="businessEmail"
                    type="email"
                    label="Business email"
                    placeholder="Enter your business email"
                    defaultValue={values.businessEmail}
                    isReadOnly={true}
                  />

                  <SDInput
                    id="businessAddress"
                    type="text"
                    label="Business address"
                    placeholder="Enter your business address"
                    value={values.businessAddress}
                    onChange={handleChange}
                    error={touched.businessAddress && errors.businessAddress}
                  />

                  <SDInput
                    id="portal"
                    type="text"
                    label="Website"
                    placeholder="speedylogistics"
                    defaultValue={`https://${values.portal}.sendrail.co`}
                    isReadOnly={true}
                  />

                  <SDSelect
                    id="businessType"
                    label="Business type"
                    placeholder="Select a business type"
                    value={values.businessType}
                    onChange={setFieldValue}
                    error={touched.businessType && errors.businessType}
                    options={BUSINESS_TYPE_OPTIONS}
                  />

                  <SDSelect
                    id="country"
                    label="Country"
                    placeholder="Select a country"
                    value={values.country}
                    onChange={setFieldValue}
                    error={touched.country && errors.country}
                    options={Country.getAllCountries().map(country => ({
                      label: country.name,
                      value: country.isoCode,
                    }))}
                  />

                  <SDSelect
                    id="state"
                    label="State"
                    placeholder="Select a state"
                    value={values.state}
                    onChange={setFieldValue}
                    error={touched.state && errors.state}
                    options={State.getStatesOfCountry(values.country?.value).map(state => ({
                      label: state.name,
                      value: state.isoCode,
                    }))}
                  />
                </SimpleGrid>

                <SDButton mt="48px" colorScheme="primary" type="submit" isLoading={isSubmitting}>
                  Save Changes
                </SDButton>
              </Form>
            )}
          </Formik>
        </Box>
      </WhiteBox>

      <SuccessModal
        isOpen={successDisclosure.isOpen}
        onClose={successDisclosure.onClose}
        message="Changes applied."
      />
    </>
  );
};

export default BusinessInfoSettings;
