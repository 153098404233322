import React, { useEffect, useMemo } from "react";
import { selectTenantProfile } from "redux/slices/authSlice";
import { useAppSelector } from "redux/store";

interface Props {
  title?: string;
}

function DocumentTitleSetter({ title = "" }: Props) {
  const tenantProfile = useAppSelector(selectTenantProfile);

  const BASE_TITLE = useMemo(
    () =>
      tenantProfile?.businessInfo?.name
        ? `${tenantProfile?.businessInfo?.name} | Sendrail`
        : "Sendrail",
    [tenantProfile?.businessInfo?.name]
  );

  useEffect(() => {
    if (title.trim()) {
      document.title = `${title} | ${BASE_TITLE}`;
    }

    return () => {
      document.title = `${BASE_TITLE}`;
    };
  }, [BASE_TITLE, title]);

  return <></>;
}

export default DocumentTitleSetter;
