import { Flex, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import { SDButton, SDFileUpload, SDInput, SDModal } from "components/global";
import SDSelect, { IOptions } from "components/global/Select";
import { IUploadValue } from "components/global/Upload/FileUploadWrapper";
import { useFormik } from "formik";
import { selectProviderProfile } from "redux/slices/authSlice";
import { useAppSelector } from "redux/store";
import { handleUpgradeTier2 } from "services/admin/dashboard";
import { TIER_2_ID_OPTIONS } from "utils/constants";
import validationSchema from "utils/validationSchema";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface IFormValues {
  idType: IOptions;
  idNumber: string;
  idFile: IUploadValue;
}

function UpgradeToTier2Modal({ isOpen, onClose }: Props) {
  const providerProfile = useAppSelector(selectProviderProfile);

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    isSubmitting,
    handleSubmit,
  } = useFormik({
    initialValues: {
      idType: TIER_2_ID_OPTIONS.find(i => i.value === providerProfile?.kyc?.level2?.type) || null,
      idNumber: providerProfile?.kyc?.level2?.idNumber || "",
      idFile: providerProfile?.kyc?.level2?.imageUrl
        ? { filename: "", url: providerProfile?.kyc?.level2?.imageUrl }
        : null,
    },
    validationSchema: validationSchema.providerKycTier2,
    onSubmit: async (values: IFormValues) => {
      const data = {
        type: values.idType?.value as string,
        idNumber: values.idNumber,
        imageUrl: values.idFile?.url,
      };

      await handleUpgradeTier2(data, () => onClose());
    },
  });

  return (
    <SDModal isOpen={isOpen} onClose={onClose} size="2xl">
      <Heading fontSize="20px" fontWeight={600} lineHeight="25px" mb={3}>
        Upgrade Account to Tier 2
      </Heading>
      <Text fontWeight={300} color="neutral.500" mb={8}>
        Upgrade account to tier 2 for a higher transaction limit
      </Text>

      <form onSubmit={handleSubmit} onBlur={handleBlur}>
        <SimpleGrid columns={[1, 1, 2]} columnGap={8}>
          <SDSelect
            id="idType"
            label="ID Type"
            placeholder="Select an option"
            options={TIER_2_ID_OPTIONS}
            value={values.idType}
            onChange={setFieldValue}
            error={touched.idType && errors.idType}
          />

          <SDInput
            id="idNumber"
            type="text"
            label="ID Number"
            placeholder="Enter an ID number"
            value={values.idNumber}
            onChange={handleChange}
            error={touched.idNumber && errors.idNumber}
          />
        </SimpleGrid>

        <SDFileUpload
          label="Upload an image of (National ID, Passport or Voter’s Card)"
          name="idFile"
          onFileChange={setFieldValue}
          value={values.idFile}
          error={touched.idFile && errors.idFile}
          supportedFileTypes={["image/*", "application/pdf"]}
        />

        <Flex gap={4} mt={10} justify="flex-end">
          <SDButton colorScheme="primary" variant="outline" onClick={onClose}>
            Cancel
          </SDButton>
          <SDButton colorScheme="primary" type="submit" isLoading={isSubmitting}>
            Confirm
          </SDButton>
        </Flex>
      </form>
    </SDModal>
  );
}

export default UpgradeToTier2Modal;
