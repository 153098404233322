import { useDisclosure } from "@chakra-ui/react";
import { SDButton, SDInput, SDModal, SDSelect } from "components/global";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { NEW_EMPLOYEE_ROLES_TYPE_OPTIONS } from "utils/constants";
import validationSchema from "utils/validationSchema";
import { AddNewRoleModal } from ".";
import { selectBankLists } from "redux/slices/adminSlices/dashboardSlice";
import { useAppSelector } from "redux/store";

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

interface IFormValues {
  name: string;
  email: string;
  bank: { label: string; value: string };
  accountNumber: string;
  role: {
    label: string;
    value: string;
  } | null;
}

function AddEmployeeModal({ onClose, isOpen }: Props) {
  const bankLists = useAppSelector(selectBankLists);

  const createCustomRoleDisclosure = useDisclosure();

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    isSubmitting,
    handleSubmit,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      bank: null,
      accountNumber: "",
      role: null,
    },
    validationSchema: validationSchema.providerAddNewEmployee,
    onSubmit: async (values: IFormValues, action) => {
      //   const data = {
      //     name: values.name,
      //     email: values.email,
      // bankCode: values.bank?.value,
      // bankName: values.bank?.label,
      // bankAccountNumber: values.accountNumber,
      //     role: values.role?.value,

      //   };
      //   await handleAddEmployee(data, res => {
      //   action.resetForm();
      //     onClose()
      //   });
      action.resetForm();
      onClose();
    },
  });

  useEffect(() => {
    const createCustomRoleOption =
      NEW_EMPLOYEE_ROLES_TYPE_OPTIONS[NEW_EMPLOYEE_ROLES_TYPE_OPTIONS.length - 1];
    if (values.role?.value === createCustomRoleOption.value) {
      // Open custom role modal
      createCustomRoleDisclosure.onOpen();

      setFieldValue("role", null);
    }
  }, [createCustomRoleDisclosure, setFieldValue, values]);

  return (
    <>
      <SDModal isOpen={isOpen} onClose={onClose} title="Add New Employee" size="sm">
        <form onSubmit={handleSubmit} onBlur={handleBlur}>
          <SDInput
            id="name"
            type="text"
            label="Full Name"
            placeholder="John Doe"
            value={values.name}
            onChange={handleChange}
            error={touched.name && errors.name}
          />

          <SDInput
            id="email"
            type="email"
            label="Email Address"
            placeholder="john.doe@gmail.com"
            value={values.email}
            onChange={handleChange}
            error={touched.email && errors.email}
          />

          <SDSelect
            id="bank"
            label="Bank Name"
            placeholder="Select a bank"
            options={bankLists.map(item => ({
              label: item.name,
              value: item.code,
            }))}
            value={values.bank}
            onChange={setFieldValue}
            error={touched.bank && errors.bank}
          />

          <SDInput
            id="accountNumber"
            type="text"
            label="Account Number"
            placeholder="9999999999"
            value={values.accountNumber}
            onChange={e => e.target.value.length <= 10 && handleChange(e)}
            error={touched.accountNumber && errors.accountNumber}
            min={10}
            max={10}
          />

          <SDSelect
            id="role"
            label="Role"
            placeholder="Select a role"
            options={NEW_EMPLOYEE_ROLES_TYPE_OPTIONS}
            value={values.role}
            onChange={setFieldValue}
            error={touched.role && errors.role}
          />

          <SDButton type="submit" colorScheme="primary" w="full" mt={8} isLoading={isSubmitting}>
            Proceed to create payroll
          </SDButton>
        </form>
      </SDModal>

      <AddNewRoleModal
        isOpen={createCustomRoleDisclosure.isOpen}
        onClose={createCustomRoleDisclosure.onClose}
      />
    </>
  );
}

export default AddEmployeeModal;
