import { IOrderType, IUserVerificationType } from "types/global";

export class AllRoutes {
  // ###===> AUTH PAGES ROUTES
  // ###===> AUTH PAGES ROUTES
  // ###===> AUTH PAGES ROUTES
  static components = "/components";
  static colors = "/colors";

  static providerLogin = "/login";
  static providerLoginHandover = "/handover";
  static clientLogin = "/login/client";

  static signUp = "/signup";
  static providerSignUp = "/signup/provider";
  static clientSignUp = "/signup/client";
  static courierSignUp = "/signup/courier";

  static forgotPassword = "/forgot-password";
  static resetPassword = (resetToken: string) =>
    resetToken ? `/reset-password?token=${resetToken}` : `/reset-password`;

  static verifyEmail = (email: string = "", type: IUserVerificationType = "email") =>
    email ? `/verify?email=${email}&type=${type}` : `/verify`;

  // ###===> PROVIDER ROUTES
  // ###===> PROVIDER ROUTES
  // ###===> PROVIDER ROUTES

  static providerDashboard = "/provider/dashboard";
  static providerAccountSetup = "/provider/dashboard/setup";

  static providerShipment = "/provider/shipment";
  static providerCreateSingleOrder = (deliveryType: "" | IOrderType) =>
    deliveryType
      ? `/provider/shipment/new-order/single?type=${deliveryType}`
      : `/provider/shipment/new-order/single`;
  static providerCreateBulkOrder = "/provider/shipment/new-order/bulk";
  static providerShipmentRoute = (shipmentId: ":shipmentId" | string) =>
    `/provider/shipment/${shipmentId}/route`;

  static providerCustomer = "/provider/customer";
  static providerAddCustomer = "/provider/customer/add";

  static providerCourier = "/provider/courier";
  static providerAddCourier = "/provider/courier/add";
  static providerViewCourier = (id: ":id" | string) => `/provider/courier/${id}`;

  static providerPayroll = "/provider/payroll";
  static providerAddPayroll = "/provider/payroll/add";
  static providerEditPayroll = "/provider/payroll/edit";

  static providerTransactions = "/provider/transactions";

  static providerSettings = "/provider/settings";
  static providerAddLastMilePricing = "?p=addLastMilePricing";
  static providerEditLastMilePricing = (id: string) => `?p=editLastMilePricing&id=${id}`;
  static providerAddInterStatePricing = "?p=addInterStatePricing";
  static providerEditInterStatePricing = (id: string) => `?p=editInterStatePricing&id=${id}`;

  // ###===> CLIENT ROUTES
  // ###===> CLIENT ROUTES
  // ###===> CLIENT ROUTES

  static clientEntryPoint = "/order";
  static clientDashboard = "/client/dashboard";
  static clientCreateSingleOrder = (deliveryType: "" | IOrderType) =>
    deliveryType
      ? `/client/dashboard/new-order/single?type=${deliveryType}`
      : `/client/dashboard/new-order/single`;

  static clientEstimate = "/client/estimate";

  static clientOrderHistory = "/client/order-history";
  static clientOrderRoute = (orderId: ":orderId" | string) =>
    `/client/order-history/${orderId}/route`;

  static clientRecentActivities = "/client/recent-activities";

  static clientProfile = "/client/profile";
}
