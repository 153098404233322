import { Center, PinInput, PinInputField, Spinner, Text } from "@chakra-ui/react";
import { SDButton, SDModal } from "components/global";
import { useFormik } from "formik";
import { useState } from "react";
import { handleSuccessMessage } from "services";
import { handleClientLogin, handleVerifyClientLogin } from "services/auth";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  email: string;
  onSuccess: (() => Promise<void>) & (() => Promise<any>);
}

interface IFormValues {
  code: string;
}

function ClientOTPVerificationModal({ isOpen, onClose, email, onSuccess }: Props) {
  const [isResendingOTP, setIsResendingOTP] = useState(false);

  const { values, setFieldValue, isSubmitting, handleSubmit, submitForm } = useFormik({
    initialValues: { code: "" },
    onSubmit: async (values: IFormValues) => {
      const data = {
        identifier: email,
        code: values.code,
      };

      await handleVerifyClientLogin(data, () => {
        onClose();
        onSuccess();
      });
    },
  });

  async function handleResendOTP() {
    setIsResendingOTP(true);

    const data = {
      identifier: email,
    };

    await handleClientLogin(data, () => {
      handleSuccessMessage("OTP has been resent successfully");
    });

    setIsResendingOTP(false);
  }

  return (
    <SDModal isOpen={isOpen} onClose={onClose} title="OTP Verification" size="md">
      <form onSubmit={handleSubmit}>
        <Center gap={[2, 3, 6]}>
          <PinInput
            id="code"
            placeholder=""
            value={values.code}
            onChange={value => setFieldValue("code", value)}
            onComplete={() => !isSubmitting && submitForm()}
          >
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
        </Center>

        <Center flexDir={{ base: "column", md: "row" }} gap={4} mt={6}>
          <Text>
            Did not receive OTP?{" "}
            <Text
              as="span"
              fontWeight={700}
              cursor="pointer"
              onClick={handleResendOTP}
              _hover={{ textDecoration: "underline" }}
            >
              Resend
            </Text>
          </Text>
        </Center>

        {isResendingOTP && (
          <Center mt={4}>
            <Spinner colorScheme="primary" />
          </Center>
        )}

        <Center mt={10}>
          <SDButton
            colorScheme="primary"
            w="80%"
            mx="auto"
            isDisabled={values.code.length !== 5}
            type="submit"
            isLoading={isSubmitting}
          >
            Verify
          </SDButton>
        </Center>
      </form>
    </SDModal>
  );
}

export default ClientOTPVerificationModal;
