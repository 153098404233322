import { Box, Flex, Heading, SimpleGrid, useDisclosure } from "@chakra-ui/react";
import {
  ProfilePicUpload,
  SDButton,
  SDInput,
  SDSelect,
  SuccessModal,
  WhiteBox,
} from "components/global";
import { Form, Formik } from "formik";
import { AllRoutes } from "routes/AllRoutes";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "components/global/Layouts/DashboardLayout";
import { handleCreateCustomer } from "services/admin/customer";
import validationSchema from "utils/validationSchema";
import { GENDER_TYPE_OPTIONS } from "utils/constants";
import { IUploadValue } from "components/global/Upload/FileUploadWrapper";

interface IFormValues {
  profilePic: IUploadValue;
  fullName: string;
  gender: { label: string; value: string };
  phoneNumber: string;
  email: string;
}

const AddCustomerPage = () => {
  const navigate = useNavigate();

  const addCustomerSuccessDisclosure = useDisclosure();

  async function handleSubmit(values: IFormValues) {
    const data = {
      avatar: values.profilePic?.url,
      fullName: values.fullName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      gender: values.gender.value,
    };

    await handleCreateCustomer(data, () => addCustomerSuccessDisclosure.onOpen());
  }

  return (
    <>
      <DashboardLayout
        role="provider"
        pageTitle="Add Customer"
        isGreyBackground={true}
        showBackButton={true}
      >
        <WhiteBox>
          <Box maxW="1000px" mx="auto">
            <Heading color="neutral.500" fontWeight={600} fontSize="20px" mb={8}>
              Customer Profile
            </Heading>

            <Formik
              initialValues={{
                profilePic: null,
                fullName: "",
                gender: null,
                phoneNumber: "",
                email: "",
              }}
              validationSchema={validationSchema.providerAddCustomer}
              onSubmit={values => handleSubmit(values)}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                setFieldValue,
                handleChange,
                isSubmitting,
              }) => (
                <Form onBlur={handleBlur}>
                  <ProfilePicUpload
                    name="profilePic"
                    error={touched.profilePic && errors.profilePic}
                    value={values.profilePic}
                    onFileChange={setFieldValue}
                  />

                  <SimpleGrid columns={[1, 1, 2]} columnGap={12} mt={8}>
                    <SDInput
                      id="fullName"
                      type="text"
                      label="Full Name"
                      placeholder="Enter full name"
                      value={values.fullName}
                      onChange={handleChange}
                      error={touched.fullName && errors.fullName}
                    />

                    <SDSelect
                      id="gender"
                      label="Gender"
                      placeholder="Select a gender"
                      options={GENDER_TYPE_OPTIONS}
                      value={values.gender}
                      onChange={setFieldValue}
                      error={touched.gender && errors.gender}
                    />

                    <SDInput
                      id="phoneNumber"
                      type="tel"
                      label="Phone Number"
                      placeholder="Enter phone number"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      error={touched.phoneNumber && errors.phoneNumber}
                    />

                    <SDInput
                      id="email"
                      type="email"
                      label="Email Address"
                      placeholder="Enter email address"
                      value={values.email}
                      onChange={handleChange}
                      error={touched.email && errors.email}
                    />
                  </SimpleGrid>

                  <Flex
                    mt="48px"
                    gap={4}
                    align={{ md: "center" }}
                    justify="space-between"
                    flexDir={{ base: "column", md: "row" }}
                  >
                    <SDButton colorScheme="primary" type="submit" isLoading={isSubmitting}>
                      Save Customer
                    </SDButton>
                  </Flex>
                </Form>
              )}
            </Formik>
          </Box>
        </WhiteBox>
      </DashboardLayout>

      <SuccessModal
        isOpen={addCustomerSuccessDisclosure.isOpen}
        onClose={addCustomerSuccessDisclosure.onClose}
        onBtnClick={() => navigate(AllRoutes.providerCustomer)}
        message="Customer added."
      />
    </>
  );
};

export default AddCustomerPage;
