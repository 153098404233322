import { Flex, Text } from "@chakra-ui/react";
import { handleForgotPassword } from "services/auth";
import { SDButton, SDInput } from "components/global";
import AuthLayout from "components/global/Layouts/AuthLayout";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { AllRoutes } from "routes/AllRoutes";
import validationSchema from "utils/validationSchema";

interface IFormValues {
  email: string;
}

function ForgotPasswordPage() {
  const navigate = useNavigate();

  const { values, errors, touched, handleBlur, handleChange, isSubmitting, handleSubmit } =
    useFormik({
      initialValues: { email: "" },
      validationSchema: validationSchema.forgotPassword,
      onSubmit: async (values: IFormValues) => {
        const data = { email: values.email };

        await handleForgotPassword(data, () => {
          navigate(AllRoutes.verifyEmail(values.email, "password"));
        });
      },
    });

  return (
    <AuthLayout
      title="Forgot password"
      description="A verification code will be sent to your email"
    >
      <form onSubmit={handleSubmit} onBlur={handleBlur}>
        <SDInput
          variant="grey"
          id="email"
          type="email"
          label="Email"
          placeholder="example@gmail.com"
          value={values.email}
          onChange={handleChange}
          error={touched.email && errors.email}
        />

        <SDButton colorScheme="primary" mt={10} w="full" type="submit" isLoading={isSubmitting}>
          Submit
        </SDButton>

        <Flex
          flexDir={{ base: "column", md: "row" }}
          gap={4}
          align="center"
          justify="space-between"
          mt={6}
        >
          <Text>
            Remember password?{" "}
            <Link to={AllRoutes.providerLogin}>
              <Text as="span" fontWeight={700}>
                Sign in
              </Text>
            </Link>
          </Text>
        </Flex>
      </form>
    </AuthLayout>
  );
}

export default ForgotPasswordPage;
