import { Box, Center, CenterProps, HStack, Icon, Text } from "@chakra-ui/react";
import SDFileUploadWrapper, { UploadProps } from "./FileUploadWrapper";
import { IoCloudUploadSharp } from "react-icons/io5";
import { SDInputHelperText } from "..";

interface Props extends UploadProps {
  uploadText?: string;
  hidePreview?: boolean;
  uploadContainerProps?: CenterProps;
}

function SDFileUpload({ uploadText, hidePreview = false, uploadContainerProps, ...rest }: Props) {
  return (
    <Box>
      <SDFileUploadWrapper {...rest} w="full">
        <Center
          flexDir="column"
          gap={4}
          p={4}
          borderRadius="10px"
          border="1px dashed #ABA7A7"
          minH="180px"
          w="full"
          transition="0.1s"
          _hover={{ bgColor: "#DCDBEA33" }}
          {...uploadContainerProps}
        >
          <HStack>
            <Icon as={IoCloudUploadSharp} boxSize={5} color="primary.500" />
            <Text fontWeight={600} color="primary.500">
              {uploadText || "Upload a file"}
            </Text>
          </HStack>

          {rest.value?.filename && (
            <SDInputHelperText
              helperText={rest.value?.filename}
              color="success.400"
              fontSize="md"
              mt={0}
            />
          )}
        </Center>
      </SDFileUploadWrapper>

      {hidePreview === false && rest.value?.url && (
        <Box mb={4} borderRadius="10px" overflow="hidden">
          <iframe
            title={rest.value?.url}
            src={rest.value?.url}
            style={{ width: "100%", height: "100%", border: "none" }}
          ></iframe>
        </Box>
      )}
    </Box>
  );
}

export default SDFileUpload;
