import { Box, Flex, Text, Heading } from "@chakra-ui/react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import { AllRoutes } from "routes/AllRoutes";
import { ShipmentTable } from "../shipment";

function RecentOrders() {
  return (
    <Box backgroundColor="#ffffff" borderWidth={1} borderRadius="10px" p={4}>
      <Flex mb="40px" justify="space-between" align="center">
        <Heading fontSize={["18px", "18px", "22px", "28px"]}>Recent Orders</Heading>

        <Flex as={Link} to={AllRoutes.providerShipment} color="primary.500" align="center">
          <Text fontSize="14px">View all</Text>
          <MdKeyboardArrowRight />
        </Flex>
      </Flex>

      <ShipmentTable isForRecentOrders={true} />
    </Box>
  );
}

export default RecentOrders;
