import { Box, Heading, SimpleGrid, useDisclosure } from "@chakra-ui/react";
import {
  WhiteBox,
  ProfilePicUpload,
  SDInput,
  SDSelect,
  SDButton,
  SuccessModal,
} from "components/global";
import { Formik, Form } from "formik";
import { GENDER_TYPE_OPTIONS } from "utils/constants";
import validationSchema from "utils/validationSchema";
import { IUploadValue } from "components/global/Upload/FileUploadWrapper";
import { selectProviderProfile } from "redux/slices/authSlice";
import { useAppSelector } from "redux/store";
import { handleUpdateProfile } from "services/admin/settings";

interface IFormValues {
  profilePic: IUploadValue;
  name: string;
  gender: { label: string; value: string };
  phoneNumber: string;
  email: string;
}

const ProfileSettings = () => {
  const providerProfile = useAppSelector(selectProviderProfile);

  const successDisclosure = useDisclosure();

  async function handleSubmit(values: IFormValues) {
    const data = {
      profilePic: values.profilePic?.url,
      fullName: values?.name,
      gender: values.gender?.value,
    };

    await handleUpdateProfile(data, () => {
      successDisclosure.onOpen();
    });
  }

  return (
    <>
      <WhiteBox>
        <Box maxW="1000px" mx="auto">
          <Heading color="neutral.500" fontWeight={600} fontSize="20px" mb={8}>
            Personal Information
          </Heading>

          <Formik
            initialValues={{
              profilePic: providerProfile?.personalInformation?.profilePic
                ? {
                    filename: providerProfile?.personalInformation?.fullName,
                    url: providerProfile?.personalInformation?.profilePic,
                  }
                : null,
              name: providerProfile?.personalInformation?.fullName,
              gender:
                GENDER_TYPE_OPTIONS.find(
                  item =>
                    item.value.toLowerCase() ===
                    providerProfile?.personalInformation?.gender?.toLowerCase()
                ) || null,
              phoneNumber: providerProfile?.phoneNumber,
              email: providerProfile?.email,
            }}
            validationSchema={validationSchema.providerEditProfile}
            onSubmit={values => handleSubmit(values)}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              setFieldValue,
              handleChange,
              isSubmitting,
            }) => (
              <Form onBlur={handleBlur}>
                <ProfilePicUpload
                  name="profilePic"
                  error={touched.profilePic && errors.profilePic}
                  value={values.profilePic}
                  onFileChange={setFieldValue}
                />

                <SimpleGrid columns={[1, 1, 2]} columnGap={12} mt={8}>
                  <SDInput
                    id="name"
                    type="text"
                    label="Full Name"
                    placeholder="John Doe"
                    value={values.name}
                    onChange={handleChange}
                    error={touched.name && errors.name}
                  />

                  <SDSelect
                    id="gender"
                    label="Gender"
                    placeholder="Select a gender"
                    options={GENDER_TYPE_OPTIONS}
                    value={values.gender}
                    onChange={setFieldValue}
                    error={touched.gender && errors.gender}
                  />

                  <SDInput
                    id="phoneNumber"
                    type="tel"
                    label="Phone Number"
                    placeholder="08012345678"
                    defaultValue={values.phoneNumber}
                    isReadOnly={true}
                  />

                  <SDInput
                    id="email"
                    type="email"
                    label="Email Address"
                    placeholder="john.doe@gmail.com"
                    defaultValue={values.email}
                    isReadOnly={true}
                  />
                </SimpleGrid>

                <SDButton mt="48px" colorScheme="primary" type="submit" isLoading={isSubmitting}>
                  Save Changes
                </SDButton>
              </Form>
            )}
          </Formik>
        </Box>
      </WhiteBox>

      <SuccessModal
        isOpen={successDisclosure.isOpen}
        onClose={successDisclosure.onClose}
        message="Changes applied."
      />
    </>
  );
};

export default ProfileSettings;
