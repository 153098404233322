import { Search2Icon, SearchIcon } from "@chakra-ui/icons";
import { IconButton, useDisclosure } from "@chakra-ui/react";
import { ShipmentDetailsDrawer } from "components/admin/shipment";
import { SDInput } from "components/global";
import { InputFieldProps } from "components/global/Input";
import { useFormik } from "formik";
import { useMemo, useState } from "react";
import { IShipment } from "redux/slices/adminSlices/shipmentSlice/types";
import { handleProviderGetShipmentByRef } from "services/admin/shimpent";
import { handleClientGetOrderByRef } from "services/client/orderHistory";
import { IUserType } from "types/global";
import { isClientLoggedIn, isRideHailingTenant } from "utils/utilFunctions";

interface IFormValues {
  searchValue: string;
}

interface Props extends InputFieldProps {
  role: IUserType;
  removeFancyStyles?: boolean;
}

function SearchOrderByTrackingRef({
  placeholder,
  role,
  removeFancyStyles = false,
  ...rest
}: Props) {
  const viewOrderDisclosure = useDisclosure();
  const [shipmentToView, setShipmentToView] = useState<IShipment | null>(null);

  const { values, handleBlur, handleChange, isSubmitting, handleSubmit } = useFormik({
    initialValues: { searchValue: "" },
    onSubmit: async (values: IFormValues) => {
      const searchText = values.searchValue.trim();

      if (searchText) {
        if (role === "provider") {
          await handleProviderGetShipmentByRef(searchText, res => {
            setShipmentToView(res);

            viewOrderDisclosure.onOpen();
          });
        } else if (role === "client") {
          await handleClientGetOrderByRef(searchText, res => {
            setShipmentToView(res);

            viewOrderDisclosure.onOpen();
          });
        }
      }
    },
  });

  const fancyStyles = useMemo(
    () =>
      removeFancyStyles
        ? {}
        : {
            borderRadius: role === "provider" ? "10px" : "20px",
            border: role === "provider" ? "none" : "1.5px solid #0600891A",
            boxShadow: role === "provider" ? "0px 4px 20px 0px #0600891A" : null,
            _focus: { boxShadow: role === "provider" ? "0px 4px 20px 0px #0600891A" : null },
            containerProps: {
              borderRadius: role === "provider" ? "10px" : "20px",
              overflow: role === "provider" ? "unset" : "hidden",
            },
          },
    [removeFancyStyles, role]
  );

  if (role === "client" && !isClientLoggedIn()) {
    return <></>;
  }

  return (
    <>
      <form onSubmit={handleSubmit} onBlur={handleBlur} style={{ width: "100%" }}>
        <SDInput
          id="searchValue"
          type="text"
          placeholder={
            placeholder
              ? placeholder
              : role === "provider"
              ? "Search shipment by tracking reference"
              : isRideHailingTenant()
              ? "Enter Ride ID"
              : "Enter Order ID"
          }
          value={values.searchValue}
          onChange={handleChange}
          size="md"
          fontSize="12px"
          lineHeight="14px"
          outline="none"
          addOnElementAfter={
            !!values.searchValue.trim() ? (
              <IconButton
                type="submit"
                icon={<Search2Icon w="16px" h="16px" />}
                variant="solid"
                colorScheme="primary"
                aria-label="Search"
                isLoading={isSubmitting}
                borderStartRadius={0}
                size={rest?.size || "md"}
              />
            ) : (
              <SearchIcon color="gray.300" />
            )
          }
          // Do not shift from here
          {...fancyStyles}
          {...rest}
          containerProps={{
            my: 0,
            ...fancyStyles?.containerProps,
            ...rest?.containerProps,
          }}
        />
      </form>

      <ShipmentDetailsDrawer
        isOpen={viewOrderDisclosure.isOpen}
        onClose={viewOrderDisclosure.onClose}
        shipmentToView={shipmentToView}
        role={role}
      />
    </>
  );
}

export default SearchOrderByTrackingRef;
