import { Flex, Heading, Skeleton } from "@chakra-ui/react";
import { IShipmentStat } from "./ShipmentStats";

interface Props {
  stat: IShipmentStat;
  isActive?: boolean;
  onClick?: () => void;
  isLoading: boolean;
}

function ShipmentStatItem({ stat, isLoading, isActive, onClick }: Props) {
  return (
    <Flex
      gap={2}
      color={isActive ? "#fff" : "primary.500"}
      bgColor={isActive ? "primary.500" : "#fff"}
      cursor={!!onClick ? "pointer" : "initial"}
      onClick={() => onClick && onClick()}
      borderRadius="10px"
      borderWidth={1}
      flexDir="column"
      px={4}
      py={6}
      transition="300ms ease-out"
      boxShadow="0px 4px 20px 0px #DFDEF0C3"
    >
      {/* <Flex justifyContent="flex-end">
        <Menu>
          <Flex align="center">
            <MenuButton bg="transparent">
              <Flex align="center" gap={1}>
                <Text fontSize="14px" color={isActive ? "#fff" : "neutral.500"}>
                  {selectedOption}
                </Text>
                <MdKeyboardArrowDown />
              </Flex>
            </MenuButton>
          </Flex>

          <MenuList>
            {options.map(option => (
              <MenuItem
                key={option}
                onClick={() => handleOptionChange(option)}
                color={"neutral.500"}
                _hover={{ backgroundColor: "primary.500", color: "white" }}
              >
                {option}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex> */}

      <Flex align="center" gap="15px">
        <Flex flexDir="column" gap={1}>
          <Heading
            fontWeight={600}
            fontSize={{ base: "16px", md: "18px" }}
            color={isActive ? "#fff" : "primary.500"}
          >
            {stat.title}
          </Heading>
          <Skeleton isLoaded={!isLoading}>
            <Heading
              fontWeight={600}
              fontSize={{ base: "16px", md: "18px" }}
              color={isActive ? "#fff" : "neutral.500"}
            >
              {stat.value || 0}
            </Heading>
          </Skeleton>

          {/* <Flex align="center" gap={1}>
            <Icon
              as={stat.isIncrease ? AiOutlineArrowUp : AiOutlineArrowDown}
              color={stat.isIncrease ? "success.500" : "error.500"}
            />
            <Text color={stat.isIncrease ? "success.500" : "error.500"} fontSize="12px">
              {stat.percentage}
            </Text>
            <Text color={isActive ? "#fff" : "neutral.200"} fontSize="12px" lineHeight="14px">
              than last week
            </Text>
          </Flex> */}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ShipmentStatItem;
