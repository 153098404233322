import { PhoneIcon, SearchIcon, EmailIcon } from "@chakra-ui/icons";
import { Avatar, Flex, HStack, Text } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import DashboardLayout from "components/global/Layouts/DashboardLayout";
import { AllRoutes } from "routes/AllRoutes";
import { SDButton, SDInput, SDTable } from "components/global";
import { useEffect, useState } from "react";
import { handleFetchCustomers } from "services/admin/customer";
import { useAppSelector } from "redux/store";
import { selectCustomers } from "redux/slices/adminSlices/customerSlice";
import { ICustomer } from "redux/slices/adminSlices/customerSlice/types";
import { setUpQuery } from "utils/utilFunctions";

const CustomerPage = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const customersState = useAppSelector(selectCustomers);

  // const [activeRecord, setActiveRecord] = useState<ICustomer>(null);

  // const deleteCustomerDisclosure = useDisclosure();
  // const deleteCustomerSuccessDisclosure = useDisclosure();

  // const createOrderDisclosure = useDisclosure();

  // const [isDeleting, setIsDeleting] = useState(false);
  // const [customerToDelete, setCustomerToDelete] = useState<ICustomer | null>(null);

  const tableHeader = [
    {
      label: "Name",
      dataIndex: "fullName",
      render: (fullName, record: ICustomer) =>
        !!fullName ? (
          <Flex align="center" gap={4}>
            <Avatar name={fullName} src={record?.avatar} />
            <Text fontWeight={700} color="neutral.900">
              {fullName}
            </Text>
          </Flex>
        ) : (
          "---"
        ),
    },
    {
      label: "Email",
      dataIndex: "email",
      render: email =>
        !!email ? (
          <Link to={`mailto:${email}`}>
            <SDButton
              p={2}
              size="xs"
              leftIcon={<EmailIcon />}
              colorScheme="neutral"
              variant="ghost"
            >
              {email}
            </SDButton>
          </Link>
        ) : (
          "---"
        ),
    },
    {
      label: "Phone Number",
      dataIndex: "phoneNumber",
      render: phoneNumber =>
        !!phoneNumber ? (
          <Link to={`tel:${phoneNumber}`}>
            <SDButton
              p={2}
              size="xs"
              leftIcon={<PhoneIcon />}
              colorScheme="neutral"
              variant="ghost"
            >
              {phoneNumber}
            </SDButton>
          </Link>
        ) : (
          "---"
        ),
    },
    {
      label: "Create Order",
      dataIndex: "createOrder",
      render: (_, record: ICustomer) => (
        <SDButton
          colorScheme="primary"
          size="md"
          onClick={() => {
            const query = setUpQuery({
              customerName: record?.fullName,
              customerPhoneNumber: record?.phoneNumber,
            }).replace("?", "&");
            navigate(AllRoutes.providerCreateSingleOrder("lastMile") + query);

            // TODO: Uncomment this later
            // setActiveRecord(record);

            // createOrderDisclosure.onOpen();
          }}
        >
          Create Order
        </SDButton>
      ),
    },
    // TODO: Handle this later and change the delete to block customer instead
    // {
    //   label: "Action",
    //   dataIndex: "action",
    //   render: (_, record: ICustomer) => (
    //     <SDButton
    //       variant="link"
    //       size="md"
    //       colorScheme="error"
    //       p={0}
    //       textDecor="underline"
    //       _hover={{ textDecor: "none" }}
    //       onClick={() => {
    //         setCustomerToDelete(record);
    //         deleteCustomerDisclosure.onOpen();
    //       }}
    //     >
    //       Delete Customer
    //     </SDButton>
    //   ),
    // },
  ];

  // async function deleteCustomer() {
  //   setIsDeleting(true);

  //   await handleDeleteCustomer(customerToDelete?._id, () => {
  //     deleteCustomerDisclosure.onClose();
  //     deleteCustomerSuccessDisclosure.onOpen();
  //   });

  //   setIsDeleting(false);
  // }

  async function fetchData(page?: number) {
    setIsLoading(true);

    await handleFetchCustomers(page);

    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <DashboardLayout role="provider" pageTitle="Manage Customer" documentTitle="Customer">
        <Flex
          flexDir={{ base: "column-reverse", md: "row" }}
          gap={{ md: 4 }}
          align={{ md: "center" }}
          justify="space-between"
          my={6}
        >
          <HStack>
            <SDInput
              placeholder="Search by name"
              addOnElementAfter={<SearchIcon color="neutral.200" />}
            />

            {/* <SDButton leftIcon={<BiFilter />} variant="outline" colorScheme="gray">
              Filter
            </SDButton> */}
          </HStack>

          <SDButton colorScheme="primary" onClick={() => navigate(AllRoutes.providerAddCustomer)}>
            Add Customer
          </SDButton>
        </Flex>

        <SDTable
          header={tableHeader}
          isLoading={isLoading}
          body={customersState.records}
          paginationConfig={customersState.pagination}
          onPaginationChange={page => fetchData(page)}
        />
      </DashboardLayout>

      {/* <WarningModal
        isOpen={deleteCustomerDisclosure.isOpen}
        onClose={deleteCustomerDisclosure.onClose}
        message="Do you want to delete this customer?"
        subMessage={customerToDelete?.fullName}
        proceedBtnText="Delete"
        onBtnClick={() => deleteCustomer()}
        isLoading={isDeleting}
      />

      <SuccessModal
        isOpen={deleteCustomerSuccessDisclosure.isOpen}
        onClose={deleteCustomerSuccessDisclosure.onClose}
        message="Customer deleted."
      /> */}

      {/* <SelectDeliveryTypeModal
        isOpen={createOrderDisclosure.isOpen}
        onClose={createOrderDisclosure.onClose}
        role="provider"
        customerFromProvider={activeRecord}
      /> */}
    </>
  );
};

export default CustomerPage;
