import { Flex, Text, Center, useDisclosure, useMediaQuery, Box } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import DashboardLayout from "components/global/Layouts/DashboardLayout";
import { TransactionDetailsModal } from "components/admin/transactions";
import {
  EmptyState,
  GreyBox,
  MainLoader,
  SDButton,
  SDInput,
  SDPagination,
  SDSelect,
} from "components/global";
import { useEffect, useState } from "react";
import { IOptions } from "components/global/Select";
import { conertKoboToNaira, formatAmount, formatDate } from "utils/utilFunctions";
import { CreditIcon } from "utils/customIcons";
import { handleProviderGetTransactions } from "services/admin/transaction";
import { useAppSelector } from "redux/store";
import {
  selectIsTransactionLoading,
  selectTransactions,
} from "redux/slices/adminSlices/transactionsSlice";
import { ITransaction } from "redux/slices/adminSlices/transactionsSlice/types";

const sortByOptions = [
  { label: "All", value: "All" },
  { label: "Last 30 days", value: "Last 30 days" },
  { label: "Last week", value: "Last week" },
  { label: "Last two weeks", value: "Last two weeks" },
  { label: "Last 60 days", value: "Last 60 days" },
  { label: "Custom", value: "Custom" },
];

function TransactionsPage() {
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  const isTransactionsLoading = useAppSelector(selectIsTransactionLoading);
  const transactionsState = useAppSelector(selectTransactions);

  const [sortBy, setSortBy] = useState<IOptions>(sortByOptions[0]);

  const viewTransactionDisclosure = useDisclosure();
  const [transactionToView, setTransactionToView] = useState<ITransaction>(null);

  async function getTransactions(page?: number) {
    await handleProviderGetTransactions(page);
  }

  useEffect(() => {
    getTransactions();
  }, []);

  return (
    <DashboardLayout role="provider" pageTitle="Transaction History" documentTitle="Transaction">
      {isTransactionsLoading && <MainLoader />}

      {transactionsState?.records?.length === 0 ? (
        <EmptyState message="No History to view" variant="lg" mt={10} />
      ) : (
        <Box pos="relative">
          {/* Do not remove */}
          <Box id="topOfTheList" pos="absolute" top="-150px" zIndex={99999} />

          <Flex
            flexDir={{ base: "column", md: "row" }}
            justify="flex-end"
            align="center"
            gap={{ md: 8 }}
          >
            <SDInput
              addOnElementAfter={<SearchIcon color="gray.300" />}
              placeholder="Search by date"
              type="text"
              containerProps={{
                w: { md: "fit-content" },
              }}
            />

            <Flex align="center" gap={3}>
              <Text
                flexShrink="0"
                color="neutral.500"
                fontWeight="500"
                fontSize="14px"
                lineHeight="22px"
              >
                Filter by:
              </Text>
              <SDSelect
                id="sortBy"
                value={sortBy}
                options={sortByOptions}
                onChange={(id, value: IOptions) => setSortBy(value)}
                containerProps={{ minW: "120px" }}
              />
            </Flex>
          </Flex>

          <Flex flexDir="column" gap={4}>
            {transactionsState?.records?.map((transaction, key) => (
              <GreyBox
                borderRadius="10px"
                py={4}
                px={[4, 6, 8]}
                key={key}
                onClick={() => {
                  if (isMobile) {
                    setTransactionToView(transaction);
                    viewTransactionDisclosure.onOpen();
                  }
                }}
              >
                <Flex gap={4} justify="space-between">
                  <Flex gap={4} align="center">
                    {/* {transaction?.title === "Deposit" ? ( */}
                    <CreditIcon w={{ base: 6, md: 12 }} h={{ base: 6, md: 12 }} />
                    {/* ) : (
                      <DebitIcon w={{ base: 6, md: 12 }} h={{ base: 6, md: 12 }} />
                    )} */}

                    <Flex flexDir="column">
                      <Text
                        color="neutral.700"
                        fontWeight="600"
                        fontSize={{ base: "14px", md: "18px" }}
                        lineHeight="29px"
                      >
                        {transaction?.name}
                      </Text>
                      <Text
                        color="neutral.500"
                        fontWeight="500"
                        fontSize={{ base: "12px", md: "16px" }}
                        lineHeight="29px"
                      >
                        {formatDate(transaction?.createdAt)}
                      </Text>
                    </Flex>
                  </Flex>

                  <Flex gap={4} align="center">
                    <Center flexDir="column">
                      <Text
                        as={Flex}
                        color="neutral.700"
                        fontWeight="600"
                        fontSize={{ base: "14px", md: "18px" }}
                        lineHeight="29px"
                      >
                        {/* <span>{transaction?.title === "Transfer" ? "-" : "+"}&nbsp;</span> */}
                        {formatAmount(
                          conertKoboToNaira(transaction?.disburseLog?.fees_split?.subaccount)
                        )}
                      </Text>
                      <Text
                        color="neutral.500"
                        fontWeight="300"
                        fontSize={{ base: "12px", md: "16px" }}
                        lineHeight="29px"
                        textTransform="capitalize"
                      >
                        {transaction?.status}
                      </Text>
                    </Center>

                    <SDButton
                      display={{ base: "none", md: "flex" }}
                      variant="ghost"
                      colorScheme="primary"
                      size="sm"
                      p={4}
                      color="#102471"
                      onClick={() => {
                        setTransactionToView(transaction);
                        viewTransactionDisclosure.onOpen();
                      }}
                    >
                      View
                    </SDButton>
                  </Flex>
                </Flex>
              </GreyBox>
            ))}
          </Flex>

          <SDPagination
            paginationConfig={transactionsState.pagination}
            onPaginationChange={page => getTransactions(page)}
            sectionToSrollTo="topOfTheList"
          />

          <TransactionDetailsModal
            isOpen={viewTransactionDisclosure.isOpen}
            onClose={viewTransactionDisclosure.onClose}
            transactionToView={transactionToView}
          />
        </Box>
      )}
    </DashboardLayout>
  );
}

export default TransactionsPage;
