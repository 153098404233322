import {
  Text,
  Flex,
  Box,
  Avatar,
  Heading,
  Center,
  SimpleGrid,
  Icon,
  IconButton,
  FlexProps,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { ChatIcon, UserIcon, PhoneIcon } from "utils/customIcons";
import { RiCloseCircleLine } from "react-icons/ri";
import { IShipment } from "redux/slices/adminSlices/shipmentSlice/types";
import { COURIER_OPTIONS } from "utils/constants";
import { SDTag } from "components/global";
import { Link } from "react-router-dom";

interface Props extends FlexProps {
  shipmentToView: IShipment;
  alwaysShow?: boolean;
  onCloseAction?: () => void;
}

const CourierProfileCard = ({
  shipmentToView,
  alwaysShow = false,
  onCloseAction = () => {},
  ...rest
}: Props) => {
  const [showCard, setShowCard] = useState(false);
  const {
    shipmentStatus,
    dispatch: { fullName, transportType, phoneNumber, email, profilePicture },
  } = shipmentToView;

  const orderStatus = [
    {
      label: "Accepted",
      isActive: shipmentStatus?.toLowerCase() === "accepted",
      isDone: ["picked-up", "delivered"].includes(shipmentStatus?.toLowerCase()),
    },
    {
      label: "Picked-up",
      isActive: shipmentStatus?.toLowerCase() === "picked-up",
      isDone: ["delivered"].includes(shipmentStatus?.toLowerCase()),
    },
    {
      label: "Delivered",
      isActive: shipmentStatus?.toLowerCase() === "delivered",
      isDone: ["delivered"].includes(shipmentStatus?.toLowerCase()),
    },
  ];

  const courierVehicleIcon = useMemo(
    () => COURIER_OPTIONS.find(i => i.value === transportType)?.icon || UserIcon,
    [transportType]
  );

  return (
    <>
      {!alwaysShow && (
        <IconButton
          display={showCard === false ? "flex" : "none"}
          aria-label=""
          onClick={() => setShowCard(true)}
          colorScheme="primary"
        >
          <AiOutlineEyeInvisible size="24px" />
        </IconButton>
      )}

      <Flex
        display={alwaysShow ? "flex" : showCard === true ? "flex" : "none"}
        transition="all 300ms ease"
        border="3px solid rgba(6, 0, 137, 0.10)"
        bgColor="#fff"
        borderRadius="20px"
        p={{ base: "16px 12px", md: "24px 24px 30px 24px" }}
        maxW={{ base: "intial", md: "550px", lg: "650px" }}
        w={{ md: "full" }}
        gap={4}
        justifyContent="space-between"
        {...rest}
      >
        <Flex flexDir="column" justifyContent="space-between" gap={8}>
          <Flex gap={4} align="center">
            <Avatar
              src={profilePicture}
              name={fullName}
              size={{ base: "md", md: "xl" }}
              borderRadius="20px"
            />

            <Box>
              <Heading
                color="#000"
                fontWeight="600"
                fontSize={{ base: "18px", md: "28px" }}
                lineHeight="17px"
                mb={[1, 1, 4]}
              >
                {fullName}
              </Heading>

              <SDTag>{shipmentToView?.orderStatus}</SDTag>
            </Box>
          </Flex>

          <SimpleGrid columns={3} gap={[3, 3, 5]} alignItems="flex-start" maxW="284px">
            {orderStatus.map((status, key) => (
              <Center gap={2} flexDir="column" key={key}>
                <UserIcon color={status.isActive ? "#000000" : "transparent"} />

                <Box
                  w="full"
                  border={status.isActive || status.isDone ? "3px solid #000" : "3px solid #AAAAAA"}
                  borderRadius="5px"
                />

                <Text
                  fontWeight="600"
                  fontSize={{ base: "10px", md: "12px" }}
                  lineHeight="17px"
                  color={status.isActive || status.isDone ? "#000" : "#AAAAAA"}
                >
                  {status.label}
                </Text>
              </Center>
            ))}
          </SimpleGrid>
        </Flex>

        <Flex flexDir="column" alignItems="flex-end" gap={6}>
          {alwaysShow ? (
            <RiCloseCircleLine size="24px" cursor="pointer" onClick={() => onCloseAction()} />
          ) : (
            <AiOutlineEye size="24px" cursor="pointer" onClick={() => setShowCard(false)} />
          )}

          <Flex flexDir="column" alignItems="flex-end" justify="space-between" gap={6} flex={1}>
            <Flex gap={1} align="center">
              <Icon color="#000000" as={courierVehicleIcon} />
              <Text
                color="#000000"
                fontWeight="600"
                fontSize={{ base: "12px", md: "16px" }}
                lineHeight="17px"
                textTransform="capitalize"
              >
                {transportType}
              </Text>
            </Flex>

            <Flex
              border="2px solid rgba(6, 0, 137, 0.1)"
              borderRadius="20px"
              p={{ base: "10px 12px", md: "10px 24px" }}
              gap={[6, 6, 12]}
              minH="60px"
              align="center"
            >
              <Link to={`mailto:${email}`}>
                <ChatIcon w={6} h={6} cursor="pointer" />
              </Link>
              <Link to={`tel:${phoneNumber}`}>
                <PhoneIcon w={6} h={6} cursor="pointer" />
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default CourierProfileCard;
