import { Icon } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";
interface Props {
  onClick?: () => void;
}

function RoundedBackButton({ onClick }: Props) {
  const navigate = useNavigate();

  return (
    <Icon
      as={IoArrowBackCircleOutline}
      boxSize={6}
      color="#040320"
      cursor="pointer"
      onClick={() => (onClick ? onClick() : navigate(-1))}
    />
  );
}

export default RoundedBackButton;
