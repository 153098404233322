import { Box, BoxProps, Heading } from "@chakra-ui/react";

interface IComponentItem extends BoxProps {
  title: string;
}

function ComponentItem({ title, children, ...rest }: IComponentItem) {
  return (
    <Box borderWidth={1} borderColor="secondary.900" borderRadius="md" {...rest}>
      <Heading
        borderTopRadius="md"
        fontSize="16px"
        py={2}
        fontWeight={900}
        bgColor="secondary.500"
        color="secondary.900"
        textAlign="center"
      >
        {title}
      </Heading>

      <Box p={4}>{children}</Box>
    </Box>
  );
}

export default ComponentItem;
