import { Box, BoxProps } from "@chakra-ui/react";

interface IGreyBox extends BoxProps {
  children: any;
}

function GreyBox({ children, ...rest }: IGreyBox) {
  return (
    <Box
      bgColor="rgba(7, 5, 41, 0.07)"
      borderRadius="20px"
      py={[8, 8, 8, 10]}
      px={[6, 6, 8, 12]}
      {...rest}
    >
      {children}
    </Box>
  );
}

export default GreyBox;
