import { handleError, handleSuccessMessage } from "services";
import apiClient from "services/axios";
import { store } from "redux/store";
import {
  saveBankLists,
  saveRevenueChart,
  saveWalletBalance,
} from "redux/slices/adminSlices/dashboardSlice";
import { IFundWalletDetails, IRevenueChartType, ITier1, ITier2, ITier3 } from "./types";
import { fetchProviderProfile } from "services/auth";
import { setUpQuery } from "utils/utilFunctions";

const { dispatch } = store;

export const fetchWalletBalance = async () => {
  try {
    const res = await apiClient.get(`provider/wallet-balance`);

    dispatch(saveWalletBalance(res?.data));

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleFundWallet = async (data: IFundWalletDetails, onSuccess: () => void) => {
  try {
    const res = await apiClient.post(`banking/fund-wallet`, data);

    onSuccess();

    fetchWalletBalance();

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const fetchBankLists = async () => {
  try {
    const res = await apiClient.get(`banking/bank-list`);

    dispatch(saveBankLists(res?.data));

    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleUpgradeTier1 = async (data: ITier1, onSuccess: () => void) => {
  try {
    const res = await apiClient.patch(`kyc/upgrade/1`, data);

    onSuccess();

    handleSuccessMessage(res);

    fetchProviderProfile();
    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleUpgradeTier2 = async (data: ITier2, onSuccess: () => void) => {
  try {
    const res = await apiClient.patch(`kyc/upgrade/2`, data);

    handleSuccessMessage(res);

    onSuccess();

    fetchProviderProfile();
    return res;
  } catch (error) {
    handleError(error);
  }
};

export const handleUpgradeTier3 = async (data: ITier3, onSuccess: () => void) => {
  try {
    const res = await apiClient.patch(`kyc/upgrade/3`, data);

    handleSuccessMessage(res);

    onSuccess();

    fetchProviderProfile();
    return res;
  } catch (error) {
    handleError(error);
  }
};

export const fetchRevenueChart = async (type: IRevenueChartType = "1D") => {
  try {
    const query = setUpQuery({ type });

    const res = await apiClient.get(`settlement/provider/revenue` + query);

    dispatch(saveRevenueChart(res?.data));

    return res;
  } catch (error) {
    handleError(error);
  }
};
