import { SDButton } from "..";
import { ButtonProps, IconButton } from "@chakra-ui/react";
import { BiFilter } from "react-icons/bi";

function SDFilterButton({ ...rest }: ButtonProps) {
  return (
    <>
      <SDButton
        leftIcon={<BiFilter />}
        variant="outline"
        colorScheme="gray"
        display={{ base: "none", md: "flex" }}
        {...rest}
      >
        Filter
      </SDButton>
      <IconButton
        icon={<BiFilter />}
        variant="outline"
        colorScheme="gray"
        aria-label="Filter"
        size="lg"
        display={{ base: "flex", md: "none" }}
        {...rest}
      />
    </>
  );
}

export default SDFilterButton;
