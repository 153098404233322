import { Box, Center, Text, useClipboard } from "@chakra-ui/react";
import { SDButton } from "components/global";
import { useEffect } from "react";
import { handleSuccessMessage } from "services";

function ShareLink() {
  const url = `${window.location.origin}/order`;

  const { onCopy, hasCopied } = useClipboard(url);

  useEffect(() => {
    if (hasCopied) {
      handleSuccessMessage("Copied!");
    }
  }, [hasCopied]);

  return (
    <Box flex={1} backgroundColor="#ffffff" border="1px solid #E5E4EF" p={4} borderRadius="10px">
      <Text fontSize={{ base: "12px", md: "16px" }} fontWeight={600}>
        Order Link
      </Text>

      <Text fontSize={{ base: "12px", lg: "16px" }} color="neutral.500">
        Share your Order link to your customers to effortlessly create orders to you.
      </Text>

      <Center bgColor="#07052912" mt={4} p={2}>
        <Text
          color="primary.500"
          fontWeight={700}
          fontSize={{ base: "12px", lg: "18px" }}
          wordBreak="break-word"
          textAlign="center"
        >
          {url}
        </Text>
      </Center>

      <Box mt={4}>
        <SDButton size={{ base: "sm", md: "md" }} colorScheme="primary" onClick={onCopy}>
          Copy
        </SDButton>
      </Box>
    </Box>
  );
}

export default ShareLink;
