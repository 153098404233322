import { SimpleGrid, useDisclosure, useUpdateEffect } from "@chakra-ui/react";
import { ProfilePicUpload, SDInput, SDSelect, SuccessModal } from "components/global";
import SDGooglePlacesAutoComplete from "components/global/Select/SDGooglePlacesAutoComplete";
import { useFormikContext, FormikValues } from "formik";
import { useState } from "react";
import { ICourier } from "redux/slices/adminSlices/courierSlice/types";
import { handleUpdateCourierProfilePic } from "services/admin/courier";
import { COURIER_TYPE_OPTIONS, GENDER_TYPE_OPTIONS } from "utils/constants";

interface Props {
  isFormForEdit?: boolean;
  courierToView?: ICourier;
}

function CourierForm({ isFormForEdit = false, courierToView }: Props) {
  const { values, touched, errors, handleBlur, handleChange, setFieldValue } =
    useFormikContext<FormikValues>();

  const successDisclosure = useDisclosure();
  const [isUploading, setIsUploading] = useState(false);

  async function updateCourierImage() {
    setIsUploading(true);

    const data = {
      profilePicture: values.profilePic?.url,
    };

    await handleUpdateCourierProfilePic(courierToView?._id, data, () => successDisclosure.onOpen());

    setIsUploading(false);
  }

  // Used to update the profile picture on edit mode only
  useUpdateEffect(() => {
    if (values.profilePic?.url && values.profilePic?.filename && isFormForEdit) {
      updateCourierImage();
    }
  }, [values.profilePic?.filename]);

  return (
    <>
      <ProfilePicUpload
        name="profilePic"
        error={touched.profilePic && errors.profilePic}
        value={values.profilePic}
        onFileChange={setFieldValue}
        isLoading={isUploading}
      />

      <SimpleGrid columns={[1, 1, 2]} columnGap={12} mt={8}>
        <SDInput
          id="name"
          type="text"
          label="Full Name"
          placeholder="Enter full name"
          value={values.name}
          onChange={handleChange}
          error={touched.name && errors.name}
          onBlur={handleBlur}
        />

        <SDSelect
          id="courierType"
          label="Courier Type"
          placeholder="Select a type"
          options={COURIER_TYPE_OPTIONS}
          value={values.courierType}
          onChange={setFieldValue}
          error={touched.courierType && errors.courierType}
          onBlur={handleBlur}
        />

        <SDInput
          id="phoneNumber"
          type="tel"
          label="Phone Number"
          placeholder="Enter phone number"
          value={values.phoneNumber}
          onChange={handleChange}
          error={touched.phoneNumber && errors.phoneNumber}
          onBlur={handleBlur}
        />

        <SDInput
          id="email"
          type="email"
          label="Email Address"
          placeholder="Enter email address"
          value={values.email}
          onChange={handleChange}
          error={touched.email && errors.email}
          onBlur={handleBlur}
          isReadOnly={isFormForEdit}
        />

        <SDSelect
          id="gender"
          label="Gender"
          placeholder="Select a gender"
          options={GENDER_TYPE_OPTIONS}
          value={values.gender}
          onChange={setFieldValue}
          error={touched.gender && errors.gender}
          onBlur={handleBlur}
        />

        <SDGooglePlacesAutoComplete
          id="address"
          label="Residential Address"
          placeholder="Search for a location"
          value={values.address}
          onChange={setFieldValue}
          error={touched.address && errors.address}
          onBlur={handleBlur}
        />

        <SDInput
          id="username"
          type="text"
          label="Username"
          placeholder="Enter username"
          value={values.username}
          onChange={e =>
            setFieldValue("username", e.target.value?.trim().replace(" ", "").toLowerCase())
          }
          error={touched.username && errors.username}
          onBlur={handleBlur}
          isReadOnly={isFormForEdit}
        />

        {isFormForEdit === false && (
          <SDInput
            id="password"
            type="password"
            label="Password"
            placeholder="••••••••••••••••"
            value={values.password}
            onChange={handleChange}
            error={touched.password && errors.password}
            onBlur={handleBlur}
          />
        )}
      </SimpleGrid>

      <SuccessModal
        isOpen={successDisclosure.isOpen}
        onClose={successDisclosure.onClose}
        message="Courier profile image updated successfully."
      />
    </>
  );
}

export default CourierForm;
