import Select, { StylesConfig } from "react-select";
import { Box, FormLabel, FormControl, useMediaQuery } from "@chakra-ui/react";
import { SDInputErrorMessage, SDInputHelperText } from "..";

interface SelectFieldProps {
  onChange: (id: string, selectedVal: string | IOptions) => void;
  onBlur?: (e: any) => void;
  placeholder?: string;
  error?: any;
  label?: string;
  showSearch?: boolean;
  options: IOptions[];
  value: any;
  id: string;
  clearable?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  isMulti?: boolean;
  isLoading?: boolean;
  helperText?: string | React.ReactNode;
  containerProps?: any;
  variant?: "grey";
  size?: "sm" | "md" | "lg";
  [rest: string]: any;
}

export interface IOptions {
  value: string | number | boolean;
  label: string | number | React.ReactElement;
  altLabel?: string;
}

function SDSelect({
  options,
  variant,
  placeholder,
  error,
  onChange,
  onBlur,
  label,
  showSearch,
  value,
  id,
  clearable,
  isDisabled,
  isRequired,
  isMulti,
  helperText,
  isLoading,
  containerProps,
  size = "lg",
  ...rest
}: SelectFieldProps) {
  const [isMobile] = useMediaQuery("(max-width: 550px)");

  const getMinHeight = () => {
    if (size === "sm") return "36px";

    if (size === "md") return "40px";

    if (size === "lg") return "48px";

    return "48px";
  };

  const customStyles: StylesConfig = {
    indicatorSeparator: provided => ({ display: "none" }),
    control: provided => ({
      ...provided,
      minWidth: rest?.minW || provided.minWidth,
      minHeight: getMinHeight(),
      paddingInline: "8px",
      borderRadius: "0.375rem",
      borderColor: variant === "grey" ? "#0600890D" : "#CBD5E0",
      backgroundColor: variant === "grey" ? "#0600890D" : rest?.bgColor || provided.backgroundColor,
      borderWidth: variant === "grey" ? "0" : "1px",
      ":hover": {
        ...provided[":hover"],
        borderColor: "#E2E8F0",
      },
    }),
    placeholder: provided => ({
      ...provided,
      color: "#ABA7A7",
      fontWeight: 450,
      whiteSpace: "nowrap",
      fontSize: isMobile ? "14px" : "16px",
    }),
    singleValue: provided => ({
      ...provided,
      color: rest?.color || provided.color,
      fontSize: isMobile ? "14px" : "16px",
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: rest?.indicatorColor || provided.color,
    }),
    input: provided => ({
      ...provided,
      autoComplete: "off",
    }),
  };

  function handleChange(selectedValue: any) {
    onChange(id, selectedValue);
  }

  return (
    <Box as={FormControl} my={4} {...containerProps}>
      {label && (
        <FormLabel fontSize="14px" fontWeight={700} color="neutral.500" mb={1}>
          {label}
        </FormLabel>
      )}
      <Select
        id={id}
        instanceId={id}
        placeholder={placeholder || ""}
        onChange={handleChange}
        options={options}
        value={value}
        isSearchable={showSearch}
        isClearable={clearable}
        isDisabled={isDisabled}
        required={isRequired}
        isMulti={isMulti}
        styles={customStyles}
        isLoading={isLoading}
        onBlur={onBlur}
      />

      {error && <SDInputErrorMessage error={error} />}

      {helperText && <SDInputHelperText helperText={helperText} />}
    </Box>
  );
}

export default SDSelect;
