import { useDisclosure } from "@chakra-ui/react";
import {
  PickupDetailsForm,
  DropoffDetailsForm,
  ConfirmOrderModal,
} from "components/admin/shipment";
import ClientEmailLoginModal from "components/client/dashboard/ClientEmailLoginModal";
import { MainLoader, SuccessModal } from "components/global";
import DashboardLayout from "components/global/Layouts/DashboardLayout";
import { IOptions } from "components/global/Select";
import { IUploadValue } from "components/global/Upload/FileUploadWrapper";
import { Formik, Form, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { selectClientProfile } from "redux/slices/authSlice";
import { useAppSelector } from "redux/store";
import { AllRoutes } from "routes/AllRoutes";
import { fetchProviderPricing } from "services/admin/settings";
import {
  handleGetShipmentFee,
  handleProviderCreateSingleOrder,
  handleInitiateOrderPayment,
} from "services/admin/shimpent";
import { IShipmentDetails } from "services/admin/shimpent/types";
import { IGoogleAddress, IPaymentMethod } from "types/global";
import {
  attachSubdomainPath,
  generateRandomNumbers,
  getValidSubdomain,
  isClientLoggedIn,
} from "utils/utilFunctions";
import validationSchema from "utils/validationSchema";

interface IFormValues {
  autoAssignToCourier: boolean;

  pricingId: string;

  customerName: string;
  customerPhoneNumber: string;
  pickupLocation: IGoogleAddress;
  customerPickupCode: string;
  scheduleForPickupDate: string;
  scheduleForPickupTime: string;
  image: IUploadValue;
  parcelDetails: {
    content: string;
    noOfContents: string;
    parcelType: IOptions;
  }[];

  recipientName: string;
  recipientPhoneNumber: string;
  dropOffLocation: IGoogleAddress;
  recipientDropOffCode: string;

  shipmentFee: number;
  processingFee: number;
  billingId: string;
  paymentMethod: IPaymentMethod;
}

function ClientCreateSingleOrderPage() {
  const clientProfile = useAppSelector(selectClientProfile);

  const [page, setPage] = useState<number>(1);

  const [isInitiatingPayment, setIsInitiatingPayment] = useState(false);
  const [newOrderId, setNewOrderId] = useState("");

  const navigate = useNavigate();

  const successModalDisclosure = useDisclosure();
  const confirmOrderDisclosure = useDisclosure();
  const getClientEmailDisclosure = useDisclosure();

  async function handleSubmit(values: IFormValues, actions: FormikHelpers<IFormValues>) {
    // Go To Step Two
    if (page === 1) {
      return setPage(2);
    }

    if (page === 2 && !isClientLoggedIn()) {
      return getClientEmailDisclosure.onOpen();
    }

    // Fetch the shipment fee
    if (page === 2 && confirmOrderDisclosure.isOpen === false) {
      const data = {
        pickId: values.pickupLocation?.value?.place_id,
        dropId: values.dropOffLocation?.value?.place_id,
      };

      return await handleGetShipmentFee(data, values.pricingId, res => {
        actions.setFieldValue("shipmentFee", res?.data?.baseCost);
        actions.setFieldValue("processingFee", res?.data?.processingFee);
        actions.setFieldValue("billingId", res?.data?._id);

        confirmOrderDisclosure.onOpen();
      });
    }

    // Create Shipment
    const data: IShipmentDetails = {
      pickUpDetails: {
        customerName: values.customerName,
        customerPhoneNumber: values.customerPhoneNumber,
        location: {
          placeId: values.pickupLocation?.value?.place_id,
          description: values.pickupLocation?.value?.description,
        },
        customerPickUpCode: values.customerPickupCode,
        scheduleForPickUp: {
          enterDate: values.scheduleForPickupDate,
          enterTime: values.scheduleForPickupTime,
        },
        // TODO: To be moved to parcelDetails section
        packageImage: values?.image?.url,
      },
      parcelDetails: values.parcelDetails.map(item => ({
        percelContents: item?.content,
        noOfContents: Number(item?.noOfContents),
        category: String(item?.parcelType?.value),
      })),
      dropOffDetails: {
        recipientName: values.recipientName,
        recipientPhoneNumber: values.recipientPhoneNumber,
        location: {
          placeId: values.dropOffLocation?.value?.place_id,
          description: values.dropOffLocation?.value?.description,
        },
        recipientDropOffCode: values.recipientDropOffCode,
      },
      paymentMethod: values.paymentMethod,
      originator: "customer",
    };

    await handleProviderCreateSingleOrder(data, values?.billingId, res => {
      setNewOrderId(res?.data?._id);

      confirmOrderDisclosure.onClose();
      successModalDisclosure.onOpen();
    });
  }

  async function handlePayNow() {
    setIsInitiatingPayment(true);

    await handleInitiateOrderPayment(
      newOrderId,
      attachSubdomainPath(getValidSubdomain(), AllRoutes.clientDashboard)
    );

    setIsInitiatingPayment(false);
  }

  useEffect(() => {
    fetchProviderPricing();
  }, []);

  return (
    <DashboardLayout
      role="client"
      pageTitle="Delivery Details"
      showBackButton={true}
      onBackBtnClick={() => (page === 1 ? navigate(-1) : setPage(page => page - 1))}
    >
      {isInitiatingPayment && <MainLoader />}

      <Formik
        initialValues={{
          autoAssignToCourier: false,

          pricingId: "",

          customerName: clientProfile?.fullName || "",
          customerPhoneNumber: clientProfile?.phoneNumber || "",
          pickupLocation: null,
          customerPickupCode: generateRandomNumbers(5),
          scheduleForPickupDate: "",
          scheduleForPickupTime: "",
          image: null,
          parcelDetails: [{ content: "", noOfContents: "", parcelType: null }],

          recipientName: "",
          recipientPhoneNumber: "",
          dropOffLocation: null,
          recipientDropOffCode: generateRandomNumbers(5),

          shipmentFee: 0,
          processingFee: 0,
          billingId: "",
          paymentMethod: "At Creation",
        }}
        validationSchema={
          page === 1
            ? validationSchema.providerPickupDetails
            : validationSchema.providerDropoffDetails
        }
        onSubmit={(values: IFormValues, actions) => handleSubmit(values, actions)}
      >
        {({ setFieldValue, values, submitForm }) => (
          <Form>
            {page === 1 && <PickupDetailsForm role="client" setPage={setPage} />}
            {page === 2 && <DropoffDetailsForm role="client" setPage={setPage} />}

            <ClientEmailLoginModal
              isOpen={getClientEmailDisclosure.isOpen}
              onClose={getClientEmailDisclosure.onClose}
              onSuccess={submitForm}
            />

            <ConfirmOrderModal
              role="client"
              isOpen={confirmOrderDisclosure.isOpen}
              onClose={() => {
                setFieldValue("shipmentFee", 0);
                confirmOrderDisclosure.onClose();
              }}
            />

            <SuccessModal
              isOpen={successModalDisclosure.isOpen}
              onClose={successModalDisclosure.onClose}
              message="Order created."
              btnText={values.paymentMethod === "At Creation" && "Proceed to payment"}
              onBtnClick={() =>
                values.paymentMethod === "At Creation"
                  ? handlePayNow()
                  : navigate(AllRoutes.clientDashboard)
              }
            />
          </Form>
        )}
      </Formik>
    </DashboardLayout>
  );
}

export default ClientCreateSingleOrderPage;
