import { Box, BoxProps } from "@chakra-ui/react";

interface IWhiteBox extends BoxProps {
  children: any;
}

function WhiteBox({ children, ...rest }: IWhiteBox) {
  return (
    <Box bgColor="#FFFFFF" borderRadius="20px" py={[8, 8, 8, 10]} px={[6, 6, 8, 12]} {...rest}>
      {children}
    </Box>
  );
}

export default WhiteBox;
