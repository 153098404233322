import { Text, Flex } from "@chakra-ui/react";
import { GreyBox, ReviewData, SDButton, SDModal } from "components/global";
import { conertKoboToNaira, formatAmount, formatDate } from "utils/utilFunctions";
import { CreditIcon } from "utils/customIcons";
import { ITransaction } from "redux/slices/adminSlices/transactionsSlice/types";
import { DownloadIcon } from "@chakra-ui/icons";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { TransactionReceipt } from ".";
import { IReviewDataProps } from "components/global/ReviewData";
import { useMemo } from "react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  transactionToView: ITransaction;
}

export interface IPreviewInfo {
  amount: string;
  date: string;
  data: IReviewDataProps[];
}

function TransactionDetailsModal({ isOpen, onClose, transactionToView }: Props) {
  const previewInfo: IPreviewInfo = useMemo(
    () => ({
      amount: formatAmount(
        conertKoboToNaira(transactionToView?.disburseLog?.fees_split?.subaccount)
      ),
      date: formatDate(transactionToView?.createdAt),
      data: [
        { title: "Status", value: transactionToView?.status, valueAsTag: true },
        { title: "Name", value: transactionToView?.name },
        { title: "Transaction Type", value: transactionToView?.type },
        { title: "Transaction Channel", value: transactionToView?.disburseLog?.channel },
        { title: "Bank name", value: transactionToView?.disburseLog?.authorization?.bank },
        {
          title: "Account number",
          value: `${transactionToView?.disburseLog?.authorization?.bin}****${transactionToView?.disburseLog?.authorization?.last4}`,
        },
        {
          title: "Amount",
          value: formatAmount(
            conertKoboToNaira(transactionToView?.disburseLog?.fees_split?.subaccount)
          ),
        },
        {
          title: "Reference",
          value: transactionToView?.reference,
          gap: 2,
          valueProps: { overflowWrap: "anywhere" },
          isVerticalRow: true,
        },
      ],
    }),
    [transactionToView]
  );

  return (
    <SDModal isOpen={isOpen} onClose={onClose} title="Transaction Details" size="lg">
      <Flex flexDir="column" gap={8}>
        <Flex gap={4} justify="space-between" align="center">
          <Flex gap={{ base: 1, md: 4 }} align="center">
            <CreditIcon w={{ base: 6, md: 8 }} h={{ base: 6, md: 8 }} />

            <Text
              color="neutral.700"
              fontWeight="600"
              fontSize={{ base: "14px", md: "18px" }}
              lineHeight={{ base: "14px", md: "29px" }}
            >
              {previewInfo?.amount}
            </Text>
          </Flex>

          <Text
            color="neutral.500"
            fontWeight="500"
            fontSize={{ base: "14px", md: "16px" }}
            lineHeight={{ base: "14px", md: "29px" }}
            textAlign="right"
          >
            {previewInfo?.date}
          </Text>
        </Flex>

        <GreyBox
          borderRadius="10px"
          px={{ base: "16px", md: "30px" }}
          py={{ base: "14px", md: "18px" }}
        >
          <Flex flexDir="column">
            {previewInfo?.data.map((props, key) => (
              <ReviewData key={key} {...props} />
            ))}
          </Flex>
        </GreyBox>

        <PDFDownloadLink
          document={<TransactionReceipt previewInfo={previewInfo} />}
          fileName={`${transactionToView?.reference}.pdf`}
        >
          {({ loading }) => (
            <SDButton
              colorScheme="primary"
              leftIcon={<DownloadIcon />}
              isLoading={loading}
              w="full"
            >
              Download receipt
            </SDButton>
          )}
        </PDFDownloadLink>
      </Flex>
    </SDModal>
  );
}

export default TransactionDetailsModal;
