import { Flex, SimpleGrid, Text, useDisclosure } from "@chakra-ui/react";
import { CreatePayrollModal } from "components/admin/payroll";
import { SDButton, SDCurrencyInput, SDInput, SDSelect, WhiteBox } from "components/global";
import DashboardLayout from "components/global/Layouts/DashboardLayout";
import { useFormik } from "formik";
import {
  PAYROLL_COMMISSION_FREQUENCY_TYPE_OPTIONS,
  PAYROLL_SALARY_FREQUENCY_TYPE_OPTIONS,
  PAYROLL_TYPE_OPTIONS,
} from "utils/constants";

interface IFormValues {
  payrollType: {
    label: string;
    value: string;
  } | null;
  payrollSalaryFrequency: {
    label: string;
    value: string;
  } | null;
  payrollCommissionFrequency: {
    label: string;
    value: string;
  } | null;
  amount: string;
  percentage: string;
}

function CreatePayrollPage() {
  const { isOpen: isModal, onOpen: openModal, onClose: closeModal } = useDisclosure();

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    isSubmitting,
    handleSubmit,
  } = useFormik({
    initialValues: {
      payrollType: PAYROLL_TYPE_OPTIONS[0],
      payrollSalaryFrequency: null,
      payrollCommissionFrequency: null,
      amount: "",
      percentage: "",
    },
    // TODO: add validation
    // validationSchema: validationSchema.providerAddNewEmployee,
    onSubmit: async (values: IFormValues, action) => {
      openModal();
      //   const data = {
      //     name: values.name,
      //     email: values.email,
      // bankCode: values.bank?.value,
      // bankName: values.bank?.label,
      // bankAccountNumber: values.accountNumber,
      //     role: values.role?.value,
      //   };
      //   await handleAddEmployee(data, res => {
      //   action.resetForm();
      //     onClose()
      //   });
    },
  });

  return (
    <DashboardLayout
      role="provider"
      pageTitle="Create Payroll"
      isGreyBackground={true}
      showBackButton={true}
    >
      <WhiteBox>
        <Text mb="20px" fontSize={{ base: "16px", md: "22px" }} fontWeight="600" textAlign="center">
          Create Payment Method
        </Text>

        <form onSubmit={handleSubmit} onBlur={handleBlur}>
          <SimpleGrid
            columns={{ base: 1, md: 3 }}
            gap={{ base: 0, md: 10, lg: 20 }}
            alignItems="flex-end"
          >
            <SDSelect
              id="payrollType"
              label="Select an option"
              placeholder="Select an option"
              options={PAYROLL_TYPE_OPTIONS}
              value={values.payrollType}
              onChange={setFieldValue}
              error={touched.payrollType && errors.payrollType}
            />

            {values.payrollType?.value === "salary" ? (
              <SDSelect
                id="payrollSalaryFrequency"
                label="Select an option"
                placeholder="Select an option"
                options={PAYROLL_SALARY_FREQUENCY_TYPE_OPTIONS}
                value={values.payrollSalaryFrequency}
                onChange={setFieldValue}
                error={touched.payrollSalaryFrequency && errors.payrollSalaryFrequency}
              />
            ) : (
              <SDSelect
                id="payrollCommissionFrequency"
                label="Select an option"
                placeholder="Select an option"
                options={PAYROLL_COMMISSION_FREQUENCY_TYPE_OPTIONS}
                value={values.payrollCommissionFrequency}
                onChange={setFieldValue}
                error={touched.payrollCommissionFrequency && errors.payrollCommissionFrequency}
              />
            )}

            {values.payrollType?.value === "salary" ||
            (values.payrollType?.value === "commission" &&
              values.payrollCommissionFrequency?.value === "flat") ? (
              <SDCurrencyInput
                id="amount"
                value={String(values.amount)}
                onChange={values => setFieldValue("amount", values.value)}
                error={touched.amount && errors.amount}
                label="How much per week/month"
                placeholder="Enter an amount"
                addOnElementBefore="₦"
                hidePrefix={true}
              />
            ) : null}

            {values.payrollType?.value === "commission" &&
              values.payrollCommissionFrequency?.value !== "flat" && (
                <SDInput
                  id="percentage"
                  type="number"
                  label="Percentage"
                  placeholder="Enter a percentage"
                  addOnElementBefore="%"
                  max={100}
                  value={values.percentage}
                  onChange={e => Number(e.target.value) <= 100 && handleChange(e)}
                  error={touched.percentage && errors.percentage}
                />
              )}
          </SimpleGrid>

          <Flex justify={{ md: "flex-end" }}>
            <SDButton
              type="submit"
              w={{ base: "full", md: "auto" }}
              colorScheme="primary"
              mt={8}
              isLoading={isSubmitting}
            >
              Create Payroll
            </SDButton>
          </Flex>
        </form>
      </WhiteBox>

      <CreatePayrollModal isOpen={isModal} onClose={closeModal} />
    </DashboardLayout>
  );
}

export default CreatePayrollPage;
