import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IShipment } from "redux/slices/adminSlices/shipmentSlice/types";
import { RootState } from "redux/store";
import { PaginatedResponse } from "types/global";

// Define a type for the slice state
interface InitialState {
  orderHistoryLoading: boolean;
  orderHistory: PaginatedResponse<IShipment[]>;
  recentOrderHistory: PaginatedResponse<IShipment[]>;
  activeOrderHistoryStatus: string;
}

// Define the initial state using that type
const initialState: InitialState = {
  orderHistoryLoading: false,
  orderHistory: { records: [], pagination: null },
  recentOrderHistory: { records: [], pagination: null },
  activeOrderHistoryStatus: "",
};

export const orderHistorySlice = createSlice({
  name: "orderHistoryReducer",
  initialState,
  reducers: {
    saveOrderHistoryLoadingState: (state, action: PayloadAction<boolean>) => {
      state.orderHistoryLoading = action.payload;
    },

    saveOrderHistory: (state, action: PayloadAction<PaginatedResponse<IShipment[]>>) => {
      state.orderHistory = action.payload;
    },

    saveRecentOrderHistory: (state, action: PayloadAction<PaginatedResponse<IShipment[]>>) => {
      state.recentOrderHistory = action.payload;
    },

    saveActiveOrderHistoryStatus: (state, action: PayloadAction<string>) => {
      state.activeOrderHistoryStatus = action.payload;
    },
  },
});

export const {
  saveOrderHistoryLoadingState,
  saveOrderHistory,
  saveRecentOrderHistory,
  saveActiveOrderHistoryStatus,
} = orderHistorySlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectOrderHistoryLoadingState = (state: RootState) =>
  state.orderHistoryReducer.orderHistoryLoading;
export const selectOrderHistory = (state: RootState) => state.orderHistoryReducer.orderHistory;
export const selectRecentOrderHistory = (state: RootState) =>
  state.orderHistoryReducer.recentOrderHistory;
export const selectActiveOrderHistoryStatus = (state: RootState) =>
  state.orderHistoryReducer.activeOrderHistoryStatus;

// Reducer
export const orderHistoryReducer = orderHistorySlice.reducer;
