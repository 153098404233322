import { Flex } from "@chakra-ui/react";
import {
  // BalanceCard,
  ShareLink,
  RevenueChart,
  // DeliveriesInTransit,
  RecentOrders,
  SetupAccountCTA,
} from "components/admin/dashboard";
import { ShipmentStats } from "components/admin/shipment";
import DashboardLayout from "components/global/Layouts/DashboardLayout";

function DashboardPage() {
  return (
    <DashboardLayout role="provider" pageTitle="Dashboard Overview" documentTitle="Dashboard">
      <Flex flexDir="column" gap="48px">
        <SetupAccountCTA />

        <ShipmentStats />

        <Flex flexDir={{ base: "column", md: "row" }} gap="24px" align="stretch" w="full">
          {/* <BalanceCard /> */}

          <ShareLink />
        </Flex>

        <RevenueChart />

        {/* <DeliveriesInTransit /> */}

        <RecentOrders />
      </Flex>
    </DashboardLayout>
  );
}

export default DashboardPage;
