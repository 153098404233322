import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import { shipmentReducer } from "redux/slices/adminSlices/shipmentSlice";
import { customerReducer } from "redux/slices/adminSlices/customerSlice";
import { authReducer } from "redux/slices/authSlice";
import { dashboardReducer } from "redux/slices/adminSlices/dashboardSlice";
import { courierReducer } from "redux/slices/adminSlices/courierSlice";
import { settingsReducer } from "redux/slices/adminSlices/settingsSlice";
import { orderHistoryReducer } from "redux/slices/clientSlices/orderHistorySlice";
import { transactionReducer } from "redux/slices/adminSlices/transactionsSlice";

const appReducers = combineReducers({
  // AUTH REDUCERS
  authReducer: authReducer,

  // ADMIN REDUCERS
  dashboardReducer: dashboardReducer,
  shipmentReducer: shipmentReducer,
  customerReducer: customerReducer,
  courierReducer: courierReducer,
  transactionReducer: transactionReducer,
  settingsReducer: settingsReducer,

  // CLIENT REDUCERS
  orderHistoryReducer: orderHistoryReducer,
});

export const store = configureStore({
  reducer: appReducers,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
