import {
  Box,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  Icon,
  Flex,
  StepDescription,
  Heading,
  StepTitle,
  StepSeparator,
  Text,
  ComponentWithAs,
  IconProps,
} from "@chakra-ui/react";

interface Props {
  steps: {
    sideTitle?: string;
    title: string;
    description: string | React.ReactNode;
    icon: ComponentWithAs<"svg", IconProps>;
  }[];
  showBorder?: boolean;
}

function SDVerticalStepper({ steps, showBorder = false }: Props) {
  return (
    <Stepper index={steps.length} orientation="vertical" colorScheme="primary" gap="0">
      {steps.map((step, index) => (
        <Step key={index} style={{ width: "100%" }}>
          <StepIndicator>
            <StepStatus complete={<Icon as={step.icon} />} />
          </StepIndicator>

          <Flex
            gap={10}
            flexShrink="0"
            w="full"
            flex={1}
            mb={steps.length - 1 === index ? 0 : showBorder ? 6 : 14}
            border={showBorder ? "1px solid #0600891A" : "none"}
            borderRadius="10px"
            p={showBorder ? { base: 4, md: 6 } : 0}
          >
            {step?.sideTitle && (
              <Text
                as={StepDescription}
                color="neutral.200"
                fontSize={{ base: "14px", md: "16px" }}
              >
                {step.sideTitle}
              </Text>
            )}

            <Box flex={1}>
              <Heading
                as={StepTitle}
                fontSize={{ base: "16px", md: "18px" }}
                fontWeight={500}
                mb={showBorder ? 6 : 2}
              >
                {step.title}
              </Heading>

              {typeof step.description === "string" ? (
                <Text
                  as={StepDescription}
                  color="neutral.200"
                  fontSize={{ base: "14px", md: "16px" }}
                >
                  {step.description}
                </Text>
              ) : (
                step.description
              )}
            </Box>
          </Flex>

          <StepSeparator style={{ background: "#ABA7A7", width: "1px" }} />
        </Step>
      ))}
    </Stepper>
  );
}

export default SDVerticalStepper;
