import { Flex, Text } from "@chakra-ui/react";
import { SDButton, SDInput, SDSelect } from "components/global";
import AuthLayout from "components/global/Layouts/AuthLayout";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { AllRoutes } from "routes/AllRoutes";
import { GENDER_TYPE_OPTIONS } from "utils/constants";
interface IFormValues {
  fullName: string;
  email: string;
  gender: {
    label: string;
    value: string;
  } | null;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
}

function ClientSignUpPage() {
  const navigate = useNavigate();

  const {
    values,
    errors,
    handleBlur,
    touched,
    handleChange,
    setFieldValue,
    isSubmitting,
    handleSubmit,
  } = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      gender: null,
      phoneNumber: "",
      password: "",
      confirmPassword: "",
    },
    onSubmit: async (values: IFormValues) => {
      console.log(values);

      navigate(AllRoutes.verifyEmail("email"));
    },
  });

  return (
    <AuthLayout
      role="client"
      title="Sign up"
      description="Experience a faster, easier and more reliable way to move items from one location to another on Sendrail"
    >
      <form onSubmit={handleSubmit} onBlur={handleBlur}>
        <SDInput
          variant="grey"
          id="fullName"
          type="text"
          label="Full name"
          placeholder="Enter your name"
          value={values.fullName}
          onChange={handleChange}
          error={touched.fullName && errors.fullName}
        />

        <SDInput
          variant="grey"
          id="email"
          type="email"
          label="Email"
          placeholder="example@gmail.com"
          value={values.email}
          onChange={handleChange}
          error={touched.email && errors.email}
        />

        <SDSelect
          variant="grey"
          id="gender"
          label="Gender"
          placeholder="Select a gender"
          options={GENDER_TYPE_OPTIONS}
          value={values.gender}
          onChange={setFieldValue}
          error={touched.gender && errors.gender}
        />

        <SDInput
          variant="grey"
          id="phoneNumber"
          type="text"
          label="Phone number"
          placeholder="Enter your phone number"
          value={values.phoneNumber}
          onChange={handleChange}
          error={touched.phoneNumber && errors.phoneNumber}
        />

        <SDInput
          variant="grey"
          id="password"
          type="password"
          label="Password"
          placeholder="••••••••••••••••"
          value={values.password}
          onChange={handleChange}
          error={touched.password && errors.password}
        />

        <SDInput
          variant="grey"
          id="confirmPassword"
          type="password"
          label="Confirm password"
          placeholder="••••••••••••••••"
          value={values.confirmPassword}
          onChange={handleChange}
          error={touched.confirmPassword && errors.confirmPassword}
        />

        <SDButton type="submit" colorScheme="primary" mt={10} w="full" isLoading={isSubmitting}>
          Sign up
        </SDButton>

        <Flex
          flexDir={{ base: "column", md: "row" }}
          gap={4}
          align="center"
          justify="space-between"
          mt={6}
        >
          <Text>
            Already have an account?{" "}
            <Link to={AllRoutes.clientLogin}>
              <Text as="span" fontWeight={700}>
                Sign in
              </Text>
            </Link>
          </Text>
        </Flex>
      </form>
    </AuthLayout>
  );
}

export default ClientSignUpPage;
