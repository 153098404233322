import DashboardLayout from "components/global/Layouts/DashboardLayout";
import { SDTabs } from "components/global";
import {
  ProfileSettings,
  BusinessInfoSettings,
  SecuritySettings,
  BusinessPreferencesSettings,
  PricingSettings,
  // AccountLimitsSettings,
  BankInfoSettings,
} from "components/admin/settings";

const SettingsPage = () => {
  return (
    <DashboardLayout role="provider" pageTitle="Settings" isGreyBackground={true}>
      <SDTabs
        tabs={[
          { label: "Profile", content: <ProfileSettings /> },
          { label: "Business Information", content: <BusinessInfoSettings /> },
          { label: "Bank Information", content: <BankInfoSettings /> },
          // { label: "Account Limits", content: <AccountLimitsSettings /> },
          { label: "Security", content: <SecuritySettings /> },
          { label: "Business Preferences", content: <BusinessPreferencesSettings /> },
          { label: "Pricing", content: <PricingSettings /> },
        ]}
      />
    </DashboardLayout>
  );
};

export default SettingsPage;
