import { EmptyState, SDTabs, WhiteBox } from "components/global";
import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { AllRoutes } from "routes/AllRoutes";
import {
  AddInterStatePricing,
  AddLastMilePricing,
  EditInterStatePricing,
  EditLastMilePricing,
  // InterStateLists,
  LastMileLists,
} from "..";
import { fetchProviderPricing } from "services/admin/settings";

const PricingSettings = () => {
  const [searchParams] = useSearchParams();
  const pageToView = useMemo(() => searchParams.get("p") || "", [searchParams]);

  useEffect(() => {
    fetchProviderPricing();
  }, []);

  return (
    <>
      {!pageToView ? (
        <WhiteBox>
          <SDTabs
            tabs={[
              {
                label: "Last mile",
                content: <LastMileLists />,
              },
              {
                label: "Inter-state delivery",
                content: (
                  <EmptyState variant="sm" title="Nothing to see here" message="Coming soon" />
                ),
                // content: <InterStateLists />,
              },
              {
                label: "International",
                content: (
                  <EmptyState variant="sm" title="Nothing to see here" message="Coming soon" />
                ),
              },
            ]}
            tabListProps={{ w: { md: "fit-content" }, mx: { md: "auto" } }}
          />
        </WhiteBox>
      ) : (
        <>
          {AllRoutes.providerAddLastMilePricing.includes(pageToView) && <AddLastMilePricing />}

          {AllRoutes.providerEditLastMilePricing("").includes(pageToView) && (
            <EditLastMilePricing />
          )}

          {AllRoutes.providerAddInterStatePricing.includes(pageToView) && <AddInterStatePricing />}

          {AllRoutes.providerEditInterStatePricing("").includes(pageToView) && (
            <EditInterStatePricing />
          )}
        </>
      )}
    </>
  );
};

export default PricingSettings;
