import { useDisclosure, Heading, SimpleGrid, Box } from "@chakra-ui/react";
import {
  WhiteBox,
  ProfilePicUpload,
  SDInput,
  SDButton,
  SuccessModal,
  SDSelect,
} from "components/global";
import DashboardLayout from "components/global/Layouts/DashboardLayout";
import { IUploadValue } from "components/global/Upload/FileUploadWrapper";
import { Formik, Form } from "formik";
import { selectClientProfile } from "redux/slices/authSlice";
import { useAppSelector } from "redux/store";
import { handleClientUpdateProfile } from "services/client/profile";
import { GENDER_TYPE_OPTIONS } from "utils/constants";
import validationSchema from "utils/validationSchema";

interface IFormValues {
  profilePic: IUploadValue;
  name: string;
  email: string;
  gender: { label: string; value: string };
  phoneNumber: string;
}

function ClientProfilePage() {
  const clientProfile = useAppSelector(selectClientProfile);

  const successDisclosure = useDisclosure();

  async function handleSubmit(values: IFormValues) {
    const data = {
      avatar: values.profilePic?.url,
      fullName: values.name,
      gender: values.gender?.value,
      phoneNumber: values.phoneNumber,
      nonUserEntity: clientProfile?.nonUserEntity,
    };

    await handleClientUpdateProfile(data, () => {
      successDisclosure.onOpen();
    });
  }

  return (
    <DashboardLayout role="client" pageTitle="Profile" isGreyBackground>
      <>
        <WhiteBox>
          <Box maxW="1000px" mx="auto">
            <Heading color="neutral.500" fontWeight={600} fontSize="20px" mb={8}>
              Personal Information
            </Heading>

            <Formik
              initialValues={{
                profilePic: clientProfile?.avatar
                  ? {
                      filename: clientProfile?.fullName,
                      url: clientProfile?.avatar,
                    }
                  : null,
                name: clientProfile?.fullName,
                email: clientProfile?.email,
                phoneNumber: clientProfile?.phoneNumber,
                gender:
                  GENDER_TYPE_OPTIONS.find(
                    item => item.value.toLowerCase() === clientProfile?.gender?.toLowerCase()
                  ) || null,
              }}
              validationSchema={validationSchema.clientEditProfile}
              onSubmit={values => handleSubmit(values)}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                setFieldValue,
                handleChange,
                isSubmitting,
              }) => (
                <Form onBlur={handleBlur}>
                  <ProfilePicUpload
                    name="profilePic"
                    error={touched.profilePic && errors.profilePic}
                    value={values.profilePic}
                    onFileChange={setFieldValue}
                  />

                  <SimpleGrid columns={[1, 1, 2]} columnGap={12} mt={8}>
                    <SDInput
                      id="name"
                      type="text"
                      label="Full Name"
                      placeholder="Enter full name"
                      value={values.name}
                      onChange={handleChange}
                      error={touched.name && errors.name}
                    />

                    <SDSelect
                      id="gender"
                      label="Gender"
                      placeholder="Select a gender"
                      options={GENDER_TYPE_OPTIONS}
                      value={values.gender}
                      onChange={setFieldValue}
                      error={touched.gender && errors.gender}
                    />

                    <SDInput
                      id="email"
                      type="email"
                      label="Email Address"
                      placeholder="Enter email address"
                      defaultValue={values.email}
                      isReadOnly={true}
                    />

                    <SDInput
                      id="phoneNumber"
                      type="tel"
                      label="Phone Number"
                      placeholder="Enter phone number"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      error={touched.phoneNumber && errors.phoneNumber}
                    />
                  </SimpleGrid>

                  <SDButton mt="48px" colorScheme="primary" type="submit" isLoading={isSubmitting}>
                    Save Changes
                  </SDButton>
                </Form>
              )}
            </Formik>
          </Box>
        </WhiteBox>

        <SuccessModal
          isOpen={successDisclosure.isOpen}
          onClose={successDisclosure.onClose}
          message="Changes applied."
        />
      </>
    </DashboardLayout>
  );
}

export default ClientProfilePage;
