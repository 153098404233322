import { handleError } from "services";
import apiClient from "services/axios";
import { IClientProfileDetails } from "./type";
import { fetchClientProfile } from "services/auth";

export const handleClientUpdateProfile = async (
  data: IClientProfileDetails,
  onSuccess: () => void
) => {
  try {
    const res = await apiClient.patch(`customer/me`, data);

    fetchClientProfile();

    onSuccess();

    return res;
  } catch (error) {
    handleError(error);
  }
};
