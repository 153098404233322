import { Box, Divider, Flex, Heading, Radio, RadioGroup, Text } from "@chakra-ui/react";
import { SDButton, SDModal } from "components/global";
import { FormikValues, useFormikContext } from "formik";
import { formatAmount, isRideHailingTenant } from "utils/utilFunctions";
import { PAYMENT_METHOD_OPTIONS } from "utils/constants";
import { useAppSelector } from "redux/store";
import { selectTenantProfile } from "redux/slices/authSlice";
import { IUserType } from "types/global";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  role: IUserType;
}

function ConfirmOrderModal({ onClose, isOpen, role }: Props) {
  const tenantProfile = useAppSelector(selectTenantProfile);

  const { values, submitForm, isSubmitting, setFieldValue } = useFormikContext<FormikValues>();

  return (
    <SDModal
      isOpen={isOpen}
      onClose={onClose}
      title={isRideHailingTenant() ? "Ride Confirmation" : "Order Confirmation"}
      size="sm"
    >
      <Flex flexDir="column" gap={8} mx={0}>
        {role === "provider" ? (
          <>
            <Flex align="center" justify="space-between" gap={4}>
              <Text fontWeight={500} color="neutral.500">
                Order Payment
              </Text>

              <Text fontWeight={500} color="neutral.500">
                {formatAmount(values.shipmentFee)}
              </Text>
            </Flex>

            <Flex align="center" justify="space-between" gap={4}>
              <Text fontWeight="500" color="neutral.500">
                Processing Fees
              </Text>

              <Text fontWeight={500} color="neutral.500">
                {formatAmount(values.processingFee)}
              </Text>
            </Flex>

            <Flex align="center" justify="space-between" gap={4}>
              <Text fontWeight={700} color="neutral.500">
                Total
              </Text>

              <Text fontWeight={700} color="neutral.500">
                {formatAmount(values.shipmentFee + values.processingFee)}
              </Text>
            </Flex>
          </>
        ) : (
          <Flex align="center" justify="space-between" gap={4}>
            <Text fontWeight={700} color="neutral.500">
              {isRideHailingTenant() ? "Ride Payment" : "Order Payment"}
            </Text>

            <Text fontWeight={700} color="neutral.500">
              {formatAmount(values.shipmentFee + values.processingFee)}
            </Text>
          </Flex>
        )}

        <Divider />

        {tenantProfile?.preference?.allowPayOnDelivery && (
          <>
            <Box>
              <Heading fontSize="18px" fontWeight={500} mb={4}>
                Select payment method
              </Heading>

              <RadioGroup
                colorScheme="primary"
                size="md"
                color="neutral.500"
                defaultValue={values.paymentMethod}
                onChange={value => setFieldValue("paymentMethod", value)}
              >
                <Flex flexDir="column" gap={4} mx={0}>
                  {PAYMENT_METHOD_OPTIONS.map((item, key) => (
                    <Radio key={key} value={item?.value}>
                      {item?.label}
                    </Radio>
                  ))}
                </Flex>
              </RadioGroup>
            </Box>

            <Divider />
          </>
        )}

        <SDButton
          colorScheme="primary"
          w="full"
          mt={8}
          isLoading={isSubmitting}
          onClick={() => submitForm()}
          isDisabled={!values.paymentMethod}
        >
          Confirm
        </SDButton>
      </Flex>
    </SDModal>
  );
}
export default ConfirmOrderModal;
